<script>
  import { push, replace } from "svelte-spa-router";
  import Loading from "../../components/Loading.svelte";
  import { onMount } from "svelte";
  import { urlApi } from "../../utils/const";
  import { parseJwt } from "../../utils/utils";

  export let params;

  let id = params["_id"];

  let loadingPage;
  let members = [];
  let answers = [];

  onMount(() => {
    loadMember();
  });

  let token = parseJwt(localStorage.getItem("token"));

  async function loadMember() {
    loadingPage.toggle();
    const res = await fetch(urlApi + "/Learning/" + id + "/Member", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const resData = await res.json();
    members = resData.data.cellGroupMembers;
    answers = resData.data.answerMembers;
    loadingPage.toggle();
  }
</script>

<Loading bind:this={loadingPage} />

<section class="container p-4">
  <div class="sm:flex sm:items-center sm:justify-between">
    <div>
      <div class="flex items-center gap-x-3">
        <button
          on:click={() => {
            replace("/learning/");
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-lg font-medium text-gray-800">Member Submission</h2>
      </div>
    </div>
  </div>
  <div class="flex flex-col space-y-5 mt-10">
    {#each members as member}
      <div class="flex justify-between items-center px-4">
        <p class="font-semibold text-sm">{member.userName}</p>
        <button
          on:click={() => {
            if (
              answers.findIndex((e) => e.userID == member.userID) != -1 &&
              answers[answers.findIndex((e) => e.userID == member.userID)]["status"] != 0
            ) {
              if (token.RoleID != 4) {
                push("/learning/" + id + "/lesson/" + member.userID);
              }
            }
          }}
          class="inline px-3 py-1 text-xs font-normal rounded-full {answers.findIndex((e) => e.userID == member.userID) != -1 &&
          answers[answers.findIndex((e) => e.userID == member.userID)]['status'] != 2
            ? 'text-emerald-500 bg-emerald-100/60'
            : answers.findIndex((e) => e.userID == member.userID) == -1 || answers[answers.findIndex((e) => e.userID == member.userID)]['status'] == 0
            ? 'text-gray-500 bg-gray-100/50'
            : 'text-red-500 bg-red-100/60'} gap-x-2"
        >
          {answers.findIndex((e) => e.userID == member.userID) == -1 || answers[answers.findIndex((e) => e.userID == member.userID)]["status"] == 0
            ? "Belum ada Submission"
            : answers[answers.findIndex((e) => e.userID == member.userID)]["status"] != 2
            ? "Lihat Submission"
            : "Submission Overdue"}
        </button>
      </div>
    {/each}
  </div>
</section>
