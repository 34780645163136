<script>
  import { field } from "svelte-forms";
  import { required } from "svelte-forms/validators";
  import Textarea from "../../components/control/Textarea.svelte";
  import Select from "../../components/control/Select.svelte";
  import { pop, replace } from "svelte-spa-router";
  import Cookies from "js-cookie";
  import { EDIT_ONE_MODULE, urlApi } from "../../utils/const";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import { checkRoleModule, parseJwt } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import { onMount } from "svelte";
  import Rating from "../../components/control/Rating.svelte";
  import Loading from "../../components/Loading.svelte";
  import CollapsibleSection from "../../components/control/CollapsibleSection.svelte";

  let date = field("date", new Date(), [required()]);
  let partner = field("partner", "", [required()]);
  let note = field("note", "", [required()]);
  let prayNote = field("prayNote", "", [required()]);
  let duration = field("duration", "", [required()]);
  let effective = field("effective", 0, [required()]);
  let joy = field("joy", 0, [required()]);
  let meeting = field("meeting", 0, [required()]);

  export let params;

  let id = params["_id"];
  let type = params["type"];
  const token = parseJwt(localStorage.getItem("token"));
  let userID = token.UserID;
  let roleID = token.RoleID;
  let ownerID = "";
  let ownerRoleID = "";

  let loadingQuestion;
  let spiritualQuestion = [];
  let spiritualAnswers = [];

  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";

  let errors = [],
    partners = [];

  onMount(() => {
    loadData();
    if (type == "Spiritual Conversation") {
      loadQuestionData();
    }
    loadUser();
  });

  async function loadQuestionData() {
    loadingQuestion.toggle();
    const res = await fetch(urlApi + "/Ones/SpiritualConversationQuestion", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    spiritualQuestion = data["data"];
    loadAnswer();
    loadingQuestion.toggle();
  }

  async function loadAnswer() {
    const res = await fetch(urlApi + "/Ones/SpiritualConversationAnswer/" + params["_id"], {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();

    for (var i = 0; i < spiritualQuestion.length; i++) {
      spiritualAnswers.push({ child: [] });
      for (var j = 0; j < spiritualQuestion[i]["child"].length; j++) {
        spiritualAnswers[i]["child"].push({ child: [] });
        for (var k = 0; k < spiritualQuestion[i]["child"][j]["child"].length; k++) {
          const foundAnswer = data["data"].find((e) => e.id == spiritualQuestion[i]["child"][j]["child"][k].id);
          foundAnswer
            ? spiritualAnswers[i]["child"][j]["child"].push({
                id: spiritualQuestion[i]["child"][j]["child"][k].id,
                answer: foundAnswer.answer,
              })
            : spiritualAnswers[i]["child"][j]["child"].push({
                id: spiritualQuestion[i]["child"][j]["child"][k].id,
                answer: "",
              });
        }
      }
    }
    spiritualAnswers = spiritualAnswers;
  }

  async function loadUser() {
    loadingQuestion.toggle();
    const res = await fetch(urlApi + "/Users?PerPage=9999&showAll=1", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();

    if (data["data"]) {
      partners = data["data"].map((e) => ({ id: e["id"], name: e["name"] }));
    }
    loadingQuestion.toggle();
  }

  async function loadData() {
    const res = await fetch(urlApi + "/Ones/" + params["_id"], {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    if (data["data"]) {
      $date.value = new Date(data["data"]["date"]);
      $partner.value = data["data"]["partner"];
      $note.value = data["data"]["note"];
      $prayNote.value = data["data"]["prayNote"];
      $duration.value = data["data"]["duration"];
      $effective.value = parseInt(data["data"]["effective"]);
      $joy.value = parseInt(data["data"]["joy"]);
      $meeting.value = data["data"]["meeting"];
      ownerID = data["data"]["userID"];
      ownerRoleID = data["data"]["roleID"];
    }
    if (userID != ownerID || roleID != ownerRoleID) replace("/ones/");
  }
</script>

<Loading bind:this={loadingQuestion} />

<section class="container px-4 py-4">
  <div class="flex items-center justify-between">
    <div class="w-full flex items-center justify-between">
      <div class="flex-1 flex items-center gap-x-3">
        <button
          on:click={() => {
            pop();
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-lg font-medium text-gray-800">Rincian {type}</h2>
      </div>
      {#if checkRoleModule($roleModules, EDIT_ONE_MODULE) && userID == ownerID}
        <div class="flex items-center justify-end">
          <button
            on:click={() => replace("/ones/" + id + "/" + type + "/edit")}
            class="text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 md:smt-4 text-center transition-all"
            >Edit
          </button>
        </div>
      {/if}
    </div>
  </div>

  <div class="mt-5 flex flex-col space-y-4">
    <div class={mobileClass}>
      <div class="flex-1">
        <DatePicker
          name="date"
          type="text"
          label="Tanggal"
          labelSize="sm"
          textSize="sm"
          extClass="font-semibold"
          disabled
          bind:errors
          bind:value={$date.value}
        />
      </div>
    </div>

    <div class={mobileClass}>
      <div class="flex-1">
        <Select
          on:change={(e) => {
            $partner.value = parseInt(e.detail);
          }}
          textHint="Pilih Rekan"
          fullWidth
          name="partner"
          extClass="font-semibold"
          label="Rekan"
          textSize="text-sm"
          options={partners}
          disabled
          bind:errors
          bind:selected={$partner.value}
        />
      </div>
    </div>

    <div class="flex items-center mb-4">
      <input
        id="meeting-check"
        type="checkbox"
        value="1"
        checked={$meeting.value == 1}
        on:change={(e) => {
          if ($meeting.value == 0) {
            $meeting.value = 1;
          } else {
            $meeting.value = 0;
          }
        }}
        disabled
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer focus:ring-transparent ring-transparent"
      />
      <label for="default-checkbox" class="ml-2 text-sm font-medium cursor-pointer text-gray-500">Saya yang membuat meeting</label>
    </div>

    {#if type == "Spiritual Conversation"}
      {#each spiritualQuestion as question, i}
        <CollapsibleSection headerText={question.title}>
          {#each question.child as sub, ix}
            <div class="font-semibold text-sm mb-2">{sub.subTitle}</div>
            {#each sub.child as child, index}
              <div class="flex-1 mb-2">
                {#if spiritualAnswers.length > 0}
                  <Textarea
                    bind:errors
                    name={child.id}
                    label={child.name}
                    labelBold={false}
                    disabled
                    placeholder="Ketik jawaban disini"
                    bind:value={spiritualAnswers[i].child[ix].child[index].answer}
                  />
                {/if}
              </div>
            {/each}
          {/each}
        </CollapsibleSection>
      {/each}
    {/if}

    <div class="flex space-x-2">
      <div class="flex-1">
        <Textarea bind:errors name="note" label="Catatan {type}" placeholder="Sharing apa aja?" disabled bind:value={$note.value} />
      </div>
    </div>

    {#if type == "121"}
      <div class="flex space-x-2">
        <div class="flex-1">
          <Textarea bind:errors name="prayNote" label="Catatan Doa" disabled bind:value={$prayNote.value} />
        </div>
      </div>
    {/if}

    <!-- <div class={mobileClass}>
      <div class="flex-1">
        <Input
          name="duration"
          type="number"
          label="Durasi (menit)"
          suffix
          suffixText="menit"
          disabled
          bind:errors
          bind:value={$duration.value}
        />
      </div>
    </div> -->

    <h4 class="font-semibold text-gray-700">Polling Yuk!</h4>

    <div class={mobileClass}>
      <div class="flex-1">
        <Rating label="Efektifitas 121" name="effective" disabled bind:value={$effective.value} />
      </div>

      <div class="flex-1 pt-6 md:pt-0">
        <Rating label="Joyful Feeling" name="joy" disabled bind:value={$joy.value} />
      </div>
    </div>
    <div class="my-5" />
  </div>
</section>
