<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let data;
  export let page = 1;
</script>

<table class="min-w-full divide-y divide-gray-200">
  <thead class="bg-gray-100">
    <tr>
      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500"> No. </th>

      <th scope="col" class="py-3.5 px-12 text-sm font-normal text-left rtl:text-right text-gray-500"> Nama </th>

      <th scope="col" class="py-3.5 pr-48 text-sm font-normal text-left rtl:text-right text-gray-500"> Role </th>

      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500"> Aksi </th>
    </tr>
  </thead>
  <tbody class="bg-white divide-y divide-gray-200">
    {#if data.length == 0}
      <tr>
        <td colspan="7" class="px-4 py-4 text-sm font-medium">
          <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
        </td>
      </tr>
    {/if}
    {#each data as item, index}
      <tr>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {page > 1 ? ((index + 1).toString().length > 1 ? index + 1 + 10 * (page - 1) : (page - 1).toString() + (index + 1)) : index + 1}
          </h2>
        </td>
        <td class="px-12 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["userName"]}
          </h2>
        </td>
        <td class="pr-48 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["roleName"]}
          </h2>
        </td>

        <td class="px-4 py-4 text-sm whitespace-normal">
          {#if item["status"]}
            <button
              on:click={() => dispatch("action", item["id"])}
              class="px-3 py-2 text-white bg-blue-400 text-xs font-medium transition-colors duration-200 rounded-lg w-32"
            >
              Request Removal
            </button>
          {:else}
            <button
              on:click={() => dispatch("action", item["id"])}
              class="px-3 py-2 text-white bg-red-400 text-xs font-medium transition-colors duration-200 rounded-lg w-32 break-words"
            >
              Dismiss Removal Request
            </button>
          {/if}
        </td>
      </tr>
    {/each}
  </tbody>
</table>
