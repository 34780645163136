export const attendanceStatus = {
  Aktif: 1,
  Done: 2,
  Batal: 0,
};

export const attendType = {
  None: 0,
  Onsite: 1,
  Online: 2,
};
