<script>
  import { field, form } from "svelte-forms";
  import { required } from "svelte-forms/validators";
  import { replace } from "svelte-spa-router";
  import CellGroupForm from "./CellGroupForm.svelte";
  import { urlApi, ADD_CELLGROUP_MODULE } from "../../utils/const";
  import { parseJwt, formatDate, checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import { onMount } from "svelte";
  import Modal from "../../components/Modal.svelte";
  import AuthServices from "../Auth/AuthServices";
  import Loading from "../../components/Loading.svelte";

  const token = parseJwt(localStorage.getItem("token"));
  let access = token.RoleID;
  let id = 0;
  let loading;
  let cellGroupMemberList = [];
  let covenantYearSelected = field("covenantYearSelected", "", [required()]);
  let dateCreated = field("dateCreated", new Date(), [required()]);
  let headGembalaSelected;
  let gembalaSelected;
  let pGembalaSelected;
  let memberError = false;
  let name = field("name", "", [required()]);
  let wakilGembalaError = false;
  let pWakilGembalaError = false;
  let wakilGembalaSelected;
  let pWakilGembalaSelected;
  let status = true;

  let errors = [];
  let modalBack;
  let addCellGroupForm;
  let disableSubmit = false;

  onMount(() => {
    if (!checkRoleModule($roleModules, ADD_CELLGROUP_MODULE)) {
      replace("/cellgroup/");
    }
  });

  async function addCellGroup() {
    loading.toggle();
    disableSubmit = true;
    errors = [];

    let headGembalaSelectedValid = field("headGembalaSelected", headGembalaSelected ? headGembalaSelected["id"] : "", [required()]);
    let gembalaSelectedValid = field("gembalaSelected", gembalaSelected ? gembalaSelected["id"] : "", [required()]);
    let wakilGembalaSelectedValid = field("wakilGembalaSelected", wakilGembalaSelected ? wakilGembalaSelected["id"] : "", [required()]);

    addCellGroupForm = form(covenantYearSelected, dateCreated, headGembalaSelectedValid, gembalaSelectedValid, wakilGembalaSelectedValid, name);

    await addCellGroupForm.validate();
    if ($addCellGroupForm.valid && !wakilGembalaError && !memberError && !pWakilGembalaError) {
      let cellGroupMembers = cellGroupMemberList.map(function (row) {
        return { userID: row.id };
      });
      const reqData = {
        CellGroupMembers: cellGroupMembers,
        CovenantYearID: $covenantYearSelected.value ? $covenantYearSelected.value : 0,
        DateCreated: formatDate($dateCreated.value),
        GembalaID: gembalaSelected ? parseInt(gembalaSelected["id"]) : 0,
        HeadGembalaID: headGembalaSelected ? parseInt(headGembalaSelected["id"]) : 0,
        Name: $name.value,
        PendampingGembalaID: pGembalaSelected ? parseInt(pGembalaSelected["id"]) : 0,
        PendampingWakilGembalaID: pWakilGembalaSelected ? parseInt(pWakilGembalaSelected["id"]) : 0,
        WakilGembalaID: wakilGembalaSelected ? parseInt(wakilGembalaSelected["id"]) : 0,
        IsActive: status,
      };

      const res = await fetch(urlApi + "/CellGroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(reqData),
      });
      const data = await res.json();
      if (data["data"]["id"]) {
        const tokenData = await AuthServices.refreshToken();
        localStorage.setItem("token", tokenData["data"]["token"]);
        localStorage.setItem("tokenRole", tokenData["data"]["tokenRole"]);
        globalThis.pushToast("Berhasil Menambahkan Sel Group", "success");
        setTimeout(() => replace("/cellgroup/"), 1000);
      }
    } else {
      errors = $addCellGroupForm.errors;
      // errors = errors;
      disableSubmit = false;
    }
    loading.toggle();
  }
</script>

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin ingin keluar dari laman ini? data yang Anda masukkan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/cellgroup/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>
<Loading bind:this={loading} />
<section class="container px-4 py-4">
  <CellGroupForm
    formTitle="Buat Sel Group"
    mode="add"
    bind:cellGroupMemberList
    bind:concernedID={id}
    bind:covenantYearSelected={$covenantYearSelected.value}
    bind:dateCreated={$dateCreated.value}
    bind:disableSubmit
    bind:gembalaSelected
    bind:pGembalaSelected
    bind:pWakilSelected={pWakilGembalaSelected}
    bind:headGembalaSelected
    bind:memberError
    bind:name={$name.value}
    bind:wakilGembalaSelected
    bind:wakilGembalaError
    bind:pWakilGembalaError
    bind:status
    bind:errors
    on:back={() => modalBack.toggle()}
    on:save={() => addCellGroup()}
  />
</section>
