<script>
  import { createEventDispatcher } from "svelte";
  import { attendanceStatus, attendType } from "../../pages/Attendance/AttendanceConst";
  const dispatch = createEventDispatcher();

  export let data;
  export let mode = "default";
  export let status = "";

  function resolveAttendTypeName(value) {
    if (value == attendType.None) return "-";
    else if (value == attendType.Onsite) return "On Site";
    else if (value == attendType.Online) return "Online";
  }
</script>

<table class="min-w-full divide-y divide-gray-200">
  <thead class="bg-gray-100">
    <tr>
      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left text-gray-500"> No. </th>

      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left text-gray-500"> Nama </th>

      {#if mode != "detail" && mode != "attendance"}
        <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Action </th>
      {/if}
      {#if mode == "attendance"}
        <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Survey </th>
        <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Alasan Tidak Hadir </th>
        {#if status == attendanceStatus.Aktif}
          <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Kehadiran </th>
        {/if}
        <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Hadir </th>
      {/if}
    </tr>
  </thead>
  <tbody class="bg-white divide-y divide-gray-200">
    {#if data.length == 0}
      <tr>
        <td colspan="6" class="px-4 py-4 text-sm font-medium">
          <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
        </td>
      </tr>
    {/if}
    {#each data as item, index}
      <tr>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {index + 1}
          </h2>
        </td>

        <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["name"]}
          </h2>
        </td>
        {#if mode != "detail" && mode != "attendance"}
          <td class="px-4 text-sm font-medium whitespace-nowrap">
            <button
              on:click={() => dispatch("delete", item)}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-trash text-xl text-red-500" />
            </button>
          </td>
        {/if}

        {#if mode == "attendance"}
          <td class="px-4 text-sm font-medium whitespace-nowrap">
            <h2 class="font-medium text-gray-800 text-xs">
              {item["isAttend"] ? "Hadir" : "Tidak Hadir"}
            </h2>
          </td>
          <td class="px-4 text-sm font-medium whitespace-nowrap">
            <h2 class="font-medium text-gray-800 text-xs">
              {item["note"] && item["isAttend"] == 0 ? item["note"] : "-"}
            </h2>
          </td>
          {#if status == attendanceStatus.Aktif}
            <td class="px-4 text-sm font-medium whitespace-nowrap">
              <input
                type="checkbox"
                checked={item["isAttend"]}
                on:click={() => {
                  dispatch("verifyAttendance", item);
                  item = item;
                }}
              />
            </td>
            <td class="px-4 py-2 text-sm font-medium whitespace-nowrap flex flex-col">
              <div class="flex items-center">
                <input
                  type="radio"
                  class="cursor-pointer mr-2 disabled:opacity-50 {item['isAttend'] == 1 && item['attendTypeID'] == attendType.None
                    ? 'border border-red-500'
                    : ''}"
                  id="onsite-{item['id']}"
                  disabled={item["isAttend"] == 0}
                  checked={item["isAttend"] == 1 && item["attendTypeID"] == attendType.Onsite}
                  on:input={() => {
                    dispatch("verifyAttendanceType", { item: item, value: 1 });
                    item = item;
                  }}
                />
                <label for="onsite-{item['id']}" class={item["isAttend"] == 0 ? "opacity-50" : ""}>On Site</label>
              </div>
              <div class="flex items-center">
                <input
                  type="radio"
                  class="cursor-pointer mr-2 disabled:opacity-50 {item['isAttend'] == 1 && item['attendTypeID'] == attendType.None
                    ? 'border border-red-500'
                    : ''}"
                  id="online-{item['id']}"
                  disabled={item["isAttend"] == 0}
                  checked={item["isAttend"] == 1 && item["attendTypeID"] == attendType.Online}
                  on:input={() => {
                    dispatch("verifyAttendanceType", { item: item, value: 2 });
                    item = item;
                  }}
                />
                <label for="online-{item['id']}" class={item["isAttend"] == 0 ? "opacity-50" : ""}>Online</label>
              </div>
            </td>
          {:else}
            <td class="px-4 text-sm font-medium whitespace-nowrap">
              <h2 class="font-medium text-gray-800 text-xs">
                {resolveAttendTypeName(item["attendTypeID"])}
              </h2>
            </td>
          {/if}
        {/if}
      </tr>
    {/each}
  </tbody>
</table>
