<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let value;
  export let label = "";
  export let name = "";
  export let errors = [];
  export let disabled = false;

   $: if (errors.length > 0) {
        errors = errors
            .filter((err) => err.split(".")[0] == name)
            .map((e) => ({
                field: e.split(".")[0],
                error: e.split(".")[1],
            }));
        errors = errors;
    }
</script>

<p class="text-sm font-semibold text-gray-700 mb-2">{label}</p>
<div class="relative mb-4">
  <div class="star-wrapper">
    <button
      on:click={(e) => (!disabled ? (value = 5) : null)}
      class="bx bxs-star s1 {value >= 5 ? 'text-secondary' : 'text-gray-200'}"
    />
    <button
      on:click={(e) => (!disabled ? (value = 4) : null)}
      class="bx bxs-star s2 {value >= 4 ? 'text-secondary' : 'text-gray-200'}"
    />
    <button
      on:click={(e) => (!disabled ? (value = 3) : null)}
      class="bx bxs-star s3 {value >= 3 ? 'text-secondary' : 'text-gray-200'}"
    />
    <button
      on:click={(e) => (!disabled ? (value = 2) : null)}
      class="bx bxs-star s4 {value >= 2 ? 'text-secondary' : 'text-gray-200'}"
    />
    <button
      on:click={(e) => (!disabled ? (value = 1) : null)}
      class="bx bxs-star s5 {value >= 1 ? 'text-secondary' : 'text-gray-200'}"
    />
  </div>
  {#if errors.length > 0 && errors[0]["error"] == "required"}
    <p class="text-xs text-red-500 p-0 absolute top-6">{label} harus diisi</p>
  {/if}
</div>

<style>
  .star-wrapper {
    display: flex;
    justify-content: flex-start;
    direction: rtl;
    position: absolute;
  }
  .star-wrapper button {
    font-size: 21px;
    text-decoration: none;
    transition: all 0.5s;
    margin: 1px;
  }
  .star-wrapper button:hover {
    color: #efc222;
    transform: scale(1.3);
  }
  .s1:hover ~ button {
    color: #efc222;
  }
  .s2:hover ~ button {
    color: #efc222;
  }
  .s3:hover ~ button {
    color: #efc222;
  }
  .s4:hover ~ button {
    color: #efc222;
  }
  .s5:hover ~ button {
    color: #efc222;
  }
</style>
