<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let data;
</script>

<div class="card-data min-w-full mb-3 p-2 border border-primary shadow rounded-md">
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <div class="flex-1 text-left">
      <p class="flex-1 text-left font-bold">{data["userName"]}</p>
      <p class="flex-1 text-left">{data["roleName"]}</p>
    </div>
    <!-- <p class="flex-1 text-left">{data["userName"]}</p> -->
    <p class="flex-1 line-clamp-2 text-right">
      {#if data["status"]}
        <button
          on:click={() => dispatch("action", data["id"])}
          class="px-3 py-2 text-white bg-blue-400 text-xs font-medium transition-colors duration-200 rounded-lg w-32"
        >
          Request Removal
        </button>
      {:else}
        <button
          on:click={() => dispatch("action", data["id"])}
          class="px-3 py-2 text-white bg-red-400 text-xs font-medium transition-colors duration-200 rounded-lg w-32 break-words"
        >
          Dismiss Removal Request
        </button>
      {/if}
    </p>
  </div>
</div>
