<script>
  import { link, replace } from "svelte-spa-router";
  import { onMount } from "svelte";
  import { form, field } from "svelte-forms";
  import { required, email } from "svelte-forms/validators";
  import { urlApi } from "../../utils/const";
  import { parseJwt } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import Input from "../../components/control/Input.svelte";
  import Loading from "../../components/Loading.svelte";
  let errors = [];
  export let params;

  let emails = field("emails", localStorage.getItem("email"), [required(), email()], {
    checkOnInit: false,
    validateOnChange: true,
  });

  let password = field("password", "", [required()]);
  let loginForm = form(emails, password);
  let token;

  let loading;
  let rememberMe = localStorage.getItem("remember") == "ya" ? true : false ?? false;
  onMount(async () => {
    if (localStorage.getItem("token") != null && localStorage.getItem("token") != "" && localStorage.getItem("token") != undefined) {
      setTimeout(() => replace("/"));
    } else if (params && params["_token"]) {
      token = params["_token"];
      await verifyEmail();
    }
  });
  async function login() {
    errors = [];
    loading.toggle();
    await loginForm.validate();
    if ($loginForm.valid) {
      const reqData = {
        email: $emails.value,
        password: $password.value,
      };

      const res = await fetch(urlApi + "/Authorization/Login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqData),
      });

      const data = await res.json();
      if (data["data"]["errorMessage"]) {
        loading.toggle();
        globalThis.pushToast(data["data"]["errorMessage"], "error");
      } else if (data["data"]["token"]) {
        localStorage.setItem("token", data["data"]["token"]);
        localStorage.setItem("tokenRole", data["data"]["tokenRole"]);
        const tokenRoleModule = parseJwt(data["data"]["tokenRoleModule"]);
        roleModules.set(tokenRoleModule.RolesModule);
        localStorage.setItem("imageSrc", data["data"]["userData"]["imageSrc"]);
        localStorage.setItem("lastCheck", new Date());
        localStorage.setItem("email", data["data"]["userData"]["email"]);
        localStorage.setItem("name", data["data"]["userData"]["name"]);
        loading.toggle();
        globalThis.pushToast("Berhasil Login", "success");
        setTimeout(() => replace("/users/"), 1000);
      }
    } else {
      errors = $loginForm.errors;
      errors = errors;
      loading.toggle();
    }

    if (rememberMe) {
      localStorage.setItem("email", $emails.value);
      localStorage.setItem("remember", "ya");
    } else {
      localStorage.setItem("remember", "");
    }
  }
  async function verifyEmail() {
    loading.toggle();
    const reqData = {
      token: token,
    };
    const res = await fetch(urlApi + "/Authorization/VerifyEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    });
    const data = await res.json();
    if (data["data"] == 1) {
      globalThis.pushToast("Email berhasil diverifikasi, silahkan login kembali", "success");
    } else if (data["data"] == 0) {
      globalThis.pushToast("Verifikasi Email Gagal atau Email sudah diverifikasi", "error");
    }
    loading.toggle();
  }
</script>

<Loading bind:this={loading} />
<h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 mb-6 md:text-2xl">Login</h1>
<div class="w-full bg-white rounded-lg shadow border border-gray-100 md:mt-0 sm:max-w-md xl:p-0">
  <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
    <div class="space-y-4 md:space-y-6">
      <form class="space-y-4 md:space-y-6" on:submit|preventDefault={async () => login}>
        <Input name="emails" type="email" bind:value={$emails.value} placeholder="Masukkan email" bind:errors label="Email" />
        <Input name="password" type="password" bind:value={$password.value} placeholder="••••••••" bind:errors label="Password" withEye />
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-2 text-sm font-medium text-gray-600">
            <input
              class="cursor-pointer outline-none focus:ring-transparent"
              type="checkbox"
              on:change={() => {
                rememberMe = !rememberMe;
              }}
              value="ya"
              checked={rememberMe}
            />
            <span>Remember Me</span>
          </div>
          <a use:link href="/reset-password" class="text-sm font-medium text-gray-600 hover:underline">Lupa password?</a>
        </div>
        <button
          type="submit"
          on:click|preventDefault={async () => login()}
          class="w-full text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center transition-all"
          >Login</button
        >
      </form>
      <p class="text-sm font-light text-center text-gray-400">
        Belum Punya Akun? <a use:link href="/register" class="font-medium text-gray-600 hover:underline">Daftar Sekarang</a>
      </p>
    </div>
  </div>
</div>
