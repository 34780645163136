<script>
  import { field, form } from "svelte-forms";
  import Input from "../../components/control/Input.svelte";
  import { email, required } from "svelte-forms/validators";
  import { phoneMinLength } from "../../utils/validation";
  import { pop, replace } from "svelte-spa-router";
  import { onMount } from "svelte";
  import Cookies from "js-cookie";
  import { departmentOptionData, roleOptionData, urlApi } from "../../utils/const";
  import { formatDate, parseJwt } from "../../utils/utils";
  import Modal from "../../components/Modal.svelte";
  import UserForm from "../../components/form/UserForm.svelte";
  import Loading from "../../components/Loading.svelte";

  export let params;

  const token = parseJwt(localStorage.getItem("token"));
  let access = token.RoleID;

  let emails = field("emails", "", [required(), email()], {
    checkOnInit: false,
    validateOnChange: true,
  });

  let fullName = field("fullName", "", [required()]);
  let address = field("address", "", []);
  let note = field("note", "", []);
  let phone = field("phone", "", []);
  let roleSelected = field("roleSelected", "", []);
  // let spvSelected = field("spvSelected", "", []);
  let companionSelected = field("companionSelected", "", []);
  let departmentSelected = field("departmentSelected", "", []);

  let baptismalName = field("baptismalName", "", []);
  let base64Image = field("base64Image", "", []);
  let cellGroupSelected = field("cellGroupSelected", "", []);
  // let covenantYearSelected = field("covenantYearSelected", "", []);
  let dateOfBirth = field("dateOfBirth", new Date(), []);
  let educationSelected = field("educationSelected", "", []);
  let imageFileID = field("imageFileID", "", []);
  let imageSrc = field("imageSrc", "", []);
  let job = field("job", "", []);
  let nickname = field("nickname", "", []);
  let parokiChurch = field("parokiChurch", "", []);
  let placeOfBirth = field("placeOfBirth", "", []);

  let wakilID = 0,
    gembalaID = 0,
    headGembalaID = 0;

  let status = true;
  let id = params["_id"];
  let spvOption = [];
  let errors = [];
  let loading;
  let disableSubmit = false;

  let userForm;

  onMount(() => {
    loadData();
  });

  async function loadData() {
    loading.toggle();
    const res = await fetch(urlApi + "/Users/" + params["_id"], {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    if (data["data"]) {
      status = data["data"]["isActive"] == 1 ? true : false;
      $roleSelected.value = data["data"]["rolesID"].toString();
      $fullName.value = data["data"]["name"];
      $emails.value = data["data"]["email"];
      $phone.value = data["data"]["phoneNumber"];
      $address.value = data["data"]["address"];
      // isReadOnly = access == data["data"]["rolesID"] && loggedInID != data["data"]["id"];
      $baptismalName.value = data["data"]["baptismalName"];
      $cellGroupSelected.value = data["data"]["cellGroupID"].toString();
      // $covenantYearSelected.value = data["data"]["covenantYearID"].toString();
      $dateOfBirth.value = data["data"]["dateOfBirth"] != null ? new Date(data["data"]["dateOfBirth"]) : new Date();
      $educationSelected.value = data["data"]["education"].toString();
      $imageFileID.value = data["data"]["imageFileID"].toString();
      $imageSrc.value = data["data"]["imageSrc"].toString();
      $job.value = data["data"]["job"];
      $nickname.value = data["data"]["nickname"];
      $parokiChurch.value = data["data"]["parokiChurch"];
      $placeOfBirth.value = data["data"]["placeOfBirth"];
      $companionSelected.value = data["data"]["companionID"].toString();
      // if (data["data"]["supervisorID"] != 0) {
      //   $spvSelected.value = data["data"]["supervisorID"].toString();
      //   spvOption.push({
      //     id: data["data"]["supervisorID"].toString(),
      //     name: data["data"]["supervisorName"],
      //   });
      // } else {
      //   $spvSelected.value = "";
      // }
      $departmentSelected.value = data["data"]["department"];
      $note.value = data["data"]["note"];
    }
    loading.toggle();
  }

  async function editUser() {
    disableSubmit = true;
    loading.toggle();
    errors = [];
    roleSelected = field("roleSelected", $roleSelected.value, access == 5 ? [required()] : []);

    //validation for 10 digit only if phone has value and it is not admin
    phone = field("phone", $phone.value, $phone.value != "" && access != 5 ? [phoneMinLength(9)] : []);

    companionSelected = field(
      "companionSelected",
      $companionSelected.value,
      $roleSelected.value == "12" || $roleSelected.value == 13 ? [required()] : [],
    );
    // const spvSelect = spvOption.filter((e) => e["id"] == $spvSelected.value);

    // if ($roleSelected.value != "1" && $roleSelected.value != "" && $spvSelected.value != "") {
    //   wakilID = $roleSelected.value == "4" ? $spvSelected.value : spvSelect[0]["wakilGembalaID"];
    //   gembalaID = $roleSelected.value == "3" ? $spvSelected.value : spvSelect[0]["gembalaID"];
    //   headGembalaID = $roleSelected.value == "2" ? $spvSelected.value : spvSelect[0]["headGembalaID"];
    // }

    userForm = form(emails, fullName, phone, departmentSelected, roleSelected);
    await userForm.validate();

    if ($userForm.valid) {
      const reqData = {
        ID: id,
        FullName: $fullName.value,
        Email: $emails.value,
        PhoneNumber: $phone.value,
        Department: $departmentSelected.value,
        companionID: $companionSelected.value ? $companionSelected.value : null,
        WakilGembalaID: wakilID,
        GembalaID: gembalaID,
        HeadGembalaID: headGembalaID,
        RoleID: parseInt($roleSelected.value),
        Address: $address.value,
        Note: $note.value,
        IsActive: status ? 1 : 0,
        BaptismalName: $baptismalName.value,
        Base64Image: $base64Image.value,
        CellGroupID: $cellGroupSelected.value,
        DateOfBirth: formatDate($dateOfBirth.value),
        Education: $educationSelected.value,
        ImageFileID: $imageFileID.value,
        ImageSrc: $imageSrc.value,
        Job: $job.value,
        Nickname: $nickname.value,
        ParokiChurch: $parokiChurch.value,
        PlaceOfBirth: $placeOfBirth.value,
      };
      const res = await fetch(urlApi + "/Users/Edit", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(reqData),
      });

      const data = await res.json();
      if (data["data"]["errorMessage"]) {
        if (data["data"]["errorMessage"] == "Email telah terdaftar") {
          errors.push("emails.duplicate");
        } else {
          errors.push("fullName.duplicate");
        }
        errors = errors;
        disableSubmit = false;
      } else if (data["data"]["id"]) {
        if (data["data"]["id"] == token.UserID) localStorage.setItem("imageSrc", data["data"]["imageSrc"]);
        globalThis.pushToast("Berhasil Memperbarui Pengguna", "success");
        setTimeout(() => replace("/users/"), 1000);
      }
    } else {
      errors = $userForm.errors;
      disableSubmit = false;
    }
    loading.toggle();
  }

  let modalBack;
</script>

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin ingin keluar dari laman ini? data yang Anda masukkan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/users/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>

<section class="container px-4 py-4">
  <Loading bind:this={loading} />
  <UserForm
    {access}
    formTitle="Edit Pengguna"
    bind:address={$address.value}
    bind:baptismalName={$baptismalName.value}
    bind:base64Image={$base64Image.value}
    bind:concernedID={params["_id"]}
    bind:cellGroupSelected={$cellGroupSelected.value}
    bind:dateOfBirth={$dateOfBirth.value}
    bind:departmentSelected={$departmentSelected.value}
    bind:disableSubmit
    bind:educationSelected={$educationSelected.value}
    bind:emails={$emails.value}
    bind:errors
    bind:fullName={$fullName.value}
    bind:imageSrc={$imageSrc.value}
    bind:job={$job.value}
    bind:nickname={$nickname.value}
    bind:note={$note.value}
    bind:phone={$phone.value}
    bind:parokiChurch={$parokiChurch.value}
    bind:placeOfBirth={$placeOfBirth.value}
    bind:roleSelected={$roleSelected.value}
    bind:status
    on:back={() => modalBack.toggle()}
    on:save={() => editUser()}
    mode="edit"
  />
</section>
