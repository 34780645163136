import { urlApi } from "../../utils/const";
import Cookies from "js-cookie";

export default {
  async loadCovenantYear(option) {
    const res = await fetch(urlApi + "/CellGroup/CovenantYear?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },

  async loadCellGroupList(option) {
    const res = await fetch(urlApi + "/CellGroup?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },

  async requestRegistryCellGroup(data) {
    const res = await fetch(urlApi + "/CellGroup/Member", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    });
    return res.json();
  },

  async loadCellGroupMemberList(option) {
    const res = await fetch(urlApi + "/CellGroup/Member?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },

  async updateStatusMember(data) {
    const res = await fetch(urlApi + "/CellGroup/Member", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    });
    return res.json();
  },

  async getCellGroupByID(id) {
    const res = await fetch(urlApi + "/CellGroup/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },

  async editCellGroup(data) {
    const res = await fetch(urlApi + "/CellGroup", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    });
    return res.json();
  },
  async checkMemberConflict(option) {
    const res = await fetch(urlApi + "/CellGroup/Member/CheckConflict?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },

  async checkWakilGembalaConflict(option) {
    const res = await fetch(urlApi + "/CellGroup/WakilGembala/CheckConflict?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },
  async checkPendampingWakilGembalaConflict(option) {
    const res = await fetch(urlApi + "/CellGroup/PendampingWakilGembala/CheckConflict?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },
  resolveCellGroupMemberKeterangan(status, covenantYear) {
    if (status == 1) return "-";
    else if (status == 0) return "Removal request by Wakil Gembala";
    else if (status == 3) return " Member sudah terdaftar di Sel Group lain dalam " + covenantYear;
  },
};
