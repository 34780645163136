import { urlApi } from "../../utils/const";
import { formatDateForView, formatDate, parseJwt } from "../../utils/utils";
import { utils, writeFileXLSX } from "xlsx";
import { attendanceStatus } from "./AttendanceConst";
const percentageColumnWidth = [
  { wch: 40 },
  { wch: 40 }, //Attendance Pertemuan Sel (%)
  { wch: 40 },
  { wch: 40 }, //Attendance Community Gathering (%)
  { wch: 40 },
];
const supervisorColumnWidth = [{ wch: 20 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 25 }, { wch: 20 }, { wch: 30 }, { wch: 10 }, { wch: 30 }];

function resolveAttendancePercentage(hadirCount, attendanceCount) {
  if (attendanceCount <= 0) return "0%";
  const result = (hadirCount / attendanceCount) * 100;
  return result > 100 ? "100%" : result + "%";
}

function resolveAttendanceCount(hadirCount, attendanceCount) {
  return `Hadir : ${hadirCount}, Tidak Hadir : ${attendanceCount - hadirCount}`;
}

function resolveSupervisorRoleName(roleID) {
  if (roleID == 1) return "Head Gembala";
  else if (roleID == 2) return "Gembala";
  else if (roleID == 12) return "Pendamping Gembala";
  else if (roleID == 3) return "Wakil Gembala";
  else if (roleID == 13) return "Pendamping Wakil Gembala";
}
function mapSupervisorData(data, roleID) {
  const dataToMap = data.filter(function (el) {
    return el.roleID == roleID;
  });
  const dataToMapLength = dataToMap.length;
  if (dataToMapLength <= 0) {
    const emptyObject = {};
    dataToMap.push(emptyObject);
  }
  let supervisorCreatorColumn = "Nama " + resolveSupervisorRoleName(roleID);
  let jsonSupervisor = dataToMap.map((item) => ({
    Tanggal: dataToMapLength > 0 ? formatDateForView(new Date(item.date)) : "",
    [supervisorCreatorColumn]: item.supervisorName,
    "Completed By": item.completedBy != "" ? item.completedBy : "-",
    "Nama Attendance": item.attendanceName,
    "Kategori Attendance": item.category,
    "Survey Kehadiran": dataToMapLength > 0 ? String(item.surveyAttendance) + "%" : "",
    Catatan: item.note != "" ? item.note : "-",
    Status: Object.keys(attendanceStatus).find((key) => attendanceStatus[key] === item.status), // get object key by value
    "Alasan Pembatalan": item.cancelNote != "" ? item.cancelNote : "-",
  }));
  return jsonSupervisor;
}
function handleJSONtoSheet(mappedJson, utils, fromDate, toDate, columnWidth) {
  let worksheet = utils.json_to_sheet([{}]);
  utils.sheet_add_json(worksheet, [{ A: "Tanggal" }], { skipHeader: true, origin: "A1" });
  utils.sheet_add_json(worksheet, [{ A: `${fromDate} - ${toDate}` }], {
    skipHeader: true,
    origin: "A2",
  });
  utils.sheet_add_json(worksheet, [{ A: "ATTENDANCE" }], { skipHeader: true, origin: "A4" });
  utils.sheet_add_json(worksheet, mappedJson, { origin: "A5" });
  worksheet["!cols"] = columnWidth;
  return worksheet;
}

export default {
  async getAttendanceReporting(fromDate, toDate) {
    try {
      const option = "fromDate=" + formatDate(fromDate) + "&toDate=" + formatDate(toDate);
      const res = await fetch(urlApi + "/Reporting/Attendance?" + option, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return data["data"];
    } catch (err) {
      return [];
    }
  },

  async exportAttendanceReporting(fromDate, toDate) {
    const data = await this.getAttendanceReporting(fromDate, toDate);
    const fromDateForView = formatDateForView(fromDate);
    const toDateForView = formatDateForView(toDate);

    // PERSENTASE REGION
    let jsonPercentage = data.attendancePercentageReporting.map((item) => ({
      "Nama Member": item.name,
      "Sel Group": item.cellgroups,
      "Attendance Pertemuan Sel (%)": resolveAttendancePercentage(item.hadirPertemuanSelCount, item.pertemuanSelCount),
      "Jumlah Entry Pertemuan Sel": resolveAttendanceCount(item.hadirPertemuanSelCount, item.pertemuanSelCount),
      "Attendance Community Gathering (%)": resolveAttendancePercentage(item.hadirCommunityGatheringCount, item.communityGatheringCount),
      "Jumlah Entry Community Gathering": resolveAttendanceCount(item.hadirCommunityGatheringCount, item.communityGatheringCount),
    }));
    const worksheetAttendancePercentage = handleJSONtoSheet(jsonPercentage, utils, fromDateForView, toDateForView, percentageColumnWidth);

    // ATTENDANCE MEMBER REGION
    const attendanceMemberReportingLength = data.attendanceMemberReporting.length;
    if (attendanceMemberReportingLength <= 0) {
      const emptyObject = {};
      data.attendanceMemberReporting.push(emptyObject);
    }
    let jsonAttendanceMember = data.attendanceMemberReporting.map((item) => ({
      Tanggal: attendanceMemberReportingLength > 0 ? formatDateForView(new Date(item.date)) : "",
      "Nama Member": item.memberName,
      "Nama Attendance": item.attendanceName,
      "Kategori Attendance": item.category,
      "Survey Kehadiran": item.isAttend == 1 ? "Hadir" : "Tidak Hadir",
      "Alasan Ketidakhadiran": item.isAttend == 0 && item.isAttendNote != "" ? item.isAttendNote : "-",
      Hadir: item.attendTypeName,
    }));
    const attendanceMemberColumnWidth = [{ wch: 20 }, { wch: 40 }, { wch: 40 }, { wch: 40 }, { wch: 20 }, { wch: 40 }];
    const worksheetAttendanceMember = handleJSONtoSheet(jsonAttendanceMember, utils, fromDateForView, toDateForView, attendanceMemberColumnWidth);

    // HEAD GEMBALA REGION
    const jsonHeadGembala = mapSupervisorData(data.attendanceSupervisorReporting, 1);
    const worksheetHeadGembala = handleJSONtoSheet(jsonHeadGembala, utils, fromDateForView, toDateForView, supervisorColumnWidth);

    // GEMBALA REGION
    const jsonGembala = mapSupervisorData(data.attendanceSupervisorReporting, 2);
    const worksheetGembala = handleJSONtoSheet(jsonGembala, utils, fromDateForView, toDateForView, supervisorColumnWidth);

    // Wakil GEMBALA REGION
    const jsonWakilGembala = mapSupervisorData(data.attendanceSupervisorReporting, 3);
    const worksheetWakilGembala = handleJSONtoSheet(jsonWakilGembala, utils, fromDateForView, toDateForView, supervisorColumnWidth);

    // PENDAMPING GEMBALA REGION
    const jsonPendampingGembala = mapSupervisorData(data.attendanceSupervisorReporting, 12);
    const worksheetPendampingGembala = handleJSONtoSheet(jsonPendampingGembala, utils, fromDateForView, toDateForView, supervisorColumnWidth);

    // PENDAMPING Wakil GEMBALA REGION
    const jsonPendampingWakilGembala = mapSupervisorData(data.attendanceSupervisorReporting, 13);
    const worksheetPendampingWakilGembala = handleJSONtoSheet(
      jsonPendampingWakilGembala,
      utils,
      fromDateForView,
      toDateForView,
      supervisorColumnWidth,
    );
    const new_workbook = utils.book_new(); // make new workbook
    utils.book_append_sheet(new_workbook, worksheetAttendancePercentage, "ATTENDANCE MEMBER PERSENTASE"); // add ATTENDANCE MEMBER PERSENTASE tab
    utils.book_append_sheet(new_workbook, worksheetAttendanceMember, "ATTENDANCE MEMBER"); // add ATTENDANCE MEMBER tab
    utils.book_append_sheet(new_workbook, worksheetHeadGembala, "HEAD GEMBALA"); // add HEAD GEMBALA tab
    utils.book_append_sheet(new_workbook, worksheetGembala, "GEMBALA"); // add GEMBALA tab
    utils.book_append_sheet(new_workbook, worksheetPendampingGembala, "PENDAMPING GEMBALA"); // add WAKIL GEMBALA tab
    utils.book_append_sheet(new_workbook, worksheetWakilGembala, "WAKIL GEMBALA"); // add WAKIL GEMBALA tab
    utils.book_append_sheet(new_workbook, worksheetPendampingWakilGembala, "PENDAMPING WAKIL GEMBALA"); // add WAKIL GEMBALA tab
    const token = parseJwt(localStorage.getItem("token"));
    const fileName = token.RoleID == 5 ? "Super Admin - Attendance.xlsx" : "Head Gembala - Attendance.xlsx";
    writeFileXLSX(new_workbook, fileName); // trigger download
  },
};
