<script>
  import { push } from "svelte-spa-router";
  import { onMount } from "svelte";
  import Loading from "../../components/Loading.svelte";
  import Pagination from "../../components/pagination/Pagination.svelte";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import { addDays } from "date-fns";
  import { formatDate, parseJwt } from "../../utils/utils";
  import { ADD_LEARNING_MODULE, EXPORT_LEARNING, optionStatusLearning, perPage, typeLearning, urlApi } from "../../utils/const";
  import Select from "../../components/control/Select.svelte";
  import { checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import TableLearning from "../../components/table/TableLearning.svelte";
  import LearningCard from "../../components/card/LearningCard.svelte";
  import CellGroupServices from "../CellGroup/CellGroupServices";
  import CellCategoryServices from "../CellCategory/CellCategoryServices";
  import LearningExportService from "./LearningExportService.js";

  const token = parseJwt(localStorage.getItem("token"));
  let dataList = [];
  let cellgroupOption = [{ id: "0", name: "Unassigned" }];
  let loading;
  let page = 1;
  let search = "";
  let totalData = 0,
    totalPage = 0;

  let showType = false;
  let type = "0",
    status = "0",
    year = "0";
  let covenantYearOption = [];
  let cellGroupSelected = "";
  let from = addDays(new Date(), -7);
  let to = new Date();

  onMount(() => {
    loadData();
    loadCovenantYear();
  });

  async function loadData() {
    loading.toggle();
    const statusSelect = status == "0" ? 99 : status == "3" ? 0 : status;
    const cellgroup = cellGroupSelected == "" ? 0 : cellGroupSelected;
    const option =
      "perPage=" +
      perPage +
      "&currentPage=" +
      page +
      "&searchQuery=" +
      search +
      "&fromDate=" +
      formatDate(from) +
      "&toDate=" +
      formatDate(to) +
      "&year=" +
      year +
      "&cellgroupID=" +
      cellgroup +
      "&status=" +
      statusSelect;
    const res = await fetch(urlApi + "/Learning?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    dataList = data["data"];
    totalData = data["info"]["totalItem"];
    totalPage = data["info"]["totalPage"];

    loading.toggle();
  }
  async function loadCovenantYear() {
    covenantYearOption.push({
      id: "0",
      name: "Semua Kategori Sel",
    });
    const option = "perPage=9999" + "&currentPage=1" + "&isDelete=0";
    const data = await CellCategoryServices.loadCellCategory(option);
    for (const item of data["data"]) {
      covenantYearOption.push({
        id: String(item.id),
        name: item.name,
      });
    }
    covenantYearOption = covenantYearOption;
  }
  async function loadCellGroupOption() {
    const option = "perPage=9999" + "&isActive=1" + "&convenantYear=" + year;
    const data = await CellGroupServices.loadCellGroupList(option);
    if (data["data"].length > 0) {
      data["data"]
        .map((e) => ({
          id: e["id"].toString(),
          name: e["name"],
        }))
        .forEach((e) => cellgroupOption.push(e));
      cellgroupOption = cellgroupOption;
    }
  }
  async function exportLMSReporting(id) {
    LearningExportService.exportLMSReporting(id);
  }
  async function exportLearningListReporting() {
    const statusSelect = status == "0" ? 99 : status == "3" ? 0 : status;
    const cellgroup = cellGroupSelected == "" ? 0 : cellGroupSelected;
    const option =
      "?searchQuery=" +
      search +
      "&fromDate=" +
      formatDate(from) +
      "&toDate=" +
      formatDate(to) +
      "&covenantYear=" +
      year +
      "&cellgroupID=" +
      cellgroup +
      "&status=" +
      statusSelect;
    LearningExportService.exportLMSListReporting(option, from, to);
  }
  function searchData() {
    page = 1;
    loadData();
  }
</script>

<Loading bind:this={loading} />

<div class="p-0 md:p-4">
  <!-- component -->
  <section class="container mx-auto px-4 md:px-0">
    <div class="sm:flex sm:items-center sm:justify-between">
      <div>
        <div class="flex items-center gap-x-3">
          <h2 class="text-lg font-medium text-gray-800">Daftar Materi</h2>
        </div>
      </div>
      <div class="flex w-full md:w-auto relative">
        {#if checkRoleModule($roleModules, ADD_LEARNING_MODULE)}
          <button
            on:click={() => (showType = !showType)}
            class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-lg
              shrink-0 sm:w-auto space-x-2 hover:opacity-80"
          >
            <i class="bx bx-plus" />

            <span>Tambah Materi</span>
          </button>
        {/if}
        {#if showType}
          <div class="absolute top-10 left-0 flex flex-col space-y-1 w-full bg-white z-10">
            {#each typeLearning as type}
              {#if type.id != 0}
                <button
                  on:click={() => push("/learning/add/" + type.name)}
                  class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-md
            shrink-0 sm:w-auto space-x-2 hover:opacity-80"
                >
                  <span>{type.name}</span>
                </button>
              {/if}
            {/each}
          </div>
        {/if}
      </div>
    </div>

    <div class="mt-6 flex items-end justify-between flex-col space-y-3 md:flex-row md:space-x-3 md:space-y-0">
      <div class="flex-1 flex flex-col space-y-3 w-full md:space-y-0 md:flex-row md:space-x-2">
        <div class="flex-1 flex items-center">
          <div class="relative w-full">
            <label class="block text-gray-900 text-xs font-semibold mb-1" for="search"> Cari </label>
            <div class="relative w-full">
              <input
                type="text"
                placeholder="Cari materi.."
                bind:value={search}
                on:input={searchData}
                class="block w-full text-xs py-2 pr-5 text-gray-700 focus:border-gray-300 focus:ring-transparent bg-white
                  border border-gray-200 rounded-lg placeholder-gray-400/70 pl-8 focus:outline-none"
              />
              <span class="absolute left-2 top-0 bottom-0 flex items-center">
                <i class="bx bx-search text-gray-500" />
              </span>
            </div>
          </div>
        </div>

        <!-- Kategori Sel -->
        <div class="flex-1 flex space-x-1 w-full">
          <div class="flex-1">
            <Select
              on:change={(e) => {
                cellGroupSelected = "";
                cellgroupOption = [];
                loadCellGroupOption();
                year = e.detail;
                searchData();
              }}
              textHint="Semua Kategori Sel"
              label="Kategori Sel"
              fullWidth
              name="year_learning"
              options={covenantYearOption}
              bind:selected={year}
            />
          </div>
        </div>

        <!-- Sel Group -->
        <div class="flex-1 flex space-x-1 w-full">
          <div class="flex-1">
            <Select
              on:change={(e) => {
                cellGroupSelected = e.detail;
                searchData();
              }}
              disabled={year == "0"}
              textHint="Pilih Sel Group"
              label="Sel Group"
              fullWidth
              name="year_learning"
              options={cellgroupOption}
              bind:selected={cellGroupSelected}
            />
          </div>
        </div>

        <!-- Learning Status -->
        <div class="flex-1 flex space-x-1 w-full">
          <div class="flex-1">
            <Select
              on:change={(e) => {
                status = e.detail;
                searchData();
              }}
              textHint="Pilih Status"
              label="Status"
              fullWidth
              name="status_learning"
              options={optionStatusLearning}
              bind:selected={status}
            />
          </div>
        </div>

        <!-- Aksi -->
        <div class="flex-1 flex space-x-1 w-full">
          <div class="flex-1">
            <DatePicker label="Tanggal" name="date" bind:from bind:to ranged on:update={searchData} />
          </div>
        </div>
      </div>
    </div>
    {#if checkRoleModule($roleModules, EXPORT_LEARNING)}
      <div class="mt-4 flex justify-end">
        <button
          on:click={() => exportLearningListReporting()}
          class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-md
              shrink-0 sm:w-auto space-x-2 hover:opacity-80"
        >
          <span>Export</span>
        </button>
      </div>
    {/if}
    <div class="flex flex-col mt-4">
      <div class="overflow-x-auto hidden md:block">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="overflow-hidden rounded-md">
            <TableLearning
              bind:data={dataList}
              bind:page
              on:detail={(e) => push("/learning/" + e.detail["id"] + "/detail")}
              on:edit={(e) => push("/learning/" + e.detail["id"] + "/edit")}
              on:member={(e) => push("/learning/" + e.detail["id"] + "/member")}
              on:lesson={(e) => push("/learning/" + e.detail["id"] + "/lesson/" + token.UserID)}
              on:export={(e) => exportLMSReporting(e.detail["id"])}
            />
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="overflow-x-auto block md:hidden">
        {#if dataList.length == 0}
          <p class="font-medium text-gray-500 text-xs text-center mt-2">Belum ada data</p>
        {/if}
        {#each dataList as item}
          <LearningCard
            bind:data={item}
            on:detail={(e) => push("/learning/" + e.detail["id"] + "/detail")}
            on:edit={(e) => push("/learning/" + e.detail["id"] + "/edit")}
            on:member={(e) => push("/learning/" + e.detail["id"] + "/member")}
            on:lesson={(e) => push("/learning/" + e.detail["id"] + "/lesson/" + token.UserID)}
            on:export={(e) => exportLMSReporting(e.detail["id"])}
          />
        {/each}
      </div>
    </div>

    <div class="mt-6 sm:flex sm:items-center sm:justify-between">
      <div class="text-sm text-gray-500" />
      {#if dataList.length > 0 || page > 1}
        <Pagination
          bind:page
          {totalData}
          {totalPage}
          {perPage}
          on:prev={() => {
            page -= 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          on:next={() => {
            page += 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
      {/if}
    </div>
  </section>
</div>
