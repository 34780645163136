import { urlApi } from "../../utils/const";
import Cookies from "js-cookie";

export default {
  async getUser() {
    const res = await fetch(urlApi + "/Users/user", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },
};
