<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let page;
  export let totalData;
  export let totalPage;
  export let perPage;
</script>

{#if totalData > perPage}
  <div class="flex items-center my-4 space-x-2 sm:my-0">
    {#if page > 1}
      <button
        on:click={() => dispatch("prev")}
        class="flex-1 flex items-center justify-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100"
      >
        <i class="bx bx-chevron-left" />
        <span> Sebelumnya </span>
      </button>
    {/if}

    {#if page < totalPage}
      <button
        on:click={() => dispatch("next")}
        class="flex-1 flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100"
      >
        <span> Selanjutnya </span>

        <i class="bx bx-chevron-right" />
      </button>
    {/if}
  </div>
{/if}
