<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import { attendanceStatus, attendType } from "../../pages/Attendance/AttendanceConst";

  export let data;
  export let mode;
  export let status = "";
  function resolveAttendTypeName(value) {
    if (value == attendType.None) return "-";
    else if (value == attendType.Onsite) return "On Site";
    else if (value == attendType.Online) return "Online";
  }
</script>

<div class="card-data min-w-full mb-3 p-2 border border-primary shadow rounded-md">
  <div class="flex justify-between items-center text-xs font-medium text-gray-800">
    <div class="flex flex-col justify-start items-start">
      <p class="flex-1 line-clamp-2 text-right text-lg font-bold">{data["name"]}</p>
      {#if mode == "attendance"}
        <p class="flex-1 line-clamp-2 text-right text-xs">{data["isAttend"] ? "Hadir" : "Tidak Hadir"}</p>
        <p class="flex-1 line-clamp-2 text-right text-xs">{data["note"] && data["isAttend"] == 0 ? data["note"] : "-"}</p>
        {#if status == attendanceStatus.Aktif}
          <div class="flex items-center gap-2">
            <div class="flex items-center gap-1">
              <input
                type="radio"
                class="cursor-pointer disabled:opacity-50 {data['isAttend'] == 1 && data['attendTypeID'] == attendType.None
                  ? 'border border-red-500'
                  : ''}"
                id="onsite-{data['id']}"
                disabled={data["isAttend"] == 0}
                checked={data["isAttend"] == 1 && data["attendTypeID"] == attendType.Onsite}
                on:input={() => {
                  dispatch("verifyAttendanceType", { item: data, value: 1 });
                  data = data;
                }}
              />
              <label for="onsite-{data['id']}" class={data["isAttend"] == 0 ? "opacity-50" : ""}>On Site</label>
            </div>
            <div class="flex items-center gap-1">
              <input
                type="radio"
                class="cursor-pointer disabled:opacity-50 {data['isAttend'] == 1 && data['attendTypeID'] == attendType.None
                  ? 'border border-red-500'
                  : ''}"
                id="online-{data['id']}"
                disabled={data["isAttend"] == 0}
                checked={data["isAttend"] == 1 && data["attendTypeID"] == attendType.Online}
                on:input={() => {
                  dispatch("verifyAttendanceType", { item: data, value: 2 });
                  data = data;
                }}
              />
              <label for="online-{data['id']}" class={data["isAttend"] == 0 ? "opacity-50" : ""}>Online</label>
            </div>
          </div>
        {:else}
          <p class="flex-1 line-clamp-2 text-right text-xs">{resolveAttendTypeName(data["attendTypeID"])}</p>
        {/if}
      {/if}
    </div>
    {#if mode != "detail" && mode != "attendance"}
      <div class="flex-1 flex space-x-1 justify-end">
        <button on:click={() => dispatch("delete", data)} class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100">
          <i class="bx bx-trash text-xl text-red-500" />
        </button>
      </div>
    {/if}
    {#if mode == "attendance" && status == attendanceStatus.Aktif}
      <td class="px-4 text-sm font-medium whitespace-nowrap">
        <input
          type="checkbox"
          checked={data["isAttend"]}
          on:click={() => {
            dispatch("verifyAttendance", data);
            data = data;
          }}
        />
      </td>
    {/if}
  </div>
</div>
