<script>
  import { pop, replace } from "svelte-spa-router";
  import Loading from "../../components/Loading.svelte";
  import Modal from "../../components/Modal.svelte";
  import { field, form } from "svelte-forms";
  import { required } from "svelte-forms/validators";
  import { formatDate, generateID, parseJwt, validateStatusLearning } from "../../utils/utils";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import Input from "../../components/control/Input.svelte";
  import { onMount } from "svelte";
  import { typeQuiz, urlApi } from "../../utils/const";
  import Pagination from "../../components/pagination/Pagination.svelte";
  import Textarea from "../../components/control/Textarea.svelte";
  import { addDays } from "date-fns";
  import Select from "../../components/control/Select.svelte";
  import CellCategoryServices from "../CellCategory/CellCategoryServices";

  export let params;
  let errors = [];

  const token = parseJwt(localStorage.getItem("token"));
  let type = params["type"];

  let createdDate = field("createdDate", new Date(), [required()]);
  let publishDate = field("publishDate", addDays(new Date(), 1), [required()]);
  let overdueDate = field("overdueDate", addDays(new Date(), 2), [required()]);
  let title = field("title", "", [required()]);
  let content = field("content", type == "Text" ? { text: "", html: "" } : "", []);
  let covenantYearOption = [];
  let covenantYearSelected = "0";
  let cellGroupOption = [];
  let allCellGroupOption = [];
  let cellGroupSelected = [];
  let cellGroupSelectedTemp = [];
  let totalDataCell = 0,
    totalPage = 0;
  let search = "";

  let loadingPage;
  let modalAccessMateri;
  let modalBack;
  let disableSubmit = false;
  let isCategoryCellSelectedAll = false;
  let accessMateri = [];
  let uploadedFile = [];
  let showTypeQuiz = false;
  let quizQuestion = [];

  onMount(() => {
    loadCovenantYear();
    loadAllCellGroup();
  });

  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";

  let learnForm;

  function checkValidate() {
    let errorTemp = [];
    if (cellGroupSelected.length == 0) {
      errorTemp.push("cellgroup.required");
    }
    if ((type == "PDF" || type == "PPT") && uploadedFile.length == 0) {
      errorTemp.push("module.required");
    }
    if (type == "Text" && ($content.value.html == "" || $content.value.html == "<p><br></p>")) {
      errorTemp.push("content.required");
    }
    if (quizQuestion.length == 0) {
      errorTemp.push("quiz.required");
    } else if (quizQuestion.length > 0) {
      quizQuestion.forEach((e, i) => {
        if (e.type != "0" && e.question == "") {
          errorTemp.push(i + "-question" + ".fill");
        }
        if (e.type != "0" && e.items.findIndex((x) => x.answer == true) == -1) {
          errorTemp.push(e.typeId + "-answer" + ".fill");
        }
        e.items.forEach((it, idx) => {
          if (e.type == "0" && it.question == "") {
            errorTemp.push(i + "-question" + ".required");
          }
          if (e.type != "0" && it.question == "") {
            errorTemp.push(idx + "-" + e.typeId + "-question" + ".fill");
            errorTemp.push(e.typeId + "-question" + ".fill");
          }
        });
      });
    }

    errorTemp = errorTemp;

    return errorTemp;
  }

  async function add() {
    loadingPage.toggle();
    errors = [];
    errorMap = [];
    let contentV;
    if (type == "Video") {
      contentV = field("content", $content.value, [required()]);
    } else {
      contentV = field("content", $content.value, []);
    }
    learnForm = form(createdDate, publishDate, overdueDate, title, contentV);
    let val = checkValidate();
    await learnForm.validate();
    if ($learnForm.valid && val.length == 0) {
      let mapContent = [];
      if (type == "Text") {
        mapContent.push({
          Content: JSON.stringify($content.value),
        });
      } else if (type == "Video") {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = $content.value.match(regExp);
        let videoId = "";
        if (match && match[2]) {
          videoId = match[2];
        }
        mapContent.push({
          Content: "https://www.youtube.com/embed/" + videoId,
        });
      } else {
        mapContent = uploadedFile.map((e) => ({ Content: e.name }));
      }

      let mapQuiz = quizQuestion.map((e) => ({
        Type: parseInt(e.type),
        TypeID: e.typeId,
        Question: e.question,
        Items: e.items.map((x) => ({
          TypeID: e.typeId,
          Value: x.question,
          Answer: x.answer == true ? "1" : "",
        })),
      }));

      let mapAccess = cellGroupSelected.map((e) => ({
        CellGroupID: parseInt(e.ID),
        CovenantYearID: e.CovenantYearID,
      }));

      const reqData = {
        CreatedDate: formatDate($createdDate.value),
        PublishDate: formatDate($publishDate.value),
        OverdueDate: formatDate($overdueDate.value),
        Access: mapAccess,
        Title: $title.value,
        Module: mapContent,
        Quiz: mapQuiz,
        Type: type,
        Status: validateStatusLearning(new Date($publishDate.value)),
      };

      const dataToSubmit = new FormData();
      if (uploadedFile.length > 0) {
        uploadedFile.forEach((e) => {
          dataToSubmit.append("file", e);
        });
      }

      dataToSubmit.append("data", JSON.stringify(reqData));
      const res = await fetch(urlApi + "/Learning", {
        method: "POST",
        headers: {
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        body: dataToSubmit,
      });

      const data = await res.json();
      if (data) {
        globalThis.pushToast("Berhasil Menambah Materi", "success");
        setTimeout(() => replace("/learning/"), 1000);
      }
    } else {
      errors = [...val, ...$learnForm.errors];
      console.log(errors);
      disableSubmit = false;
    }
    loadingPage.toggle();
  }

  function checkBack() {
    let result = false;

    if ($createdDate.value != "" || $publishDate.value != "" || $overdueDate.value != "") {
      result = true;
    }

    return result;
  }

  async function loadCovenantYear() {
    const option = "perPage=9999" + "&currentPage=1" + "&isActive=1" + "&isDelete=0";
    const data = await CellCategoryServices.loadCellCategory(option);
    covenantYearOption.push({
      id: "0",
      name: "Semua Kategori Sel",
    });
    for (const item of data["data"]) {
      if (covenantYearSelected == "") {
        covenantYearSelected = String(item.id);
      }
      covenantYearOption.push({
        id: String(item.id),
        name: item.name,
      });
    }
    covenantYearOption = covenantYearOption;
  }

  let pageCell = 1,
    perPage = 5;
  let timeout;
  let searching = false;

  function handleInput() {
    searching = true;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(handleSearch, 500);
  }
  function handleSearch() {
    pageCell = 1;
    loadCellGroup();
  }

  async function loadCellGroup() {
    loadingPage.toggle();
    cellGroupOption = [];
    const covenantYear = covenantYearSelected == "0" ? "" : covenantYearSelected;
    let option = "convenantYear=" + covenantYear + "&currentPage=" + pageCell + "&perPage=" + perPage + "&searchQuery=" + search + "&isActive=1";
    if (search != "") {
      option = "currentPage=" + pageCell + "&perPage=" + perPage + "&searchQuery=" + search + "&isActive=1";
    }
    if (perPage == 9999) {
      option = "currentPage=1" + "&perPage=" + perPage + "&searchQuery=" + search + "&isActive=1" + "&convenantYear=" + covenantYear;
    }
    const res = await fetch(urlApi + "/CellGroup?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    totalDataCell = data["info"]["totalItem"];
    totalPage = data["info"]["totalPage"];
    for (const item of data["data"]) {
      cellGroupOption.push({
        ID: String(item.id),
        Year: item.covenantName,
        CovenantYearID: item.covenantYearID,
        Name: item.name,
        CovenantName: item.covenantName,
      });
    }
    cellGroupOption = cellGroupOption;
    if (perPage != 5) {
      perPage = 5;
    }
    if (totalDataCell == 0) isCategoryCellSelectedAll = false;
    else
      isCategoryCellSelectedAll =
        covenantYearSelected == "0"
          ? totalDataCell == cellGroupSelected.length && search == ""
          : totalDataCell == cellGroupSelected.filter((item) => String(item.CovenantYearID) == covenantYearSelected).length && search == "";
    loadingPage.toggle();
  }

  async function loadAllCellGroup() {
    allCellGroupOption = [];

    let option = "currentPage=1" + "&perPage=9999" + "&isActive=1";
    const res = await fetch(urlApi + "/CellGroup?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();

    for (const item of data["data"]) {
      allCellGroupOption.push({
        ID: String(item.id),
        Year: item.covenantName,
        CovenantYearID: item.covenantYearID,
        Name: item.name,
        CovenantName: item.covenantName,
      });
    }
    allCellGroupOption = allCellGroupOption;
  }

  function handleUploadFile(e) {
    errors = [];
    errorMap = [];
    const selectedFile = e.target.files[0]; // Get the selected file
    const updatedName = selectedFile.name.replace(/&|\+|#/g, "-"); // change all "&","+", and "#" to "-" in file's name
    // Create a new Blob with the same content with replaced file name
    const updatedFile = new File([selectedFile], updatedName, {
      type: selectedFile.type,
      lastModified: selectedFile.lastModified,
    });
    uploadedFile = [...uploadedFile, updatedFile];
    e.target.value = "";
  }

  $: if ($publishDate.value.getDate() != addDays(new Date(), 1).getDate()) {
    if (
      ($overdueDate.value.getDate() < $publishDate.value.getDate() && $overdueDate.value.getMonth() == $publishDate.value.getMonth()) ||
      $overdueDate.value.getMonth() < $publishDate.value.getMonth()
    ) {
      $overdueDate.value = $publishDate.value;
    }
  }

  let errorMap = [];

  $: if (errors.length > 0 && errorMap.length == 0) {
    errorMap = [];
    errorMap = errors
      .filter((e) => e.toString().split(".").length > 1)
      .map((e) => ({
        field: e.toString().split(".")[0],
        error: e.toString().split(".")[1],
      }));
  }
</script>

<Loading bind:this={loadingPage} />

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin ingin keluar dari laman ini? data yang Anda masukkan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/learning/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>

<Modal
  index={20}
  closed
  on:close={() => {
    search = "";
    pageCell = 1;
    covenantYearSelected = "0";
    cellGroupSelected = cellGroupSelectedTemp;
    cellGroupSelected = cellGroupSelected;
    modalAccessMateri.toggle();
  }}
  title="Tambah Akses Materi"
  bind:this={modalAccessMateri}
  size="full"
>
  <div class="flex flex-col space-y-2">
    <div class="flex-1 flex items-center">
      <div class="relative w-full">
        <div class="relative w-full">
          <input
            type="text"
            placeholder="Cari Sel Group"
            bind:value={search}
            on:input={() => {
              handleInput();
            }}
            class="block w-full text-xs py-2 pr-5 text-gray-700 focus:border-gray-300 focus:ring-transparent bg-white
              border border-gray-200 rounded-lg placeholder-gray-400/70 pl-8 focus:outline-none"
          />
          <span class="absolute left-2 top-0 bottom-0 flex items-center">
            <i class="bx bx-search text-gray-500" />
          </span>
        </div>
      </div>
    </div>

    {#if search == ""}
      <div>
        <Select
          on:change={(e) => {
            pageCell = 1;
            covenantYearSelected = e.detail;
            loadCellGroup();
          }}
          fullWidth
          textHint="Pilih Kategori Sel"
          name="covenantYearSelected"
          extClass="font-semibold"
          label="Kategori Sel"
          textSize="text-sm"
          options={covenantYearOption}
          bind:errors
          bind:selected={covenantYearSelected}
        />
      </div>
      <div class="flex items-center mt-2">
        <input
          type="checkbox"
          id="checkAll"
          class="cursor-pointer"
          checked={isCategoryCellSelectedAll}
          on:change={(e) => {
            if (isCategoryCellSelectedAll) {
              isCategoryCellSelectedAll = false;
              if (covenantYearSelected == "0") cellGroupSelected = [];
              else cellGroupSelected = cellGroupSelected.filter((item) => String(item.CovenantYearID) != covenantYearSelected);
            } else {
              if (covenantYearSelected == "0") cellGroupSelected = allCellGroupOption;
              else {
                const cellGroupdIDSelected = cellGroupSelected.map((e) => e.ID);
                cellGroupSelected.push(
                  ...allCellGroupOption.filter(
                    (item) => String(item.CovenantYearID) == covenantYearSelected && !cellGroupdIDSelected.includes(item.ID),
                  ),
                );
                cellGroupSelected = cellGroupSelected;
              }
              isCategoryCellSelectedAll = true;
            }
            covenantYearOption = covenantYearOption;
          }}
        />
        <label for="checkAll" class="ml-2 text-sm font-medium text-gray-500">Pilih Semua</label>
      </div>
    {/if}

    <table class="min-w-full divide-y divide-gray-200 mt-4">
      <thead class="bg-gray-100">
        <tr>
          <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Pilih </th>
          <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Nama Sel Group </th>
          <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Kategori Sel</th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        {#if cellGroupOption.length == 0}
          <tr>
            <td colspan="2" class="px-4 py-4 text-sm font-medium">
              <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
            </td>
          </tr>
        {/if}
        {#each cellGroupOption as item}
          <tr>
            <td class="px-3.5 text-sm font-medium whitespace-nowrap">
              <input
                name={item.Name}
                type="checkbox"
                class="cursor-pointer"
                checked={isCategoryCellSelectedAll || cellGroupSelected.findIndex((e) => e.ID == item.ID) != -1}
                on:click={() => {
                  const index = cellGroupSelected.findIndex((e) => e.ID == item.ID);
                  if (index == -1) {
                    cellGroupSelected.push(item);
                  } else {
                    cellGroupSelected.splice(index, 1);
                  }
                  isCategoryCellSelectedAll =
                    covenantYearSelected == "0"
                      ? totalDataCell == cellGroupSelected.length && search == ""
                      : totalDataCell == cellGroupSelected.filter((item) => String(item.CovenantYearID) == covenantYearSelected).length &&
                        search == "";
                  cellGroupSelected = cellGroupSelected;
                }}
              />
            </td>
            <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
              <h2 class="font-medium text-gray-800 text-xs">
                {item["Name"]}
              </h2>
            </td>
            <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
              <h2 class="font-medium text-gray-800 text-xs">
                {item["CovenantName"]}
              </h2>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
    <div class="flex items-center justify-end">
      <Pagination
        {perPage}
        page={pageCell}
        totalData={totalDataCell}
        {totalPage}
        on:next={() => {
          pageCell += 1;
          loadCellGroup();
        }}
        on:prev={() => {
          if (pageCell > 1) {
            pageCell -= 1;
            loadCellGroup();
          }
        }}
      />
    </div>

    <div class="w-full">
      <div class="flex space-x-2 items-center justify-end mt-4">
        <button
          on:click={() => {
            search = "";
            pageCell = 1;
            cellGroupSelected = cellGroupSelectedTemp;
            cellGroupSelected = cellGroupSelected;
            covenantYearSelected = "0";
            modalAccessMateri.toggle();
          }}
          class=" px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
        >
          Batal
        </button>
        <button
          on:click={async () => {
            search = "";
            pageCell = 1;
            covenantYearSelected = "0";
            modalAccessMateri.toggle();
          }}
          class=" px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
        >
          Simpan
        </button>
      </div>
    </div>
  </div>
</Modal>

<section class="container px-4 py-2">
  <div class="sm:flex sm:items-center sm:justify-between">
    <div>
      <div class="flex items-center gap-x-3">
        <button
          on:click={() => {
            if (checkBack()) {
              modalBack.toggle();
            } else {
              pop();
            }
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-lg font-medium text-gray-800">Tambah Materi {type}</h2>
      </div>
    </div>
  </div>

  <div class="mt-5 flex flex-col space-y-4">
    <!-- Tanggal Dibuat -->
    <div class={mobileClass}>
      <div class="flex-1">
        <DatePicker
          name="createdDate"
          type="text"
          label="Tanggal Dibuat"
          labelSize="sm"
          textSize="sm"
          extClass="font-semibold"
          bind:errors
          bind:value={$createdDate.value}
        />
      </div>
    </div>
    <!-- Tanggal Publish dan Tanggal Deadline -->
    <div class={mobileClass}>
      <div class="flex-1">
        <DatePicker
          maxDateToday={false}
          name="publishDate"
          type="text"
          label="Tanggal Publish"
          labelSize="sm"
          textSize="sm"
          extClass="font-semibold"
          isMinDate
          minDate={new Date()}
          bind:errors
          bind:value={$publishDate.value}
        />
      </div>
      <div class="flex-1">
        <DatePicker
          maxDateToday={false}
          name="overdueDate"
          type="text"
          label="Tanggal Deadline"
          labelSize="sm"
          textSize="sm"
          extClass="font-semibold"
          isMinDate
          bind:minDate={$publishDate.value}
          bind:errors
          bind:value={$overdueDate.value}
        />
      </div>
    </div>

    <!-- ACCESS -->
    <div>
      <p class="font-semibold text-sm mb-2">Akses Materi</p>
      <button
        on:click={() => {
          errors = [];
          errorMap = [];
          loadCellGroup();
          cellGroupSelectedTemp = [...cellGroupSelected];
          modalAccessMateri.toggle();
        }}
        disabled={disableSubmit}
        class="w-1/2 sm:w-1/4 mb-2 text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-xs px-5 py-2 text-center transition-all"
        >Tambah Akses</button
      >
      <div class={mobileClass}>
        <div class="flex-1">
          <table class="w-full divide-y divide-gray-200">
            <thead class="bg-gray-100">
              <tr>
                <th scope="col" class="hidden sm:block px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> No. </th>
                <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Kategori Sel </th>
                <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Sel Group </th>
                <th scope="col" class="px-2 py-2 text-sm font-normal text-left text-gray-500">Aksi</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              {#if cellGroupSelected.length == 0}
                <tr>
                  <td colspan="4" class="px-4 py-4 text-sm font-medium">
                    <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
                  </td>
                </tr>
              {/if}
              {#each cellGroupSelected as item, index}
                <tr>
                  <td class="hidden sm:table-cell px-3.5 py-2 text-sm font-medium whitespace-nowrap">
                    <h2 class="font-medium text-gray-800 text-xs">
                      {index + 1}
                    </h2>
                  </td>
                  <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
                    <h2 class="font-medium text-gray-800 text-xs">
                      {item["Year"]}
                    </h2>
                  </td>
                  <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
                    <h2 class="font-medium text-gray-800 text-xs">
                      {item["Name"]}
                    </h2>
                  </td>
                  <td class="px-2 py-2 text-sm font-medium whitespace-nowrap">
                    <button
                      on:click={() => {
                        cellGroupSelected.splice(index, 1);
                        cellGroupSelected = cellGroupSelected;
                      }}
                      class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
                    >
                      <i class="bx bx-trash text-xl text-red-500" />
                    </button>
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      </div>
      {#if errorMap.findIndex((e) => e["field"] == "cellgroup") != -1}
        <p class="text-xs text-red-500">Akses Materi wajib diisi, minimal 1 Sel Group</p>
      {/if}
    </div>

    <!-- MATERI -->
    <div class="flex flex-col space-y-2">
      <p class="font-semibold text-sm">Materi</p>
      <div class={mobileClass}>
        <div class="flex-1">
          <Input name="title" type="text" label="Judul" placeholder="Ketikkan judul materi" bind:errors bind:value={$title.value} />
        </div>
      </div>

      <!-- FILE -->
      <div class={mobileClass}>
        {#if type == "Video"}
          <div class="flex-1">
            <Input name="content" type="text" label="Link Konten" placeholder="Masukkan link konten" bind:errors bind:value={$content.value} />
          </div>
        {:else if type == "Text"}
          <div class="flex-1">
            <Textarea
              name="content"
              bind:content={$content.value}
              bind:errors
              label="Konten"
              inputEditor={true}
              placeholder="Masukkan konten..."
              maxlength={3000}
            />
          </div>
        {:else if type == "PDF" || type == "PPT"}
          <div>
            <label for="formFile" class="mb-1 inline-block text-sm font-semibold">File</label>
            <input
              class="cursor-pointer text-sm relative m-0 block w-full min-w-0 flex-auto rounded border border-solid {errorMap.findIndex(
                (e) => e['field'] == 'module',
              ) != -1
                ? 'border-red-500'
                : 'border-neutral-300'} bg-clip-padding px-3 py-[0.32rem] font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none"
              type="file"
              accept={type == "PDF" ? "application/pdf" : ".ppt, .pptx"}
              on:change={handleUploadFile}
              id="formFile"
            />
          </div>
        {/if}
      </div>
      {#if errorMap.findIndex((e) => e["field"] == "module") != -1}
        <p class="text-xs text-red-500">Materi wajib diisi, Minimal 1 file</p>
      {/if}

      {#if type == "PDF" || type == "PPT"}
        <div class={mobileClass}>
          <div class="flex-1">
            <table class="w-full divide-y divide-gray-200">
              <thead class="bg-gray-100">
                <tr>
                  <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Nama File </th>
                  <th scope="col" class="px-2 py-2 text-sm font-normal text-left text-gray-500">Aksi</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                {#if uploadedFile.length == 0}
                  <tr>
                    <td colspan="2" class="px-4 py-4 text-sm font-medium">
                      <h2 class="font-medium text-gray-500 text-xs">Belum ada file</h2>
                    </td>
                  </tr>
                {/if}
                {#each uploadedFile as item, index}
                  <tr>
                    <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
                      <h2 class="font-medium text-gray-800 text-xs">
                        {item["name"]}
                      </h2>
                    </td>
                    <td class="px-2 py-2 text-sm font-medium whitespace-nowrap">
                      <button
                        on:click={() => {
                          uploadedFile.splice(index, 1);
                          uploadedFile = uploadedFile;
                        }}
                        class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
                      >
                        <i class="bx bx-trash text-xl text-red-500" />
                      </button>
                    </td>
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        </div>
      {/if}
    </div>

    <!-- QUIZ -->
    <div>
      <p class="font-semibold text-sm mb-1">Quiz</p>
      <div class="relative">
        <button
          on:click={() => (showTypeQuiz = !showTypeQuiz)}
          disabled={disableSubmit}
          class="w-1/2 sm:w-1/4 text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-xs px-5 py-2 text-center transition-all"
          >Tambah Quiz</button
        >

        {#if showTypeQuiz}
          <div class="absolute top-10 left-0 flex flex-col w-1/4 pb-2 space-y-1 bg-white z-20">
            {#each typeQuiz as q}
              <button
                on:click={() => {
                  errors = [];
                  errorMap = [];
                  let generateUnique = generateID(5);
                  if (q.id == "0") {
                    quizQuestion.push({
                      id: generateID(10),
                      typeId: generateUnique,
                      type: q.id,
                      items: [
                        {
                          question: "",
                          answer: "",
                        },
                      ],
                    });
                  } else if (q.id == "1") {
                    quizQuestion.push({
                      id: generateID(10),
                      typeId: generateUnique,
                      type: q.id,
                      question: "",
                      items: [
                        {
                          id: generateID(10),
                          question: "",
                          answer: false,
                        },
                        {
                          id: generateID(10),
                          question: "",
                          answer: false,
                        },
                      ],
                    });
                  } else if (q.id == "2") {
                    quizQuestion.push({
                      id: generateID(10),
                      typeId: generateUnique,
                      type: q.id,
                      question: "",
                      items: [
                        {
                          id: generateID(10),
                          question: "",
                          answer: false,
                        },
                        {
                          id: generateID(10),
                          question: "",
                          answer: false,
                        },
                      ],
                    });
                  }
                  quizQuestion = quizQuestion;
                  showTypeQuiz = false;
                }}
                class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-md
          shrink-0 sm:w-auto space-x-2 hover:opacity-80"
              >
                <span>{q.name}</span>
              </button>
            {/each}
          </div>
        {/if}
      </div>

      <div class="mt-2 flex flex-col space-y-4">
        {#each quizQuestion as question, index}
          <div class="flex items-start space-x-2">
            <div class="rounded-full bg-gray-200 mt-3 flex px-3 py-2 items-center justify-center">
              <span class="text-gray-700 font-medium text-xs">{index + 1}</span>
            </div>
            <div class="flex-1 flex flex-col space-y-2">
              {#if question.type == "1" || question.type == "2"}
                <Textarea bind:value={question.question} name="{index}-question" placeholder="Masukkan pertanyaan disini" bind:errors noLabel />
              {/if}
              {#if errorMap.findIndex((e) => e["field"] == question.typeId + "-answer") != -1 || errorMap.findIndex((e) => e["field"] == question.typeId + "-question") != -1}
                <p class="text-xs text-red-500">Harap lengkapi pilihan Quiz</p>
              {/if}
              {#each question.items as item, indexItem}
                {#if question.type == "0"}
                  <Textarea bind:value={item.question} name="{index}-question" placeholder="Masukkan pertanyaan disini" bind:errors noLabel />
                {/if}

                {#if question.type == "1"}
                  <div class="flex space-x-2">
                    <input
                      type="radio"
                      class="cursor-pointer mt-3 {errorMap.findIndex((e) => e['field'] == question.typeId + '-answer') != -1
                        ? 'border border-red-500'
                        : ''}"
                      name="{question.typeId}-answer"
                      on:input={(e) => {
                        quizQuestion[index]["items"].map((it) => {
                          if (it.answer == true) {
                            it.answer = false;
                          }
                        });
                        quizQuestion[index]["items"][indexItem].answer = true;
                        quizQuestion = quizQuestion;
                      }}
                    />

                    <div class="flex flex-1">
                      <div class="flex-1">
                        <Input
                          placeholder="Masukkan Pilihan Ganda"
                          name="{indexItem}-{question.typeId}-question"
                          bind:errors
                          noLabel
                          type="text"
                          bind:value={item.question}
                        />
                      </div>
                      <div>
                        {#if indexItem + 1 == quizQuestion[index]["items"].length && quizQuestion[index]["items"].length < 5}
                          <button
                            on:click={() => {
                              quizQuestion[index]["items"].push({
                                id: generateID(10),
                                question: "",
                                answer: false,
                              });
                              quizQuestion = quizQuestion;
                            }}
                            class="px-1 py-1 text-gray-500 transition-colors mt-1 h-8 duration-200 rounded-lg hover:bg-gray-100"
                          >
                            <i class="bx bx-plus text-sm text-blue-500" />
                          </button>
                        {/if}
                        {#if indexItem + 1 > 2}
                          <button
                            on:click={() => {
                              quizQuestion[index]["items"].splice(indexItem, 1);
                              quizQuestion = quizQuestion;
                            }}
                            class="px-1 py-1 text-gray-500 transition-colors mt-1 h-8 duration-200 rounded-lg hover:bg-gray-100"
                          >
                            <i class="bx bx-trash text-sm text-red-500" />
                          </button>
                        {/if}
                      </div>
                    </div>
                  </div>
                {/if}

                {#if question.type == "2"}
                  <div class="flex space-x-2">
                    <input
                      type="checkbox"
                      class="cursor-pointer mt-3 {errorMap.findIndex((e) => e['field'] == question.typeId + '-answer') != -1
                        ? 'border border-red-500'
                        : ''}"
                      name="{question.typeId}-answer"
                      checked={item["answer"]}
                      on:input={(e) => {
                        item["answer"] = !item["answer"];
                      }}
                    />
                    <div class="flex flex-1">
                      <div class="flex-1">
                        <Input
                          placeholder="Masukkan Pilihan"
                          bind:errors
                          name="{indexItem}-{question.typeId}-question"
                          noLabel
                          type="text"
                          bind:value={item.question}
                        />
                      </div>
                      {#if indexItem + 1 == quizQuestion[index]["items"].length && quizQuestion[index]["items"].length < 5}
                        <button
                          on:click={() => {
                            quizQuestion[index]["items"].push({
                              id: generateID(10),
                              question: "",
                              answer: false,
                            });
                            quizQuestion = quizQuestion;
                          }}
                          class="px-1 py-1 text-gray-500 transition-colors duration-200 mt-1 h-8 rounded-lg hover:bg-gray-100"
                        >
                          <i class="bx bx-plus text-sm text-blue-500" />
                        </button>
                      {/if}
                      {#if indexItem + 1 > 2}
                        <button
                          on:click={() => {
                            quizQuestion[index]["items"].splice(indexItem, 1);
                            quizQuestion = quizQuestion;
                          }}
                          class="px-1 py-1 text-gray-500 transition-colors duration-200 mt-1 h-8 rounded-lg hover:bg-gray-100"
                        >
                          <i class="bx bx-trash text-sm text-red-500" />
                        </button>
                      {/if}
                    </div>
                  </div>
                {/if}
              {/each}
            </div>
            <button
              on:click={() => {
                quizQuestion.splice(index, 1);
                quizQuestion = quizQuestion;
              }}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-trash text-sm text-red-500" />
            </button>
          </div>
        {/each}
      </div>
      {#if errorMap.findIndex((e) => e["field"] == "quiz") != -1}
        <p class="text-xs text-red-500">Quiz wajib diisi, minimal 1 Quiz</p>
      {/if}
    </div>

    <div class="mt-4" />

    <div class="flex w-full space-x-4">
      <button
        on:click={() => {
          if (checkBack()) {
            modalBack.toggle();
          } else {
            pop();
          }
        }}
        class="w-full text-white bg-gray-400 hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
        >Batal</button
      >
      <button
        on:click={async () => {
          await add();
        }}
        disabled={disableSubmit}
        class="w-full text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
        >Simpan</button
      >
    </div>
  </div>
</section>
