<script>
  import { push, replace } from "svelte-spa-router";
  import { onMount } from "svelte";
  import Loading from "../../components/Loading.svelte";
  import Cookies from "js-cookie";
  import Modal from "../../components/Modal.svelte";
  import Select from "../../components/control/Select.svelte";
  import UserCard from "../../components/card/UserCard.svelte";
  import TableUser from "../../components/table/TableUser.svelte";
  import Pagination from "../../components/pagination/Pagination.svelte";
  import { ADD_USER_MODULE, perPage, urlApi } from "../../utils/const";
  import { checkRoleModule, parseJwt } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import AuthServices from "../../pages/Auth/AuthServices";

  let users = [];
  let loading;
  let page = 1;
  let search = "";
  let roleSelected = "0";
  let statusSelected = "0";
  let totalData = 0,
    totalPage = 0;
  let modalSendResetPasswordEmail;
  let modalSendEmailVerificaiton;
  let resetPasswordEmail;
  let emailVerificationEmail;
  const token = parseJwt(localStorage.getItem("token"));
  let access = token.RoleID;
  let userId = token.UserID;

  onMount(() => {
    loadRoles();
    loadData();
  });

  async function loadData() {
    loading.toggle();

    const roleOption = roleSelected == "0" ? "" : roleSelected;
    const isActiveOption = statusSelected == "0" ? "" : statusSelected == "2" ? "0" : statusSelected;
    const showAll = 1;
    const withSuperAdmin = access == 5 ? 1 : 0;
    const option =
      "perPage=" +
      perPage +
      "&currentPage=" +
      page +
      "&searchQuery=" +
      search +
      "&isActive=" +
      isActiveOption +
      "&role=" +
      roleOption +
      "&showAll=" +
      showAll +
      "&withSuperAdmin=" +
      withSuperAdmin;
    const res = await fetch(urlApi + "/Users?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    totalData = data["info"]["totalItem"];
    totalPage = data["info"]["totalPage"];
    users = data["data"];
    loading.toggle();
  }
  async function loadRoles() {
    const option = "isActive=1";
    try {
      const res = await fetch(urlApi + "/Users/Roles?" + option, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      for (const item of data["data"]) {
        rolesOption.push({
          id: String(item.id),
          name: item.name,
        });
      }
      rolesOption = rolesOption;
    } catch (e) {
      Cookies.remove("token");
      localStorage.removeItem("token");
      replace("/login");
    }
  }
  async function resetPassword() {
    loading.toggle();
    const reqData = {
      Email: resetPasswordEmail,
    };
    const res = await fetch(urlApi + "/Authorization/ForgotPassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    });
    const data = await res.json();
    if (data["data"]["errorMessage"]) {
      globalThis.pushToast(data["data"]["errorMessage"], "error");
    } else {
      globalThis.pushToast("Email reset password sudah dikirim", "success");
    }
    modalSendResetPasswordEmail.toggle();
    loading.toggle();
  }
  async function sendEmailVerification() {
    loading.toggle();

    const res = await fetch(urlApi + "/Authorization/SendEmailVerification?Email=" + emailVerificationEmail, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    if (res.status == 400) {
      globalThis.pushToast(data["data"]["errorMessage"], "error");
    } else {
      globalThis.pushToast("Email verifikasi berhasil dikirim, silahkan cek email anda", "success");
    }
    modalSendEmailVerificaiton.toggle();
    loading.toggle();
  }
  function searchData() {
    page = 1;
    loadData();
  }
  function toggleResetPasswordModal(email) {
    modalSendResetPasswordEmail.toggle();
    resetPasswordEmail = email;
  }
  function toggleSendEmailVerificationModal(email) {
    modalSendEmailVerificaiton.toggle();
    emailVerificationEmail = email;
  }

  let rolesOption = [{ id: "0", name: "Semua Role" }];

  let statusOption = [
    { id: "0", name: "Semua Status" },
    { id: "1", name: "Aktif" },
    { id: "2", name: "Tidak Aktif" },
  ];
</script>

<Modal index={50} title="Reset Password" bind:this={modalSendResetPasswordEmail} size="default">
  <div class="flex flex-col items-start justify-start space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">
      Kirim tautan email reset password ke alamat email <span class="font-bold">"{resetPasswordEmail}"</span> ?
    </p>
    <div class="flex space-x-2 justify-end items-end mt-4 w-full h-full px-5">
      <button
        on:click={() => modalSendResetPasswordEmail.toggle()}
        class="px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg w-fit"
      >
        Batal
      </button>
      <button
        on:click={() => resetPassword()}
        class="px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg w-fit"
      >
        Kirim
      </button>
    </div>
  </div>
</Modal>

<Modal index={50} title="Kirim Email Verifikasi" bind:this={modalSendEmailVerificaiton} size="default">
  <div class="flex flex-col items-start justify-start space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">
      Kirim tautan email verifikasi ke alamat email <span class="font-bold">"{emailVerificationEmail}"</span> ?
    </p>
    <div class="flex space-x-2 justify-end items-end mt-4 w-full h-full px-5">
      <button
        on:click={() => modalSendEmailVerificaiton.toggle()}
        class="px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg w-fit"
      >
        Batal
      </button>
      <button
        on:click={() => sendEmailVerification()}
        class="px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg w-fit"
      >
        Kirim
      </button>
    </div>
  </div>
</Modal>
<Loading bind:this={loading} />

<div class="p-0 md:p-4">
  <!-- component -->
  <section class="container mx-auto px-4 md:px-0">
    <div class="sm:flex sm:items-center sm:justify-between">
      <div>
        <div class="flex items-center gap-x-3">
          <h2 class="text-lg font-medium text-gray-800">Pengguna</h2>

          <!-- <span
            class="px-3 py-1 text-xs text-primary bg-blue-400/20 rounded-full"
            >{users.length} pengguna</span
          > -->
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-end justify-between flex-col space-y-3 md:flex-row md:space-x-3 md:space-y-0">
      {#if checkRoleModule($roleModules, ADD_USER_MODULE)}
        <div class="flex w-full md:w-auto">
          <button
            on:click={() => push("/users/add")}
            class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-lg
          shrink-0 sm:w-auto space-x-2 hover:opacity-80"
          >
            <i class="bx bx-plus" />

            <span>Tambah Pengguna</span>
          </button>
        </div>
      {/if}

      <div class="flex-1 flex flex-col space-y-3 w-full md:space-y-0 md:flex-row md:space-x-2">
        <div class="flex-1 flex items-center">
          <div class="relative w-full">
            <label class="block text-gray-900 text-xs font-semibold mb-1" for="search"> Cari </label>
            <div class="relative w-full">
              <input
                type="text"
                placeholder="Cari nama atau email.."
                bind:value={search}
                on:input={searchData}
                class="block w-full text-xs py-2 pr-5 text-gray-700 focus:border-gray-300 focus:ring-transparent bg-white
            border border-gray-200 rounded-lg placeholder-gray-400/70 pl-8 focus:outline-none"
              />
              <span class="absolute left-2 top-0 bottom-0 flex items-center">
                <i class="bx bx-search text-gray-500" />
              </span>
            </div>
          </div>
        </div>

        <div class="flex-1 flex space-x-2 w-full">
          <div class="flex-1">
            <Select
              on:change={(e) => {
                statusSelected = e.detail;
                searchData();
              }}
              textHint="Pilih Status"
              label="Status"
              fullWidth
              name="status_search"
              options={statusOption}
              bind:selected={statusSelected}
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-4">
      <div class="overflow-x-auto hidden md:block">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="overflow-hidden rounded-md">
            <TableUser
              bind:data={users}
              bind:page
              on:detail={(e) => push("/users/" + e.detail + "/detail")}
              on:edit={(e) => push("/users/" + e.detail + "/edit")}
              on:reset={(e) => toggleResetPasswordModal(e.detail.email)}
              on:email-verification={(e) => toggleSendEmailVerificationModal(e.detail.email)}
            />
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="overflow-x-auto block md:hidden">
        {#if users.length == 0}
          <p class="font-medium text-gray-500 text-xs text-center mt-2">Belum ada data</p>
        {/if}
        {#each users as user, index}
          <UserCard
            bind:data={user}
            {index}
            bind:page
            on:detail={() => push("/users/" + user["id"] + "/detail")}
            on:edit={() => push("/users/" + user["id"] + "/edit")}
            on:reset={() => toggleResetPasswordModal(user["email"])}
            on:email-verification={(e) => toggleSendEmailVerificationModal(e.detail.email)}
          />
        {/each}
      </div>
    </div>

    <div class="mt-6 sm:flex sm:items-center sm:justify-between">
      <div class="text-sm text-gray-500" />
      {#if users.length > 0 || page > 1}
        <Pagination
          bind:page
          {totalData}
          {totalPage}
          {perPage}
          on:prev={() => {
            page -= 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          on:next={() => {
            page += 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
      {/if}
    </div>
  </section>
</div>
