<script>
  import Router, { replace, location } from "svelte-spa-router";
  import Add from "./Add.svelte";
  import Edit from "./Edit.svelte";
  import Detail from "./Detail.svelte";
  import LayoutSidebar from "../../layouts/LayoutSidebar.svelte";
  import List from "./List.svelte";
  import Reset from "./Reset.svelte";
  import { onMount } from "svelte";
  import Cookies from "js-cookie";
  import { parseJwt } from "../../utils/utils";

  const prefix = "/users";
  const routes = {
    "/": List,
    "/add": Add,
    "/:_id/edit": Edit,
    "/:_id/detail": Detail,
    "/:_id/reset-password": Reset,
  };

  let openSidebar = false;

  const token = parseJwt(localStorage.getItem("token"));
  onMount(() => {
    const currentUrl = $location; // return /users/id/detail
    const currentComponent = currentUrl.substring(currentUrl.lastIndexOf("/") + 1); // return detail
    if (token.RoleID == "4" && currentComponent != "detail" && currentComponent != "edit") {
      replace("/journals/");
    }
  });
</script>

<LayoutSidebar bind:openSidebar>
  <div class="md:flex pt-14 mt-8 md:mt-4">
    <!-- // content -->
    <div class="w-full md:ml-64">
      <Router {routes} {prefix} />
    </div>
  </div>
</LayoutSidebar>
