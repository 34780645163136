<script>
  import { createEventDispatcher } from "svelte";
  import { checkRoleModule, formatDateForView, getStatusAnswerUser, getStatusLearning } from "../../utils/utils";
  import { DETAIL_LEARNING_MODULE, EDIT_LEARNING_MODULE, EXPORT_LEARNING, LEARNING_MEMBER, LESSON } from "../../utils/const";
  import { roleModules } from "../../stores/store";
  import { learningStatusObject } from "../../pages/Learning/LearningConst";
  const dispatch = createEventDispatcher();
  import { parseJwt } from "../../utils/utils";

  export let data;
  const token = parseJwt(localStorage.getItem("token"));
  let id = token.UserID;
</script>

<div class="card-data min-w-full mb-3 p-2 border border-primary shadow rounded-md">
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Nama Materi</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["title"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Kategori Sel</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["covenantYearName"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Sel Group</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["cellGroupName"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Tanggal Published</p>
    <p class="flex-1 line-clamp-2 text-right">
      {formatDateForView(new Date(data["publishDate"]))}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Tanggal Deadline</p>
    <p class="flex-1 line-clamp-2 text-right">
      {formatDateForView(new Date(data["overdueDate"]))}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between items-center text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Status</p>
    <p
      class="inline px-3 py-1 text-xs font-normal rounded-full {data['status'] == 1
        ? 'text-emerald-500 bg-emerald-100/60'
        : data['status'] == 2
        ? 'text-cyan-500 bg-cyan-100/60'
        : 'text-red-500 bg-red-100/60'}"
    >
      {getStatusLearning(data["status"])}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  {#if token.RoleID == 4}
    <div class="flex justify-between items-center text-xs font-medium text-gray-800">
      <p class="flex-1 text-left">Status Submission</p>
      <p
        class="inline px-3 py-1 text-xs font-normal rounded-full {data['statusAnswerUser'] == 1
          ? 'text-emerald-500 bg-emerald-100/60'
          : data['statusAnswerUser'] == 0
          ? 'text-gray-500 bg-gray-100/50'
          : data['statusAnswerUser'] == 3
          ? 'text-blue-500 bg-blue-100/50'
          : 'text-red-500 bg-red-100/60'}"
      >
        {getStatusAnswerUser(data["statusAnswerUser"])}
      </p>
    </div>
    <hr class="divide-y-2 my-2" />
  {/if}

  <div class="flex justify-between items-center text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Aksi</p>
    <div class="flex-1 flex space-x-1 justify-end">
      {#if checkRoleModule($roleModules, DETAIL_LEARNING_MODULE)}
        <button
          on:click={() => dispatch("detail", { id: data["id"], type: data["type"] })}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-show text-md" />
        </button>
      {/if}

      {#if checkRoleModule($roleModules, EDIT_LEARNING_MODULE) && data["status"] == 2}
        <button
          on:click={() => dispatch("edit", { id: data["id"], type: data["type"] })}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-pencil text-md" />
        </button>
      {/if}

      {#if checkRoleModule($roleModules, LEARNING_MEMBER)}
        <button
          on:click={() => dispatch("member", { id: data["id"] })}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-file text-md" />
        </button>
      {/if}

      {#if checkRoleModule($roleModules, LESSON) && token.RoleID == 4 && data["status"] != learningStatusObject.Upcoming}
        <button
          on:click={() => dispatch("lesson", { id: data["id"] })}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-book-add text-md" />
        </button>
      {/if}
      {#if checkRoleModule($roleModules, EXPORT_LEARNING) && data["status"] != learningStatusObject.Upcoming}
            <button
              on:click={() => dispatch("export", { id: data["id"] })}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-export text-md" />
            </button>
          {/if}
    </div>
  </div>
</div>
