<script>
  export let data;
</script>

<div class="card-data max-w-lg mb-3 p-2 border border-primary shadow rounded-md">
  <div class="text-xs font-medium text-gray-800">
    <p class="line-clamp-2 text-left">
      {data["roleName"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="text-xs font-medium text-gray-800">
    <p class=" line-clamp-2 text-left">
      {data["groupYear"] == "" ? "-" : data["groupYear"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="text-xs font-medium text-gray-800">
    <p class=" line-clamp-2 text-left">
      {data["groupName"] == "" ? "-" : data["groupName"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="text-xs font-medium text-gray-800">
    <p class=" line-clamp-2 text-left">
      {data["groupSpv"] == "" ? "-" : data["groupSpv"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="text-xs font-medium text-gray-800">
    <p
      class="inline px-3 py-1 text-xs font-normal rounded-full {data['groupID'] == 0
        ? 'text-blue-500 bg-blue-100/60'
        : data['groupStatus'] == '1'
        ? 'text-emerald-500 bg-emerald-100/60'
        : 'text-red-500 bg-red-100/60'}  gap-x-2"
    >
      {data["groupID"] == 0 ? "Default" : data["groupStatus"] == "1" ? "Active" : "In Active"}
    </p>
  </div>
</div>
