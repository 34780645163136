<script>
  import Toast from "../components/Toast.svelte";
  import { link, location } from "svelte-spa-router";
  import { bgColorDefault, bgPrimaryColor, textPrimaryColor } from "../utils/const";
  import { version } from "../utils/utils";

  let openMobileMenu = false;
</script>

<nav class="fixed top-0 z-30 w-full bg-white border-b-2 py-4 md:py-0 border-gray-100">
  <div class="max-w-screen flex flex-wrap items-center justify-between mx-auto px-4">
    <a href="/" class="flex items-center">
      <!-- LOGO -->
      <img src="/images/logo.png" class="h-12" alt="Dimus Cordis" />
    </a>
    <button
      type="button"
      on:click={() => (openMobileMenu = !openMobileMenu)}
      class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      aria-controls="navbar-default"
      aria-expanded="false"
    >
      <span class="sr-only">Open main menu</span>
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
        ><path
          fill-rule="evenodd"
          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
          clip-rule="evenodd"
        /></svg
      >
    </button>
    <!-- MENU FOR MOBILE -->
    <div class="{!openMobileMenu ? 'hidden' : ''} block top-12 left-0 z-10 p-5 w-full md:block md:w-auto" id="navbar-default">
      <ul class="font-medium flex {!openMobileMenu ? '' : 'flex-col p-4 mt-4 shadow-md rounded-lg'} md:p-0 bg-white">
        <li>
          <a
            use:link
            href="/login"
            class="block py-2 px-3 text-center {$location == '/login' ? 'text-white ' + bgPrimaryColor : 'text-gray-900 hover:bg-gray-200'} rounded"
            >Login</a
          >
        </li>
        <li>
          <a
            use:link
            href="/register"
            class="block py-2 px-3 text-center {$location == '/register'
              ? 'text-white ' + bgPrimaryColor
              : 'text-gray-900 hover:bg-gray-200'} rounded">Register</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="relative">
  <Toast />
  <slot />
</div>
