<script>
  import Toast from "../components/Toast.svelte";
  import { blur } from "svelte/transition";
  import AuthServices from "../pages/Auth/AuthServices";
  import { replace, push, location, link } from "svelte-spa-router";
  import active from "svelte-spa-router/active";
  import { checkRoleModule, version, setProfilePicture, lastCheckProfile, redirectAfterChangeRole } from "../utils/utils";
  import { CELLGROUP_MODULE, ATTENDANCE_MODULE, JOURNAL_MODULE, ONE_MODULE, USER_MODULE, urlApi, LEARNING_MODULE } from "../utils/const";
  import { ATTENDANCE_CATEGORY_MODULE, MASTER_DATA, CELL_CATEGORY_MODULE } from "../utils/const";
  import { parseJwt } from "../utils/utils";
  import { onMount } from "svelte";
  import Select from "../components/control/Select.svelte";
  import { roleModules } from "../stores/store";
  import Loading from "../components/Loading.svelte";

  export let openSidebar = false;
  let openMenuUser = false;
  let loading;
  let className = "group menu-active  hover:opacity-80 bg-primary pl-4";
  let inactiveClassName = "hover:bg-gray-100";
  const token = parseJwt(localStorage.getItem("token"));
  const tokenRole = parseJwt(localStorage.getItem("tokenRole"));
  let imageSrc = localStorage.getItem("imageSrc");
  let userRoles = [];
  let roleOption = [];
  let url = window.location.href;
  let isMasterDataExpand = url.includes("attendancecategory") || url.includes("cellcategory");
  if (tokenRole) {
    userRoles = JSON.parse(tokenRole.Roles);
    roleOption = userRoles.map((x) => ({ id: x.RolesID.toString(), name: x.RoleName }));
    roleOption = roleOption.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
  }

  onMount(async () => {
    imageSrc = (await lastCheckProfile(new Date())) ? localStorage.getItem("imageSrc") : imageSrc;
  });

  async function changeRole(e) {
    loading.toggle();
    const reqData = {
      ID: token.UserID,
      RoleID: e.detail,
    };

    const tokenData = await AuthServices.refreshToken();
    localStorage.setItem("tokenRole", tokenData["data"]["tokenRole"]);
    const mostRecentTokenRole = parseJwt(localStorage.getItem("tokenRole"));
    const mostRecentRole = JSON.parse(mostRecentTokenRole.Roles);
    if (mostRecentRole.filter((x) => x.RolesID == e.detail) <= 0) reqData.RoleID = (4).toString();

    const res = await fetch(urlApi + "/Users/ChangeRole", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(reqData),
    });

    const data = await res.json();
    if (data["data"]["id"]) {
      let url = window.location.href;
      redirectAfterChangeRole(url);
    }
  }

  function onClickMasterDataMenu() {
    isMasterDataExpand = !isMasterDataExpand;
  }
</script>

<Loading bind:this={loading} />

<nav class="fixed top-0 z-40 md:z-20 w-full bg-white border-b border-gray-200">
  <div class="px-3 py-3 md:px-5">
    <div class="flex items-center justify-between">
      {#if localStorage.getItem("token") != null && localStorage.getItem("token") != undefined && localStorage.getItem("token") != ""}
        <div class="flex items-center justify-center md:hidden">
          <!-- Menu Sidebar Burger -->
          <button
            data-drawer-target="logo-sidebar"
            data-drawer-toggle="logo-sidebar"
            aria-controls="logo-sidebar"
            on:click={() => {
              openSidebar = !openSidebar;
            }}
            type="button"
            class="inline-flex md:hidden items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          >
            <span class="sr-only">Open sidebar</span>
            <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                fill-rule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
              />
            </svg>
          </button>
        </div>
      {/if}
      <a use:link href="/users/" class="flex">
        <img src="/images/logo.png" class="w-28" alt="Domus Cordis" />
      </a>
      <div class="flex items-center">
        {#if localStorage.getItem("token") == null || localStorage.getItem("token") == undefined || localStorage.getItem("token") == ""}
          <!-- MENU FOR MOBILE -->
          <div class="block top-12 left-0 z-10 w-full" id="navbar-default">
            <ul class="font-medium flex {!openMenuUser ? '' : 'flex-col p-4 mt-4 shadow-md rounded-lg'} md:p-0 bg-white">
              <li>
                <a
                  use:link
                  href="/login"
                  class="block py-2 px-3 text-center {$location == '/login' ? 'text-white  bg-primary' : 'text-gray-900 hover:bg-gray-200'} rounded"
                  >Login</a
                >
              </li>
              <li>
                <a
                  use:link
                  href="/register"
                  class="block py-2 px-3 text-center {$location == '/register' ? 'text-white bg-primary' : 'text-gray-900 hover:bg-gray-200'} rounded"
                  >Register</a
                >
              </li>
            </ul>
          </div>
        {:else}
          <div class="flex items-center">
            <div>
              <button
                type="button"
                on:click={() => {
                  openMenuUser = !openMenuUser;
                }}
                class="flex text-sm bg-gray-800 rounded-full ring-transparent"
                aria-expanded="false"
                data-dropdown-toggle="dropdown-user"
              >
                <span class="sr-only">Open user menu</span>
                <img src={setProfilePicture(imageSrc)} class="w-8 h-8 rounded-full object-cover" alt="user photo" />
              </button>
            </div>
            <div
              class="z-20 {openMenuUser
                ? 'block'
                : 'hidden'} absolute top-16 right-5 my-4 shadow-md text-base list-none bg-white divide-y divide-gray-100 rounded"
              id="dropdown-user"
            >
              <div class="px-4 py-3" role="none">
                <p class="text-sm text-gray-900" role="none">
                  {localStorage.getItem("name")}
                </p>
                <p class="text-sm mb-2 font-medium text-gray-900 truncate" role="none">
                  {localStorage.getItem("email")}
                </p>
                <Select name="role" on:change={(e) => changeRole(e)} options={roleOption} selected={token.RoleID} />
              </div>
              <ul class="py-1" role="none">
                <li>
                  <!-- svelte-ignore a11y-interactive-supports-focus -->
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <!-- svelte-ignore a11y-missing-attribute -->
                  <a
                    on:click={() => {
                      push("/users/" + token.UserID + "/detail");
                    }}
                    class="block cursor-pointer px-4 py-2 text-sm text-gray-900 font-medium hover:bg-gray-100"
                    role="menuitem">Profil</a
                  >
                </li>
              </ul>
              <ul class="py-1" role="none">
                <li>
                  <!-- svelte-ignore a11y-interactive-supports-focus -->
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <!-- svelte-ignore a11y-missing-attribute -->
                  <a
                    on:click={() => {
                      localStorage.removeItem("token");
                      localStorage.removeItem("tokenRole");
                      replace("/login");
                    }}
                    class="block cursor-pointer px-4 py-2 text-sm text-red-500 font-medium hover:bg-gray-100"
                    role="menuitem">Keluar</a
                  >
                </li>
              </ul>
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</nav>

<div
  id="logo-sidebar"
  class="{openSidebar ? 'block translate-x-0' : 'hidden -translate-x-full'} z-30 {localStorage.getItem('token')
    ? 'md:block md:translate-x-0'
    : ''} fixed top-0 left-0 md:z-10 w-64 h-screen pt-24 transition-transform bg-white border-r border-gray-200"
  aria-label="Sidebar"
>
  <div class="relative h-full px-3 pb-4 overflow-y-auto bg-white">
    <ul class="space-y-2 font-medium">
      {#if checkRoleModule($roleModules, USER_MODULE)}
        <li>
          <a
            use:link
            href="/users/"
            use:active={{ path: "/users/*", className, inactiveClassName }}
            class="flex items-center p-2 transition-all rounded-lg cursor-pointer"
          >
            <i class="bx bx-user group-[.menu-active]:text-white text-inactivePrimary" />
            <span class="ml-3 text-sm group-[.menu-active]:text-white text-gray-900">Pengguna</span>
          </a>
        </li>
      {/if}
      {#if checkRoleModule($roleModules, JOURNAL_MODULE)}
        <li>
          <a
            use:link
            href="/journals/"
            use:active={{ path: "/journals/*", className, inactiveClassName }}
            class="flex items-center p-2 transition-all rounded-lg cursor-pointer"
          >
            <i class="bx bx-book group-[.menu-active]:text-white text-inactivePrimary" />
            <span class="ml-3 text-sm group-[.menu-active]:text-white text-gray-900">Covenant Journal</span>
          </a>
        </li>
      {/if}
      {#if checkRoleModule($roleModules, ONE_MODULE)}
        <li>
          <a
            use:link
            href="/ones/"
            use:active={{ path: "/ones/*", className, inactiveClassName }}
            class="flex items-center p-2 transition-all rounded-lg cursor-pointer"
          >
            <i class="bx bx-conversation group-[.menu-active]:text-white text-inactivePrimary" />
            <span class="ml-3 text-sm group-[.menu-active]:text-white text-gray-900">121</span>
          </a>
        </li>
      {/if}
      {#if checkRoleModule($roleModules, ATTENDANCE_MODULE)}
        <li>
          <a
            use:link
            href="/attendances/"
            use:active={{ path: "/attendances/*", className, inactiveClassName }}
            class="flex items-center p-2 transition-all rounded-lg cursor-pointer"
          >
            <i class="bx bx-calendar-check group-[.menu-active]:text-white text-inactivePrimary" />
            <span class="ml-3 text-sm group-[.menu-active]:text-white text-gray-900">Attendance</span>
          </a>
        </li>
      {/if}
      {#if checkRoleModule($roleModules, CELLGROUP_MODULE)}
        <li>
          <a
            use:link
            href="/cellgroup/"
            use:active={{ path: "/cellgroup/*", className, inactiveClassName }}
            class="flex items-center p-2 transition-all rounded-lg cursor-pointer"
          >
            <i class="bx bx-group group-[.menu-active]:text-white text-inactivePrimary" />
            <span class="ml-3 text-sm group-[.menu-active]:text-white text-gray-900">Sel Group</span>
          </a>
        </li>
      {/if}
      {#if checkRoleModule($roleModules, LEARNING_MODULE)}
        <li>
          <a
            use:link
            href="/learning/"
            use:active={{ path: "/learning/*", className, inactiveClassName }}
            class="flex items-center p-2 transition-all rounded-lg cursor-pointer"
          >
            <i class="bx bx-book-open group-[.menu-active]:text-white text-inactivePrimary" />
            <span class="ml-3 text-sm group-[.menu-active]:text-white text-gray-900">Learning Management System</span>
          </a>
        </li>
      {/if}
      {#if checkRoleModule($roleModules, MASTER_DATA)}
        <div class="cursor-pointer">
          <li class="hover:bg-gray-100">
            <button class="w-full" on:click={onClickMasterDataMenu}>
              <div class="flex justify-between items-center">
                <div class="flex items-center p-2 transition-all rounded-lg cursor-pointer">
                  <i class="bx bx-data text-inactivePrimary" />
                  <span class="ml-3 text-sm text-gray-900">Master Data</span>
                </div>
                <i class="bx bx-chevron-{isMasterDataExpand ? 'up' : 'down'} text-xl cursor-pointer hover:opacity-90" />
              </div>
            </button>
          </li>
        </div>
        <ul class="pl-8 mt-2 {isMasterDataExpand ? '' : 'hidden'}">
          {#if checkRoleModule($roleModules, ATTENDANCE_CATEGORY_MODULE)}
            <li>
              <a
                use:link
                href="/attendancecategory/"
                use:active={{ path: "/attendancecategory/*", className, inactiveClassName }}
                class="flex items-center p-2 transition-all rounded-lg cursor-pointer"
              >
                <i class="bx bx-calendar-edit group-[.menu-active]:text-white text-inactivePrimary" />
                <span class="ml-3 text-sm group-[.menu-active]:text-white text-gray-900">Kategori Attendance</span>
              </a>
            </li>
          {/if}
          {#if checkRoleModule($roleModules, CELL_CATEGORY_MODULE)}
            <li>
              <a
                use:link
                href="/cellcategory/"
                use:active={{ path: "/cellcategory/*", className, inactiveClassName }}
                class="flex items-center p-2 transition-all rounded-lg cursor-pointer"
              >
                <i class="bx bxs-user-detail group-[.menu-active]:text-white text-inactivePrimary" />
                <span class="ml-3 text-sm group-[.menu-active]:text-white text-gray-900">Kategori Sel</span>
              </a>
            </li>
          {/if}
        </ul>
      {/if}
    </ul>
    <div class="absolute left-0 bottom-0 px-4 py-2">
      <p class="font-medium text-xs text-gray-500">Domus Cordis - {version}</p>
    </div>
  </div>
</div>

<div
  drawer-backdrop=""
  in:blur={{ duration: 250 }}
  class=" {openSidebar ? 'block' : 'hidden'} bg-gray-900 backdrop-blur-sm bg-opacity-50 transition-all dark:bg-opacity-80 fixed inset-0"
  style="z-index: 25;"
/>

<div class="relative">
  <Toast />
  <slot />
</div>
