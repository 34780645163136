<script>
  import { onMount } from "svelte";
  import Layout from "../layouts/Layout.svelte";
  import { replace } from "svelte-spa-router";
  onMount(() => {
    replace("/users/");
  });
</script>

<Layout>
  <p class="text-blue-500">tes</p>
</Layout>
