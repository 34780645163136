<script>
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();

  export let name;
  export let selected;
  export let errors = [];
  export let label = "";
  export let textHint = "Choose";
  export let options = [];
  export let disabled = false;
  export let fullWidth = false;
  export let extClass = "";
  export let textSize = "text-xs";
  export let enableTextHint = false;

  $: if (errors.length > 0) {
    errors = errors
      .filter((err) => err.split(".")[0] == name)
      .map((e) => ({
        field: e.split(".")[0],
        error: e.split(".")[1],
      }));
    errors = errors;
  }
</script>

{#if label}
  <label class="block font-semibold text-gray-900 {textSize} mb-1" for={selected}>
    {label}
  </label>
{/if}
<div class="relative flex flex-col {fullWidth ? ' w-full ' : ''}">
  <select
    bind:value={selected}
    {name}
    on:change={(e) => {
      selected = e.target.value;
      dispatch("change", selected);
    }}
     on:blur={() => {
      dispatch("blur");
    }}
     on:click={() => {
      dispatch("click");
    }}
    class="flex-1 w-full cursor-pointer block {textSize}  text-gray-700 border {errors.length > 0
      ? 'border-red-500 focus:border-red-600'
      : 'border-gray-200 focus:border-gray-300'}  rounded-lg px-4 pr-7 focus:ring-transparent {extClass}"
    {disabled}
  >
    {#if selected == null || selected == "" || selected == undefined}
      <option class="text-gray-400/70" selected disabled value="">{textHint}</option>
    {:else if enableTextHint}
      <option class="text-gray-400/70" value="">{textHint}</option>
    {/if}
    {#each options as option}
      {#if selected == option["id"]}
        <option value={option["id"]} selected>{option.name}</option>
      {:else}
        <option value={option["id"]}>{option.name}</option>
      {/if}
    {/each}
  </select>
  {#if errors.length > 0 && errors[0]["error"] == "required"}
    <p class="text-xs text-left text-red-500 p-0 mt-1">{label} harus diisi</p>
  {/if}
</div>
