<script>
  import { field } from "svelte-forms";
  import { required } from "svelte-forms/validators";
  import { replace } from "svelte-spa-router";
  import CellCategoryForm from "./CellCategoryForm.svelte";
  import CellCategoryService from "./CellCategoryServices";
  import { onMount } from "svelte";
  import Loading from "../../components/Loading.svelte";

  export let params;
  let name = field("name", "", [required()]);
  let note = field("note", "", []);

  let disableSubmit = false;
  let errors = [];
  let status = true;
  let loading;
  const id = params["_id"];

  onMount(() => {
    loadData();
  });

  async function loadData() {
    disableSubmit = true;
    loading.toggle();
    errors = [];
    const data = await CellCategoryService.getCellCategoryByID(id);
    if (data["data"]["id"]) {
      $name.value = data["data"]["name"];
      $note.value = data["data"]["note"];
      status = data["data"]["isActive"];
    }
    loading.toggle();
  }
</script>

<Loading bind:this={loading} />
<section class="container px-4 py-4">
  <CellCategoryForm
    formTitle="Detail Kategori Sel"
    mode="detail"
    concernedID={id}
    bind:disableSubmit
    bind:errors
    bind:name={$name.value}
    bind:note={$note.value}
    bind:status
    on:back={() => replace("/attendancecategory/")}
  />
</section>
