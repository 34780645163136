<script>
  import { parseJwt } from "../../utils/utils";

  const token = parseJwt(localStorage.getItem("token"));

  export let roles;
</script>

<table class="min-w-full divide-y divide-gray-200">
  <thead class="bg-gray-100">
    <tr>
      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left text-gray-500"> Role </th>

      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left text-gray-500"> Kategori Sel </th>

      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Sel Group </th>

      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Supervisor </th>

      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Status </th>
    </tr>
  </thead>
  <tbody class="bg-white divide-y divide-gray-200">
    {#if roles.length == 0}
      <tr>
        <td colspan="5" class="px-4 py-4 text-sm font-medium">
          <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
        </td>
      </tr>
    {/if}
    {#each roles as item, index}
      <tr>
        <td class="px-4 py-2 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["roleName"]}
          </h2>
        </td>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">{item["groupYear"] == "" ? "-" : item["groupYear"]}</h2>
        </td>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">{item["groupName"] == "" ? "-" : item["groupName"]}</h2>
        </td>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">{item["groupSpv"] == "" ? "-" : item["groupSpv"]}</h2>
        </td>

        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <div
            class="inline px-3 py-1 text-xs font-normal rounded-full {item['groupID'] == 0
              ? 'text-blue-500 bg-blue-100/60'
              : item['groupStatus'] == '1'
              ? 'text-emerald-500 bg-emerald-100/60'
              : 'text-red-500 bg-red-100/60'}  gap-x-2"
          >
            {item["groupID"] == 0 ? "Default" : item["groupStatus"] == "1" ? "Active" : "In Active"}
          </div>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
