<script>
  import Router, { replace } from "svelte-spa-router";
  import LayoutSidebar from "../../layouts/LayoutSidebar.svelte";
  import List from "./List.svelte";
  import Add from "./Add.svelte";
  import Detail from "./Detail.svelte";
  import Edit from "./Edit.svelte";
  import { onMount } from "svelte";
  import { checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import { ATTENDANCE_CATEGORY_MODULE } from "../../utils/const";

  const prefix = "/attendancecategory";
  const routes = {
    "/": List,
    "/add/": Add,
    "/:_id/edit": Edit,
    "/:_id/detail": Detail,
  };

  onMount(() => {
    if (!checkRoleModule($roleModules, ATTENDANCE_CATEGORY_MODULE)) {
      replace("/");
    }
  });

  let openSidebar = false;
</script>

<LayoutSidebar bind:openSidebar>
  <div class="md:flex pt-14 mt-8 md:mt-4">
    <div class="w-full md:ml-64">
      <Router {routes} {prefix} />
    </div>
  </div>
</LayoutSidebar>
