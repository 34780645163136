<script>
  import { push } from "svelte-spa-router";
  import { onMount } from "svelte";
  import Loading from "../../components/Loading.svelte";
  import OneExportService from "./OneExportService";
  import Pagination from "../../components/pagination/Pagination.svelte";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import { addDays, differenceInDays } from "date-fns";
  import { formatDate, parseJwt } from "../../utils/utils";
  import { perPage, urlApi } from "../../utils/const";
  import TableOne from "../../components/table/TableOne.svelte";
  import OneCard from "../../components/card/OneCard.svelte";
  import Select from "../../components/control/Select.svelte";
  import { typeOneOption, ADD_SPIRITUAL_CONVERSATION_MODULE, ADD_ONE_MODULE, EXPORT_ONE } from "../../utils/const";
  import { checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import Modal from "../../components/Modal.svelte";
  import OneService from "./OneService";

  const token = parseJwt(localStorage.getItem("token"));
  let dataList = [];
  let loading;
  let page = 1;
  let search = "";
  let totalData = 0,
    totalPage = 0;
  let typeOneOptionFiltered = [...typeOneOption];
  let showType = false;
  let typeOneSelected = "0";

  let from = addDays(new Date(), -7);
  let to = new Date();
  let modalDeleteOne;
  let idToDelete;
  let creatorName;
  let oneType;
  let disableSimpan = false;
  let oneTwoOneCount;
  let spiritualConversationCount;
  onMount(() => {
    loadData();
  });

  async function loadData() {
    loading.toggle();
    const showAll = token.RoleID == 5 ? 1 : 0;
    const roleID = token.RoleID == 5 ? 0 : token.RoleID;
    let oneType;
    if (token.RoleID == 5) oneType = typeOneSelected == "0" ? "" : typeOneSelected == "1" ? "121" : "Spiritual Conversation";
    else oneType = parseInt(token.RoleID) == 4 ? "121" : "Spiritual Conversation";

    const option =
      "perPage=" +
      perPage +
      "&currentPage=" +
      page +
      "&searchQuery=" +
      search +
      "&fromDate=" +
      formatDate(from) +
      "&toDate=" +
      formatDate(to) +
      "&oneType=" +
      oneType +
      "&showAll=" +
      showAll +
      "&roleID=" +
      roleID;
    const res = await fetch(urlApi + "/Ones?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();

    // if head gembala does not have entry
    if (token.RoleID == 1 && data["data"][0]["oneTwoOneCount"] >= 0) {
      dataList = []; // datalist for table is empty,
      oneTwoOneCount = data["data"][0].oneTwoOneCount; // then count 121
      spiritualConversationCount = data["data"][0].spiritualConversationCount; // and count SC
    } else {
      // the normal flow
      dataList = data["data"];
    }
    totalData = data["info"]["totalItem"];
    totalPage = data["info"]["totalPage"];
    loading.toggle();

    if (token.RoleID == 5) return;
    if (!checkRoleModule($roleModules, ADD_SPIRITUAL_CONVERSATION_MODULE)) {
      typeOneOptionFiltered = typeOneOptionFiltered.filter((x) => x.name != "Spiritual Conversation");
    }

    if (parseInt(token.RoleID) != 4) {
      typeOneOptionFiltered = typeOneOptionFiltered.filter((x) => x.name != "121");
    }
  }

  function searchData() {
    page = 1;
    loadData();
  }
  async function exportOneReporting() {
    await OneExportService.exportOneReporting(from, to);
  }
  function onDeleteOne(oneID, name, type) {
    idToDelete = oneID;
    creatorName = name;
    oneType = type;
    modalDeleteOne.toggle();
  }
  async function deleteOne() {
    disableSimpan = true;
    loading.toggle();
    const reqData = { ID: idToDelete, SpiritualConvAnswer: [] };
    const data = await OneService.deleteOne(reqData);
    if (data["data"]) {
      globalThis.pushToast("Berhasil Menghapus " + oneType, "success");
      loadData();
    }
    disableSimpan = false;
    loading.toggle();
    modalDeleteOne.toggle();
  }
</script>

<Loading bind:this={loading} />
<Modal
  index={50}
  title="Hapus {oneType}"
  bind:this={modalDeleteOne}
  size="default"
  closed
  on:close={() => {
    modalDeleteOne.toggle();
  }}
>
  <div class="flex flex-col items-start justify-start space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">
      Apakah Anda yakin akan menghapus data {oneType}
      {oneType == "121" ? "Member" : ""} "{creatorName}"?
    </p>
    <div class="flex space-x-2 justify-end items-end mt-4 w-full h-full px-5">
      <button
        on:click={() => {
          modalDeleteOne.toggle();
        }}
        class="px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg w-fit"
      >
        Batal
      </button>
      <button
        on:click={() => {
          deleteOne();
        }}
        disabled={disableSimpan}
        class="px-3 py-2 text-white text-sm font-medium bg-red-500 transition-colors duration-200 rounded-lg w-fit"
      >
        Hapus
      </button>
    </div>
  </div>
</Modal>
<div class="p-0 md:p-4">
  <!-- component -->
  <section class="container mx-auto px-4 md:px-0">
    <div class="sm:flex sm:items-center sm:justify-between">
      <div>
        <div class="flex items-center gap-x-3">
          <h2 class="text-lg font-medium text-gray-800">Daftar 121</h2>
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-end justify-between flex-col space-y-3 md:flex-row md:space-y-0">
      {#if checkRoleModule($roleModules, ADD_ONE_MODULE)}
        <div class="flex w-full md:w-auto relative mr-2">
          {#if checkRoleModule($roleModules, ADD_SPIRITUAL_CONVERSATION_MODULE)}
            <button
              on:click={() => push("/ones/add/Spiritual Conversation")}
              class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-lg
              shrink-0 sm:w-auto space-x-2 hover:opacity-80"
            >
              <i class="bx bx-plus" />
              <span>Tambah Spiritual Conversation</span>
            </button>
          {:else}
            <button
              on:click={() => push("/ones/add/121")}
              class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-lg
              shrink-0 sm:w-auto space-x-2 hover:opacity-80"
            >
              <i class="bx bx-plus" />
              <span>Tambah 121</span>
            </button>
          {/if}
          <!-- {#if showType}
          <div class="absolute top-10 left-0 flex flex-col space-y-1 w-full bg-white z-20">
            {#each typeOneOptionFiltered as type}
              {#if type.id != 0}
                <button
                  on:click={() => push("/ones/add/" + type.name)}
                  class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-md
            shrink-0 sm:w-auto space-x-2 hover:opacity-80"
                >
                  <span>{type.name}</span>
                </button>
              {/if}
            {/each}
          </div>
        {/if} -->
        </div>
      {/if}

      <div class="flex-1 flex flex-col space-y-3 w-full md:space-y-0 md:flex-row md:space-x-2">
        <!-- Hide search bar from super admin -->
        {#if token.RoleID != 5}
          <div class="flex-1 flex items-center">
            <div class="relative w-full">
              <label class="block text-gray-900 text-xs font-semibold mb-1" for="search"> Cari </label>
              <div class="relative w-full">
                <input
                  type="text"
                  placeholder="Cari pembuat 121.."
                  bind:value={search}
                  on:input={searchData}
                  class="block w-full text-xs py-2 pr-5 text-gray-700 focus:border-gray-300 focus:ring-transparent bg-white
                border border-gray-200 rounded-lg placeholder-gray-400/70 pl-8 focus:outline-none"
                />
                <span class="absolute left-2 top-0 bottom-0 flex items-center">
                  <i class="bx bx-search text-gray-500" />
                </span>
              </div>
            </div>
          </div>
        {/if}

        <div class="{token.RoleID != 5 ? 'hidden' : ''} flex-1 space-x-1 w-full">
          <div class="flex-1">
            <Select
              on:change={(e) => {
                typeOneSelected = e.detail;
                searchData();
              }}
              textHint="Pilih Tipe"
              label="Tipe 121"
              fullWidth
              name="typeOne_search"
              options={typeOneOptionFiltered}
              bind:selected={typeOneSelected}
            />
          </div>
        </div>

        <div class="flex-1 flex space-x-1 w-full">
          <div class="flex-1">
            <DatePicker label="Tanggal" name="date" bind:from bind:to ranged on:update={searchData} />
          </div>
        </div>
      </div>
    </div>
    {#if checkRoleModule($roleModules, EXPORT_ONE)}
      <div class="mt-4 flex justify-end">
        <button
          on:click={() => exportOneReporting()}
          class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-md
              shrink-0 sm:w-auto space-x-2 hover:opacity-80"
        >
          <span>Export</span>
        </button>
      </div>
    {/if}
    <div class="flex flex-col mt-4">
      <div class="overflow-x-auto hidden md:block">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="overflow-hidden rounded-md">
            <TableOne
              bind:data={dataList}
              bind:page
              bind:totalData
              bind:oneTwoOneCount
              bind:spiritualConversationCount
              on:detail={(e) => push("/ones/" + e.detail["id"] + "/" + e.detail["type"] + "/detail")}
              on:edit={(e) => push("/ones/" + e.detail["id"] + "/" + e.detail["type"] + "/edit")}
              on:delete={(e) => onDeleteOne(e.detail["id"], e.detail["name"], e.detail["type"])}
            />
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="overflow-x-auto block md:hidden">
        {#if dataList.length == 0 && totalData == 0}
          <p class="font-medium text-gray-500 text-xs text-center mt-2">Belum ada data</p>
        {:else if dataList.length == 0 && totalData > 0}
          <p class="font-medium text-gray-500 text-xs text-center mt-2">
            Terdapat {oneTwoOneCount} Data 121 dan {spiritualConversationCount} Data Spiritual Conversation
          </p>
        {/if}
        {#each dataList as item, index}
          <OneCard
            bind:data={item}
            {index}
            bind:page
            on:detail={() => push("/ones/" + item["id"] + "/" + item["type"] + "/detail")}
            on:edit={() => push("/ones/" + item["id"] + "/" + item["type"] + "/edit")}
            on:delete={() => onDeleteOne(item["id"], item["name"], item["type"])}
          />
        {/each}
      </div>
    </div>

    <div class="mt-6 sm:flex sm:items-center sm:justify-between">
      <div class="text-sm text-gray-500" />
      {#if dataList.length > 0 || page > 1}
        <Pagination
          bind:page
          {totalData}
          {totalPage}
          {perPage}
          on:prev={() => {
            page -= 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          on:next={() => {
            page += 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
      {/if}
    </div>
  </section>
</div>
