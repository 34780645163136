<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let data;
  export let value;
  export let displayedValue;
  function handleClick(e, data) {
    var allCard = document.querySelectorAll(".card-data");
    allCard.forEach((el) => {
      el.classList.remove("bg-gray-400");
    });
    e.currentTarget.classList.add("bg-gray-400");
    value = data["id"];
    displayedValue = data["name"];
    console.log(displayedValue);
  }
</script>

{#each data as item, index}
  <button
    class="card-data min-w-full mb-2 p-2 border border-primary rounded-md {value == item['id'] ? 'bg-primary text-white' : 'text-gray-800'} "
    on:click={(e) => handleClick(e, item)}
  >
    <div class="flex justify-between items-center text-xs font-medium" id="item-{index}">
      <div class="flex flex-col justify-start items-start">
        <p class="flex-1 line-clamp-2 text-right text-md font-bold">{item["name"]}</p>
        <p class="flex-1 line-clamp-2 text-right text-xs">{item["roleName"]}</p>
      </div>
    </div>
  </button>
{/each}
