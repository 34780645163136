import { urlApi } from "../../utils/const";
import { utils, writeFileXLSX } from "xlsx";
import { formatDateWithSlash } from "../../utils/utils";
const columnWidth = [
  { wch: 20 }, // Tanggal Dibuat
  { wch: 40 }, // Nama Sel Group
  { wch: 15 }, // Kategori Sel
  { wch: 25 }, // Status Sel Group
  { wch: 30 }, // Head Gembala
  { wch: 30 }, // Gembala
  { wch: 30 }, // Pendamping Gembala
  { wch: 30 }, // Wakil Gembala
  { wch: 30 }, // Pendamping Wakil Gembala
  { wch: 30 }, // Member
];

export default {
  async getCellGroupReporting(id, search, isActive, covenantYear) {
    try {
      let option = "";
      if (id) option = "?cellgroupID=" + id;
      else option = "?search=" + search + "&isActive=" + isActive + "&covenantYearID=" + covenantYear;
      const res = await fetch(urlApi + "/Reporting/CellGroup" + option, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return data["data"];
    } catch (err) {
      return [];
    }
  },

  async exportCellGroupReporting(id, search, isActive, covenantYear) {
    const data = await this.getCellGroupReporting(id, search, isActive, covenantYear);
    let jsonCellGroup = data.map((item) => ({
      "Tanggal Dibuat": formatDateWithSlash(new Date(item.dateCreated)),
      "Nama Sel Group": item.cellGroupName,
      "Kategori Sel": item.cellCategoryName,
      "Status Sel Group": item.status,
      "Head Gembala": item.headGembala,
      Gembala: item.gembala,
      "Pendamping Gembala": item.pendampingGembala === "" ? "-" : item.pendampingGembala,
      "Wakil Gembala": item.wakilGembala,
      "Pendamping Wakil Gembala": item.pendampingWakilGembala === "" ? "-" : item.pendampingWakilGembala,
      Member: item.member === "" ? "-" : item.member,
    }));
    let worksheetCellGroup = utils.json_to_sheet([{}]);
    utils.sheet_add_json(worksheetCellGroup, [{ A: id ? "Sel Group" : "Daftar Sel Group" }], { skipHeader: true, origin: "A1" }); // header text
    utils.sheet_add_json(worksheetCellGroup, jsonCellGroup, { origin: "A2" });
    worksheetCellGroup["!cols"] = columnWidth; // set column width for each column

    const new_workbook = utils.book_new(); // make new workbook
    utils.book_append_sheet(new_workbook, worksheetCellGroup, id ? data[0].cellGroupName : "Daftar Sel Group"); // sheet name

    const fileName = "Super Admin - Sel Group.xlsx";
    writeFileXLSX(new_workbook, fileName); // trigger download
  },
};
