<script>
  import { field, form } from "svelte-forms";
  import Input from "../../components/control/Input.svelte";
  import { matchField, required } from "svelte-forms/validators";
  import { pop, replace } from "svelte-spa-router";
  import Cookies from "js-cookie";
  import { urlApi } from "../../utils/const";
  import Loading from "../../components/Loading.svelte";

  export let params;

  let password = field("password", "", [required()]);
  let confirmPassword = field("confirmPassword", "", [required(), matchField(password)]);

  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";

  let id = params["_id"];
  let errors = [];

  let email = localStorage.getItem("emailUser");
  let userForm = form(password, confirmPassword);

  let loading;

  async function submit() {
    loading.toggle();
    await userForm.validate();
    if ($userForm.valid) {
      const reqData = {
        ID: id,
        Password: $password.value,
        ConfirmPassword: $confirmPassword.value,
      };

      const res = await fetch(urlApi + "/Authorization/ResetPassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(reqData),
      });

      const data = await res.json();
      if (data["data"]["id"]) {
        loading.toggle();
        globalThis.pushToast("Berhasil Memperbarui Password", "success");
        setTimeout(() => replace("/users/"), 1000);
      }
    } else {
      errors = $userForm.errors;
      loading.toggle();
    }
  }
</script>

<Loading bind:this={loading} />
<section class="container px-4 py-4">
  <div class="sm:flex sm:items-center sm:justify-between">
    <div>
      <div class="flex items-center gap-x-3">
        <button
          on:click={() => {
            pop();
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-lg font-medium text-gray-800">Ganti Password</h2>
      </div>
    </div>
  </div>

  <div class="mt-5 flex flex-col space-y-4">
    <div class={mobileClass}>
      <p class="text-xs font-semibold text-gray-700">
        Email: {email}
      </p>
    </div>
  </div>

  <div class="mt-5 flex flex-col space-y-4">
    <div class={mobileClass}>
      <div class="flex-1">
        <Input name="password" type="password" label="Password Baru" bind:errors withEye bind:value={$password.value} />
      </div>
      <div class="flex-1">
        <Input name="confirmPassword" type="password" label="Konfirmasi Password" bind:errors withEye bind:value={$confirmPassword.value} />
      </div>
    </div>

    <div class="mt-4" />

    <button
      on:click={submit}
      class="w-full text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
      >Simpan</button
    >
  </div>
</section>
