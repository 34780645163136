<script>
  import { field, form } from "svelte-forms";
  import { required } from "svelte-forms/validators";
  import Input from "../../components/control/Input.svelte";
  import { push, replace } from "svelte-spa-router";
  import { onMount } from "svelte";
  import Loading from "../../components/Loading.svelte";
  import AttendanceExportService from "./AttendanceExportService";
  import Pagination from "../../components/pagination/Pagination.svelte";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import Select from "../../components/control/Select.svelte";
  import { addDays } from "date-fns";
  import { formatDateTime, formatDateWithTimeForView, parseJwt, checkRoleModule } from "../../utils/utils";
  import Modal from "../../components/Modal.svelte";
  import { perPage, urlApi, EXPORT_ATTENDANCE } from "../../utils/const";
  import TableAttendance from "../../components/table/TableAttendance.svelte";
  import AttendanceCard from "../../components/card/AttendanceCard.svelte";
  import { roleModules } from "../../stores/store";
  import AttendanceCategoryServices from "../AttendanceCategory/AttendanceCategoryServices";

  let dataList = [];
  let attendanceData = [];
  let attendanceDate = "";
  let IsAttend = 1;
  let IsApprove = 2;
  let loading;
  let disableSubmit = false;
  let page = 1;
  let search = "";
  let totalData = 0,
    totalPage = 0;
  let statusSelected = "0";
  let categorySelected = "0";
  let categoryOption = [];
  let modalAttendance;
  let modalApproval;
  const token = parseJwt(localStorage.getItem("token"));
  let access = token.RoleID;
  let errors = [];
  let notAttendReason = field("notAttendReason", "", [required()]);
  let notApproveReason = field("notApproveReason", "", [required()]);

  let from = addDays(new Date(), -7);
  let to = new Date();
  let attendanceForm = form(notAttendReason);
  let approvalForm = form(notApproveReason);

  onMount(() => {
    loadData();
    loadAttendanceCategory();
  });

  async function loadData() {
    loading.toggle();
    const attendanceStatusFilter = statusSelected == "0" ? "" : statusSelected == "3" ? "0" : statusSelected;
    const showAllAttendance = access == 5 ? 1 : 0;
    const option =
      "perPage=" +
      perPage +
      "&currentPage=" +
      page +
      "&searchQuery=" +
      search +
      "&fromDate=" +
      formatDateTime(from, true) +
      "&toDate=" +
      formatDateTime(to, false) +
      "&status=" +
      attendanceStatusFilter +
      "&category=" +
      categorySelected +
      "&showAll=" +
      showAllAttendance;
    const res = await fetch(urlApi + "/Attendance?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    dataList = data["data"];
    totalData = data["info"]["totalItem"];
    totalPage = data["info"]["totalPage"];
    loading.toggle();
  }
  async function loadAttendanceCategory() {
    categoryOption.push({
      id: "0",
      name: "Semua Kategori Attendance",
    });
    loading.toggle();
    const option = "perPage=9999" + "&currentPage=1" + "&isDelete=0";
    const data = await AttendanceCategoryServices.loadAttendanceCategory(option);
    for (const item of data["data"]) {
      categoryOption.push({
        id: String(item.id),
        name: item.name,
      });
    }
    categoryOption = categoryOption;
    loading.toggle();
  }
  async function loadAttendance(data, modal) {
    attendanceData = data["data"];
    attendanceDate = formatDateWithTimeForView(new Date(attendanceData["date"]));
    if (modal == "attend") modalAttendance.toggle();
    else modalApproval.toggle();
  }
  async function submitAttendance() {
    loading.toggle();
    disableSubmit = true;
    var radios = document.getElementsByName("IsAttend");
    for (var i = 0, length = radios.length; i < length; i++) {
      if (radios[i].checked) {
        IsAttend = radios[i].value;
        break;
      }
    }
    errors = [];
    await attendanceForm.validate();
    // if participant attends the event, hit api and if participant is not attending and fill the form, hit api
    if ($attendanceForm.valid || IsAttend == 1) {
      modalAttendance.toggle();
      const reqData = {
        AttendanceID: attendanceData["id"],
        UserID: token.UserID,
        IsAttend: IsAttend,
        participantCount: attendanceData["participantCount"],
        surveyAttendance: attendanceData["surveyAttendance"],
        Note: IsAttend == 0 ? $notAttendReason.value : null,
      };
      const res = await fetch(urlApi + "/Attendance", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(reqData),
      });
      const data = await res.json();
      IsAttend = 1;
      $notAttendReason.value = "";
      await loadData();
      if (data["data"]["errorMessage"]) {
        globalThis.pushToast(data["data"]["errorMessage"], "error");
      } else {
        globalThis.pushToast("Berhasil Mengisi Survey Kehadiran Attendance", "success");
      }
    } else {
      errors = $attendanceForm.errors;
      errors = errors;
    }
    loading.toggle();
    disableSubmit = false;
  }
  async function submitApproval() {
    loading.toggle();
    disableSubmit = true;
    var radios = document.getElementsByName("IsApprove");
    for (var i = 0, length = radios.length; i < length; i++) {
      if (radios[i].checked) {
        IsApprove = radios[i].value;
        break;
      }
    }
    errors = [];
    await approvalForm.validate();
    if ($approvalForm.valid || IsApprove == 2) {
      const reqData = {
        ID: attendanceData["id"],
        Status: IsApprove,
        CancelNote: IsApprove == 2 ? null : $notApproveReason.value,
      };
      const res = await fetch(urlApi + "/Attendance/Edit/Status/", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(reqData),
      });
      const data = await res.json();
      if (data["data"]["errorMessage"]) {
        globalThis.pushToast(data["data"]["errorMessage"], "error");
      } else {
        globalThis.pushToast("Berhasil Mengubah Status Attendance", "success");
      }
      modalApproval.toggle();
      IsApprove = 2;
      $notApproveReason.value = "";
      loadData();
    } else {
      errors = $approvalForm.errors;
      errors = errors;
    }
    disableSubmit = false;
    loading.toggle();
  }
  function searchData() {
    page = 1;
    loadData();
  }
  function notAttendChange() {
    if (IsAttend == 1) IsAttend = 0;
    else IsAttend = 1;
    errors = [];
  }
  function onApproveChange() {
    if (IsApprove == 2) IsApprove = 0;
    else IsApprove = 2;
    errors = [];
  }
  let statusOption = [
    { id: "0", name: "Semua Status" },
    { id: "1", name: "Aktif" },
    { id: "2", name: "Done" },
    { id: "3", name: "Batal" },
  ];
  async function exportAttendanceReporting() {
    await AttendanceExportService.exportAttendanceReporting(from, to);
  }
</script>

<Loading bind:this={loading} />
<Modal
  index={50}
  title="Survey Kehadiran"
  bind:this={modalAttendance}
  size="default"
  closed
  on:close={() => {
    IsAttend = 1;
    errors = [];
    modalAttendance.toggle();
  }}
>
  <div class="flex flex-col items-start justify-start space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">
      Apakah anda akan menghadiri attendance <span class="font-bold">"{attendanceData["attendanceName"]}"</span> di tanggal
      <span class="font-bold">{attendanceDate}</span>?
    </p>
    <div class="flex items-center mt-4">
      <input
        id="default-radio-1"
        type="radio"
        value="1"
        name="IsAttend"
        checked
        on:change={notAttendChange}
        class="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
      />
      <label for="default-radio-1" class="ml-2 text-sm font-medium text-gray-800">Hadir</label>
    </div>
    <div class="flex items-center">
      <input
        id="default-radio-2"
        type="radio"
        value="0"
        name="IsAttend"
        on:change={notAttendChange}
        class="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
      />
      <label for="default-radio-2" class="ml-2 text-sm font-medium text-gray-900">Tidak Hadir</label>
    </div>
    {#if IsAttend == 0}
      <div>
        <Input name="notAttendReason" type="text" label="Alasan tidak hadir" bind:errors bind:value={$notAttendReason.value} />
      </div>
    {/if}
    <div class="flex space-x-2 justify-end items-end mt-4 w-full h-full px-5">
      <button
        on:click={() => {
          IsAttend = 1;
          errors = [];
          modalAttendance.toggle();
        }}
        class="px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg w-fit"
      >
        Batal
      </button>
      <button
        on:click={() => {
          submitAttendance();
        }}
        disabled={disableSubmit}
        class="px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg w-fit"
      >
        Simpan
      </button>
    </div>
  </div>
</Modal>
<Modal
  index={50}
  title="Attendance Approval"
  bind:this={modalApproval}
  size="default"
  closed
  on:close={() => {
    IsApprove = 2;
    errors = [];
    $notApproveReason.value = "";
    modalApproval.toggle();
  }}
>
  <div class="flex flex-col items-start justify-start space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">
      Apakah anda akan menyetujui pengadaan attendance <span class="font-bold">"{attendanceData["attendanceName"]}"</span> di tanggal
      <span class="font-bold">{attendanceDate}</span>?
    </p>
    <div class="flex items-center mt-4">
      <input
        id="approve-radio-1"
        type="radio"
        value="2"
        name="IsApprove"
        checked
        on:change={onApproveChange}
        class="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
      />
      <label for="approve-radio-1" class="ml-2 text-sm font-medium text-gray-800">Setujui</label>
    </div>
    <div class="flex items-center">
      <input
        id="approve-radio-2"
        type="radio"
        value="0"
        name="IsApprove"
        on:change={onApproveChange}
        class="w-4 h-4 text-blue-600 bg-white-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
      />
      <label for="approve-radio-2" class="ml-2 text-sm font-medium text-gray-900">Batalkan</label>
    </div>
    {#if IsApprove == 0}
      <div class="mt-4">
        <Input name="notApproveReason" type="text" label="Alasan dibatalkan" bind:errors bind:value={$notApproveReason.value} />
      </div>
    {/if}
    <div class="flex space-x-2 justify-end items-end mt-4 w-full h-full px-5">
      <button
        on:click={() => {
          errors = [];
          IsApprove = 2;
          $notApproveReason.value = "";
          modalApproval.toggle();
        }}
        class="px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg w-fit"
      >
        Batal
      </button>
      <button
        on:click={() => {
          submitApproval();
        }}
        disabled={disableSubmit}
        class="px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg w-fit"
      >
        Simpan
      </button>
    </div>
  </div>
</Modal>
<div class="p-0 md:p-4">
  <!-- component -->
  <section class="container mx-auto px-4 md:px-0">
    <div class="sm:flex sm:items-center sm:justify-between">
      <div>
        <div class="flex items-center gap-x-3">
          <h2 class="text-lg font-medium text-gray-800">Daftar Attendance</h2>
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-end justify-between flex-col space-y-3 md:flex-row md:space-y-0">
      {#if access != 4 && access != 5}
        <div class="flex w-full md:w-auto mr-2">
          <button
            on:click={() => push("/attendances/add")}
            class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-lg
              shrink-0 sm:w-auto space-x-2 hover:opacity-80"
          >
            <i class="bx bx-plus" />

            <span>Tambah Attendance</span>
          </button>
        </div>
      {/if}

      <div class="flex-1 flex flex-col space-y-3 w-full md:space-y-0 md:flex-row md:space-x-2">
        <div class="flex-1 flex items-center">
          <div class="relative w-full">
            <label class="block text-gray-900 text-xs font-semibold mb-1" for="search"> Cari </label>
            <div class="relative w-full group">
              <input
                type="text"
                placeholder="Cari Nama, Nama Pembuat, atau Completed By"
                bind:value={search}
                on:input={searchData}
                class="block w-full text-xs py-2 pr-5 text-gray-700 focus:border-gray-300 focus:ring-transparent bg-white
                border border-gray-200 rounded-lg placeholder-gray-400/70 pl-8 focus:outline-none"
              />
              <span
                class="pointer-events-none absolute -top-10 bg-slate-800 text-white p-2 rounded-lg left-7 w-max text-xs opacity-0 transition-opacity md:group-hover:opacity-100 2xl:group-hover:opacity-0"
              >
                Cari Nama, Nama Pembuat, atau Completed By
              </span>

              <span class="absolute left-2 top-0 bottom-0 flex items-center">
                <i class="bx bx-search text-gray-500" />
              </span>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <Select
            on:change={(e) => {
              categorySelected = e.detail;
              searchData();
            }}
            textHint="Semua Kategori Attendance"
            label="Kategori Attendance"
            fullWidth
            options={categoryOption}
            bind:selected={categorySelected}
          />
        </div>
        <div class="flex-1 flex space-x-2 w-full">
          <div class="flex-1">
            <DatePicker label="Tanggal Attendance" name="date" bind:from bind:to ranged on:update={searchData} />
          </div>
        </div>
        <div class="flex-1">
          <Select
            on:change={(e) => {
              statusSelected = e.detail;
              searchData();
            }}
            textHint="Pilih Status"
            label="Status"
            fullWidth
            name="status_search"
            options={statusOption}
            bind:selected={statusSelected}
          />
        </div>
      </div>
    </div>
    {#if checkRoleModule($roleModules, EXPORT_ATTENDANCE)}
      <div class="mt-4 flex justify-end">
        <button
          on:click={() => exportAttendanceReporting()}
          class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-md
              shrink-0 sm:w-auto space-x-2 hover:opacity-80"
        >
          <span>Export</span>
        </button>
      </div>
    {/if}
    <div class="flex flex-col mt-4">
      <div class="overflow-x-auto hidden md:block">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="overflow-hidden rounded-md">
            <TableAttendance
              bind:data={dataList}
              bind:page
              on:detail={(e) => push("/attendances/" + e.detail["id"] + "/detail")}
              on:edit={(e) => push("/attendances/" + e.detail["id"] + "/edit")}
              on:attend={(e) => loadAttendance(e.detail, "attend")}
              on:approve={(e) => loadAttendance(e.detail, "approve")}
            />
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="overflow-x-auto block md:hidden">
        {#each dataList as item, index}
          <AttendanceCard
            bind:data={item}
            {index}
            bind:page
            on:detail={() => push("/attendances/" + item["id"] + "/detail")}
            on:edit={() => push("/attendances/" + item["id"] + "/edit")}
            on:attend={(e) => loadAttendance(e.detail, "attend")}
            on:approve={(e) => loadAttendance(e.detail, "approve")}
          />
        {/each}
      </div>
    </div>

    <div class="mt-6 sm:flex sm:items-center sm:justify-between">
      <div class="text-sm text-gray-500" />
      {#if dataList.length > 0 || page > 1}
        <Pagination
          bind:page
          {totalData}
          {totalPage}
          {perPage}
          on:prev={() => {
            page -= 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          on:next={() => {
            page += 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
      {/if}
    </div>
  </section>
</div>
