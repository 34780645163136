<script>
  import { createEventDispatcher } from "svelte";
  import { formatDateTimeShortVersion } from "../../utils/utils";
  import { checkRoleModule, parseJwt } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  const dispatch = createEventDispatcher();
  import { EDIT_ATTENDANCE_MODULE, SURVEY_ATTENDANCE_MODULE, ATTENDANCE_APPROVAL_MODULE, DETAIL_ATTENDANCE_MODULE } from "../../utils/const";
  import { attendanceStatus } from "../../pages/Attendance/AttendanceConst";

  export let data;
  export let index;
  export let page = 1;
  const token = parseJwt(localStorage.getItem("token"));
</script>

<div class="card-data min-w-full mb-3 p-2 border border-primary shadow rounded-md">
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p>#.</p>
    <p>
      {page > 1 ? ((index + 1).toString().length > 1 ? index + 1 + 10 * (page - 1) : (page - 1).toString() + (index + 1)) : index + 1}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Nama Attendance</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["attendanceName"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Nama Pembuat</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["createdBy"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Completed By</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["actionBy"] ?? "-"}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Kategori</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["categoryName"] ?? "-"}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Waktu</p>
    <p class="flex-1 line-clamp-2 text-right">
      {formatDateTimeShortVersion(new Date(data["date"]))}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Survey Kehadiran</p>
    <p class="flex-1 line-clamp-2 text-right">
      {Math.round(data["surveyAttendance"])} %
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between items-center text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Status</p>
    <p
      class="inline px-3 py-1 text-xs font-normal rounded-full {data['status'] == 1
        ? 'text-emerald-500 bg-emerald-100/60'
        : data['status'] == 2
        ? 'text-cyan-500 bg-cyan-100/60'
        : 'text-red-500 bg-red-100/60'}  gap-x-2"
    >
      {data["status"] == 1 ? "Aktif" : data["status"] == 2 ? "Done" : "Batal"}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between items-center text-xs font-medium text-gray-800 h-6">
    <p class="flex-1 text-left">Aksi</p>
    <div class="flex-1 flex space-x-1 justify-end">
      {#if checkRoleModule($roleModules, DETAIL_ATTENDANCE_MODULE)}
        <button
          on:click={() => dispatch("detail", { id: data["id"] })}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-show text-md" />
        </button>
      {/if}
      {#if checkRoleModule($roleModules, EDIT_ATTENDANCE_MODULE) && data["status"] == attendanceStatus.Aktif}
        <button
          on:click={() => dispatch("edit", { id: data["id"] })}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-pencil text-md" />
        </button>
      {/if}
      {#if checkRoleModule($roleModules, ATTENDANCE_APPROVAL_MODULE) && data["status"] == attendanceStatus.Aktif}
        {#if ((token.RoleID == 3 || token.RoleID == 13) && data["roleID"] == token.RoleID && data["userID"] == token.UserID) || (token.RoleID != 3 && token.RoleID != 13)}
          <button
            on:click={() => dispatch("approve", { data: data })}
            class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
          >
            <i class="bx bx-list-check text-md" />
          </button>
        {/if}
      {/if}
      {#if checkRoleModule($roleModules, SURVEY_ATTENDANCE_MODULE) && data["status"] == attendanceStatus.Aktif}
        <button
          disabled={data["status"] == 2 || data["status"] == 0}
          on:click={() => dispatch("attend", { data: data })}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100 disabled:opacity-50"
        >
          <i class="bx bx-copy text-md" />
        </button>
      {/if}
    </div>
  </div>
</div>
