import { urlApi } from "../../utils/const";

export default {
  async loadCellCategory(option) {
    const res = await fetch(urlApi + "/CellCategory?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },
  async createCellCategory(reqData) {
    const res = await fetch(urlApi + "/CellCategory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(reqData),
    });
    return res.json();
  },
  async getCellCategoryByID(id) {
    const res = await fetch(urlApi + "/CellCategory/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },
  async editCellCategory(reqData) {
    const res = await fetch(urlApi + "/CellCategory", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(reqData),
    });
    return res.json();
  },
  async validateDelete(id) {
    const res = await fetch(urlApi + "/CellCategory/ValidateDelete/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },
  async validateDeactivate(id) {
    const res = await fetch(urlApi + "/CellCategory/ValidateDeactivate/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },
};
