<script>
  import { field, form } from "svelte-forms";
  import { email, required } from "svelte-forms/validators";
  import { replace } from "svelte-spa-router";
  import UserForm from "../../components/form/UserForm.svelte";
  import Cookies from "js-cookie";
  import { urlApi } from "../../utils/const";
  import { parseJwt } from "../../utils/utils";
  import Modal from "../../components/Modal.svelte";
  import Loading from "../../components/Loading.svelte";

  const token = parseJwt(localStorage.getItem("token"));
  let access = token.RoleID;

  let emails = field("emails", "", [required(), email()], {
    checkOnInit: false,
    validateOnChange: true,
  });

  let fullName = field("fullName", "", [required()]);
  let address = field("address", "", []);
  let note = field("note", "", []);
  let phone = field("phone", "", []);
  let roleSelected = field("roleSelected", "4", []);
  // let spvSelected = field("spvSelected", "", []);
  let companionSelected = field("companionSelected", "", []);
  let departmentSelected = field("departmentSelected", "Formation", []);

  let base64Image = field("base64Image", "", []);
  let baptismalName = field("baptismalName", "", []);
  // let cellGroupSelected = field("cellGroupSelected", "0", []);
  // let covenantYearSelected = field("covenantYearSelected", "0", []);
  let dateOfBirth = field("dateOfBirth", new Date(), []);
  let educationSelected = field("educationSelected", "", []);
  let job = field("job", "", []);
  let nickname = field("nickname", "", []);
  let parokiChurch = field("parokiChurch", "", []);
  let placeOfBirth = field("placeOfBirth", "", []);

  let wakilID = 0,
    gembalaID = 0,
    headGembalaID = 0;

  let errors = [];
  let modalBack;
  let status = true;
  let spvOption = [];
  let userForm;
  let disableSubmit = false;
  let loading;

  async function addUser() {
    disableSubmit = true;
    loading.toggle();
    errors = [];
    roleSelected = field("roleSelected", $roleSelected.value, access == 5 ? [required()] : []);
    companionSelected = field(
      "companionSelected",
      $companionSelected.value,
      $roleSelected.value == "12" || $roleSelected.value == 13 ? [required()] : [],
    );
    userForm = form(emails, fullName, phone, departmentSelected, companionSelected, roleSelected);

    // const spvSelect = spvOption.filter((e) => e["id"] == $spvSelected.value);

    // if ($roleSelected.value != "1" && $roleSelected.value != "" && $spvSelected.value != "") {
    //   wakilID = $roleSelected.value == "4" ? $spvSelected.value : spvSelect[0]["wakilGembalaID"];
    //   gembalaID = $roleSelected.value == "3" ? $spvSelected.value : spvSelect[0]["gembalaID"];
    //   headGembalaID = $roleSelected.value == "2" ? $spvSelected.value : spvSelect[0]["headGembalaID"];
    // }

    await userForm.validate();

    if ($userForm.valid) {
      const reqData = {
        FullName: $fullName.value,
        Email: $emails.value,
        CellGroupID: 0,
        CovenantYearID: 0,
        Department: $departmentSelected.value,
        supervisorID: 0,
        companionID: $companionSelected.value ? parseInt($companionSelected.value) : 0,
        WakilGembalaID: wakilID,
        GembalaID: gembalaID,
        HeadGembalaID: headGembalaID,
        RoleID: parseInt($roleSelected.value),
        Base64Image: $base64Image.value,
        IsActive: status ? 1 : 0,
        Note: $note.value,
      };
      const res = await fetch(urlApi + "/Users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(reqData),
      });

      const data = await res.json();
      if (data["data"]["errorMessage"]) {
        if (data["data"]["errorMessage"] == "Email telah terdaftar") {
          errors.push("emails.duplicate");
        } else {
          errors.push("fullName.duplicate");
        }
        errors = errors;
        disableSubmit = false;
      } else if (data["data"]["id"]) {
        globalThis.pushToast("Berhasil Menambahkan Pengguna", "success");
        setTimeout(() => replace("/users/"), 1000);
      }
    } else {
      errors = $userForm.errors;
      errors = errors;
      disableSubmit = false;
    }
    loading.toggle();
  }
</script>

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin ingin keluar dari laman ini? data yang Anda masukkan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/users/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>

<Loading bind:this={loading} />
<section class="container px-4 py-4">
  <UserForm
    {access}
    formTitle="Buat Pengguna"
    bind:address={$address.value}
    bind:base64Image={$base64Image.value}
    bind:baptismalName={$baptismalName.value}
    bind:dateOfBirth={$dateOfBirth.value}
    bind:disableSubmit
    bind:departmentSelected={$departmentSelected.value}
    bind:educationSelected={$educationSelected.value}
    bind:emails={$emails.value}
    bind:errors
    bind:fullName={$fullName.value}
    bind:job={$job.value}
    bind:nickname={$nickname.value}
    bind:note={$note.value}
    bind:phone={$phone.value}
    bind:parokiChurch={$parokiChurch.value}
    bind:placeOfBirth={$placeOfBirth.value}
    bind:roleSelected={$roleSelected.value}
    bind:status
    on:back={() => modalBack.toggle()}
    on:save={() => addUser()}
    mode="add"
  />
</section>
