<script>
  import { bgColorDefault } from "../../utils/const";
  import LayoutSidebar from "../../layouts/LayoutSidebar.svelte";
  import Register from "./Register.svelte";
  import Login from "./Login.svelte";
  import Router from "svelte-spa-router";
  import Footer from "../../components/Footer.svelte";
  import { onMount } from "svelte";
  import EmailVerification from "./EmailVerification.svelte";

  const routes = {
    "/login": Login,
    "/login/:_token": Login,
    "/email-verification/:_email": EmailVerification,
    "/register": Register,
  };

  onMount(() => {
    if (localStorage.getItem("remember") == null) {
      localStorage.setItem("remember", "");
      localStorage.setItem("email", "");
    }
  });
</script>

<LayoutSidebar>
  <section class="h-screen mt-16 pt-2 {bgColorDefault}">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <Router {routes} />
      <Footer />
    </div>
  </section>
</LayoutSidebar>
