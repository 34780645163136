export const bgPrimaryColor = "bg-blue-500";
export const hoverBgPrimary = "bg-blue-600";

export const textPrimaryColor = "text-blue-500";
export const hoverTextPrimary = "text-blue-600";

export const bgColorDefault = "bg-extWhite";

export const urlApi = "https://test-api.domuscordis.com";
export const imageKitPublicKey = "public_vnT+1RnX+jkNevxbsRX/Q8Cezqw=";
export const imageKitUrlEndpoint = "https://ik.imagekit.io/2ifzny8x9";

export const perPage = 10;

export const inputClass =
  "border border-gray-200 focus:ring-transparent focus:border-gray-300 outline-none sm:text-sm rounded-lg block w-full px-3 placeholder-gray-400 text-gray-800 font-semibold";
export const inputErrClass =
  "border border-red-500  focus:ring-transparent focus:border-red-500 outline-none sm:text-sm rounded-lg block w-full px-3 placeholder-gray-400 text-gray-800 font-semibold";

export const attendanceCategoryOptionData = [
  { id: "1", name: "Pertemuan Sel" },
  { id: "2", name: "Community Gathering" },
  { id: "3", name: "Retreat" },
  { id: "4", name: "Meeting" },
  { id: "5", name: "Events" },
  { id: "6", name: "Lainnya" },
];
export const covenantYearOptionData = [
  { id: "0", name: "Unassigned" },
  { id: "1", name: "DC X" },
  { id: "2", name: "Covenant Year 1" },
  { id: "3", name: "Covenant Year 2" },
  { id: "4", name: "Covenant Year 3" },
];

export const departmentOptionData = [
  { id: "G. Affair", name: "G. Affair" },
  { id: "Outreach", name: "Outreach" },
  { id: "Prod. (EO)", name: "Prod. (EO)" },
  { id: "Formation", name: "Formation" },
  { id: "Com. Plant", name: "Com. Plant" },
  { id: "Fund Raise", name: "Fund Raise" },
];

export const educationOptionData = [
  { id: "0", name: "Unassigned" },
  { id: "1", name: "Tidak bersekolah" },
  { id: "2", name: "SD" },
  { id: "3", name: "SMP" },
  { id: "4", name: "SMU/SMK" },
  { id: "5", name: "D1" },
  { id: "6", name: "D3" },
  { id: "7", name: "D4" },
  { id: "8", name: "S1" },
  { id: "9", name: "S2" },
  { id: "10", name: "S3" },
];

export const roleOptionData = [
  { id: "1", name: "Head gembala" },
  { id: "2", name: "Gembala" },
  { id: "3", name: "Wakil Gembala" },
  { id: "4", name: "Member" },
  { id: "5", name: "Super Admin" },
  { id: "12", name: "Pendamping Gembala" },
  { id: "13", name: "Pendamping Wakil Gembala" },
];

export const paymentOptionData = [
  { id: "1", name: "Cash" },
  { id: "2", name: "Transfer" },
];

export const typeJournalOption = [
  { id: "0", name: "Semua Tipe Journal" },
  { id: "1", name: "Doa" },
  { id: "2", name: "Pembacaan Alkitab" },
  { id: "3", name: "Persembahan Kasih" },
  { id: "4", name: "Sakramen Tobat" },
  { id: "5", name: "Misa Harian" },
  { id: "6", name: "Testimonial" },
];

export const typeOneOption = [
  { id: "0", name: "Semua 121" },
  { id: "1", name: "121" },
  { id: "2", name: "Spiritual Conversation" },
];

export const typeLearning = [
  { id: "0", name: "Semua" },
  { id: "1", name: "Video" },
  { id: "2", name: "Text" },
  { id: "3", name: "PDF" },
  { id: "4", name: "PPT" },
];

export const typeQuiz = [
  { id: "0", name: "Text" },
  { id: "1", name: "Pilihan Ganda" },
  { id: "2", name: "Checkbox" },
];

export const optionStatusLearning = [
  { id: "0", name: "Semua" },
  { id: "1", name: "Published" },
  { id: "2", name: "Upcoming" },
  { id: "3", name: "Closed" },
];

// role module code

//USER
export const USER_MODULE = "user";
export const ADD_USER_MODULE = "add_user";
export const EDIT_USER_MODULE = "edit_user";
export const EDIT_USER_BASIC_MODULE = "edit_user_basic";
export const EDIT_USER_SUPER_MODULE = "edit_user_super";
export const RESET_PASSWORD_MODULE = "reset_password_user";
export const SEND_EMAIL_VERIFICATION = "send_email_verification";

//121
export const ONE_MODULE = "one";
export const ADD_ONE_MODULE = "add_one";
export const EDIT_ONE_MODULE = "edit_one";
export const DETAIL_ONE_MODULE = "detail_one";
export const DELETE_ONE_MODULE = "delete_one";
export const ADD_SPIRITUAL_CONVERSATION_MODULE = "add_spiritual_conversation";

//JOURNAL
export const JOURNAL_MODULE = "journal";
export const ADD_JOURNAL_MODULE = "add_journal";
export const EDIT_JOURNAL_MODULE = "edit_journal";
export const DETAIL_JOURNAL_MODULE = "detail_journal";
export const DELETE_JOURNAL_MODULE = "delete_journal";

//ATTENDANCE
export const ATTENDANCE_MODULE = "attendance";
export const ADD_ATTENDANCE_MODULE = "add_attendance";
export const EDIT_ATTENDANCE_MODULE = "edit_attendance";
export const DETAIL_ATTENDANCE_MODULE = "detail_attendance";
export const ATTENDANCE_APPROVAL_MODULE = "approval_attendance";
export const SURVEY_ATTENDANCE_MODULE = "survey_attendance";

//CELLGROUP
export const CELLGROUP_MODULE = "cellgroup";
export const ADD_CELLGROUP_MODULE = "add_cellgroup";
export const DETAIL_CELLGROUP_MODULE = "detail_cellgroup";
export const EDIT_CELLGROUP_MODULE = "edit_cellgroup";
export const MEMBER_REQUEST_CELLGROUP_MODULE = "member_request_cellgroup";
export const MEMBER_REQUEST_ACTION_CELLGROUP_MODULE = "member_request_action_cellgroup";
export const MEMBER_REMOVAL_CELLGROUP_MODULE = "member_removal_cellgroup";

// LMS
export const LEARNING_MODULE = "learning";
export const ADD_LEARNING_MODULE = "add_learning";
export const EDIT_LEARNING_MODULE = "edit_learning";
export const DETAIL_LEARNING_MODULE = "detail_learning";
export const LEARNING_MEMBER = "member_learning";
export const LESSON = "lesson";

// EXPORT
export const EXPORT_JOURNAL = "export_journal";
export const EXPORT_ONE = "export_one";
export const EXPORT_ATTENDANCE = "export_attendance";
export const EXPORT_CELLGROUP = "export_cellgroup";
export const EXPORT_LEARNING = "export_learning";

// MASTER DATA
export const MASTER_DATA = "master_data";

// ATTENDANCE CATEGORY
export const ATTENDANCE_CATEGORY_MODULE = "attendance_category";
export const ADD_ATTENDANCE_CATEGORY_MODULE = "add_attendance_category";
export const EDIT_ATTENDANCE_CATEGORY_MODULE = "edit_attendance_category";
export const DETAIL_ATTENDANCE_CATEGORY_MODULE = "detail_attendance_category";

// CELL CATEGORY
export const CELL_CATEGORY_MODULE = "cell_category";
export const ADD_CELL_CATEGORY_MODULE = "add_cell_category";
export const EDIT_CELL_CATEGORY_MODULE = "edit_cell_category";
export const DETAIL_CELL_CATEGORY_MODULE = "detail_cell_category";
