<script>
  import { createEventDispatcher } from "svelte";
  import { checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import { EDIT_USER_MODULE, RESET_PASSWORD_MODULE, SEND_EMAIL_VERIFICATION } from "../../utils/const";
  const dispatch = createEventDispatcher();

  export let data;
  export let index;
  export let page = 1;
</script>

<div class="card-data min-w-full mb-3 p-2 border border-primary shadow rounded-md">
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p>No.</p>
    <p>
      {page > 1 ? ((index + 1).toString().length > 1 ? index + 1 + 10 * (page - 1) : (page - 1).toString() + (index + 1)) : index + 1}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Nama</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["name"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Email</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["email"]}
    </p>
  </div>
  <!-- <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Role</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["roleName"]}
    </p>
  </div> -->
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Supervisor</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["supervisorName"] == "" ? "-" : data["supervisorName"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Departemen</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["department"] == "" ? "-" : data["department"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between items-center text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Status</p>
    <p
      class="inline px-3 py-1 text-xs font-normal rounded-full {data['isActive'] == 1
        ? 'text-emerald-500 bg-emerald-100/60'
        : 'text-red-500 bg-red-100/60'}  gap-x-2"
    >
      {data["isActive"] == 1 ? "Aktif" : "Tidak Aktif"}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between items-center text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Aksi</p>
    <div class="flex-1 flex space-x-1 justify-end">
      <button
        on:click={() => dispatch("detail", data["id"])}
        class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
      >
        <i class="bx bx-show text-md" />
      </button>
      {#if checkRoleModule($roleModules, EDIT_USER_MODULE)}
        <button
          on:click={() => dispatch("edit", data["id"])}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-pencil text-md" />
        </button>
      {/if}
      {#if checkRoleModule($roleModules, RESET_PASSWORD_MODULE)}
        <button
          on:click={() => dispatch("reset", data["id"])}
          data-te-toggle="tooltip"
          title="Ganti Password"
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bxs-key text-md" />
        </button>
      {/if}
      {#if checkRoleModule($roleModules, SEND_EMAIL_VERIFICATION)}
        <button
          on:click={() => dispatch("email-verification", { email: data["email"] })}
          data-te-toggle="tooltip"
          title="Kirim Email Verifikasi"
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-select-multiple text-md" />
        </button>
      {/if}
    </div>
  </div>
</div>
