import { urlApi } from "../../utils/const";

export default {
  async loadAttendanceCategory(option) {
    const res = await fetch(urlApi + "/AttendanceCategory?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },

  async createAttendanceCategory(reqData) {
    const res = await fetch(urlApi + "/AttendanceCategory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(reqData),
    });
    return res.json();
  },
  async editAttendanceCategory(reqData) {
    const res = await fetch(urlApi + "/AttendanceCategory", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(reqData),
    });
    return res.json();
  },
  async getAttendanceCategoryByID(id) {
    const res = await fetch(urlApi + "/AttendanceCategory/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },
  async validateDelete(id) {
    const res = await fetch(urlApi + "/AttendanceCategory/ValidateDelete/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    return res.json();
  },
};
