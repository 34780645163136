<script>
  import { field, form } from "svelte-forms";
  import { required } from "svelte-forms/validators";
  import { replace } from "svelte-spa-router";
  import CellCategoryForm from "./CellCategoryForm.svelte";
  import CellCategoryService from "./CellCategoryServices";
  import { onMount } from "svelte";
  import Modal from "../../components/Modal.svelte";
  import Loading from "../../components/Loading.svelte";

  export let params;
  let name = field("name", "", [required()]);
  let note = field("note", "", []);

  let cellCategoryForm;
  let disableSubmit = false;
  let disableDeleteButton = false;
  let errors = [];
  let status = true;
  let modalBack;
  let modalDeleteValidation;
  let modalDeleteConfirmation;
  let previousIsActive;
  let actionName;
  let loading;
  const id = params["_id"];

  onMount(() => {
    loadData();
  });

  async function loadData() {
    loading.toggle();
    errors = [];
    const data = await CellCategoryService.getCellCategoryByID(id);
    if (data["data"]["id"]) {
      $name.value = data["data"]["name"];
      $note.value = data["data"]["note"];
      status = data["data"]["isActive"];
      previousIsActive = data["data"]["isActive"];
    }
    loading.toggle();
  }
  async function validateDeleteorDeactiveCondition(from) {
    if (from == "delete") disableDeleteButton = true;
    loading.toggle();
    const isValidated = from == "delete" ? await CellCategoryService.validateDelete(id) : await CellCategoryService.validateDeactivate(id);
    if (!isValidated) {
      if (from == "deactivate") {
        status = true; // make switch become true, since validation is failed
        actionName = "menonaktifkan";
      } else actionName = "menghapus";
      modalDeleteValidation.toggle();
    } else {
      if (from == "deactivate") status = false;
      else modalDeleteConfirmation.toggle();
    }
    loading.toggle();
    if (from == "delete") disableDeleteButton = false;
  }
  async function editCellCategory(from) {
    disableSubmit = true;
    loading.toggle();
    errors = [];

    cellCategoryForm = form(name);
    await cellCategoryForm.validate();
    if ($cellCategoryForm.valid) {
      const reqData = {
        ID: id,
        Name: $name.value,
        IsActive: status ? 1 : 0,
        PreviousIsActive: previousIsActive,
        Note: $note.value,
        IsDelete: from == "delete" ? 1 : 0,
      };

      const data = await CellCategoryService.editCellCategory(reqData);
      if (data["data"]["id"]) {
        const id = data["data"]["id"];
        globalThis.pushToast(`Berhasil ${from == "delete" ? "Menghapus" : "Mengubah"} Kategori Sel`, "success");
        if (from == "delete") setTimeout(() => replace("/cellcategory/"), 1000);
        else setTimeout(() => replace("/cellcategory/" + id + "/detail"), 1000);
      } else if (data["data"]["errorMessage"] == "Duplicate Name") {
        globalThis.pushToast("Nama Kategori Sel sudah digunakan", "error");
        disableSubmit = false;
      }
    } else {
      errors = $cellCategoryForm.errors;
      errors = errors;
      disableSubmit = false;
    }
    loading.toggle();
  }
</script>

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Apakah Anda yakin akan membatalkan perubahan? Perubahan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/cellcategory/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>
<Modal index={50} title="Konfirmasi" bind:this={modalDeleteValidation} size="default">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">
      Tidak dapat {actionName} Kategori Sel yang masih tercatat dalam Learning Management System yang berstatus
      <span class="font-bold">"Upcoming"</span>. Ubah atau hapus akses untuk Kategori Sel
      <span class="font-bold">"{$name.value}"</span>
      terlebih dahulu pada Learning Management System yang masih dalam Status
      <span class="font-bold">"Upcoming"</span>. sebelum {actionName} Kategori Sel <span class="font-bold">"{$name.value}"</span>
    </p>
    <div class="flex space-x-2 justify-end items-center mt-4">
      <button
        on:click={() => modalDeleteValidation.toggle()}
        class="px-3 py-2 text-white bg-primary text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Kembali
      </button>
    </div>
  </div>
</Modal>
<Modal index={50} title="Konfirmasi" bind:this={modalDeleteConfirmation} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">
      Apakah Anda yakin akan menghapus Kategori Sel <span class="font-bold">"{$name.value}"</span>. Kategori Sel yang sudah dihapus tidak dapat
      diaktifkan kembali
    </p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalDeleteConfirmation.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          editCellCategory("delete");
          modalDeleteConfirmation.toggle();
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>

<Loading bind:this={loading} />

<section class="container px-4 py-4">
  <CellCategoryForm
    formTitle="Edit Kategori Sel"
    mode="edit"
    bind:disableSubmit
    bind:disableDeleteButton
    bind:errors
    bind:name={$name.value}
    bind:note={$note.value}
    bind:status
    on:back={() => modalBack.toggle()}
    on:deactivate={() => validateDeleteorDeactiveCondition("deactivate")}
    on:delete={() => validateDeleteorDeactiveCondition("delete")}
    on:save={() => editCellCategory("edit")}
  />
</section>
