import { urlApi } from "../../utils/const";
export default {
  async refreshToken() {
    const res = await fetch(urlApi + "/Authorization/RefreshToken", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    if (res.status == 200) {
      return res.json();
    } else {
      return { error: "unAuthorized" };
    }
  },
};
