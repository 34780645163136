import { urlApi } from "../../utils/const";
import { formatDateForView, formatDate, parseJwt } from "../../utils/utils";
import { handleJSONtoSheet } from "../../utils/Export/exportExcelUtils";
import { differenceInMonths } from "date-fns";
import * as XLSX from "xlsx";
const percentageColumnWidth = [
  { wch: 40 }, //Nama Member
  { wch: 20 }, //121 (%)
  { wch: 30 }, //Jumlah Entry 121
];
let supervisorColumnWidth = [{ wch: 20 }];
for (let i = 0; i < 32; i++) {
  let width = { wch: 30 };
  supervisorColumnWidth.push(width);
}

function resolveOnePercentage(oneCount, month) {
  if (oneCount <= 0) return "0%";
  const result = (oneCount / (month * 2)) * 100;
  return result > 100 ? "100%" : result + "%";
}

function resolveSupervisorRoleName(roleID) {
  if (roleID == 1) return "Head Gembala";
  else if (roleID == 2) return "Gembala";
  else if (roleID == 12) return "Pendamping Gembala";
  else if (roleID == 3) return "Wakil Gembala";
  else if (roleID == 13) return "Pendamping Wakil Gembala";
}

function mapSupervisorData(data, roleID, tokenRoleID, tokenUserID, userRelation) {
  // roleID is the supervisor data's role, tokenRoleID is current user's role
  const dataToMap = data.filter(function (el) {
    if (tokenRoleID != 5) {
      if (tokenRoleID == roleID) return el.roleID == roleID && el.userID == tokenUserID;
      else return el.roleID == roleID && userRelation.some((relation) => relation.userID === Number(el.userID) && relation.rolesID === el.roleID);
    } else return el.roleID == roleID;
  });
  const dataToMapInitialLength = dataToMap.length;
  if (dataToMap.length <= 0) {
    const emptyObject = {};
    dataToMap.push(emptyObject);
  }

  let supervisorCreatorColumn = "Nama " + resolveSupervisorRoleName(roleID);
  let jsonSupervisor = dataToMap.map((item) => ({
    Tanggal: dataToMapInitialLength > 0 ? formatDateForView(new Date(item.date)) : "",
    [supervisorCreatorColumn]: item.name,
    "Rekan Spiritual Conversation": item.partnerName,
    "Who is Christ for you?": item.mission1,
    "What is your favorite passage about Jesus in the Gospels?": item.mission2,
    "How has it inspired you, and how has it inspired others through you?": item.mission3,
    "What makes you proud of being a Catholic?": item.sevenHabits1,
    "What is the most challenging Catholic principle for you?": item.sevenHabits2,
    "How do you deal with it?": item.sevenHabits3,
    "What is the hardest choice that you have ever made to obey God?": item.sevenHabits4,
    "What kind of criticisms have you received because of this?": item.sevenHabits5,
    "What could you have done better?": item.sevenHabits6,
    "What has been the most painful experience in obedience?": item.sevenHabits7,
    "How do you wish that the Church had done differently?": item.sevenHabits8,
    "How do you perceive the figure of authority in your life?": item.sevenHabits9,
    "In what way has comfort become your prison?": item.sevenHabits10,
    "Has your comfort damaged others?": item.sevenHabits11,
    "How has your cell group become a form of your bondage of comforts?": item.sevenHabits12,
    "How do you usually react when you feel being taken for granted by others?": item.sevenHabits13,
    "What is the biggest challenge to do good to others?": item.sevenHabits14,
    "What “good” have you seen in others because of your sacrifice?": item.sevenHabits15,
    "What is your gift of the Holy Spirit?": item.sevenHabits16,
    "How do you build others by using your gift of the Holy Spirit?": item.sevenHabits17,
    "Have others questioned your gift of the Holy Spirit?": item.sevenHabits18,
    "Based on your experience or your observation, can you tell us the difference between “act courageously” and “act stubbornly”?":
      item.sevenHabits19,
    "Have you taken actions against the majority as the result of your discernment?": item.sevenHabits20,
    "How have you justified your fear of speaking up and of acting courageously?": item.sevenHabits21,
    "In the process of cultivating the 7 DC habits, what are your strength and weakness?": item.general1,
    "Do you have any feedback to DC formation and mission?": item.general2,
    "How do you see yourself called by God to participate in DC mission?": item.general3,
    "Catatan Spiritual Conversation": item.note,
    "Efektifitas Spiritual Conversation": item.effective,
    "Joyful Feeling": item.joy,
  }));
  return jsonSupervisor;
}

export default {
  async getOneReporting(fromDate, toDate) {
    try {
      const option = "fromDate=" + formatDate(fromDate) + "&toDate=" + formatDate(toDate);
      const res = await fetch(urlApi + "/Reporting/One?" + option, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return data["data"];
    } catch (err) {
      return [];
    }
  },

  async exportOneReporting(fromDate, toDate) {
    const data = await this.getOneReporting(fromDate, toDate);
    const fromDateForView = formatDateForView(fromDate);
    const toDateForView = formatDateForView(toDate);
    const month = differenceInMonths(toDate, fromDate) == 0 ? 1 : differenceInMonths(toDate, fromDate);
    const token = parseJwt(localStorage.getItem("token"));
    // PERSENTASE REGION
    const jsonPercentage = data.onePercentageReporting.map((item) => ({
      "Nama Member": item.name,
      "Sel Group": item.cellgroups,
      "121 (%)": resolveOnePercentage(item.oneCount, month),
      "Jumlah Entry 121": item.oneCount,
    }));
    const worksheetOnePercentage = handleJSONtoSheet(
      jsonPercentage,
      XLSX.utils,
      fromDateForView,
      toDateForView,
      percentageColumnWidth,
      "PERSENTASE 121",
    );

    // 121 MEMBER REGION
    let jsonOneMember = data.oneMemberReporting.map((item) => ({
      Tanggal: formatDateForView(new Date(item.date)),
      "Nama Member": item.name,
      "Rekan 121 Attendance": item.partnerName,
      "Efektifitas 121": item.effective,
      "Joyful Feeling": item.joy,
    }));
    const oneMemberColumnWidth = [{ wch: 20 }, { wch: 40 }, { wch: 40 }, { wch: 20 }, { wch: 20 }];
    const worksheetOneMember = handleJSONtoSheet(jsonOneMember, XLSX.utils, fromDateForView, toDateForView, oneMemberColumnWidth, "121");
    if (jsonOneMember.length <= 0) {
      XLSX.utils.sheet_add_json(worksheetOneMember, [{ A: "Nama Member " }], { skipHeader: true, origin: "A5" });
      XLSX.utils.sheet_add_json(worksheetOneMember, [{ A: "Rekan 121 Attendance " }], { skipHeader: true, origin: "B5" });
      XLSX.utils.sheet_add_json(worksheetOneMember, [{ A: "Efektifitas 121 " }], { skipHeader: true, origin: "C5" });
      XLSX.utils.sheet_add_json(worksheetOneMember, [{ A: "Joyful Feeling " }], { skipHeader: true, origin: "D5" });
    }

    // HEAD GEMBALA REGION
    const jsonHeadGembala = mapSupervisorData(data.spiritualConvReporting, 1, token.RoleID, token.UserID, data.userRelation);
    const worksheetHeadGembala = handleJSONtoSheet(
      jsonHeadGembala,
      XLSX.utils,
      fromDateForView,
      toDateForView,
      supervisorColumnWidth,
      "Spiritual Conversation",
    );

    // GEMBALA REGION
    const jsonGembala = mapSupervisorData(data.spiritualConvReporting, 2, token.RoleID, token.UserID, data.userRelation);
    const worksheetGembala = handleJSONtoSheet(
      jsonGembala,
      XLSX.utils,
      fromDateForView,
      toDateForView,
      supervisorColumnWidth,
      "Spiritual Conversation",
    );

    // PENDAMPING GEMBALA REGION
    const jsonPendampingGembala = mapSupervisorData(data.spiritualConvReporting, 12, token.RoleID, token.UserID, data.userRelation);
    const worksheetPendampingGembala = handleJSONtoSheet(
      jsonPendampingGembala,
      XLSX.utils,
      fromDateForView,
      toDateForView,
      supervisorColumnWidth,
      "Spiritual Conversation",
    );

    // Wakil GEMBALA REGION
    const jsonWakilGembala = mapSupervisorData(data.spiritualConvReporting, 3, token.RoleID, token.UserID, data.userRelation);
    const worksheetWakilGembala = handleJSONtoSheet(
      jsonWakilGembala,
      XLSX.utils,
      fromDateForView,
      toDateForView,
      supervisorColumnWidth,
      "Spiritual Conversation",
    );

    // Pendamping Wakil GEMBALA REGION
    const jsonPendampingWakilGembala = mapSupervisorData(data.spiritualConvReporting, 13, token.RoleID, token.UserID, data.userRelation);
    const worksheetPendampingWakilGembala = handleJSONtoSheet(
      jsonPendampingWakilGembala,
      XLSX.utils,
      fromDateForView,
      toDateForView,
      supervisorColumnWidth,
      "Spiritual Conversation",
    );

    const new_workbook = XLSX.utils.book_new(); // make new workbook
    XLSX.utils.book_append_sheet(new_workbook, worksheetOnePercentage, "121 MEMBER PERSENTASE"); // add ATTENDANCE MEMBER PERSENTASE tab
    XLSX.utils.book_append_sheet(new_workbook, worksheetOneMember, "121 MEMBER"); // add ATTENDANCE MEMBER tab
    XLSX.utils.book_append_sheet(new_workbook, worksheetHeadGembala, "HEAD GEMBALA"); // add HEAD GEMBALA tab
    XLSX.utils.book_append_sheet(new_workbook, worksheetGembala, "GEMBALA"); // add GEMBALA tab
    XLSX.utils.book_append_sheet(new_workbook, worksheetPendampingGembala, "PENDAMPING GEMBALA"); // add GEMBALA tab
    XLSX.utils.book_append_sheet(new_workbook, worksheetWakilGembala, "WAKIL GEMBALA"); // add WAKIL GEMBALA tab
    XLSX.utils.book_append_sheet(new_workbook, worksheetPendampingWakilGembala, "PENDAMPING WAKIL GEMBALA"); // add WAKIL GEMBALA tab
    const fileName = token.RoleID == 5 ? "Super Admin - 121.xlsx" : "Head Gembala - 121.xlsx";
    XLSX.writeFileXLSX(new_workbook, fileName); // trigger download
  },
};
