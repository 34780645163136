<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  export let data;
  export let selected;
</script>

<table class="min-w-full divide-y divide-gray-200 mt-1" id="tableAddSpv">
  <thead class="bg-gray-100">
    <tr>
      <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Nama </th>
    </tr>
  </thead>
  <tbody class="bg-white divide-y divide-gray-200">
    {#if data.length == 0}
      <tr>
        <td colspan="1" class="px-4 py-4 text-sm font-medium">
          <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
        </td>
      </tr>
    {/if}
    {#each data as item}
      <tr class="hover:bg-gray-100 cursor-pointer" on:click={() => dispatch("select", item)}>
        <div
          class="px-3.5 py-2 text-sm font-medium whitespace-nowrap {selected && selected['id'] == item['id'] ? 'bg-blue-100/60 text-blue-500' : ''}"
        >
          <h2 class="font-medium text-gray-800 text-xs">
            {item["name"]}
          </h2>
        </div>
      </tr>
    {/each}
  </tbody>
</table>
