<script>
  import { inputClass, inputErrClass, urlApi } from "../../utils/const";
  import Cleave from "cleave.js";
  import { onMount } from "svelte";
  import Quill from "quill";
  import ImageKit from "imagekit-javascript";
  import { generateID } from "../../utils/utils";
  import { imageKitPublicKey, imageKitUrlEndpoint } from "../../utils/const";
  import Compressor from "compressorjs";

  export let name = "";
  export let value;
  export let errors = [];
  export let content = { text: "", html: "" };
  export let inputEditor = false;
  export let label = "";
  export let type = "text";
  export let placeholder = "";
  export let withEye = false;
  export let showPass = false;
  export let noLabel = false;
  export let maxlength;
  export let disabled = false;
  export let disableQuil = false;
  export let labelBold = true;

  let uploading = false;
  let imageInput;
  let quill;
  let editor;

  export let extClass = "";
  let extPattern = "";

  $: countChar = inputEditor ? content.text.length - 1 : 0;

  $: if (errors.length > 0) {
    errors = errors
      .filter((err) => err.split(".")[0] == name)
      .map((e) => ({
        field: e.split(".")[0],
        error: e.split(".")[1],
      }));
    errors = errors;
  }

  function handleInput(e) {
    if (type == "money") {
      const { target } = e;
      value = target.value;
    } else {
      value = e.target.value;
    }
  }

  onMount(() => {
    if (inputEditor) {
      // hide toolbar with false value if disableQuil is true
      quill = new Quill(editor, {
        modules: {
          toolbar:
            disableQuil == false
              ? {
                  container: [
                    ["bold", "italic", "underline", "strike"],
                    [{ header: [1, 2, false] }, { list: "ordered" }, { list: "bullet" }, "blockquote"],
                    ["image"],
                  ],
                  handlers: {
                    image: handleImageSelection,
                  },
                }
              : false,
        },
        placeholder: placeholder,
        theme: "snow",
      });
      quill.on("text-change", function () {
        content = { text: quill.getText(), html: quill.root.innerHTML };
        value = content.html;
      });
      if (disableQuil == true) quill.enable(false);
    }

    const inputArr = document.getElementsByClassName("input-money");
    const fields = Array.from(inputArr);

    if (type == "phone") {
      extClass = " pl-12 ";
      extPattern = "[0-9]*";
    }

    if (type == "money") {
      fields.forEach(function (field) {
        new Cleave(field, {
          numeral: true,
          prefix: "Rp. ",
          numeralDecimalMark: ",",
          delimiter: ".",
        });
      });
    }
  });

  let imageSrc;
  let base64Image;

  function handleImageSelection() {
    if (imageInput) {
      imageInput.click();
    }
  }

  function compressImage(index, file) {
    uploadImg = true;
    new Compressor(file, {
      maxWidth: "1000",
      success(result) {
        convertImage(index, result);
      },
      error(err) {
        // eslint-disable-next-line
        console.log(err.message);
      },
    });
  }
  function convertImage(index, file) {
    var reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      imageSrc = URL.createObjectURL(file);
      base64Image = reader.result;
      quill.insertEmbed(index, "image", reader.result);
    };
    reader.onerror = function (error) {
      // eslint-disable-next-line
      console.log(error);
      this.$emit(error);
    };
  }

  function processUpload(file) {
    const imagekit = new ImageKit({
      publicKey: imageKitPublicKey,
      urlEndpoint: imageKitUrlEndpoint,
      authenticationEndpoint: urlApi + "/Authorization/ImgKit",
    });
    return new Promise((resolve, reject) => {
      imagekit.upload(
        {
          file,
          fileName: `POST_IMAGE_${generateID(10)}`,
          tags: ["post", "image", "inline"],
        },
        async function (error, result) {
          if (error) {
            reject({ error });
          } else {
            resolve({ result: result.url });
          }
        },
      );
    });
  }

  async function handleImageUpload(files) {
    if (quill) {
      uploading = true;
      const range = quill.getSelection(true);
      const resp = await processUpload(files[0]);
      if (resp.result) {
        quill.insertEmbed(range.index, "image", resp.result);
      }

      uploading = false;
    }
  }
</script>

<svelte:head>
  <link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet" />
</svelte:head>

<div>
  {#if !noLabel}
    <label for={name} class="block mb-2 text-sm {labelBold ? 'font-semibold' : ''} text-gray-900">{label}</label>
  {/if}
  <div class="relative">
    {#if type == "phone"}
      <small class="absolute left-3 inset-y-0 flex items-center text-gray-700 font-semibold outline-none focus:ring-transparent focus:outline-none"
        >+62</small
      >
    {/if}
    {#if inputEditor}
      <div class="h-60 relative">
        <div class="" bind:this={editor}>
          <div>
            {@html content.html}
          </div>
        </div>
        {#if uploading}
          <div class="absolute w-full h-full bg-white bg-opacity-70" style="top:0; left:0" />
        {/if}
      </div>
      <input class="hidden" type="file" accept=".jpg, .jpeg, .png" on:change={(e) => handleImageUpload(e.target.files)} bind:this={imageInput} />
    {:else}
      <textarea
        class="{type == 'money' ? 'input-money' : ''} appearance-none {errors.length > 0 ? inputErrClass + extClass : inputClass + extClass}"
        type={type == "money" ? "text" : type == "phone" ? "number" : type}
        {name}
        value={value || ""}
        id={name}
        pattern={extPattern}
        {placeholder}
        {maxlength}
        {disabled}
        on:input={handleInput}
        rows="6"
      />
    {/if}
    {#if inputEditor}
      <p class=" {countChar > maxlength ? 'text-red-500' : 'text-gray-700'} text-xs italic text-right mt-12">
        {countChar < 0 ? 0 : countChar}/{maxlength}
      </p>
    {:else if maxlength}
      <div>
        <h2 class="font-medium text-gray-500 text-xs float-right">{value.length}/{maxlength}</h2>
      </div>
    {/if}
    <!-- {/if} -->

    {#if withEye}
      <button
        type="button"
        on:click={() => {
          if (!showPass) {
            type = "text";
            showPass = !showPass;
          } else {
            type = "password";
            showPass = !showPass;
          }
        }}
        class="absolute right-0 inset-y-0 flex items-center pr-3 text-gray-500 outline-none focus:ring-transparent focus:outline-none"
      >
        <i class="bx {showPass ? 'bx-show' : 'bx-hide'}" />
      </button>
    {/if}
  </div>
  {#if errors.length > 0 && errors[0]["error"] == "required"}
    <p class="text-xs text-red-500 p-0 mt-1">{label ?? ""} harus diisi</p>
  {/if}
  {#if errors.length > 0 && errors[0]["error"] == "not_an_email"}
    <p class="text-xs text-red-500 p-0 mt-1">{label} not valid</p>
  {/if}
  {#if errors.length > 0 && errors[0]["error"] == "match_field"}
    <p class="text-xs text-red-500 p-0 mt-1">Password must match</p>
  {/if}
</div>

<style>
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
