<script>
  import { field, form } from "svelte-forms";
  import { required } from "svelte-forms/validators";
  import { replace } from "svelte-spa-router";
  import AttendanceCategoryForm from "./AttendanceCategoryForm.svelte";
  import AttendanceCategoryService from "./AttendanceCategoryServices";
  import Modal from "../../components/Modal.svelte";
  import Loading from "../../components/Loading.svelte";

  let name = field("name", "", [required()]);
  let note = field("note", "", []);

  let errors = [];
  let modalBack;
  let status = true;

  let attendanceCategoryForm;
  let disableSubmit = false;
  let loading;

  async function addAttendanceCategory() {
    disableSubmit = true;
    loading.toggle();
    errors = [];

    attendanceCategoryForm = form(name);
    await attendanceCategoryForm.validate();
    if ($attendanceCategoryForm.valid) {
      const reqData = {
        Name: $name.value,
        IsActive: status ? 1 : 0,
        Note: $note.value,
      };

      const data = await AttendanceCategoryService.createAttendanceCategory(reqData);
      if (data["data"]["id"]) {
        const id = data["data"]["id"];
        globalThis.pushToast("Berhasil Menambahkan Kategori Attendance", "success");
        setTimeout(() => replace("/attendancecategory/" + id + "/detail"), 1000);
      } else if (data["data"]["errorMessage"] == "Duplicate Name") {
        globalThis.pushToast("Nama Kategori Attendance sudah digunakan", "error");
        disableSubmit = false;
      }
    } else {
      errors = $attendanceCategoryForm.errors;
      errors = errors;
      disableSubmit = false;
    }
    loading.toggle();
  }
</script>

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Apakah Anda yakin akan membatalkan perubahan? Perubahan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/attendancecategory/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>

<Loading bind:this={loading} />
<section class="container px-4 py-4">
  <AttendanceCategoryForm
    formTitle="Tambah Kategori Attendance"
    mode="add"
    bind:disableSubmit
    bind:errors
    bind:name={$name.value}
    bind:note={$note.value}
    bind:status
    on:back={() => modalBack.toggle()}
    on:save={() => addAttendanceCategory()}
  />
</section>
