<script>
  import { createEventDispatcher } from "svelte";
  import CellGroupServices from "../../../pages/CellGroup/CellGroupServices";
  const dispatch = createEventDispatcher();

  export let data;
  export let mode = "default";
  export let covenantYearName
</script>

<table class="min-w-full divide-y divide-gray-200">
  <thead class="bg-gray-100">
    <tr>
      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left text-gray-500"> No. </th>

      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left text-gray-500"> Nama </th>

      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Role </th>

      {#if mode == "add" || mode == "edit"}
        <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500 w-1/3"> Keterangan </th>
      {/if}
      {#if mode == "add" || mode == "edit"}
        <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Action </th>
      {/if}
    </tr>
  </thead>
  <tbody class="bg-white divide-y divide-gray-200">
    {#if data.length == 0}
      <tr>
        <td colspan="6" class="px-4 py-4 text-sm font-medium">
          <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
        </td>
      </tr>
    {/if}
    {#each data as item, index}
      <tr>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {index + 1}
          </h2>
        </td>

        <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["name"]}
          </h2>
        </td>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            Member
          </h2>
        </td>
        {#if mode == "add" || mode == "edit"}
          <td class="px-4 text-sm font-medium whitespace-nowrap">
            <h2 class="{item["status"] == 1 ? "text-gray-800" : 'text-red-500'} font-semibold  text-xs">
              {CellGroupServices.resolveCellGroupMemberKeterangan(item["status"], covenantYearName)}
            </h2>
          </td>
        {/if}
        {#if mode == "add" || mode == "edit"}
          <td class="px-4 text-sm font-medium whitespace-nowrap">
            <button
              on:click={() => dispatch("delete", item)}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-trash text-xl text-red-500" />
            </button>
          </td>
        {/if}
      </tr>
    {/each}
  </tbody>
</table>
