<script>
  import { onMount, createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let text = "";
  export let disabled = false;
  export let type = "success";

  let btnClass = "text-white bg-green-500";

  if (type == "gray") {
    btnClass = "text-white bg-gray-400";
  } else if (type == "danger") {
    btnClass = "text-white bg-red-500";
  } else if (type == "primary") {
    btnClass = "text-white bg-primary";
  }
</script>

<button
  on:click={() => dispatch("click")}
  {disabled}
  class="w-full {btnClass} hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
  >{text}
</button>
