<script>
  import Router, { replace } from "svelte-spa-router";
  import routes from "./routes";
  import { onMount } from "svelte";
  import { roleModules } from "./stores/store";
  import AuthServices from "./pages/Auth/AuthServices";
  import { parseJwt } from "./utils/utils";

  import "flatpickr/dist/flatpickr.min.css";

  let load = true;

  onMount(async () => {
    await loadRoleModules();
  });

  async function loadRoleModules() {
    const token = localStorage.getItem("token");
    if (token && $roleModules.length == 0) {
      const tokenData = await AuthServices.refreshToken();
      if (tokenData["error"]) {
        localStorage.clear("token");
        localStorage.clear("tokenRole");
        replace("/login");
      } else {
        localStorage.setItem("token", tokenData["data"]["token"]);
        const tokenRoleModule = parseJwt(tokenData["data"]["tokenRoleModule"]);
        localStorage.setItem("tokenRole", tokenData["data"]["tokenRole"]);
        roleModules.set(tokenRoleModule.RolesModule);
      }
    }
    load = false;
  }
  function conditionsFailed(event) {
    if (
      event.detail.userData.token == null ||
      event.detail.userData.token == undefined ||
      event.detail.userData.name == undefined ||
      event.detail.userData.name == null
    ) {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenRole");
      replace("/login");
    }
  }
</script>

<main>
  {#if !load}
    <Router {routes} on:conditionsFailed={conditionsFailed} />
  {/if}
</main>

<style global lang="postcss">
  body {
    font-family: "Inter";
  }

  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
