<script>
  import Input from "../../components/control/Input.svelte";
  import Select from "../../components/control/Select.svelte";
  import Switch from "../../components/control/Switch.svelte";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import { pop, replace } from "svelte-spa-router";
  import { createEventDispatcher, onMount } from "svelte";
  import { MEMBER_REQUEST_CELLGROUP_MODULE, EDIT_CELLGROUP_MODULE, urlApi } from "../../utils/const";
  import { parseJwt, checkRoleModule, checkEmptyInput } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import TableCellGroupMember from "../../components/table/CellGroup/TableCellGroupMember.svelte";
  import TableAddCellGroupMember from "../../components/table/CellGroup/TableAddCellGroupMember.svelte";
  import Modal from "../../components/Modal.svelte";
  import ModalPagination from "../../components/pagination/ModalPagination.svelte";
  import AddCellGroupMemberCard from "../../components/card/CellGroup/AddCellGroupMemberCard.svelte";
  import CellGroupMemberCard from "../../components/card/CellGroup/CellGroupMemberCard.svelte";
  import CellGroupServices from "./CellGroupServices";
  import TableAddSpv from "../../components/table/CellGroup/TableAddSpv.svelte";
  import AddSpv from "../../components/card/CellGroup/AddSpv.svelte";
  import Loading from "../../components/Loading.svelte";
  import CellCategoryServices from "../CellCategory/CellCategoryServices";

  const dispatch = createEventDispatcher();

  export let cellGroupMemberList;
  export let cellGroupMemberListBefore = [];
  export let cellGroupMemberListTemp = [];
  export let concernedID;
  export let covenantYearSelected;
  export let dateCreated;
  export let disableSubmit;
  export let errors = [];
  export let formTitle = [];
  export let gembalaSelected;
  export let pGembalaSelected;
  export let pWakilSelected;
  export let headGembalaSelected;
  export let initialMembers = [];
  export let mode;
  export let memberError;
  export let wakilGembalaError;
  export let pWakilGembalaError;
  export let wakilGembalaSelected;
  export let status;

  export let name;

  const token = parseJwt(localStorage.getItem("token"));
  const loggedInID = token.UserID;
  let allMembers = [];
  let allCellGroupMembers = [];
  let covenantYearOption = [];
  let covenantYearName;
  let ignoredIDArray = [];
  let isCellCategoryAvailable = true;
  let members = [];
  let memberDeleted = [];
  let memberDeletedBefore = [];
  let memberDeletedTemp = [];
  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";
  let modalAddCellGroupMember;
  let page = 1;
  let pageAll = 1;
  let perPage = 5;
  let perPageAll = 5;
  let search = "";
  let totalData;
  let totalDataAll;
  let totalPage;
  let totalPageAll;
  let modalSpv;
  let modalData;
  let loading;
  let loadingMember = false;
  let isOptionOpened = false;
  let errorMap = [];

  onMount(() => {
    loadData();
  });

  async function loadData() {
    loading.toggle();
    await Promise.all([loadCovenantYear()]);
    await loadMembers(true);
    if (mode == "edit") {
      loadCovenantName();
    }
    if (loading) loading.toggle();
  }
  function loadCovenantName() {
    covenantYearName = covenantYearOption.find((item) => item.id == covenantYearSelected)?.name || "";
  }
  function checkBack() {
    if (cellGroupMemberList.length > 0) return true;
    else return checkEmptyInput([name, covenantYearSelected, headGembalaSelected, gembalaSelected, wakilGembalaSelected]);
  }
  function searchData() {
    loadingMember = true;
    page = 1;
    loadMembers(false);
  }
  function searchDataAll() {
    loadingMember = true;
    pageAll = 1;
    loadMore();
  }
  async function loadCovenantYear() {
    const option = "perPage=9999" + "&currentPage=1" + "&isActive=1" + "&isDelete=0";
    const data = await CellCategoryServices.loadCellCategory(option);
    for (const item of data["data"]) {
      covenantYearOption.push({
        id: String(item.id),
        name: item.name,
      });
    }
    covenantYearOption = covenantYearOption;
    if (mode == "detail" || mode == "edit") {
      const cellCategory = covenantYearOption.find((e) => e.id === covenantYearSelected);
      isCellCategoryAvailable = cellCategory !== undefined;
      if (!isCellCategoryAvailable) covenantYearSelected = "";
    }
  }
  async function loadMembers(showAllMembers) {
    let ignoredIDs = "&ignoredIDs=0";
    // if (headGembalaSelected) {
    //   ignoredIDs += `&ignoredIDs=${headGembalaSelected["id"]}`;
    //   ignoredIDArray.push(headGembalaSelected["id"]);
    // }

    // if (gembalaSelected) {
    //   ignoredIDs += `&ignoredIDs=${gembalaSelected["id"]}`;
    //   ignoredIDArray.push(gembalaSelected["id"]);
    // }

    // if (pGembalaSelected) {
    //   ignoredIDs += `&ignoredIDs=${pGembalaSelected["id"]}`;
    //   ignoredIDArray.push(pGembalaSelected["id"]);
    // }

    // if (wakilGembalaSelected) {
    //   ignoredIDs += `&ignoredIDs=${wakilGembalaSelected["id"]}`;
    //   ignoredIDArray.push(wakilGembalaSelected["id"]);
    // }

    // if (pWakilSelected) {
    //   ignoredIDs += `&ignoredIDs=${pWakilSelected["id"]}`;
    //   ignoredIDArray.push(pWakilSelected["id"]);
    // }
    if (showAllMembers == true) perPage = 9999;
    else perPage = 5;

    const option =
      "perPage=" + perPage + "&currentPage=" + page + "&onlyName=1" + "&searchQuery=" + search + "&showAll=1" + "&isActive=" + 1 + ignoredIDs;
    const res = await fetch(urlApi + "/Users?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();

    if (data["data"] && showAllMembers == false) {
      members = data["data"].map((e) => ({ id: e["id"], name: e["name"], roleName: e["roleName"] }));
      totalData = data["info"]["totalItem"];
      totalPage = data["info"]["totalPage"];
    } else if (data["data"] && showAllMembers == true) {
      allCellGroupMembers = data["data"].map((e) => ({ id: e["id"], name: e["name"], roleName: e["roleName"] }));
      if (allCellGroupMembers.length > 0 && mode != "add") {
        if (!headGembalaSelected["id"]) {
          headGembalaSelected = allCellGroupMembers.filter((e) => e["id"] == headGembalaSelected)[0];
        }
        if (!gembalaSelected["id"]) {
          gembalaSelected = allCellGroupMembers.filter((e) => e["id"] == gembalaSelected)[0];
        }
        if (!pGembalaSelected["id"]) {
          pGembalaSelected = allCellGroupMembers.filter((e) => e["id"] == pGembalaSelected)[0];
        }
        if (!wakilGembalaSelected["id"]) {
          wakilGembalaSelected = allCellGroupMembers.filter((e) => e["id"] == wakilGembalaSelected)[0];
        }
        if (!pWakilSelected["id"]) {
          pWakilSelected = allCellGroupMembers.filter((e) => e["id"] == pWakilSelected)[0];
        }
      }
    }
    loadingMember = false;
    perPage = 5;
  }
  async function loadMore() {
    if (
      mode != "add" &&
      !headGembalaSelected["id"] &&
      !gembalaSelected["id"] &&
      !pGembalaSelected["id"] &&
      !wakilGembalaSelected["id"] &&
      !pWakilSelected["id"]
    ) {
      perPageAll = 999999;
    }
    let ignoredIDs = "&ignoredIDs=0";
    // if (mode == "add" || (mode == "edit" && perPageAll == 5)) {
    //   if (headGembalaSelected) {
    //     ignoredIDs += `&ignoredIDs=${headGembalaSelected["id"]}`;
    //   }

    //   if (gembalaSelected) {
    //     ignoredIDs += `&ignoredIDs=${gembalaSelected["id"]}`;
    //   }

    //   if (pGembalaSelected) {
    //     ignoredIDs += `&ignoredIDs=${pGembalaSelected["id"]}`;
    //   }

    //   if (wakilGembalaSelected) {
    //     ignoredIDs += `&ignoredIDs=${wakilGembalaSelected["id"]}`;
    //   }

    //   if (pWakilSelected) {
    //     ignoredIDs += `&ignoredIDs=${pWakilSelected["id"]}`;
    //   }
    // }
    const option =
      "perPage=" + perPageAll + "&currentPage=" + pageAll + "&onlyName=1" + "&searchQuery=" + search + "&showAll=1" + "&isActive=" + 1 + ignoredIDs;
    const res = await fetch(urlApi + "/Users?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();

    if (data["data"]) {
      allMembers = data["data"].map((e) => ({ id: e["id"], name: e["name"], roleName: e["roleName"] }));

      totalDataAll = data["info"]["totalItem"];
      totalPageAll = data["info"]["totalPage"];

      if (perPageAll != 5) {
        perPageAll = 5;
        loadMore();
      }
      loadingMember = false;
    }
  }
  function addOrRemoveMember(item, action) {
    var index = cellGroupMemberListTemp.findIndex((e) => e.id == item.id);
    if (index != -1) {
      var indexDeleted = memberDeletedTemp.findIndex((e) => e.id == item.id);
      if (indexDeleted == -1) {
        memberDeletedTemp.push(cellGroupMemberListTemp[index]);
      }
      cellGroupMemberListTemp.splice(index, 1);
      if (action) {
        memberDeleted.push(cellGroupMemberListBefore[index]);
        memberDeletedBefore.push(cellGroupMemberListBefore[index]);
        cellGroupMemberListBefore.splice(index, 1);
      }
    } else {
      var indexDeleted = memberDeletedTemp.findIndex((e) => e.id == item.id);
      if (indexDeleted != -1) {
        cellGroupMemberListTemp.push(memberDeletedTemp[indexDeleted]);
        memberDeletedTemp.splice(indexDeleted, 1);
      } else {
        item["status"] = 1;
        cellGroupMemberListTemp.push(item);
      }
    }
    cellGroupMemberListTemp = cellGroupMemberListTemp;
  }
  function addOrRemoveAllMember(checked) {
    if (!checked) {
      memberDeletedTemp = [...cellGroupMemberListTemp];
      cellGroupMemberListTemp = [];
    } else {
      cellGroupMemberListTemp = [
        ...allCellGroupMembers
          .filter((item) => ignoredIDArray.findIndex((e) => item.id == e) == -1)
          .map((item) => {
            return {
              ...item,
              status: 1,
            };
          }),
      ];
      cellGroupMemberList.forEach((item) => {
        var foundItem = cellGroupMemberListTemp.findIndex((e) => e.id == item.id);
        if (foundItem) {
          cellGroupMemberListTemp[foundItem].status = item.status;
        }
      });

      memberDeletedTemp.forEach((item) => {
        var foundItem = cellGroupMemberListTemp.findIndex((e) => e.id == item.id);
        if (foundItem) {
          cellGroupMemberListTemp[foundItem].status = item.status;
        }
      });
      memberDeletedTemp = [];
    }
  }
  function checkStatusDisabled() {
    if (mode == "detail") return true;
    return false;
  }
  async function checkMemberConflict() {
    if (covenantYearSelected != "") {
      let cellGroupMemberQueryString = "";
      cellGroupMemberListTemp.forEach((element) => {
        cellGroupMemberQueryString += `&MembersID=${element["id"]}`;
      });
      const option = `CovenantYearID=${covenantYearSelected}&CellGroupID=${concernedID}${cellGroupMemberQueryString}`;
      const data = await CellGroupServices.checkMemberConflict(option);

      cellGroupMemberListTemp = cellGroupMemberListTemp.map((item) => {
        let status =
          initialMembers.length == 0 ? 1 : initialMembers.find((e) => e.id == item.id) ? initialMembers.find((e) => e.id == item.id).status : 1;
        if (data["data"]["cellGroupMembers"].findIndex((x) => x.userID == item.id) != -1)
          return {
            ...item,
            status: 3,
          };
        else return { ...item, status: status };
      });
      cellGroupMemberList = [...cellGroupMemberListTemp];
      cellGroupMemberListBefore = [...cellGroupMemberListTemp];
      memberError = data["data"]["cellGroupMembers"].length > 0;
    }
  }
  async function checkWakilConflict() {
    if (wakilGembalaSelected != undefined && covenantYearSelected != "") {
      let wakilGembalaID = wakilGembalaSelected == "" ? 0 : wakilGembalaSelected["id"];
      const option = `CovenantYearID=${covenantYearSelected}&CellGroupID=${concernedID}&WakilGembalaID=${wakilGembalaID}`;
      const data = await CellGroupServices.checkWakilGembalaConflict(option);
      wakilGembalaError = data["data"]["countWakilGembala"] > 0;
    }
  }
  async function checkPendampingWakilConflict() {
    if (pWakilSelected != undefined && covenantYearSelected != "") {
      let pWakilGembalaID = pWakilSelected == "" ? 0 : pWakilSelected["id"];
      const option = `CovenantYearID=${covenantYearSelected}&CellGroupID=${concernedID}&PendampingWakilGembalaID=${pWakilGembalaID}`;
      const data = await CellGroupServices.checkPendampingWakilGembalaConflict(option);
      pWakilGembalaError = data["data"]["countPendampingWakilGembala"] > 0;
    }
  }

  function valueSelected(type) {
    let res =
      type == "head"
        ? headGembalaSelected
        : type == "gembala"
        ? gembalaSelected
        : type == "pGembala"
        ? pGembalaSelected
        : type == "wakil"
        ? wakilGembalaSelected
        : pWakilSelected;

    return res;
  }

  $: if (errors.length > 0 && errorMap.length == 0) {
    errorMap = [];
    errorMap = errors
      .filter((e) => e.toString().split(".").length > 1)
      .map((e) => ({
        field: e.toString().split(".")[0],
        error: e.toString().split(".")[1],
      }));
  }
</script>

<Modal
  index={50}
  title="Pilih {modalData ? modalData.title : '-'}"
  bind:this={modalSpv}
  size="mobile"
  isScrollable
  closed
  on:close={() => {
    modalSpv.toggle();
    pageAll = 1;
    search = "";
  }}
>
  <div class="w-full flex items-center px-4">
    <div class="relative w-full">
      <div class="relative w-full">
        <input
          type="text"
          placeholder="Cari berdasarkan nama.."
          bind:value={search}
          on:input={searchDataAll}
          class="block w-full text-xs py-2 pr-5 text-gray-700 focus:border-gray-300 focus:ring-transparent bg-white
    border border-gray-200 rounded-lg placeholder-gray-400/70 pl-8 focus:outline-none"
        />
        <span class="absolute left-2 top-0 bottom-0 flex items-center">
          <i class="bx bx-search text-gray-500" />
        </span>
      </div>
    </div>
  </div>

  <div class="relative {!loadingMember ? 'overflow-x-auto' : ''} h-auto block px-4 my-2">
    {#if modalData.type == "head" && headGembalaSelected}
      <div class="card-data min-w-full p-2 bg-primary rounded-md cursor-pointer">
        <div class="flex justify-between items-center text-xs font-medium text-white">
          <div class="flex flex-col justify-start items-start">
            <p class="text-md font-semibold">{headGembalaSelected["name"]}</p>
          </div>
        </div>
      </div>
    {/if}

    {#if modalData.type == "gembala" && gembalaSelected}
      <div class="card-data min-w-full p-2 bg-primary rounded-md cursor-pointer">
        <div class="flex justify-between items-center text-xs font-medium text-white">
          <div class="flex flex-col justify-start items-start">
            <p class="text-md font-semibold">{gembalaSelected["name"]}</p>
          </div>
        </div>
      </div>
    {/if}

    {#if modalData.type == "pGembala" && pGembalaSelected}
      <div class="card-data min-w-full p-2 bg-primary rounded-md cursor-pointer">
        <div class="flex justify-between items-center text-xs font-medium text-white">
          <div class="flex flex-col justify-start items-start">
            <p class="text-md font-semibold">{pGembalaSelected["name"]}</p>
          </div>
        </div>
      </div>
    {/if}

    {#if modalData.type == "wakil" && wakilGembalaSelected}
      <div class="card-data min-w-full p-2 bg-primary rounded-md cursor-pointer">
        <div class="flex justify-between items-center text-xs font-medium text-white">
          <div class="flex flex-col justify-start items-start">
            <p class="text-md font-semibold">{wakilGembalaSelected["name"]}</p>
          </div>
        </div>
      </div>
    {/if}

    {#if modalData.type == "pWakil" && pWakilSelected}
      <div class="card-data min-w-full p-2 bg-primary rounded-md cursor-pointer">
        <div class="flex justify-between items-center text-xs font-medium text-white">
          <div class="flex flex-col justify-start items-start">
            <p class="text-md font-semibold">{pWakilSelected["name"]}</p>
          </div>
        </div>
      </div>
    {/if}

    {#if !loadingMember}
      <div class="hidden md:block">
        <TableAddSpv
          bind:data={allMembers}
          selected={valueSelected(modalData.type)}
          on:select={(e) => {
            if (modalData.type == "head") {
              headGembalaSelected = e.detail;
              pageAll = 1;
            }
            if (modalData.type == "gembala") {
              gembalaSelected = e.detail;
              pageAll = 1;
            }
            if (modalData.type == "pGembala") {
              pGembalaSelected = e.detail;
              pageAll = 1;
            }
            if (modalData.type == "wakil") {
              wakilGembalaSelected = e.detail;
              checkWakilConflict();
              pageAll = 1;
            }
            if (modalData.type == "pWakil") {
              pWakilSelected = e.detail;
              checkPendampingWakilConflict();
              pageAll = 1;
            }
            search = "";
            modalSpv.toggle();
          }}
        />
      </div>

      <div class="block md:hidden">
        <AddSpv
          bind:data={allMembers}
          selected={valueSelected(modalData.type)}
          on:select={(e) => {
            if (modalData.type == "head") {
              headGembalaSelected = e.detail;
              pageAll = 1;
            }
            if (modalData.type == "gembala") {
              gembalaSelected = e.detail;
              pageAll = 1;
            }
            if (modalData.type == "pGembala") {
              pGembalaSelected = e.detail;
              pageAll = 1;
            }
            if (modalData.type == "wakil") {
              wakilGembalaSelected = e.detail;
              checkWakilConflict();
              pageAll = 1;
            }
            if (modalData.type == "pWakil") {
              pWakilSelected = e.detail;
              checkPendampingWakilConflict();
              pageAll = 1;
            }
            search = "";
            loadMore();

            modalSpv.toggle();
          }}
        />
      </div>
    {:else}
      <i class="bx bx-loader-circle bx-spin text-xl text-gray-400" />
    {/if}
  </div>
  {#if !loadingMember}
    <div class="px-4 sm:flex sm:items-center sm:justify-between pb-4">
      <div />
      {#if allMembers.length > 0 || pageAll > 1}
        <ModalPagination
          bind:page={pageAll}
          bind:totalData={totalDataAll}
          bind:totalPage={totalPageAll}
          bind:perPage={perPageAll}
          on:prev={() => {
            loadingMember = true;
            pageAll -= 1;
            loadMore();
          }}
          on:next={() => {
            loadingMember = true;
            pageAll += 1;
            loadMore();
          }}
        />
      {/if}
    </div>
  {/if}
</Modal>

<Modal
  index={50}
  title="Tambah Member"
  bind:this={modalAddCellGroupMember}
  size="mobile"
  isScrollable
  closed
  on:close={() => {
    modalAddCellGroupMember.toggle();
    search = "";
    page = 1;
  }}
>
  <div class="w-full flex items-center px-4">
    <div class="relative w-full">
      <div class="relative w-full">
        <input
          type="text"
          placeholder="Cari berdasarkan nama.."
          bind:value={search}
          on:input={searchData}
          class="block w-full text-xs py-2 pr-5 text-gray-700 focus:border-gray-300 focus:ring-transparent bg-white
  border border-gray-200 rounded-lg placeholder-gray-400/70 pl-8 focus:outline-none"
        />
        <span class="absolute left-2 top-0 bottom-0 flex items-center">
          <i class="bx bx-search text-gray-500" />
        </span>
      </div>
    </div>
  </div>

  {#if !loadingMember}
    <div class="relative overflow-y-auto h-72 hidden md:block pl-4">
      <TableAddCellGroupMember
        bind:allMembers={allCellGroupMembers}
        bind:data={members}
        bind:cellGroupMemberList={cellGroupMemberListTemp}
        on:addOrRemoveMember={(e) => addOrRemoveMember(e.detail)}
        on:checkAll={(e) => addOrRemoveAllMember(e.detail)}
      />
    </div>
    <div class="relative overflow-x-auto h-auto block md:hidden px-4">
      <AddCellGroupMemberCard
        bind:allMembers={allCellGroupMembers}
        bind:data={members}
        bind:cellGroupMemberList={cellGroupMemberListTemp}
        on:addOrRemoveMember={(e) => addOrRemoveMember(e.detail)}
        on:checkAll={(e) => addOrRemoveAllMember(e.detail)}
      />
    </div>
  {:else}
    <i class="bx bx-loader-circle bx-spin text-xl px-4 text-gray-400" />
  {/if}
  <div class="px-4 sm:flex sm:items-center sm:justify-between">
    <div class="text-sm text-gray-500" />
    {#if members.length > 0 || page > 1}
      <ModalPagination
        bind:page
        {totalData}
        {totalPage}
        {perPage}
        on:prev={async () => {
          loadingMember = true;
          page -= 1;
          await loadMembers(false);
        }}
        on:next={async () => {
          loadingMember = true;
          page += 1;
          await loadMembers(false);
        }}
      />
    {/if}
  </div>

  <div class="flex justify-end space-x-4 px-4 py-2 md:p-4">
    <button
      on:click={() => {
        search = "";
        page = 1;
        cellGroupMemberListTemp = [...cellGroupMemberListBefore];
        memberDeletedTemp = [...memberDeletedBefore];
        modalAddCellGroupMember.toggle();
      }}
      class="text-white bg-gray-400 hover:opacity-80 outline-none font-medium rounded-lg text-sm px-7 py-2.5 mt-4 text-center transition-all"
      >Batal</button
    >
    <button
      on:click={() => {
        search = "";
        page = 1;
        checkMemberConflict();
        memberDeleted = [...memberDeletedTemp];
        memberDeletedBefore = [...memberDeletedTemp];
        modalAddCellGroupMember.toggle();
      }}
      class="text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-7 py-2.5 mt-4 text-center transition-all"
      >Simpan</button
    >
  </div>
</Modal>

<Loading bind:this={loading} />

<div>
  <div class="sm:flex sm:items-center sm:justify-between">
    <div class="w-full flex items-center justify-between">
      <div class="flex-1 flex items-center gap-x-3">
        <button
          on:click={() => {
            if (checkBack() && mode != "detail") {
              dispatch("back");
            } else {
              pop();
            }
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-lg font-medium text-gray-800">{formTitle}</h2>
      </div>
      {#if mode == "detail" && checkRoleModule($roleModules, EDIT_CELLGROUP_MODULE)}
        <div class="flex items-center justify-end">
          <button
            on:click={() => replace("/cellgroup/" + concernedID + "/edit")}
            class="text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 md:mt-4 text-center transition-all"
            >Edit
          </button>
        </div>
      {/if}
    </div>
  </div>

  <div class="mt-5 flex flex-col space-y-4">
    <!-- Tanggal Dibuat -->
    <div class={mobileClass}>
      <div class="flex-1">
        <DatePicker
          name="dateCreated"
          label="Tanggal Dibuat"
          labelSize="sm"
          textSize="sm"
          extClass="font-semibold"
          bind:value={dateCreated}
          disabled={mode == "detail"}
        />
      </div>
    </div>

    <!-- Nama Sel Group -->
    <div class={mobileClass}>
      <div class="flex-1">
        <Input
          name="name"
          type="text"
          label="Nama Sel Group"
          placeholder="Ketik Nama Sel Group"
          maxlength="50"
          bind:errors
          bind:value={name}
          disabled={mode == "detail"}
        />
      </div>
    </div>

    <!-- Kategori Sel -->
    <div class={mobileClass}>
      <div class="flex-1">
        <Select
          on:change={(e) => {
            covenantYearSelected = e.detail;
            covenantYearName = covenantYearOption.find((item) => item.id == e.detail).name;
            isCellCategoryAvailable = true;
            isOptionOpened = false;
            checkMemberConflict();
            checkWakilConflict();
            checkPendampingWakilConflict();
          }}
          on:blur={() => {
            if (covenantYearSelected == "" && mode == "edit") {
              isCellCategoryAvailable = false;
              isOptionOpened = false;
            }
          }}
          on:click={() => {
            isOptionOpened = !isOptionOpened;
            if (isOptionOpened && mode == "edit" && !isCellCategoryAvailable) {
              isCellCategoryAvailable = true;
            } else if (!isOptionOpened && mode == "edit" && covenantYearSelected == "") {
              isCellCategoryAvailable = false;
            }
          }}
          textHint="Pilih Kategori Sel"
          fullWidth
          name="covenantYearSelected"
          extClass="font-semibold {isCellCategoryAvailable ? '' : 'bg-red-100 border-red-500 focus:border-red-500'}"
          label="Kategori Sel"
          textSize="text-sm"
          options={covenantYearOption}
          bind:errors
          bind:selected={covenantYearSelected}
          disabled={mode == "detail"}
        />

        {#if !isCellCategoryAvailable}
          <p class="text-xs text-red-500 p-0 mt-1">Kategori Sel tidak aktif atau sudah dihapus</p>
        {/if}
      </div>
    </div>

    <!-- Head Gembala, Gembala and Pendamping Gembala -->
    <div class={mobileClass}>
      <div class="flex-1">
        <p class="block font-semibold text-gray-900 text-sm mb-1">Head Gembala</p>
        <button
          on:click={() => {
            if (mode != "detail") {
              loadingMember = true;
              loadMore();
              modalData = {
                title: "Head Gembala",
                type: "head",
              };
              modalSpv.toggle();
            }
          }}
          class="text-gray-700 border {errorMap.findIndex((e) => e['field'] == 'headGembalaSelected') != -1
            ? 'border-red-500'
            : ''} bg-white py-2 w-full text-sm rounded-lg text-left px-4 font-semibold flex justify-between items-center"
          >{!headGembalaSelected ? "Pilih Head Gembala" : headGembalaSelected["name"] ?? "-"} <i class="bx bx-chevron-right" /></button
        >
        {#if errorMap.findIndex((e) => e["field"] == "headGembalaSelected") != -1}
          <p class="text-xs text-left text-red-500 p-0 mt-1">Head Gembala harus diisi</p>
        {/if}
      </div>
      <div class="flex-1">
        <p class="block font-semibold text-gray-900 text-sm mb-1">Gembala</p>
        <button
          on:click={() => {
            if (mode != "detail") {
              loadingMember = true;
              loadMore();
              modalData = {
                title: "Gembala",
                type: "gembala",
              };
              modalSpv.toggle();
            }
          }}
          class="text-gray-700 border {errorMap.findIndex((e) => e['field'] == 'gembalaSelected') != -1
            ? 'border-red-500'
            : ''} bg-white py-2 w-full text-sm rounded-lg text-left px-4 font-semibold flex justify-between items-center"
          >{!gembalaSelected ? "Pilih Gembala" : gembalaSelected["name"]} <i class="bx bx-chevron-right" /></button
        >
        {#if errorMap.findIndex((e) => e["field"] == "gembalaSelected") != -1}
          <p class="text-xs text-left text-red-500 p-0 mt-1">Gembala harus diisi</p>
        {/if}
      </div>
      <div class="flex-1">
        <p class="block font-semibold text-gray-900 text-sm mb-1">Pendamping Gembala</p>
        <button
          on:click={() => {
            if (mode != "detail") {
              loadingMember = true;
              loadMore();
              modalData = {
                title: "Pendamping Gembala",
                type: "pGembala",
              };
              modalSpv.toggle();
            }
          }}
          class="text-gray-700 border {errorMap.findIndex((e) => e['field'] == 'gembalaCompanionID') != -1
            ? 'border-red-500'
            : ''} bg-white py-2 w-full text-sm rounded-lg text-left px-4 font-semibold flex justify-between items-center"
          >{!pGembalaSelected ? "Pilih Pendamping Gembala" : pGembalaSelected["name"]} <i class="bx bx-chevron-right" /></button
        >
        {#if errorMap.findIndex((e) => e["field"] == "gembalaCompanionID") != -1}
          <p class="text-xs text-left text-red-500 p-0 mt-1">Pendamping Gembala harus diisi</p>
        {/if}
      </div>
    </div>

    <!-- Wakil Gembala and Pendamping Wakil Gembala -->
    <div class={mobileClass}>
      <div class="flex-1">
        <p class="block font-semibold text-gray-900 text-sm mb-1">Wakil Gembala</p>
        <button
          on:click={() => {
            if (mode != "detail") {
              loadingMember = true;
              loadMore();
              modalData = {
                title: "Wakil Gembala",
                type: "wakil",
              };
              modalSpv.toggle();
            }
          }}
          class="text-gray-700 border {errorMap.findIndex((e) => e['field'] == 'wakilGembalaSelected') != -1 || wakilGembalaError
            ? 'border-red-500'
            : ''} bg-white py-2 w-full text-sm rounded-lg text-left px-4 font-semibold flex justify-between items-center"
          >{!wakilGembalaSelected ? "Pilih Wakil Gembala" : wakilGembalaSelected["name"]} <i class="bx bx-chevron-right" /></button
        >
        {#if wakilGembalaError}
          <span class="text-red-500 text-xs">Wakil Gembala sudah terdaftar di Sel Group lain dalam {covenantYearName} </span>
        {/if}
        {#if errorMap.findIndex((e) => e["field"] == "wakilGembalaSelected") != -1}
          <p class="text-xs text-left text-red-500 p-0 mt-1">Wakil Gembala harus diisi</p>
        {/if}
      </div>
      <div class="flex-1">
        <p class="block font-semibold text-gray-900 text-sm mb-1">Pendamping Wakil Gembala</p>
        <button
          on:click={() => {
            if (mode != "detail") {
              loadingMember = true;
              loadMore();
              modalData = {
                title: "Pendamping Wakil Gembala",
                type: "pWakil",
              };
              modalSpv.toggle();
            }
          }}
          class="text-gray-700 border {errorMap.findIndex((e) => e['field'] == 'wakilGembalaCompanionID') != -1 || pWakilGembalaError
            ? 'border-red-500'
            : ''} bg-white py-2 w-full text-sm rounded-lg text-left px-4 font-semibold flex justify-between items-center"
          >{!pWakilSelected ? "Pilih Pendamping Wakil Gembala" : pWakilSelected["name"]} <i class="bx bx-chevron-right" /></button
        >
        {#if pWakilGembalaError}
          <span class="text-red-500 text-xs">Pendamping Wakil Gembala sudah terdaftar di Sel Group lain dalam {covenantYearName} </span>
        {/if}
        {#if errorMap.findIndex((e) => e["field"] == "wakilGembalaCompanionID") != -1}
          <p class="text-xs text-left text-red-500 p-0 mt-1">Pendamping Wakil Gembala harus diisi</p>
        {/if}
      </div>
    </div>

    <div class="flex w-full">
      <div class="flex-1">
        <label for="status" class="block mb-2 text-sm font-semibold text-gray-900">Status</label>
        <Switch
          on:change={(e) => {
            status = e.detail.checked;
          }}
          bind:value={status}
          disabled={checkStatusDisabled()}
          checked={status}
          name="status"
          textRight={status ? "Aktif" : "Tidak Aktif"}
        />
      </div>
    </div>

    <div class="flex w-full items-center justify-between space-x-3">
      <h2 class="text-sm md:text-lg font-medium text-gray-800 w-full">Daftar Member</h2>
      {#if mode == "detail" && checkRoleModule($roleModules, MEMBER_REQUEST_CELLGROUP_MODULE && false)}
        <button
          on:click={() => replace("/cellgroup/" + concernedID + "/member/request")}
          class="flex items-center justify-center px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-lg cursor-pointer shrink-0 sm:w-auto space-x-2 hover:opacity-80"
        >
          <i class="bx bx-plus" />
          <span>Member Request</span>
        </button>
      {:else if mode == "add" || mode == "edit"}
        <button
          on:click={() => {
            loadingMember = true;
            if (covenantYearSelected == "") {
              globalThis.pushToast("Pilih Kategori Sel dahulu", "error");
              loadingMember = false;
            } else {
              loadMembers(false);
              modalAddCellGroupMember.toggle();
            }
          }}
          disabled={mode == "detail" || !headGembalaSelected || !gembalaSelected || !wakilGembalaSelected || covenantYearSelected == ""}
          class="flex items-center justify-center px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-lg cursor-pointer shrink-0 sm:w-auto space-x-2 hover:opacity-80 disabled:bg-gray-500"
        >
          <i class="bx bx-plus" />
          <span>Member</span>
        </button>
      {/if}
    </div>

    <!-- Member Table Dekstop -->
    <div class="overflow-x-auto hidden md:block">
      <div class="inline-block min-w-full py-2 align-middle">
        <div class="overflow-hidden rounded-md">
          <TableCellGroupMember
            bind:data={cellGroupMemberList}
            bind:covenantYearName
            bind:mode
            on:delete={(e) => {
              addOrRemoveMember(e.detail, 1);
              cellGroupMemberList = [...cellGroupMemberListTemp];
            }}
          />
        </div>
      </div>
    </div>

    <!-- Member Table Mobile -->
    <div class="overflow-x-auto block md:hidden">
      {#each cellGroupMemberList as item}
        <CellGroupMemberCard
          bind:data={item}
          bind:covenantYearName
          bind:mode
          on:delete={(e) => {
            addOrRemoveMember(e.detail, 1);
            cellGroupMemberList = [...cellGroupMemberListTemp];
          }}
        />
      {/each}
    </div>

    <!-- Button Cancel dan Simpan -->
    {#if mode != "detail"}
      <div class="flex w-full space-x-4">
        <button
          on:click={() => {
            if (checkBack() && mode != "detail") {
              dispatch("back");
              errorMap = [];
            } else {
              pop();
              errorMap = [];
            }
          }}
          class="w-full text-white bg-gray-400 hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
          >Batal</button
        >
        <button
          on:click={async () => {
            errorMap = [];
            await checkMemberConflict();
            dispatch("save");
          }}
          disabled={disableSubmit || !isCellCategoryAvailable}
          class="w-full text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
          >Simpan</button
        >
      </div>
    {/if}
  </div>
</div>
