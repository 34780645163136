<script>
  import { field, form } from "svelte-forms";
  import { required } from "svelte-forms/validators";
  import { replace } from "svelte-spa-router";
  import AttendanceCategoryForm from "./AttendanceCategoryForm.svelte";
  import AttendanceCategoryService from "./AttendanceCategoryServices";
  import { onMount } from "svelte";
  import Modal from "../../components/Modal.svelte";
  import Loading from "../../components/Loading.svelte";

  export let params;
  let name = field("name", "", [required()]);
  let note = field("note", "", []);

  let attendanceCategoryForm;
  let disableSubmit = false;
  let disableDeleteButton = false;
  let errors = [];
  let status = true;
  let modalBack;
  let modalDeleteValidation;
  let modalDeleteConfirmation;
  let actionName;
  let loading;
  const id = params["_id"];

  onMount(() => {
    loadData();
  });

  async function loadData() {
    loading.toggle();
    errors = [];
    const data = await AttendanceCategoryService.getAttendanceCategoryByID(id);
    if (data["data"]["id"]) {
      $name.value = data["data"]["name"];
      $note.value = data["data"]["note"];
      status = data["data"]["isActive"];
    }
    loading.toggle();
  }
  async function validateDeleteorDeactiveCondition(from) {
    if (from == "delete") disableDeleteButton = true;
    loading.toggle();
    const isValidated = await AttendanceCategoryService.validateDelete(id);
    if (!isValidated) {
      modalDeleteValidation.toggle();
      if (from == "deactivate") {
        status = true;
        actionName = "menonaktifkan";
      } else actionName = "menghapus";
    } else {
      if (from == "delete") modalDeleteConfirmation.toggle();
      if (from == "deactivate") status = false;
    }
    if (from == "delete") disableDeleteButton = false;
    loading.toggle();
  }

  async function editAttendanceCategory(from) {
    disableSubmit = true;
    loading.toggle();
    errors = [];

    attendanceCategoryForm = form(name);
    await attendanceCategoryForm.validate();
    if ($attendanceCategoryForm.valid) {
      const reqData = {
        ID: id,
        Name: $name.value,
        IsActive: status ? 1 : 0,
        Note: $note.value,
        IsDelete: from == "delete" ? 1 : 0,
      };

      const data = await AttendanceCategoryService.editAttendanceCategory(reqData);
      if (data["data"]["id"]) {
        const id = data["data"]["id"];
        globalThis.pushToast(`Berhasil ${from == "delete" ? "Menghapus" : "Mengubah"} Kategori Attendance`, "success");
        if (from == "delete") setTimeout(() => replace("/attendancecategory/"), 1000);
        else setTimeout(() => replace("/attendancecategory/" + id + "/detail"), 1000);
      } else if (data["data"]["errorMessage"] == "Duplicate Name") {
        globalThis.pushToast("Nama Kategori Attendance sudah digunakan", "error");
        disableSubmit = false;
      }
    } else {
      errors = $attendanceCategoryForm.errors;
      errors = errors;
      disableSubmit = false;
    }
    loading.toggle();
  }
</script>

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Apakah Anda yakin akan membatalkan perubahan? Perubahan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/attendancecategory/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>
<Modal index={50} title="Konfirmasi" bind:this={modalDeleteValidation} size="default">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">
      Tidak dapat {actionName} Kategori Attendance yang masih tercatat dalam Attendance yang berstatus <span class="font-bold">"Aktif"</span>. Ubah
      Kategori Attendance <span class="font-bold">"{$name.value}"</span> terlebih dahulu pada Attendance yang masih dalam status
      <span class="font-bold">"Aktif"</span>. sebelum {actionName} Kategori Attendance <span class="font-bold">"{$name.value}"</span>
    </p>
    <div class="flex space-x-2 justify-end items-center mt-4">
      <button
        on:click={() => modalDeleteValidation.toggle()}
        class="px-3 py-2 text-white bg-primary text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Kembali
      </button>
    </div>
  </div>
</Modal>
<Modal index={50} title="Konfirmasi" bind:this={modalDeleteConfirmation} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">
      Apakah Anda yakin akan menghapus Kategori Attendance <span class="font-bold">"{$name.value}"</span>. Kategori yang sudah dihapus tidak dapat
      diaktifkan kembali
    </p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalDeleteConfirmation.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          editAttendanceCategory("delete");
          modalDeleteConfirmation.toggle();
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>

<Loading bind:this={loading} />

<section class="container px-4 py-4">
  <AttendanceCategoryForm
    formTitle="Edit Kategori Attendance"
    mode="edit"
    concernedID={id}
    bind:disableSubmit
    bind:disableDeleteButton
    bind:errors
    bind:name={$name.value}
    bind:note={$note.value}
    bind:status
    on:back={() => modalBack.toggle()}
    on:deactivate={() => validateDeleteorDeactiveCondition("deactivate")}
    on:delete={() => validateDeleteorDeactiveCondition("delete")}
    on:save={() => editAttendanceCategory("edit")}
  />
</section>
