<script>
  import { createEventDispatcher } from "svelte";
  import { checkRoleModule, parseJwt } from "../../../utils/utils";
  import { roleModules } from "../../../stores/store";
  import {
    DETAIL_CELLGROUP_MODULE,
    EDIT_CELLGROUP_MODULE,
    MEMBER_REQUEST_ACTION_CELLGROUP_MODULE,
    MEMBER_REMOVAL_CELLGROUP_MODULE,
    EXPORT_CELLGROUP,
  } from "../../../utils/const";

  const dispatch = createEventDispatcher();
  const token = parseJwt(localStorage.getItem("token"));
  const access = token.RoleID;

  export let data;
  export let index;
  export let page = 1;
</script>

<div class="card-data min-w-full mb-3 p-2 border border-primary shadow rounded-md">
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p>No.</p>
    <p>
      {page > 1 ? ((index + 1).toString().length > 1 ? index + 1 + 10 * (page - 1) : (page - 1).toString() + (index + 1)) : index + 1}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Nama Sel Group</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["name"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Kategori Sel</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["covenantName"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between items-center text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Status</p>
    <p
      class="inline px-3 py-1 text-xs font-normal rounded-full {data['isActive'] == 1
        ? 'text-emerald-500 bg-emerald-100/60'
        : 'text-red-500 bg-red-100/60'}  gap-x-2"
    >
      {data["isActive"] == 1 ? "Aktif" : "Tidak Aktif"}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between items-center text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Aksi</p>
    <div class="flex-1 flex space-x-1 justify-end">
      {#if checkRoleModule($roleModules,DETAIL_CELLGROUP_MODULE)|| data["isShowDetail"]}
        <button
          on:click={() => dispatch("detail", data["id"])}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-show text-md" />
        </button>
      {/if}
      {#if checkRoleModule($roleModules,EDIT_CELLGROUP_MODULE)}
        <button
          on:click={() => dispatch("edit", data["id"])}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-pencil text-md" />
        </button>
      {/if}
      {#if checkRoleModule($roleModules, EXPORT_CELLGROUP)}
        <button
          on:click={() => dispatch("export", data["id"])}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-export text-md" />
        </button>
      {/if}
      {#if checkRoleModule($roleModules, MEMBER_REMOVAL_CELLGROUP_MODULE) && false}
        <button
          on:click={() => dispatch("member", data["id"])}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-detail text-md" />
        </button>
      {/if}
      {#if checkRoleModule($roleModules,MEMBER_REQUEST_ACTION_CELLGROUP_MODULE) && false}
        <button
          on:click={() => dispatch("request", data)}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
          disabled={access == 5}
        >
          <i class="bx bx-right-arrow-circle text-md" />
        </button>
      {/if}
    </div>
  </div>
</div>
