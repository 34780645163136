<script>
  import { pop, replace } from "svelte-spa-router";
  import Loading from "../../components/Loading.svelte";
  import Modal from "../../components/Modal.svelte";
  import { field } from "svelte-forms";
  import { required } from "svelte-forms/validators";
  import { checkRoleModule, getTypeLearning } from "../../utils/utils";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import Input from "../../components/control/Input.svelte";
  import { onMount } from "svelte";
  import { EDIT_LEARNING_MODULE, urlApi } from "../../utils/const";
  import Textarea from "../../components/control/Textarea.svelte";
  import { roleModules } from "../../stores/store";

  export let params;
  export let errors;

  let id = params["_id"];
  let type = "";
  let data = {};
  let windowReference;
  let createdDate = field("createdDate", new Date(), [required()]);
  let publishDate = field("publishDate", new Date(), [required()]);
  let overdueDate = field("overdueDate", new Date(), [required()]);
  let title = field("title", "", [required()]);
  let content = field("content", "", [required()]);
  let cellGroupSelected = [];
  let isCellCategoryAvailable;

  let loadingPage;
  let modalAccessMateri;
  let modalBack;
  let uploadedFile = [];
  let quizQuestion = [];

  onMount(() => {
    loadData();
  });

  async function loadData() {
    const res = await fetch(urlApi + "/Learning/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const resData = await res.json();
    data = resData["data"];
    $createdDate.value = new Date(data.createdDate);
    $publishDate.value = new Date(data.publishDate);
    $overdueDate.value = new Date(data.overdueDate);
    cellGroupSelected.push({
      ID: String(data.id),
      Year: data.covenantYearName,
      CovenantYearID: data.covenantYearID,
      Name: data.cellGroupName,
    });
    isCellCategoryAvailable = data.covenantYearIsActive == 1 && data.covenantYearIsDelete == 0;
    cellGroupSelected = cellGroupSelected;
    $title.value = data.title;
    type = getTypeLearning(data.type);

    $content.value = type == "Text" ? JSON.parse(data.module[0].content) : type == "Video" ? data.module[0].content : "";
    if (type == "PDF" || type == "PPT") {
      uploadedFile = data.module.map((e) => ({ nameToDisplay: e.content.split("/").slice(2).join("/"), name: e.content }));
    }

    uploadedFile = data.module.map((e) => ({ nameToDisplay: e.content.split("/").slice(2).join("/"), name: e.content }));
    quizQuestion = data.question.map((e) => ({
      question: e.question,
      items: e.items,
      type: e.type.toString(),
    }));
  }

  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";

  async function previewFile(file) {
    const res = await fetch(urlApi + "/Learning/Download?fileName=" + file, {
      method: "GET",
      headers: {
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const resData = await res.json();
    windowReference.location = resData.data;
  }
</script>

<Loading bind:this={loadingPage} />

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin ingin keluar dari laman ini? data yang Anda masukkan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/learning/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>

<section class="container p-4">
  <div class="sm:flex sm:items-center sm:justify-between">
    <div>
      <div class="flex items-center gap-x-3">
        <button
          on:click={() => {
            pop();
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-lg font-medium text-gray-800">Rincian Materi</h2>
      </div>
    </div>
    {#if checkRoleModule($roleModules, EDIT_LEARNING_MODULE) && data["status"] == 2}
      <div class="flex items-center justify-end">
        <button
          on:click={() => replace("/learning/" + id + "/edit")}
          class="text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 md:smt-4 text-center transition-all"
          >Edit
        </button>
      </div>
    {/if}
  </div>

  <div class="mt-5 flex flex-col space-y-4">
    <!-- Tanggal Dibuat -->
    <div class={mobileClass}>
      <div class="flex-1">
        <DatePicker
          name="createdDate"
          type="text"
          label="Tanggal Dibuat"
          labelSize="sm"
          textSize="sm"
          extClass="font-semibold"
          disabled
          bind:errors
          bind:value={$createdDate.value}
        />
      </div>
    </div>
    <!-- Tanggal Publish dan Tanggal Deadline -->
    <div class={mobileClass}>
      <div class="flex-1">
        <DatePicker
          maxDateToday={false}
          name="publishDate"
          type="text"
          label="Tanggal Publish"
          labelSize="sm"
          textSize="sm"
          extClass="font-semibold"
          disabled
          bind:errors
          bind:value={$publishDate.value}
        />
      </div>
      <div class="flex-1">
        <DatePicker
          maxDateToday={false}
          name="overdueDate"
          type="text"
          label="Tanggal Batas Submission"
          labelSize="sm"
          textSize="sm"
          extClass="font-semibold"
          disabled
          bind:errors
          bind:value={$overdueDate.value}
        />
      </div>
    </div>

    <!-- ACCESS -->
    <div>
      <p class="font-semibold text-sm mb-2">Akses Materi</p>

      <div class={mobileClass}>
        <div class="flex-1">
          <table class="w-full divide-y divide-gray-200">
            <thead class="bg-gray-100">
              <tr>
                <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> No. </th>
                <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Kategori Sel </th>
                <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Sel Group </th>
                <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Keterangan </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              {#if cellGroupSelected.length == 0}
                <tr>
                  <td colspan="4" class="px-4 py-4 text-sm font-medium">
                    <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
                  </td>
                </tr>
              {/if}
              {#each cellGroupSelected as item, index}
                <tr>
                  <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
                    <h2 class="font-medium text-gray-800 text-xs">
                      {index + 1}
                    </h2>
                  </td>
                  <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
                    <h2 class="font-medium text-gray-800 text-xs">
                      {item["Year"]}
                    </h2>
                  </td>
                  <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
                    <h2 class="font-medium text-gray-800 text-xs">
                      {item["Name"]}
                    </h2>
                  </td>
                  <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
                    <h2 class="font-medium {isCellCategoryAvailable ? 'text-gray-800-500' : 'text-red-500'} text-xs">
                      {isCellCategoryAvailable ? "-" : "Kategori Sel tidak aktif atau sudah dihapus"}
                    </h2>
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- MATERI -->
    <div class="flex flex-col space-y-2">
      <p class="font-semibold text-sm">Materi</p>
      <div class={mobileClass}>
        <div class="flex-1">
          <Input disabled name="title" type="text" label="Judul" placeholder="Ketikkan judul materi" bind:errors bind:value={$title.value} />
        </div>
      </div>

      <!-- FILE -->
      <div class={mobileClass}>
        {#if type == "Video"}
          <div class="flex-1">
            <Input
              disabled
              name="content"
              type="text"
              label="Link Konten"
              placeholder="Masukkan link konten"
              bind:errors
              bind:value={$content.value}
            />
          </div>
        {:else if type == "Text"}
          <div class="flex-1">
            <Textarea
              disableQuil={true}
              disabled
              name="content"
              bind:content={$content.value}
              label="Konten"
              inputEditor={true}
              placeholder="Masukkan konten..."
              maxlength={3000}
            />
          </div>
        {/if}
      </div>

      {#if type == "PDF" || type == "PPT"}
        <div class={mobileClass}>
          <div class="flex-1">
            <table class="w-full divide-y divide-gray-200">
              <thead class="bg-gray-100">
                <tr>
                  <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Nama File </th>
                  <th scope="col" class="px-2 py-2 text-sm font-normal text-left text-gray-500">Aksi</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                {#if uploadedFile.length == 0}
                  <tr>
                    <td colspan="2" class="px-4 py-4 text-sm font-medium">
                      <h2 class="font-medium text-gray-500 text-xs">Belum ada file</h2>
                    </td>
                  </tr>
                {/if}
                {#each uploadedFile as item, index}
                  <tr>
                    <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
                      <h2 class="font-medium text-gray-800 text-xs">
                        {item["nameToDisplay"]}
                      </h2>
                    </td>
                    <td class="px-2 py-2 text-sm font-medium whitespace-nowrap">
                      <button
                        on:click={() => {
                          windowReference = window.open();
                          previewFile(item["name"]);
                        }}
                        class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
                      >
                        <i class="bx bx-show text-xl text-blue-500" />
                      </button>
                    </td>
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        </div>
      {/if}
    </div>

    <!-- QUIZ -->
    <div>
      <p class="font-semibold text-sm mb-1">Quiz</p>

      <div class="mt-2 flex flex-col space-y-4">
        {#each quizQuestion as question, index}
          <div class="flex items-start space-x-2">
            <div class="rounded-full bg-gray-200 mt-3 flex px-3 py-2 items-center justify-center">
              <span class="text-gray-700 font-medium text-xs">{index + 1}</span>
            </div>
            <div class="flex-1 flex flex-col space-y-2">
              {#if question.type == "1" || question.type == "2"}
                <Textarea disabled bind:value={question.question} name="{index}-question" placeholder="Masukkan pertanyaan disini" noLabel />
              {/if}
              {#each question.items as item, indexItem}
                {#if question.type == "0"}
                  <Textarea disabled bind:value={item.value} name="{index}-question" placeholder="Masukkan pertanyaan disini" noLabel />
                {/if}

                {#if question.type == "1"}
                  <div class="flex items-center space-x-2">
                    <input disabled type="radio" class="cursor-pointer" name="{item.learnQuestionID}-question" checked={item.answer == "1"} />
                    <div class="flex-1">
                      <Input disabled placeholder="Masukkan Pilihan Ganda" name="{indexItem}-question" noLabel type="text" bind:value={item.value} />
                    </div>
                  </div>
                {/if}

                {#if question.type == "2"}
                  <div class="flex items-center space-x-2">
                    <input disabled type="checkbox" class="cursor-pointer" name="{question.learnQuestionID}-question" checked={item.answer == "1"} />
                    <div class="flex-1">
                      <Input disabled placeholder="Masukkan Pilihan" name="{indexItem}-question" noLabel type="text" bind:value={item.value} />
                    </div>
                  </div>
                {/if}
              {/each}
            </div>
          </div>
        {/each}
      </div>
    </div>

    <div class="mt-4" />
  </div>
</section>
