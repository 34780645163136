<script>
  import { push } from "svelte-spa-router";
  import { onMount } from "svelte";
  import Loading from "../../components/Loading.svelte";
  import Pagination from "../../components/pagination/Pagination.svelte";
  import { perPage } from "../../utils/const";
  import TableAttendanceCategory from "../../components/table/TableAttendanceCategory.svelte";
  import AttendanceCategoryCard from "../../components/card/AttendanceCategoryCard.svelte";
  import { ADD_ATTENDANCE_CATEGORY_MODULE } from "../../utils/const";
  import { checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import AttendanceCategoryServices from "./AttendanceCategoryServices";
  import Select from "../../components/control/Select.svelte";

  let dataList = [];
  let loading;
  let page = 1;
  let search = "";
  let totalData = 0,
    totalPage = 0;
  let statusSelected = "0";
  let statusOption = [
    { id: "0", name: "Semua Status" },
    { id: "1", name: "Aktif" },
    { id: "2", name: "Tidak Aktif" },
  ];
  onMount(() => {
    loadData();
  });

  async function loadData() {
    loading.toggle();
    const status = statusSelected == "0" ? "" : statusSelected == "2" ? 0 : 1;
    const option = "perPage=" + perPage + "&currentPage=" + page + "&isActive=" + status + "&searchQuery=" + search + "&isDelete=0";
    const data = await AttendanceCategoryServices.loadAttendanceCategory(option);
    let result = data["data"];
    // Define the id of the specific objects you want to be at the front
    const specificID = [1, 2];

    // Separate the specific objects from the rest
    const specificObjects = result.filter((obj) => specificID.includes(obj.id));

    // Remove the specific objects from the original array
    result = result.filter((obj) => !specificID.includes(obj.id));

    // Combine the specific objects and the remaining objects
    dataList = specificObjects.concat(result);
    totalData = data["info"]["totalItem"];
    totalPage = data["info"]["totalPage"];
    loading.toggle();
  }

  function searchData() {
    page = 1;
    loadData();
  }
</script>

<Loading bind:this={loading} />

<div class="p-0 md:p-4">
  <!-- component -->
  <section class="container mx-auto px-4 md:px-0">
    <div class="sm:flex sm:items-center sm:justify-between">
      <div>
        <div class="flex items-center gap-x-3">
          <h2 class="text-lg font-medium text-gray-800">Daftar Kategori Attendance</h2>
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-end justify-between flex-col space-y-3 md:flex-row md:space-y-0">
      {#if checkRoleModule($roleModules, ADD_ATTENDANCE_CATEGORY_MODULE)}
        <div class="flex w-full md:w-auto relative mr-2">
          <button
            on:click={() => push("/attendancecategory/add/")}
            class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-lg
              shrink-0 sm:w-auto space-x-2 hover:opacity-80"
          >
            <i class="bx bx-plus" />
            <span>Tambah Kategori Attendance</span>
          </button>
        </div>
      {/if}

      <div class="flex-1 flex flex-col space-y-3 w-full md:space-y-0 md:flex-row md:space-x-2">
        <div class="flex-1 flex items-center">
          <div class="relative w-full">
            <label class="block text-gray-900 text-xs font-semibold mb-1" for="search"> Cari </label>
            <div class="relative w-full">
              <input
                type="text"
                placeholder="Cari Kategori Attendance"
                bind:value={search}
                on:input={searchData}
                class="block w-full text-xs py-2 pr-5 text-gray-700 focus:border-gray-300 focus:ring-transparent bg-white
                border border-gray-200 rounded-lg placeholder-gray-400/70 pl-8 focus:outline-none"
              />
              <span class="absolute left-2 top-0 bottom-0 flex items-center">
                <i class="bx bx-search text-gray-500" />
              </span>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <Select
            on:change={(e) => {
              statusSelected = e.detail;
              searchData();
            }}
            textHint="Pilih Status"
            label="Status"
            fullWidth
            name="status_search"
            options={statusOption}
            bind:selected={statusSelected}
          />
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-4">
      <div class="overflow-x-auto hidden md:block">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="overflow-hidden rounded-md">
            <TableAttendanceCategory
              bind:data={dataList}
              bind:page
              on:detail={(e) => push("/attendancecategory/" + e.detail["id"] + "/detail")}
              on:edit={(e) => push("/attendancecategory/" + e.detail["id"] + "/edit")}
            />
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="overflow-x-auto block md:hidden">
        {#if dataList.length == 0}
          <p class="font-medium text-gray-500 text-xs text-center mt-2">Belum ada data</p>
        {/if}
        {#each dataList as item, index}
          <AttendanceCategoryCard
            bind:data={item}
            {index}
            bind:page
            on:detail={() => push("/attendancecategory/" + item["id"] + "/detail")}
            on:edit={() => push("/attendancecategory/" + item["id"] + "/edit")}
          />
        {/each}
      </div>
    </div>

    <div class="mt-6 sm:flex sm:items-center sm:justify-between">
      <div class="text-sm text-gray-500" />
      {#if dataList.length > 0 || page > 1}
        <Pagination
          bind:page
          {totalData}
          {totalPage}
          {perPage}
          on:prev={() => {
            page -= 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          on:next={() => {
            page += 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
      {/if}
    </div>
  </section>
</div>
