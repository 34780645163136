import { urlApi } from "../../utils/const";
import { formatDateForView, formatDate, formatRupiah, parseJwt } from "../../utils/utils";
import { utils, writeFileXLSX } from "xlsx";
import { handleJSONtoSheet } from "../../utils/Export/exportExcelUtils";
import { differenceInWeeks } from "date-fns";
const columnWidth = [
  { wch: 40 },
  { wch: 10 }, //Doa (%)
  { wch: 15 },
  { wch: 20 }, //Pembacaan Alkitab (%)
  { wch: 30 },
  { wch: 20 }, // Misa Harian (%)
  { wch: 30 },
  { wch: 20 }, // Persembahan Kasih (%)
  { wch: 30 },
  { wch: 20 }, // Sakramen Tobat (%)
  { wch: 30 },
];

function resolveDoaPercentage(prayTime, diffInDays) {
  const result = ((prayTime / (60 * diffInDays)) * 100).toFixed(2); // 1 hari minimal 60 menit
  return result > 100 ? "100%" : result + "%";
}
function resolvePembacaanAlkitabPercentage(bibleStudyCount, diffInDays) {
  const result = ((bibleStudyCount / diffInDays) * 100).toFixed(2); // 1 hari minimal 1 entry
  return result > 100 ? "100%" : result + "%";
}
function resolveMisaHarianPercentage(misaHarianCount, week) {
  const result = ((misaHarianCount / week) * 100).toFixed(2); //
  return result > 100 ? "100%" : result + "%";
}
function resolveExistOrNotPercentage(itemCount) {
  return itemCount > 0 ? "100%" : "0%"; // jika ada entry dalam date range maka 100%
}
function countSundaysBetweenDates(startDate, endDate) {
  let count = 0;
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    if (currentDate.getDay() === 0) {
      // 0 corresponds to Sunday in JavaScript
      count++;
    }
    // Increment the current date by one day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return count;
}
export default {
  async getJournalReporting(fromDate, toDate) {
    try {
      const option = "fromDate=" + formatDate(fromDate) + "&toDate=" + formatDate(toDate);
      const res = await fetch(urlApi + "/Reporting/Journal?" + option, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
      });
      const data = await res.json();
      return data["data"];
    } catch (err) {
      return [];
    }
  },

  async exportJournalReporting(fromDate, toDate, diffInDays) {
    const data = await this.getJournalReporting(fromDate, toDate);
    const fromDateForView = formatDateForView(fromDate);
    const toDateForView = formatDateForView(toDate);
    const week = differenceInWeeks(toDate, fromDate, { roundingMethod: "ceil" }) || 1;
    let jsonPercentage = data.journalPercentageReporting.map((item) => ({
      "Nama Member": item.name,
      "Sel Group": item.cellgroups,
      "Doa (%)": resolveDoaPercentage(item.prayTime, diffInDays),
      "Jumlah Menit Doa": String(item.prayTime),
      "Pembacaan Alkitab (%)": resolvePembacaanAlkitabPercentage(item.bibleStudyCount, diffInDays),
      "Jumlah Entry Pembacaan Alkitab": String(item.bibleStudyCount),
      "Misa Harian (%)": resolveMisaHarianPercentage(item.misaHarianCountForPercentage, week),
      "Jumlah Entry Misa Harian": String(item.misaHarianCount),
      "Persembahan Kasih (%)": resolveExistOrNotPercentage(item.persembahanKasihCount),
      "Jumlah Entry Persembahan Kasih": String(item.persembahanKasihCount),
      "Sakramen Tobat (%)": resolveExistOrNotPercentage(item.sakramenTobatCount),
      "Jumlah Entry Sakramen Tobat": String(item.sakramenTobatCount),
    }));

    // PERSENTASE REGION
    let worksheetJournalPercentage = utils.json_to_sheet([{}]);
    utils.sheet_add_json(worksheetJournalPercentage, [{ A: "Tanggal" }], { skipHeader: true, origin: "A1" });
    utils.sheet_add_json(worksheetJournalPercentage, [{ A: `${fromDateForView} - ${toDateForView}` }], {
      skipHeader: true,
      origin: "A2",
    });
    utils.sheet_add_json(worksheetJournalPercentage, [{ A: "PERSENTASE COVENANT JOURNAL" }], { skipHeader: true, origin: "A4" });
    utils.sheet_add_json(worksheetJournalPercentage, jsonPercentage, { origin: "A5" });
    worksheetJournalPercentage["!cols"] = columnWidth; // set column width for each column

    // DOA REGION
    const journalDoa = data.journalTypeReporting.filter(function (el) {
      return el.type == "Doa";
    });
    let jsonDoa = journalDoa.map((item) => ({
      Tanggal: formatDateForView(new Date(item.date)),
      "Nama Member": item.name,
      "Tipe Journal": item.type,
      "Waktu (menit)": String(item.prayTime),
    }));
    const doaWidth = [{ wch: 20 }, { wch: 35 }, { wch: 20 }, { wch: 20 }]; // set column width for each column
    const worksheetDoa = handleJSONtoSheet(jsonDoa, utils, fromDateForView, toDateForView, doaWidth, [], "Covenant Journal");
    if (journalDoa.length <= 0) {
      utils.sheet_add_json(worksheetDoa, [{ A: "Tanggal Journal " }], { skipHeader: true, origin: "A5" });
      utils.sheet_add_json(worksheetDoa, [{ A: "Nama Member " }], { skipHeader: true, origin: "B5" });
      utils.sheet_add_json(worksheetDoa, [{ A: "Tipe Journal " }], { skipHeader: true, origin: "C5" });
      utils.sheet_add_json(worksheetDoa, [{ A: "Waktu (menit)" }], { skipHeader: true, origin: "D5" });
    }

    // PEMBACAAN ALKITAB REGION
    const journalPembacaanAlkitab = data.journalTypeReporting.filter(function (el) {
      return el.type == "Pembacaan Alkitab";
    });
    let jsonPembacaanAlkitab = journalPembacaanAlkitab.map((item) => ({
      Tanggal: formatDateForView(new Date(item.date)),
      "Nama Member": item.name,
      "Tipe Journal": item.type,
      Bacaan: item.devotional,
    }));
    const pembacaanAlkitabWidth = [{ wch: 20 }, { wch: 35 }, { wch: 20 }, { wch: 20 }]; // set column width for each column
    const worksheetPembacaanAlkitab = handleJSONtoSheet(
      jsonPembacaanAlkitab,
      utils,
      fromDateForView,
      toDateForView,
      pembacaanAlkitabWidth,
      [],
      "Covenant Journal",
    );
    if (journalPembacaanAlkitab.length <= 0) {
      utils.sheet_add_json(worksheetPembacaanAlkitab, [{ A: "Tanggal Journal " }], { skipHeader: true, origin: "A5" });
      utils.sheet_add_json(worksheetPembacaanAlkitab, [{ A: "Nama Member " }], { skipHeader: true, origin: "B5" });
      utils.sheet_add_json(worksheetPembacaanAlkitab, [{ A: "Tipe Journal " }], { skipHeader: true, origin: "C5" });
      utils.sheet_add_json(worksheetPembacaanAlkitab, [{ A: "Bacaan" }], { skipHeader: true, origin: "D5" });
    }

    // PERSEMBAHAN KASIH REGION
    const journalPersembahanKasih = data.journalTypeReporting.filter(function (el) {
      return el.type == "Persembahan Kasih";
    });
    let jsonPersembahanKasih = journalPersembahanKasih.map((item) => ({
      Tanggal: formatDateForView(new Date(item.date)),
      "Nama Member": item.name,
      "Tipe Journal": item.type,
      "Tipe Pembayaran": item.paymentType,
      "Total Persembahan": formatRupiah(parseInt(item.total), "Rp "),
    }));
    const persembahanKasihWidth = [{ wch: 20 }, { wch: 35 }, { wch: 20 }, { wch: 20 }, { wch: 30 }]; // set column width for each column
    const worksheetPersembahanKasih = handleJSONtoSheet(
      jsonPersembahanKasih,
      utils,
      fromDateForView,
      toDateForView,
      persembahanKasihWidth,
      [],
      "Covenant Journal",
    );
    if (journalPersembahanKasih.length <= 0) {
      utils.sheet_add_json(worksheetPersembahanKasih, [{ A: "Tanggal Journal " }], { skipHeader: true, origin: "A5" });
      utils.sheet_add_json(worksheetPersembahanKasih, [{ A: "Nama Member " }], { skipHeader: true, origin: "B5" });
      utils.sheet_add_json(worksheetPersembahanKasih, [{ A: "Tipe Journal " }], { skipHeader: true, origin: "C5" });
      utils.sheet_add_json(worksheetPersembahanKasih, [{ A: "Tipe Pembayaran " }], { skipHeader: true, origin: "D5" });
      utils.sheet_add_json(worksheetPersembahanKasih, [{ A: "Total Persembahan" }], { skipHeader: true, origin: "E5" });
    }

    // SAKRAMEN TOBAT REGION
    const journalSakramenTobat = data.journalTypeReporting.filter(function (el) {
      return el.type == "Sakramen Tobat";
    });
    let jsonSakramenTobat = journalSakramenTobat.map((item) => ({
      Tanggal: formatDateForView(new Date(item.date)),
      "Nama Member": item.name,
      "Tipe Journal": item.type,
      "Tempat/Gereja": item.place,
    }));
    const sakramenTobatWidth = [{ wch: 20 }, { wch: 35 }, { wch: 20 }, { wch: 30 }]; // set column width for each column
    const worksheetSakramenTobat = handleJSONtoSheet(
      jsonSakramenTobat,
      utils,
      fromDateForView,
      toDateForView,
      sakramenTobatWidth,
      [],
      "Covenant Journal",
    );
    if (journalSakramenTobat.length <= 0) {
      utils.sheet_add_json(worksheetSakramenTobat, [{ A: "Tanggal Journal " }], { skipHeader: true, origin: "A5" });
      utils.sheet_add_json(worksheetSakramenTobat, [{ A: "Nama Member " }], { skipHeader: true, origin: "B5" });
      utils.sheet_add_json(worksheetSakramenTobat, [{ A: "Tipe Journal " }], { skipHeader: true, origin: "C5" });
      utils.sheet_add_json(worksheetSakramenTobat, [{ A: "Tempat/Gereja" }], { skipHeader: true, origin: "D5" });
    }

    // MISA HARIAN REGION
    const journalMisaHarian = data.journalTypeReporting.filter(function (el) {
      return el.type == "Misa Harian";
    });
    let jsonMisaHarian = journalMisaHarian.map((item) => ({
      Tanggal: formatDateForView(new Date(item.date)),
      "Nama Member": item.name,
      "Tipe Journal": item.type,
      "Tempat/Gereja": item.place,
    }));
    const misaHarianWidth = [{ wch: 20 }, { wch: 35 }, { wch: 20 }, { wch: 30 }]; // set column width for each column
    const worksheetMisaHarian = handleJSONtoSheet(jsonMisaHarian, utils, fromDateForView, toDateForView, misaHarianWidth, [], "Covenant Journal");
    if (journalMisaHarian.length <= 0) {
      utils.sheet_add_json(worksheetMisaHarian, [{ A: "Tanggal Journal " }], { skipHeader: true, origin: "A5" });
      utils.sheet_add_json(worksheetMisaHarian, [{ A: "Nama Member " }], { skipHeader: true, origin: "B5" });
      utils.sheet_add_json(worksheetMisaHarian, [{ A: "Tipe Journal " }], { skipHeader: true, origin: "C5" });
      utils.sheet_add_json(worksheetMisaHarian, [{ A: "Tempat/Gereja" }], { skipHeader: true, origin: "D5" });
    }

    const new_workbook = utils.book_new(); // make new workbook
    utils.book_append_sheet(new_workbook, worksheetJournalPercentage, "COVENANT JOURNAL PERSENTASE"); // add COVENANT JOURNAL PERSENTASE tab
    utils.book_append_sheet(new_workbook, worksheetDoa, "DOA"); // add DOA tab
    utils.book_append_sheet(new_workbook, worksheetPembacaanAlkitab, "PEMBACAAN ALKITAB"); // add PEMBACAAN ALKITAB tab
    utils.book_append_sheet(new_workbook, worksheetPersembahanKasih, "PERSEMBAHAN KASIH"); // add PERSEMBAHAN KASIH tab
    utils.book_append_sheet(new_workbook, worksheetSakramenTobat, "SAKRAMEN TOBAT"); // add SAKRAMEN TOBAT tab
    utils.book_append_sheet(new_workbook, worksheetMisaHarian, "MISA HARIAN"); // add MISA HARIAN tab

    const token = parseJwt(localStorage.getItem("token"));
    const fileName = token.RoleID == 5 ? "Super Admin - Covenant Journal.xlsx" : "Head Gembala - Covenant Journal.xlsx";
    writeFileXLSX(new_workbook, fileName); // trigger download
  },
};
