export function handleJSONtoSheet(mappedJson, utils, fromDate, toDate, columnWidth, rowHeight, A4Content) {
  let worksheet = utils.json_to_sheet([{}]);
  utils.sheet_add_json(worksheet, [{ A: "Tanggal" }], { skipHeader: true, origin: "A1" });
  utils.sheet_add_json(worksheet, [{ A: `${fromDate} - ${toDate}` }], {
    skipHeader: true,
    origin: "A2",
  });
  utils.sheet_add_json(worksheet, [{ A: A4Content }], { skipHeader: true, origin: "A4" });
  utils.sheet_add_json(worksheet, mappedJson, { origin: "A5" });

  worksheet["!rows"] = rowHeight;
  worksheet["!cols"] = columnWidth;
  return worksheet;
}
