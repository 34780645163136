<script>
  import { replace } from "svelte-spa-router";
  import { onMount } from "svelte";
  import { urlApi } from "../../utils/const";
  import Loading from "../../components/Loading.svelte";

  export let params;
  let email;
  let disableResend = false;
  let loading;
  onMount(() => {
    if (localStorage.getItem("token") != null && localStorage.getItem("token") != "" && localStorage.getItem("token") != undefined) {
      replace("/");
    } else if (params && params["_email"]) {
      email = params["_email"];
    }
  });

  async function sendEmailVerification() {
    loading.toggle();

    const res = await fetch(urlApi + "/Authorization/SendEmailVerification?Email=" + email, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    if (res.status == 400) {
      globalThis.pushToast(data["data"]["errorMessage"], "error");
      disableResend = true;
    } else {
      globalThis.pushToast("Email verifikasi berhasil dikirim, silahkan cek email anda", "success");
    }

    loading.toggle();
  }
</script>

<Loading bind:this={loading} />
<div class="w-full bg-white rounded-lg shadow border border-gray-100 md:mt-0 sm:max-w-4xl xl:p-0">
  <div class="p-6 space-y-4 md:space-y-6 sm:p-8 text-center">
    <div class="space-y-4 md:space-y-6">
      <div class="flex justify-center"><img src="/images/logo.png" class="w-36" alt="Domus Cordis" /></div>

      <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 mb-6 md:text-2xl">Verifikasi Email</h1>

      <p class="text-md font-bold leading-tight tracking-tight text-gray-900 mb-6 md:text-lg">
        Kami telah mengirimkan e-mail verifikasi ke alamat email "{email}"
      </p>
      <p class="text-md font-bold leading-tight tracking-tight text-gray-900 mb-6 md:text-lg">
        Klik tombol "Verifikasi Email" untuk melanjutkan pendaftaran akun Anda. Apabila e-mail verifikasi tidak ditemukan, dimohon untuk mengecek
        folder Spam.
      </p>
      <p class="text-md font-bold leading-tight tracking-tight text-gray-900 mb-6 md:text-lg">
        E-mail verifikasi tidak ditemukan? Silahkan klik tombol dibawah untuk mengirimkan e-mail verifikasi
      </p>
      <button
        type="submit"
        disabled={disableResend}
        on:click|preventDefault={async () => sendEmailVerification()}
        class="w-full text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5
        text-center transition-all disabled:cursor-not-allowed disabled:bg-gray-500"
      >
        Kirim Ulang
      </button>
    </div>
  </div>
</div>
