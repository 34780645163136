<script>
    import { form, field } from "svelte-forms";
    import { required, matchField } from "svelte-forms/validators";
    import Layout from "../../layouts/Layout.svelte";
    import { bgColorDefault, bgPrimaryColor, hoverBgPrimary } from "../../utils/const";
    import Input from "../../components/control/Input.svelte";
    import { onMount } from "svelte";
    import { replace } from "svelte-spa-router";
    import Footer from "../../components/Footer.svelte";
    import { urlApi } from "../../utils/const";
    import Loading from "../../components/Loading.svelte";
    import { minLength, containAlphabet, containNumber, containSymbol } from "../../utils/validation";

    export let params;
    let token = "";
    let loading;

    onMount(() => {
        if (params == undefined || params["wild"] == "" || params["wild"] == null || params["wild"] == undefined) {
            replace("/");
        }
        token = params["wild"];
    });

    let errors = [];

    let password = field("password", "", [required(), minLength(6), containAlphabet(), containNumber(), containSymbol()]);
    let confirmPassword = field("confirmPassword", "", [required(), matchField(password)]);

    let newPassForm = form(password, confirmPassword);

    async function setPassword() {
        await newPassForm.validate();
        if ($newPassForm.valid) {
            loading.toggle();

            const reqData = {
                password: $password.value,
                confirmPassword: $confirmPassword.value,
                passwordResetToken: token,
            };
            const res = await fetch(urlApi + "/Authorization/ResetPassword", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(reqData),
            });
            const data = await res.json();
            loading.toggle();
            if (data["data"]["errorMessage"]) {
                globalThis.pushToast(data["data"]["errorMessage"], "error");
            } else {
                globalThis.pushToast("Berhasil Reset Password, Silahkan Login.", "success");
                setTimeout(() => replace("/login/"), 1000);
            }
            errors = [];
        } else {
            errors = $newPassForm.errors;
            errors = errors;
        }
    }
</script>

<Loading bind:this={loading} />

<Layout>
    <section class="h-screen {bgColorDefault}">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 mb-6 md:text-2xl">Set New Password</h1>
            <div class="w-full bg-white rounded-lg shadow border border-gray-100 md:mt-0 sm:max-w-md xl:p-0">
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <div class="space-y-4 md:space-y-6">
                        <Input
                            name="password"
                            type="password"
                            bind:value={$password.value}
                            placeholder="••••••••"
                            bind:errors
                            label="New Password"
                            withEye
                        />

                        <Input
                            name="confirmPassword"
                            type="password"
                            bind:value={$confirmPassword.value}
                            placeholder="••••••••"
                            bind:errors
                            label="Confirm Password"
                            withEye
                        />

                        <button
                            on:click={setPassword}
                            class="w-full text-white {bgPrimaryColor} hover:{hoverBgPrimary} outline-none font-medium rounded text-sm px-5 py-2.5 text-center transition-all"
                            >Confirm</button
                        >
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </section>
</Layout>
