<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let selected;
  export let data;
</script>

<div class="mt-2 flex flex-col space-y-2 pb-4">
  {#each data as item}
    <div
      class="card-data min-w-full p-2 {selected && selected['id'] == item['id'] ? 'bg-primary' : 'border border-primary'} rounded-md cursor-pointer"
      on:click={() => dispatch("select", item)}
    >
      <div class="flex justify-between items-center text-xs font-medium {selected && selected['id'] == item['id'] ? 'text-white' : 'text-gray-800'} ">
        <div class="flex flex-col justify-start items-start">
          <p class="text-md font-semibold">{item["name"]}</p>
        </div>
      </div>
    </div>
  {/each}
</div>
