<script>
  import { pop, replace } from "svelte-spa-router";
  import Loading from "../../components/Loading.svelte";
  import { onMount } from "svelte";
  import { urlApi } from "../../utils/const";
  import { learningRatingObject, submissionStatus } from "./LearningConst";
  import Input from "../../components/control/Input.svelte";
  import { getTypeLearning, parseJwt } from "../../utils/utils";
  import Textarea from "../../components/control/Textarea.svelte";
  import Modal from "../../components/Modal.svelte";
  import Rating from "../../components/control/Rating.svelte";
  import Button from "../../components/control/Button.svelte";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import { field, form } from "svelte-forms";
  import { required } from "svelte-forms/validators";

  export let params;
  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";
  let errors = [];
  let uploadedFile = [];
  let quizQuestion = [];
  let answerUser = [];
  let type = "";
  let content = "";
  let note = "";
  let modalBack;
  let reviewerID = null;
  let answerID = 0;
  let rating = field("rate", null, [required()]);
  let submitDate = new Date();

  let id = params["_id"];
  let userID = params["_userId"];

  const token = parseJwt(localStorage.getItem("token"));

  let loadingPage;
  let data = {};
  let windowReference;
  let statusAnswer = 0;
  let learnForm;

  let disableSubmit = false;
  let errorTemp = [];
  let errorRate = false;
  let ratingToCheckRadio = 0;

  onMount(() => {
    loadingPage.toggle();
    loadData();
  });

  async function loadData() {
    const res = await fetch(urlApi + "/Learning/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const resData = await res.json();
    data = resData["data"];
    type = getTypeLearning(data.type);
    if (type == "Text") {
      content = JSON.parse(data.module[0].content);
    } else if (type == "Video") {
      content = data.module[0].content;
    }
    uploadedFile = data.module.map((e) => ({ nameToDisplay: e.content.split("/").slice(2).join("/"), name: e.content }));
    quizQuestion = data.question.map((e) => ({
      id: e.id,
      question: e.question,
      items: e.items.map((it) => ({ ...it, answer: "" })),
      type: e.type.toString(),
      typeId: e.typeID,
    }));

    // load lesson exist?
    const resLesson = await fetch(urlApi + "/Learning/Lesson?id=" + id + "&userId=" + userID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const resDataLesson = await resLesson.json();

    if (!resDataLesson["data"]["errorMessage"]) {
      answerID = resDataLesson["data"][0]["id"];
      answerUser = resDataLesson["data"][0]["answers"];
      note = resDataLesson["data"][0]["note"];
      $rating.value = resDataLesson["data"][0]["rating"] == 0 ? null : resDataLesson["data"][0]["rating"];
      ratingToCheckRadio = $rating.value;
      reviewerID = resDataLesson["data"][0]["reviewerID"];
      statusAnswer = resDataLesson["data"][0]["status"];
      submitDate = new Date(resDataLesson["data"][0]["submitDate"]);
    }

    if (answerUser.length > 0) {
      quizQuestion.forEach((q, index) => {
        if (q.type == "0") {
          const indexAnswer = answerUser.findIndex((a) => a.questionID == q.id);
          if (indexAnswer != -1) {
            quizQuestion[index]["items"][0]["answer"] = answerUser[indexAnswer]["answer"];
          }
        } else if (q.type == "1") {
          const indexAnswer = answerUser.findIndex((a) => a.questionID == q.id);
          if (indexAnswer != -1) {
            const indexQ = q.items.findIndex((qI) => qI.id == parseInt(answerUser[indexAnswer]["answer"]));
            if (indexQ != -1) {
              quizQuestion[index]["items"][indexQ]["answer"] = true;
            }
          }
        } else {
          const filteredAnswer = answerUser.filter((a) => a.questionID == q.id);
          filteredAnswer.forEach((f) => {
            const indexFilter = q.items.findIndex((a) => a.id == parseInt(f["answer"]));
            quizQuestion[index]["items"][indexFilter]["answer"] = true;
          });
        }
      });
    }

    loadingPage.toggle();
  }

  async function previewFile(file) {
    const res = await fetch(urlApi + "/Learning/Download?fileName=" + file, {
      method: "GET",
      headers: {
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const resData = await res.json();
    windowReference.location = resData.data;
  }

  function checkBack() {
    let result = false;

    if (quizQuestion.findIndex((e) => e.items.filter((it) => it.answer != "").length > 0) != -1) {
      result = true;
    }

    return result;
  }

  function checkValidate() {
    errorTemp = [];
    quizQuestion.forEach((e, i) => {
      if (e.type != "0" && e.question == "") {
        errorTemp.push(i + "-question" + ".fill");
      }
      if (e.type != "0" && e.items.findIndex((x) => x.answer == true) == -1) {
        errorTemp.push(e.typeId + "-answer" + ".fill");
      }

      // if there is text quiz that is empty, add the error
      if (e.type == "0" && e.items.filter((x) => x.answer.length == 0).length == 1) {
        errorTemp.push(e.typeId + "-answer" + ".fill");
      }
      e.items.forEach((it, idx) => {
        if (e.type == "0" && it.question == "") {
          errorTemp.push(i + "-question" + ".required");
        }
        if (e.type != "0" && it.question == "") {
          errorTemp.push(idx + "-" + e.typeId + "-question" + ".fill");
          errorTemp.push(e.typeId + "-question" + ".fill");
        }
      });
    });

    errorTemp = errorTemp;

    return errorTemp;
  }

  async function submit(status) {
    let val = [];
    if (status == 1) val = checkValidate();
    if (val.length == 0) {
      let mapQuiz = quizQuestion.map((e) => ({
        ID: e.id,
        Type: parseInt(e.type),
        TypeID: e.typeId,
        Question: e.question,
        Items: e.items.map((x) =>
          e.type != "0"
            ? {
                ID: x.id,
                TypeID: e.typeId,
                Value: x.value,
                Answer: x.answer == true ? "1" : "",
              }
            : {
                ID: x.id,
                TypeID: e.typeId,
                Value: x.value,
                Answer: x.answer,
              },
        ),
      }));
      const reqData = {
        ID: parseInt(id),
        CellGroupID: data.cellGroupID,
        Quiz: mapQuiz,
        Status: status,
        SubmitDate: status == 0 ? null : new Date(),
      };

      const res = await fetch(urlApi + "/Learning/Lesson", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(reqData),
      });

      const resData = await res.json();
      if (resData["data"]["id"]) {
        if (status == 1) globalThis.pushToast("Berhasil Mengirim Submission", "success");
        else globalThis.pushToast("Berhasil Menyimpan Submission", "success");
        setTimeout(() => replace("/learning/"), 1000);
      }
    } else {
      errorMap = [];
      errors = [...val];
      disableSubmit = false;
      globalThis.pushToast("Gagal mengirim jawaban. Lengkapi Jawaban Quiz terlebih dahulu.", "error");
    }
  }

  async function submitReview() {
    learnForm = form(rating);
    await learnForm.validate();
    if ($learnForm.valid) {
      errorRate = false; // reset errorRate to false
      const reqData = {
        ID: answerID,
        Note: note,
        Rating: $rating.value,
        ReviewerID: token.UserID,
      };

      const res = await fetch(urlApi + "/Learning/Lesson/Review", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(reqData),
      });

      const resData = await res.json();

      if (resData["data"]["id"]) {
        globalThis.pushToast("Berhasil Mereview Submission");
        setTimeout(() => replace("/learning/"), 1000);
      }
    } else {
      errors = [...$learnForm.errors];
      errors = errors;
      errorRate = errors.indexOf("rate.required") != -1; // errorRate is true if rate.required exist
    }
  }

  function onRatingChange(event) {
    $rating.value = event.currentTarget.value;
  }

  let errorMap = [];

  $: if (errors.length > 0 && errorMap.length == 0) {
    errorMap = [];
    errorMap = errors
      .filter((e) => e.toString().split(".").length > 1)
      .map((e) => ({
        field: e.toString().split(".")[0],
        error: e.toString().split(".")[1],
      }));
  }
</script>

<Loading bind:this={loadingPage} />

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin ingin keluar dari laman ini? data yang Anda masukkan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/learning/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>

<section class="container p-4">
  <div class="sm:flex sm:items-center sm:justify-between">
    <div>
      <div class="flex items-center gap-x-3">
        <button
          on:click={() => {
            pop();
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-lg font-medium text-gray-800">Lesson Submission</h2>
      </div>
    </div>
  </div>
  <div class="flex flex-col space-y-2 mt-5">
    {#if statusAnswer != 0}
      <div class={mobileClass}>
        <div class="flex-1">
          <DatePicker
            name="date"
            type="text"
            label="Tanggal Submit Submission"
            labelSize="sm"
            textSize="sm"
            extClass="font-semibold {statusAnswer == 2 ? 'bg-red-100 border-red-500 focus:border-red-500' : ''}"
            bind:errors
            disabled
            bind:value={submitDate}
          />
        </div>
      </div>
      {#if statusAnswer == submissionStatus.Overdue}
        <p class="text-xs text-red-500 p-0 mt-1">Submission is overdue</p>
      {/if}
    {/if}
    <div class={mobileClass}>
      <div class="flex-1">
        <Input disabled name="title" type="text" label="Judul" placeholder="Ketikkan judul materi" bind:errors bind:value={data.title} />
      </div>
    </div>
    <div class="flex flex-col space-y-2">
      <p class="font-semibold text-sm">Materi</p>
      <!-- FILE -->
      {#if type == "Video" || type == "Text"}
        <div class={mobileClass}>
          {#if type == "Video"}
            <div class="flex-1">
              <iframe class="w-full lg:w-1/2 h-[400px]" src={content} />
            </div>
          {:else if type == "Text"}
            <div class="flex-1">
              <Textarea
                disabled
                name="content"
                bind:content
                label="Konten"
                inputEditor={true}
                disableQuil={token.RoleID == 4}
                placeholder="Masukkan konten..."
                maxlength={3000}
              />
            </div>
          {/if}
        </div>
      {/if}

      {#if type == "PDF" || type == "PPT"}
        <div class={mobileClass}>
          <div class="flex-1">
            <table class="w-full divide-y divide-gray-200">
              <thead class="bg-gray-100">
                <tr>
                  <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Nama File </th>
                  <th scope="col" class="px-2 py-2 text-sm font-normal text-left text-gray-500">Aksi</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                {#if uploadedFile.length == 0}
                  <tr>
                    <td colspan="2" class="px-4 py-4 text-sm font-medium">
                      <h2 class="font-medium text-gray-500 text-xs">Belum ada file</h2>
                    </td>
                  </tr>
                {/if}
                {#each uploadedFile as item, index}
                  <tr>
                    <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
                      <h2 class="font-medium text-gray-800 text-xs">
                        {item["nameToDisplay"]}
                      </h2>
                    </td>
                    <td class="px-2 py-2 text-sm font-medium whitespace-nowrap">
                      <button
                        on:click={() => {
                          windowReference = window.open();
                          previewFile(item["name"]);
                        }}
                        class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
                      >
                        <i class="bx bx-show text-xl text-blue-500" />
                      </button>
                    </td>
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        </div>
      {/if}
    </div>
    <div class="flex flex-col space-y-2">
      <p class="font-semibold text-sm">Quiz</p>

      {#each quizQuestion as question, index}
        <div class="flex items-start space-x-2">
          <div class="rounded-full bg-gray-200 mt-3 flex px-3 py-2 items-center justify-center">
            <span class="text-gray-700 font-medium text-xs">{index + 1}</span>
          </div>
          <div class="flex-1 flex flex-col space-y-2">
            {#if question.type == "1" || question.type == "2"}
              <Textarea disabled bind:value={question.question} name="{index}-question" placeholder="Masukkan pertanyaan disini" noLabel />
            {/if}
            {#if (question.type != "0" && errorMap.findIndex((e) => e["field"] == question.typeId + "-answer") != -1) || errorMap.findIndex((e) => e["field"] == question.typeId + "-question") != -1}
              <p class="text-xs text-red-500">Harap masukkan jawaban Quiz</p>
            {/if}
            {#each question.items as item, indexItem}
              {#if question.type == "0"}
                <Textarea disabled bind:value={item.value} name="{index}-question" noLabel />
              {/if}
              {#if (question.type == "0" && errorMap.findIndex((e) => e["field"] == question.typeId + "-answer") != -1) || errorMap.findIndex((e) => e["field"] == question.typeId + "-question") != -1}
                <p class="text-xs text-red-500">Harap masukkan jawaban Quiz</p>
              {/if}

              {#if question.type == "0"}
                <Textarea bind:value={item.answer} name="{index}-answer" disabled={statusAnswer != 0} placeholder="Masukkan jawaban disini" noLabel />
              {/if}

              {#if question.type == "1"}
                <div class="flex items-center space-x-2">
                  <input
                    type="radio"
                    class="cursor-pointer {errorMap.findIndex((e) => e['field'] == question.typeId + '-answer') != -1 ? 'border border-red-500' : ''}"
                    name="{item.typeID}-answer"
                    checked={item.answer == "1"}
                    disabled={statusAnswer != 0}
                    on:input={(e) => {
                      item["answer"] = !item["answer"];

                      quizQuestion[index]["items"].map((it) => {
                        if (it.answer == true) {
                          it.answer = false;
                        }
                      });
                      quizQuestion[index]["items"][indexItem].answer = true;
                      quizQuestion = quizQuestion;
                    }}
                  />
                  <div class="flex-1">
                    <Input disabled placeholder="Masukkan Pilihan Ganda" name="{indexItem}-question" noLabel type="text" bind:value={item.value} />
                  </div>
                </div>
              {/if}

              {#if question.type == "2"}
                <div class="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    class="cursor-pointer {errorMap.findIndex((e) => e['field'] == question.typeId + '-answer') != -1 ? 'border border-red-500' : ''}"
                    name="{item.typeID}-answer"
                    checked={item.answer == "1"}
                    disabled={statusAnswer != 0}
                    on:input={(e) => {
                      item["answer"] = !item["answer"];
                    }}
                  />
                  <div class="flex-1">
                    <Input disabled placeholder="Masukkan Pilihan" name="{indexItem}-question" noLabel type="text" bind:value={item.value} />
                  </div>
                </div>
              {/if}
            {/each}
          </div>
        </div>
      {/each}
    </div>
    {#if token.RoleID != 4}
      <div class="flex flex-col space-y-2 mt-4">
        <Textarea disabled={reviewerID != 0} bind:value={note} name="note" label="Catatan" bind:errors />
        <p class="text-sm font-semibold text-gray-700 mb-2">Rate Submission</p>
        {#each learningRatingObject as rating}
          <div class="flex items-center gap-x-2">
            <input
              checked={ratingToCheckRadio == rating.value}
              disabled={reviewerID != 0}
              type="radio"
              class="cursor-pointer"
              name="rating"
              value={rating.value}
              on:change={onRatingChange}
            />
            <p class="text-sm font-semibold">{rating.name}</p>
          </div>
        {/each}
        <!-- manual validation -->
        {#if errorRate}
          <p class="text-xs text-red-500">Rate Submission harus diisi</p>
        {/if}
      </div>
    {/if}
    <div class="pt-4" />
    {#if statusAnswer != submissionStatus.Submitted && statusAnswer != submissionStatus.Overdue && token.RoleID == 4}
      <div class="flex w-full space-x-4">
        <Button
          text="Batal"
          type="gray"
          disabled={disableSubmit}
          on:click={() => {
            if (checkBack()) {
              modalBack.toggle();
            } else {
              pop();
            }
          }}
        />
        <Button
          text="Save as Draft"
          type="primary"
          disabled={disableSubmit}
          on:click={async () => {
            disableSubmit = true;
            await submit(0);
          }}
        />
        <Button
          text="Kirim"
          disabled={disableSubmit}
          on:click={async () => {
            disableSubmit = true;
            await submit(1);
          }}
        />
      </div>
    {/if}

    {#if token.RoleID != 4 && reviewerID == 0}
      <div class="flex w-full space-x-4">
        <Button
          text="Batal"
          type="gray"
          disabled={disableSubmit}
          on:click={() => {
            if (checkBack()) {
              modalBack.toggle();
            } else {
              pop();
            }
          }}
        />
        <Button
          text="Submit"
          disabled={disableSubmit}
          on:click={async () => {
            await submitReview();
          }}
        />
      </div>
    {/if}
  </div>
</section>
