import { urlApi } from "../../utils/const";
import { utils, writeFileXLSX } from "xlsx";
import { formatDateForView, formatDateWithSlash } from "../../utils/utils";
import { submissionStatus } from "./LearningConst";
const ratingsObject = {
  Inspiring: { value: 1, name: "Inspiring" },
  Just_Okay: { value: 2, name: "Just Okay" },
  Need_Help_Struggling: { value: 3, name: "Need Help/Struggling" },
  Disengage: { value: 4, name: "Disengage" },
};
const nonDynamicProperties = [
  "Nama Materi",
  "Kategori Sel",
  "Sel Group",
  "Tanggal Published",
  "Tanggal Deadline",
  "Status",
  "Nama Member",
  "Status Submission",
  "Tanggal Submit",
  "Catatan",
  "Rate Submission",
  "Closed By",
];
async function getLMSReporting(id) {
  try {
    const res = await fetch(urlApi + "/Learning/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    return data["data"];
  } catch (err) {
    return [];
  }
}
async function getLMSListReporting(option) {
  try {
    const res = await fetch(urlApi + "/Reporting/Learning" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    return data["data"];
  } catch (err) {
    return [];
  }
}
async function getLMSSubmissionReporting(id, userID) {
  try {
    const res = await fetch(urlApi + "/Learning/Lesson?id=" + id + "&userId=" + userID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    return data["data"];
  } catch (err) {
    return [];
  }
}
async function getLMSMember(id) {
  try {
    const res = await fetch(urlApi + "/Learning/" + id + "/Member", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    return data["data"].cellGroupMembers;
  } catch (err) {
    return [];
  }
}
function resolveLMSStatus(status) {
  if (status == 1) return "Published";
  else if (status == 2) return "Upcoming";
  else return "Closed";
}
function resolveSubmissionStatus(status) {
  if (status == 1) return "Submitted";
  else if (status == 2) return "Submission Overdue";
  else return "Belum ada Submission";
}
function resolveSubmissionRating(rating) {
  const ratingsVal = [
    ratingsObject.Inspiring.name,
    ratingsObject.Just_Okay.name,
    ratingsObject.Need_Help_Struggling.name,
    ratingsObject.Disengage.name,
  ];
  return ratingsVal[rating - 1];
}

// Function to reorder properties within an object
function reorderObjectProperties(obj) {
  const reorderedObject = {};
  nonDynamicProperties.forEach((key) => {
    if (obj[key] !== undefined) {
      reorderedObject[key] = obj[key];
    }
  });
  // Dynamic properties (Pertanyaan, Kunci, Jawaban)
  const dynamicProperties = Object.keys(obj).filter((key) => key.startsWith("Pertanyaan") || key.startsWith("Kunci") || key.startsWith("Jawaban"));
  // Group and sort dynamic properties
  const groupedDynamicProperties = dynamicProperties.reduce((acc, key) => {
    const match = key.match(/(Pertanyaan|Kunci|Jawaban) (\d+)/);
    if (match) {
      const groupKey = match[1];
      const groupIndex = match[2];
      if (!acc[groupIndex]) {
        acc[groupIndex] = {};
      }
      acc[groupIndex][groupKey] = key;
    }
    return acc;
  }, {});
  const sortedDynamicProperties = Object.keys(groupedDynamicProperties).sort((a, b) => {
    // Use localeCompare to order property by numeric sorting. Result will be 1,2,10,11 not 1,10,11,2
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
  });

  sortedDynamicProperties.forEach((groupIndex) => {
    const dynamicGroup = groupedDynamicProperties[groupIndex];
    ["Pertanyaan", "Kunci", "Jawaban"].forEach((groupKey) => {
      if (dynamicGroup[groupKey]) {
        reorderedObject[dynamicGroup[groupKey]] = obj[dynamicGroup[groupKey]];
      }
    });
  });

  return reorderedObject;
}
export default {
  async exportLMSReporting(id) {
    // LMS DETAIL REGION
    const lmsData = await getLMSReporting(id);
    const jsonLMS = [];
    const lmsObject = {
      "Nama Materi": lmsData.title,
      "Kategori Sel": lmsData.covenantYearName,
      "Sel Group": lmsData.cellGroupName,
      "Tanggal Published": formatDateWithSlash(new Date(lmsData.publishDate)),
      "Tanggal Deadline": formatDateWithSlash(new Date(lmsData.overdueDate)),
      Status: resolveLMSStatus(lmsData.status),
    };
    jsonLMS.push(lmsObject);
    // QUESTION AND ANSWER KEY REGION
    const questions = lmsData.question.filter((e) => e.type != 0);
    const jsonQuestionAnswerKey = [];
    const questionAnswerKey = questions.reduce((acc, question) => {
      const answers = question.items.filter((item) => item.answer === "1"); // get answer key
      if (answers.length > 0) {
        acc[question.question] = answers.map((answer) => answer.value).join(", "); // set question as property and answer that is joined with ", "as value
      }
      return acc;
    }, {});
    jsonQuestionAnswerKey.push(questionAnswerKey);
    // MEMBER SUBMISSION REGION
    const lmsMember = await getLMSMember(id);
    const lmsSubmissionData = await getLMSSubmissionReporting(id, 0);
    const jsonSubmissions = lmsSubmissionData.errorMessage
      ? []
      : lmsSubmissionData
          .filter((submission) => submission.status !== submissionStatus.Draft)
          .map((submission) => {
            const submissionData = lmsData.question.reduce(
              (acc, question) => {
                const answers = submission.answers.filter((item) => item.questionID === question.id);
                if (answers.length > 0) {
                  if (question.type === 0) {
                    acc[question.items[0].value] = answers[0].answer; // for question with textbox type
                  } else {
                    const matchingQuestions = lmsData.question.filter((q) => answers.some((answer) => answer.questionID === q.id));
                    const answerValues = matchingQuestions[0].items
                      .filter((item) => answers.some((answer) => parseInt(answer.answer) === item.id))
                      .map((answer) => answer.value)
                      .join(", ");
                    acc[question.question] = answerValues;
                  }
                }
                return acc;
              },
              {
                "Nama Member": submission.name,
                "Status Submission": resolveSubmissionStatus(submission.status),
                "Tanggal Submit": formatDateWithSlash(new Date(submission.submitDate)),
              },
            );
            submissionData.Catatan = submission.note;
            submissionData["Rate Submission"] = resolveSubmissionRating(submission.rating);
            submissionData["Closed By"] = submission.reviewerName;
            return submissionData;
          });

    // make an object that has the question as property with "" as value
    const questionListWithoutAnswer = lmsData.question.reduce((acc, question) => {
      if (question.type === 0) acc[question.items[0].value] = "";
      else acc[question.question] = "";
      return acc;
    }, {});
    // Handle for Member that does not submit answer
    lmsMember.forEach((member) => {
      let userIDExistsInSubmission;
      // if there is an errorMessage, this means there is no submission.
      if (lmsSubmissionData.errorMessage) userIDExistsInSubmission = false;
      else {
        // Check if the member's userID is in lmsSubmissionData and the submission status is not 0
        userIDExistsInSubmission = lmsSubmissionData.some(
          (submission) => submission.userID === member.userID && submission.status != submissionStatus.Draft,
        );
      }
      // If not found in lmsSubmissionData, create a new default object with member name and "Not Submiited" as Status Submission
      if (!userIDExistsInSubmission) {
        const newSubmission = {
          "Nama Member": member.userName,
          "Status Submission": "Belum ada Submission",
          "Tanggal Submit": "",
          ...questionListWithoutAnswer,
          Catatan: "",
          "Rate Submission": "",
          "Closed By": "",
        };
        jsonSubmissions.push(newSubmission);
      }
    });

    let worksheetLMS = utils.json_to_sheet([{}]);
    utils.sheet_add_json(worksheetLMS, jsonLMS, { origin: "A1" });
    utils.sheet_add_json(worksheetLMS, [{ A: "Quiz" }], { skipHeader: true, origin: "A4" });
    utils.sheet_add_json(worksheetLMS, jsonQuestionAnswerKey, { origin: "A5" });
    utils.sheet_add_json(worksheetLMS, [{ A: "Member Submission" }], { skipHeader: true, origin: "A8" });
    utils.sheet_add_json(worksheetLMS, jsonSubmissions, { origin: "A9" });
    const columnWidth = Array(Object.keys(jsonSubmissions[0]).length).fill({ wch: 30 }); // set column width = 30 for each column
    worksheetLMS["!cols"] = columnWidth;

    const new_workbook = utils.book_new(); // make new workbook
    utils.book_append_sheet(new_workbook, worksheetLMS, "Sheet1"); // sheet name
    const fileName = `Export LMS - ${lmsData.cellGroupName} - ${lmsData.title}.xlsx`;
    writeFileXLSX(new_workbook, fileName); // trigger download
  },

  async exportLMSListReporting(option, from, to) {
    const lmsData = await getLMSListReporting(option);

    const jsonLMS = [].concat(
      ...lmsData.map((material) =>
        material.learningMembers.map((member) => {
          const submissionDataMember = material.answerSubmissions.find(
            (submission) => submission.userID === member.userID && submission.status != "Belum ada submission",
          );
          let questionCount = 1; // Initialize the question count
          const uniqueQuestions = material.learningQuestions.filter((question, index, self) => {
            // Filter out duplicate questions based on question.questionID
            return self.findIndex((q) => q.questionID === question.questionID) === index;
          });
          const questionWithAnswerKey = uniqueQuestions.map((question) => {
            let answerKey;
            if (question.type == 0) answerKey = "-";
            else {
              const answers = material.learningQuestions.filter((item) => item.questionID == question.questionID); // get answer key
              answerKey = answers
                .filter((answer) => answer.answer == "1")
                .map((answer) => answer.value)
                .join(", "); // set question as property and answer that is joined with ", "as value
            }
            return { ...question, value: answerKey };
          });

          const submissionData = questionWithAnswerKey.reduce(
            (acc, question) => {
              const answers = submissionDataMember ? submissionDataMember.answers.filter((item) => item.questionID === question.questionID) : [];
              if (question.type === 0) {
                acc[`Jawaban ${questionCount}`] = answers.length == 0 ? "" : answers[0].answer; // Set property name with Q1, Q2, ...
              } else {
                const matchingQuestions = material.learningQuestions.filter((q) => answers.some((answer) => answer.questionID === q.questionID));
                const answerValues = matchingQuestions
                  .filter((item) => answers.some((answer) => parseInt(answer.answer) === item.id))
                  .map((answer) => answer.value)
                  .join(", ");
                acc[`Jawaban ${questionCount}`] = answers.length == 0 ? "" : answerValues;
              }
              acc[`Pertanyaan ${questionCount}`] = question.question;
              acc[`Kunci ${questionCount}`] = question.value;
              questionCount++; // Increment the question count
              return acc;
            },
            {
              "Nama Member": submissionDataMember ? submissionDataMember.name : member.userName,
              "Status Submission": submissionDataMember ? submissionDataMember.status : "Belum ada submission",
              "Nama Materi": material.title,
              "Tanggal Submit": submissionDataMember ? formatDateWithSlash(new Date(submissionDataMember.submitDate)) : "",
              "Nama Materi": material.title,
              "Kategori Sel": material.covenantYearName,
              "Sel Group": material.cellGroupName,
              "Tanggal Published": formatDateWithSlash(new Date(material.publishDate)),
              "Tanggal Deadline": formatDateWithSlash(new Date(material.overdueDate)),
              Status: resolveLMSStatus(material.status),
              Catatan: submissionDataMember ? submissionDataMember.note : "",
              "Rate Submission": submissionDataMember && submissionDataMember.rating ? resolveSubmissionRating(submissionDataMember.rating) : "",
              "Closed By": submissionDataMember ? submissionDataMember.reviewerName : "",
            },
          );
          return submissionData;
        }),
      ),
    );
    const reorderedArray = jsonLMS.map((obj) => reorderObjectProperties(obj));

    // Get the length of the object that has the most property.
    let maxPropertiesLength = 0;
    reorderedArray.forEach((obj) => {
      const numProperties = Object.keys(obj).length;
      if (numProperties > maxPropertiesLength) {
        maxPropertiesLength = numProperties;
      }
    });
    let worksheetLMS = utils.json_to_sheet([{}]);
    utils.sheet_add_json(worksheetLMS, [{ A: "Tanggal" }], { skipHeader: true, origin: "A1" }); // header text
    utils.sheet_add_json(worksheetLMS, [{ A: `${formatDateForView(from)} - ${formatDateForView(to)}` }], { skipHeader: true, origin: "A2" }); // header text
    utils.sheet_add_json(worksheetLMS, reorderedArray, { origin: "A4" });
    const columnWidth = Array(maxPropertiesLength).fill({ wch: 30 }); // set a set amount of column width = 30 based on maxPropertiesLength
    worksheetLMS["!cols"] = columnWidth; // set column width for each column

    const new_workbook = utils.book_new(); // make new workbook
    utils.book_append_sheet(new_workbook, worksheetLMS, "Daftar Materi"); // sheet name

    const fileName = "LMS Export - Daftar Materi.xlsx";
    writeFileXLSX(new_workbook, fileName); // trigger download
  },
};
