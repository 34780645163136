import { urlApi } from "../../utils/const";

export default {
  async deleteJournal(data) {
    const res = await fetch(urlApi + "/Journals/Delete", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    });
    return res.json();
  },
};
