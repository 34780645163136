<script>
  import { pop } from "svelte-spa-router";
  import { form, field } from "svelte-forms";
  import { onMount } from "svelte";
  import Cookies from "js-cookie";
  import { required, email } from "svelte-forms/validators";
  import Loading from "../../components/Loading.svelte";

  import Layout from "../../layouts/Layout.svelte";
  import { bgColorDefault, bgPrimaryColor, hoverBgPrimary } from "../../utils/const";
  import Input from "../../components/control/Input.svelte";
  import LayoutSidebar from "../../layouts/LayoutSidebar.svelte";
  import Footer from "../../components/Footer.svelte";
  import { urlApi } from "../../utils/const";

  let errors = [];
  let loading;

  let isEmailSent = false;
  let emails = field("emails", "", [required(), email()], {
    checkOnInit: false,
    validateOnChange: true,
  });

  let resetForm = form(emails);
  onMount(() => {
    if (localStorage.getItem("token") != null && localStorage.getItem("token") != "" && localStorage.getItem("token") != undefined) {
      pop();
    }
  });
  async function resetPassword() {
    await resetForm.validate();
    if ($resetForm.valid) {
      loading.toggle();
      const reqData = {
        email: $emails.value,
      };

      const res = await fetch(urlApi + "/Authorization/ForgotPassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqData),
      });
      const data = await res.json();
      if (data["data"]["errorMessage"]) {
        globalThis.pushToast(data["data"]["errorMessage"], "error");
      } else {
        isEmailSent = true;
        globalThis.pushToast("Email reset password sudah dikirim, silahkan cek email anda", "success");
      }
      loading.toggle();
    } else {
      errors = $resetForm.errors;
      errors = errors;
    }
  }
</script>

<Loading bind:this={loading} />

<LayoutSidebar>
  <section class="h-screen {bgColorDefault}">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
      <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 mb-6 md:text-2xl">Reset Password</h1>
      <div class="w-full bg-white rounded-lg shadow border border-gray-100 md:mt-0 sm:max-w-lg xl:p-0">
        <div class="p-3 space-y-4 md:space-y-6 sm:p-8">
          <div class="space-y-4 md:space-y-6">
            {#if isEmailSent}
              <p class="text-center">Silahkan cek email anda untuk reset password</p>
            {/if}
            {#if !isEmailSent}
              <Input name="emails" type="email" bind:value={$emails.value} placeholder="Enter your email" bind:errors label="Email" />

              <button
                on:click={resetPassword}
                class="w-full text-white bg-primary hover:opacity-80 outline-none font-medium rounded text-sm px-5 py-2.5 text-center transition-all"
                >Reset Password</button
              >
            {/if}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </section>
</LayoutSidebar>
