<script>
  import { field } from "svelte-forms";
  import { email, required } from "svelte-forms/validators";
  import UserForm from "../../components/form/UserForm.svelte";
  import { onMount } from "svelte";
  import { urlApi } from "../../utils/const";
  import { parseJwt } from "../../utils/utils";
  import Loading from "../../components/Loading.svelte";
  let emails = field("emails", "", [required(), email()], {
    checkOnInit: false,
    validateOnChange: true,
  });

  let fullName = field("fullName", "", [required()]);
  let address = field("address", "", []);
  let note = field("note", "", []);
  let phone = field("phone", "", [required()]);
  let roleSelected = field("roleSelected", "", [required()]);
  let spvSelected = field("spvSelected", "", []);
  let companionSelected = field("companionSelected", "", []);
  let departmentSelected = field("departmentSelected", "", [required()]);

  let baptismalName = field("baptismalName", "", []);
  let cellGroupSelected = field("cellGroupSelected", "", []);
  let covenantYearSelected = field("covenantYearSelected", "", []);
  let dateOfBirth = field("dateOfBirth", new Date(), [required()]);
  let educationSelected = field("educationSelected", "", []);
  let imageSrc = field("imageSrc", "", []);
  let job = field("job", "", []);
  let nickname = field("nickname", "", []);
  let parokiChurch = field("parokiChurch", "", []);
  let placeOfBirth = field("placeOfBirth", "", []);

  let status = true;
  export let params;

  const token = parseJwt(localStorage.getItem("token"));
  let access = token.RoleID;

  let companionOption = [];

  let spvOption = [];
  let roleList = [];
  let errors = [];
  let loading;

  onMount(() => {
    loadData();
  });

  async function loadData() {
    loading.toggle();
    const res = await fetch(urlApi + "/Users/" + params["_id"], {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    if (data["data"]) {
      status = data["data"]["isActive"];
      $roleSelected.value = data["data"]["rolesID"];
      $fullName.value = data["data"]["name"];
      $emails.value = data["data"]["email"];
      $phone.value = data["data"]["phoneNumber"];
      $address.value = data["data"]["address"];

      $baptismalName.value = data["data"]["baptismalName"];
      $cellGroupSelected.value = data["data"]["cellGroupID"].toString();
      $covenantYearSelected.value = data["data"]["covenantYearID"].toString();
      $dateOfBirth.value = new Date(data["data"]["dateOfBirth"]);
      $educationSelected.value = data["data"]["education"].toString();
      $imageSrc.value = data["data"]["imageSrc"].toString();
      $job.value = data["data"]["job"];
      $nickname.value = data["data"]["nickname"];
      $parokiChurch.value = data["data"]["parokiChurch"];
      $placeOfBirth.value = data["data"]["placeOfBirth"];
      roleList = data["data"]["roles"];
      roleList.sort(function (a, b) {
        return (a.rolesID === 4) - (b.rolesID === 4);
      });
      roleList.sort(function (a, b) {
        return (a.groupID === 0) - (b.groupID === 0);
      });
      if (data["data"]["companionID"] != 0) {
        $companionSelected.value = "0";
        companionOption.push({ id: "0", name: data["data"]["companionName"] });
      } else {
        $companionSelected.value = "";
      }
      if (data["data"]["supervisorID"] != 0) {
        $spvSelected.value = "0";
        spvOption.push({ id: "0", name: data["data"]["supervisorName"] });
      } else {
        $spvSelected.value = "";
      }
      $departmentSelected.value = data["data"]["department"];
      $note.value = data["data"]["note"];
    }
    loading.toggle();
  }
</script>

<Loading bind:this={loading} />
<section class="container px-4 py-4">
  <UserForm
    {access}
    formTitle="Detail Pengguna"
    bind:address={$address.value}
    bind:baptismalName={$baptismalName.value}
    bind:concernedID={params["_id"]}
    bind:dateOfBirth={$dateOfBirth.value}
    bind:departmentSelected={$departmentSelected.value}
    bind:educationSelected={$educationSelected.value}
    bind:emails={$emails.value}
    bind:errors
    bind:fullName={$fullName.value}
    bind:imageSrc={$imageSrc.value}
    bind:job={$job.value}
    bind:nickname={$nickname.value}
    bind:note={$note.value}
    bind:phone={$phone.value}
    bind:parokiChurch={$parokiChurch.value}
    bind:placeOfBirth={$placeOfBirth.value}
    bind:roleList
    bind:roleSelected={$roleSelected.value}
    bind:status
    mode="detail"
  />
</section>
