<script>
  import { field } from "svelte-forms";
  import Input from "../../components/control/Input.svelte";
  import { required } from "svelte-forms/validators";
  import Textarea from "../../components/control/Textarea.svelte";
  import Loading from "../../components/Loading.svelte";
  import Select from "../../components/control/Select.svelte";
  import { pop, replace } from "svelte-spa-router";
  import { onMount } from "svelte";
  import Cookies from "js-cookie";
  import { EDIT_JOURNAL_MODULE, paymentOptionData, urlApi } from "../../utils/const";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import { checkRoleModule, formatRupiah, parseJwt } from "../../utils/utils";
  import { roleModules } from "../../stores/store";

  let date = field("date", new Date(), [required()]);
  let place = field("place", "", [required()]);
  let prayTime = field("prayTime", "", [required()]);
  let prayNote = field("prayNote", "", [required()]);
  let reader = field("reader", "", []);
  let devotional = field("devotional", "", [required()]);
  let total = field("total", "", []);
  let payType = field("payType", "", []);
  let note = field("note", "", []);

  let loading;
  let bibleOption = [];

  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";

  export let params;

  let id = params["_id"];
  let type = params["type"];
  let errors = [];
  const token = parseJwt(localStorage.getItem("token"));
  let userID = token.UserID;
  let ownerID = "";

  onMount(() => {
    loadData();
  });

  async function loadData() {
    const res = await fetch(urlApi + "/Journals/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();

    if (data["data"]) {
      $date.value = new Date(data["data"]["date"]);
      $place.value = data["data"]["place"];
      $prayTime.value = data["data"]["prayTime"];
      $prayNote.value = data["data"]["prayNote"];
      $reader.value = data["data"]["reader"];
      $devotional.value = data["data"]["devotional"];
      $total.value = formatRupiah(parseInt(data["data"]["total"]), "Rp ");
      $payType.value = data["data"]["paymentType"] == "CASH" ? "1" : "2";
      $note.value = data["data"]["note"];
      ownerID = data["data"]["userID"];
    }
  }
</script>

<Loading bind:this={loading} />

<section class="container px-4 py-4">
  <div class="sm:flex sm:items-center sm:justify-between">
    <div class="w-full flex items-center justify-between">
      <div class="flex-1 flex items-center gap-x-3">
        <button
          on:click={() => {
            pop();
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-sm md:text-lg font-medium text-gray-800">
          Rincian Journal - {type}
        </h2>
      </div>
      {#if checkRoleModule($roleModules,EDIT_JOURNAL_MODULE) && userID == ownerID}
        <div class="flex items-center justify-end">
          <button
            on:click={() => replace("/journals/" + id + "/" + type + "/edit")}
            class="text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 md:mt-4 text-center transition-all"
            >Edit
          </button>
        </div>
      {/if}
    </div>
  </div>

  <div class="mt-5 flex flex-col space-y-4">
    <div class={mobileClass}>
      <div class="flex-1">
        <DatePicker
          name="date"
          type="text"
          label="Tanggal"
          labelSize="sm"
          textSize="sm"
          extClass="font-semibold"
          bind:errors
          disabled
          bind:value={$date.value}
        />
      </div>
    </div>

    {#if type == "Doa"}
      <div class={mobileClass}>
        <div class="flex-1">
          <Input
            name="prayTime"
            type="number"
            label="Waktu {type == 'Doa' ? 'Berdoa' : type} (menit)"
            suffix
            disabled
            suffixText="menit"
            bind:errors
            bind:value={$prayTime.value}
          />
        </div>
      </div>

      <div class="flex space-x-2">
        <div class="flex-1">
          <Textarea bind:errors disabled name="prayNote" label="Catatan {type}" bind:value={$prayNote.value} />
        </div>
      </div>
    {/if}

    {#if type == "Sakramen Tobat" || type == "Misa Harian"}
      <div class={mobileClass}>
        <div class="flex-1">
          <Input name="place" type="text" label="Tempat/Gereja" disabled bind:errors bind:value={$place.value} />
        </div>
      </div>

      <div class="flex space-x-2">
        <div class="flex-1">
          <Textarea bind:errors disabled name="prayNote" label="Catatan {type}" bind:value={$prayNote.value} />
        </div>
      </div>
    {/if}

    {#if type == "Pembacaan Alkitab"}
      <div class="flex space-x-2">
        <div class="flex-1">
          <Input name="reader" type="text" disabled label="Bacaan" bind:errors bind:value={$reader.value} />
        </div>
      </div>

      <div class="flex space-x-2">
        <div class="flex-1">
          <Textarea bind:errors disabled name="devotional" label="Renungan" bind:value={$devotional.value} />
        </div>
      </div>
    {/if}

    {#if type == "Persembahan Kasih"}
      <div class={mobileClass}>
        <div class="flex-1">
          <Input name="total" type="money" disabled label="Total Persembahan" bind:errors bind:value={$total.value} />
        </div>

        <div class="flex-1">
          <Select
            on:change={(e) => {
              $payType.value = e.detail;
            }}
            textHint="Pilih Cara Pemberian"
            fullWidth
            name="payType"
            disabled
            extClass="font-semibold"
            label="Cara Pemberian"
            textSize="text-sm"
            options={paymentOptionData}
            bind:errors
            bind:selected={$payType.value}
          />
        </div>
      </div>

      <div class="flex space-x-2">
        <div class="flex-1">
          <Textarea bind:errors name="note" disabled label="Catatan" bind:value={$note.value} />
        </div>
      </div>
    {/if}

    {#if type == "Testimonial"}
      <div class="flex space-x-2">
        <div class="flex-1">
          <Textarea bind:errors name="note" label="Testimonial" placeholder="Tulis Testimonial" disabled bind:value={$note.value} />
        </div>
      </div>
    {/if}
    <div class="my-5" />
  </div>
</section>
