<script>
  import { link, pop, replace } from "svelte-spa-router";
  import { onMount } from "svelte";
  import { form, field } from "svelte-forms";
  import { required, email, matchField } from "svelte-forms/validators";
  import { minLength, containAlphabet, containNumber, containSymbol, phoneMinLength } from "../../utils/validation";
  import { urlApi } from "../../utils/const";
  import Input from "../../components/control/Input.svelte";
  import Loading from "../../components/Loading.svelte";
  import Modal from "../../components/Modal.svelte";

  let errors = [];
  let requirement = [{ isCheck: false, text: "Saya Setuju" }];
  let disabled = true;

  let emails = field("emails", "", [required(), email()], {
    checkOnInit: false,
    validateOnChange: true,
  });

  let password = field("password", "", [required(), minLength(6), containAlphabet(), containNumber(), containSymbol()]);
  let fullName = field("fullName", "", [required()]);
  let phone = field("phone", "", [required(), phoneMinLength(9)]);
  let confirmPassword = field("confirmPassword", "", [required(), matchField(password)]);

  let loading;
  let modalAccept;

  let registerForm = form(fullName, emails, password, confirmPassword, phone);

  onMount(() => {
    if (localStorage.getItem("token") != null && localStorage.getItem("token") != "" && localStorage.getItem("token") != undefined) {
      pop();
    }
  });

  let errConsentForm = false;

  async function register() {
    loading.toggle();
    errors = [];
    await registerForm.validate();
    if ($registerForm.valid) {
      if (!checkRequirement()) {
        errConsentForm = true;
        loading.toggle();
      } else {
        const reqData = {
          FullName: $fullName.value,
          Email: $emails.value,
          PhoneNumber: $phone.value,
          Password: $password.value,
          Address: null,
          SupervisorID: null,
          WakilGembalaID: null,
          GembalaID: null,
          HeadGembalaID: null,
          DepartmentID: null,
          Department: "Formation",
          Note: null,
          isActive: 1,
          RoleID: 4,
        };

        const res = await fetch(urlApi + "/Authorization/Register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reqData),
        });

        const data = await res.json();

        if (data["data"]["errorMessage"]) {
          loading.toggle();
          globalThis.pushToast(data["data"]["errorMessage"], "error");
        } else if (data["data"]["id"]) {
          loading.toggle();
          globalThis.pushToast("Berhasil Mendaftarkan Akun, Silahkan Verifikasi Email", "success");
          setTimeout(() => replace("/email-verification/" + $emails.value), 1000);
        }
      }
    } else {
      errors = $registerForm.errors;
      errors = errors;
      loading.toggle();
    }
  }

  function requirementChange(index) {
    requirement[index].isCheck = !requirement[index].isCheck;
    checkRequirement();
  }

  function checkRequirement() {
    if (requirement.some((e) => e.isCheck === false)) {
      disabled = true;
      return false;
    }
    disabled = false;
    return true;
  }
</script>

<Modal index={50} title="Consent Form" closeAble={false} bind:this={modalAccept}>
  <div class="space-x-3 mb-4 overflow-y-auto max-h-96">
    <p />
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">Hai Cordisian, selamat datang di Domus Cordis!</p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Dalam menggunakan apps ini, ada beberapa hal yang perlu diperhatikan. Dengan membuat akun dan/atau menggunakan apps Domus Cordis ini, kamu
      menyatakan telah membaca, memahami, dan menyetujui Ketentuan Penggunaan ini.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Ketentuan Penggunaan ini dapat diubah atau diperbaharui baik sebagian maupun seluruhnya dari waktu ke waktu, dan akan berlaku sejak diunggah
      pada Situs.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Dengan tetap mengakses atau menggunakan apps Domus Cordis, maka kamu dianggap menyetujui perubahan atas Ketentuan Penggunaan ini. Apabila kamu
      tidak menyetujui perubahan Ketentuan Penggunaan, kamu dapat mengirimkan email ke info@domuscordis.com.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">Silakan membaca Ketentuan Penggunaan ini dengan saksama!</p>

    <p class="text-left text-xs md:text-sm text-gray-700 font-bold mb-2">1. Definisi</p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      “Akun Domus Cordis” adalah setiap akun yang terdaftar di Apps Domus Cordis, dimana setiap pemilik akun dapat menyimpan Data Pribadinya.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      “Dokumen Elektronik” adalah setiap Informasi Elektronik, termasuk namun tidak terbatas pada Kontrak Elektronik, yang dibuat, diteruskan,
      dikirimkan, diterima, atau disimpan dalam bentuk analog, digital, elektromagnetik, optikal, atau sejenisnya, yang dapat dilihat, ditampilkan,
      dan/atau didengar melalui komputer atau sistem elektronik, termasuk tetapi tidak terbatas pada tulisan, suara, gambar, peta, rancangan, foto
      atau sejenisnya, huruf, tanda, angka, kode akses, simbol atau perforasi yang memiliki makna atau arti atau dapat dipahami oleh orang yang mampu
      memahaminya.
    </p>

    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      “Informasi Elektronik” berarti satu atau sekumpulan data elektronik, termasuk tetapi tidak terbatas pada tulisan, suara, gambar, peta,
      rancangan, foto, Electronic Data Interchange (EDI), surat elektronik (e-mail), telegram, teleks, telecopy atau sejenisnya, huruf, tanda, angka,
      kode akses, simbol, atau perforasi yang telah diolah yang memiliki arti atau dapat dipahami oleh orang yang mampu memahaminya.
    </p>

    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      “Cordisian” berarti setiap orang perorangan, yang adalah anggota komunitas dan menggunakan apps Domus Cordis.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      “Aplikasi Domus Cordis” berarti aplikasi ponsel (mobile apps) yang dioperasikan oleh komunitas Domus Cordis.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      “Data Pribadi” adalah setiap data tentang seseorang baik yang teridentifikasi dan/atau dapat diidentifikasi secara tersendiri atau dikombinasi
      dengan informasi lainnya baik secara langsung maupun tidak langsung melalui sistem elektronik dan/atau non-elektronik.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      “Situs” berarti segala URL yang menggunakan domain dengan alamat “domuscordis.com” atau situs lain yang dinyatakan oleh Domus Cordis dari waktu
      ke waktu.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      “Basic Data” adalah Data Pribadi yang dibutuhkan oleh Apps Domus Cordis untuk dapat menerbitkan UserID dan membuat Akun Domus Cordis.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-bold mb-2">2. Penggunaan Aplikasi Domus Cordis</p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Hanya Cordisian yang dapat menggunakan Aplikasi Domus Cordis. Cordisian tidak boleh mengalihkan Akun pribadi kepada pihak manapun.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Cordisian setuju bahwa data pribadi tidak hanya diketahui oleh Management Domus Cordis yang mana Cordisian berhubungan, tetapi juga dapat
      diketahui oleh SysAdmin dan Struktur resmi Domus Cordis.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Dalam menggunakan Akun Domus Cordis dan Layanan aplikasi, Cordisian setuju untuk mematuhi seluruh ketentuan hukum dan peraturan
      perundang-undangan dalam yurisdiksi yang berlaku dimana Akun Domus Cordis atau Layanan aplikasi diakses atau digunakan.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-bold mb-2">3. Batas Penggunaan Layanan</p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Cordisian setuju untuk tidak menggunakan Layanan aplikasi Domus Cordis untuk melakukan transaksi atau perbuatan yang: (i) melanggar hukum atau
      ketentuan peraturan perundang-undangan; (ii) melanggar hak Domus Cordis atau Pihak Ketiga, termasuk namun tidak terbatas pada hak privasi, hak
      cipta, merek, paten, rahasia dagang, atau hak kekayaan intelektual lainnya, serta hak kontraktual atau hak-hak lain yang dilindungi berdasarkan
      ketentuan peraturan perundang-undangan yang berlaku; (iii) dengan sengaja dan tanpa hak atau melawan hukum dengan cara apapun mengubah,
      menambah, mengurangi, melakukan transmisi, merusak, menghilangkan, memindahkan, menyembunyikan suatu Informasi Elektronik sehubungan dengan
      Layanan aplikasi Domus Cordis; (iv) dapat merusak atau mengganggu reputasi Domus Cordis.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-bold mb-2">4. Cordisian menyatakan dan menjamin bahwa:</p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Cordisian telah membaca dan memahami seluruh isi Ketentuan Penggunaan ini, dan karenanya secara sukarela setuju untuk tunduk pada dan
      melaksanakan seluruh syarat dan ketentuan dalam Ketentuan Penggunaan ini.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Cordisian dengan ini menyatakan dan menjamin penggunaan aplikasi Domus Cordis hanya digunakan sesuai yang telah ditentukan dalam Ketentuan
      Penggunaan ini dengan itikad baik dan penuh kehati-hatian serta untuk tujuan yang sah dan tidak melanggar ketentuan hukum yang berlaku di Negara
      Republik Indonesia.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-bold mb-2">6. Keamanan dan Kerahasiaan</p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Domus Cordis melakukan upaya-upaya pengamanan dan penyimpanan dengan penuh kehati-hatian guna melindungi kerahasiaan Data Pribadi Cordisian dari
      waktu ke waktu.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Setiap konten yang diunggah dan dikirim oleh Cordisian ke Layanan aplikasi Domus Cordis akan tersimpan secara aman dan terkirim secara rahasia
      dengan menggunakan standar pengamanan informasi elektronik industri.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Domus Cordis menjaga akses tehadap Dokumen Elektronik yang Cordisian unggah melalui Akun Domus Cordis ke Layanan aplikasi Domus Cordis agar
      hanya Cordisian dan pihak Management DC yang dapat mengakses dan/atau melihat Dokumen Elektronik tersebut.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-bold mb-2">7. Kontak dan Pemberitahuan</p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Setiap pemberitahuan dari Domus Cordis yang ditujukan kepada Cordisian akan diumumkan melalui Situs dan dikirimkan melalui push notification
      melalui Aplikasi Domus Cordis yang terpasang pada gawai Cordisian yang terdaftar di Apps Domus Cordis.
    </p>
    <p class="text-left text-xs md:text-sm text-gray-700 font-medium mb-2">
      Setiap pemberitahuan dari Cordisian yang ditujukan kepada Domus Cordis menjadi efektif ketika pemberitahuan tersebut diterima oleh Domus Cordis
      melalui alamat surat elektronik (e-mail address) info@domuscordis.com dan/atau melalui dokumen fisik yang dikirimkan ke Komunitas Domus Cordis
      dengan alamat Wisma Gatot Soebroto, Lt. LG 01, Jl. Jend. Gatot Soebroto Kav. 22, Jakarta Selatan 12930, Indonesia.
    </p>
  </div>
  {#each requirement as item, index}
    <div class="flex items-start mb-4 ml-3">
      <input id="default-checkbox" type="checkbox" checked={item.isCheck} on:click={() => requirementChange(index)} />
      <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-700">{item.text}</label>
    </div>
  {/each}
  <div class="flex flex-col items-center justify-center space-x-3">
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => {
          errConsentForm = false;
          modalAccept.toggle();
        }}
        class="flex-1 px-3 py-2 text-white bg-primary disabled:bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
        {disabled}
      >
        Accept
      </button>
    </div>
  </div>
</Modal>

<Loading bind:this={loading} />
<div class="overflow-y-auto flex flex-col w-full items-center">
  <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 mb-6 md:text-2xl">Register</h1>
  <div class="w-full bg-white rounded-lg shadow border border-gray-100 md:mt-0 sm:max-w-md xl:p-0">
    <div class="p-6 sm:p-8">
      <div class="space-y-4 md:space-y-6">
        <Input name="fullName" type="text" bind:value={$fullName.value} placeholder="Masukkan nama lengkap" bind:errors label="Nama Lengkap" />

        <Input name="emails" type="email" bind:value={$emails.value} placeholder="Masukkan email yang valid" bind:errors label="Email" />

        <Input name="phone" type="phone" bind:value={$phone.value} placeholder="Masukkan nomor handphone" bind:errors label="No. Handphone" minLength="9" />

        <Input name="password" type="password" bind:value={$password.value} placeholder="••••••••" bind:errors label="Password" withEye />

        <Input
          name="confirmPassword"
          type="password"
          bind:value={$confirmPassword.value}
          placeholder="••••••••"
          bind:errors
          label="Confirm Password"
          withEye
        />
      </div>

      <button
        on:click={modalAccept.toggle()}
        class="underline text-xs font-semibold {errConsentForm ? 'text-red-500' : 'mb-4 text-gray-700'} mt-4 text-center w-full"
        >Persetujuan untuk Consent Form</button
      >

      {#if errConsentForm}
        <p class="text-xs text-red-500 text-center italic mt-0 mb-4 p-0">Isi Consent Form terlebih dahulu</p>
      {/if}

      <button
        on:click={register}
        class="w-full text-white mb-4 bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center transition-all"
        >Daftar</button
      >
      <p class="text-sm font-light text-center text-gray-500 dark:text-gray-400">
        Sudah Punya Akun? <a use:link href="/login" class="font-medium text-gray-600 hover:underline">Login</a>
      </p>
    </div>
  </div>
</div>
