<script>
  import { createEventDispatcher } from "svelte";
  import { checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import { EDIT_USER_MODULE, RESET_PASSWORD_MODULE, SEND_EMAIL_VERIFICATION } from "../../utils/const";
  const dispatch = createEventDispatcher();

  export let data;
  export let page = 1;
</script>

<table class="min-w-full divide-y divide-gray-200">
  <thead class="bg-gray-100">
    <tr>
      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500"> No. </th>

      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500"> Nama </th>

      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500"> Email </th>

      <!-- <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500"> Role </th> -->

      <!-- <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500"> Supervisor </th> -->

      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500"> Departemen </th>

      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500"> Status </th>
      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500"> Aksi </th>
    </tr>
  </thead>
  <tbody class="bg-white divide-y divide-gray-200">
    {#if data.length == 0}
      <tr>
        <td colspan="7" class="px-4 py-4 text-sm font-medium">
          <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
        </td>
      </tr>
    {/if}
    {#each data as user, index}
      <tr>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {page > 1 ? ((index + 1).toString().length > 1 ? index + 1 + 10 * (page - 1) : (page - 1).toString() + (index + 1)) : index + 1}
          </h2>
        </td>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {user["name"]}
          </h2>
        </td>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {user["email"]}
          </h2>
        </td>
        <!-- <td class="px-12 py-2 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {user["roleName"]}
          </h2>
        </td> -->
        <!-- <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {user["supervisorName"] == "" ? "-" : user["supervisorName"]}
          </h2>
        </td> -->
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {user["department"] == "" ? "-" : user["department"]}
          </h2>
        </td>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <div
            class="inline px-3 py-1 text-xs font-normal rounded-full {user['isActive'] == 1
              ? 'text-emerald-500 bg-emerald-100/60'
              : 'text-red-500 bg-red-100/60'}  gap-x-2"
          >
            {user["isActive"] == 1 ? "Aktif" : "Tidak Aktif"}
          </div>
        </td>
        <!-- <td class="px-4 py-4 text-sm whitespace-nowrap">
              <div>
                <h4 class="text-gray-700">Content curating app</h4>
                <p class="text-gray-500">
                  Brings all your news into one place
                </p>
              </div>
            </td> -->

        <td class="px-4 py-4 text-sm whitespace-nowrap">
          <button
            on:click={() => dispatch("detail", user["id"])}
            class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
          >
            <i class="bx bx-show text-xl" />
          </button>
          {#if checkRoleModule($roleModules, EDIT_USER_MODULE)}
            <button
              on:click={() => dispatch("edit", user["id"])}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-pencil text-xl" />
            </button>
          {/if}
          {#if checkRoleModule($roleModules, RESET_PASSWORD_MODULE)}
            <button
              on:click={() => dispatch("reset", { id: user["id"], email: user["email"] })}
              data-te-toggle="tooltip"
              title="Ganti Password"
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bxs-key text-xl" />
            </button>
          {/if}
          {#if checkRoleModule($roleModules, SEND_EMAIL_VERIFICATION)}
            <button
              on:click={() => dispatch("email-verification", { email: user["email"] })}
              data-te-toggle="tooltip"
              title="Kirim Email Verifikasi"
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-select-multiple text-xl" />
            </button>
          {/if}
        </td>
      </tr>
    {/each}
  </tbody>
</table>
