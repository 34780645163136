<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let data;
  let isAcceptClicked = false;
  let isDismissClicked = false;
</script>

<div class="card-data min-w-full mb-3 p-2 border border-primary shadow rounded-md">
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <div class="flex-1 text-left">
      <p class="flex-1 text-left font-bold">{data["userName"]}</p>
      <p class="flex-1 text-left">{data["roleName"]}</p>
    </div>
    <!-- <p class="flex-1 text-left">{data["userName"]}</p> -->
    <p class="flex-1 line-clamp-2 text-right">
      <button
        id={"accept" + data["id"]}
        on:click={(event) => {
          isAcceptClicked = true;
          isDismissClicked = false;
          dispatch("accept", data["id"]);
        }}
        disabled={isAcceptClicked}
        class="p-1 text-white bg-green-400 text-base font-medium transition-colors duration-200 rounded-lg w-12 disabled:bg-gray-500"
      >
        <i class="bx bx-check" />
      </button>
      <button
        id={"dismiss" + data["id"]}
        on:click={(event) => {
          isDismissClicked = true;
          isAcceptClicked = false;
          dispatch("dismiss", data["id"]);
        }}
        disabled={isDismissClicked}
        class="p-1 text-white bg-red-400 text-base font-medium transition-colors duration-200 rounded-lg w-12 break-words disabled:bg-gray-500"
      >
        <i class="bx bx-x" />
      </button>
    </p>
  </div>
</div>
