<script>
  import { createEventDispatcher } from "svelte";
  import { checkRoleModule, formatDateForView, getStatusAnswerUser, getStatusLearning } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import { learningStatusObject } from "../../pages/Learning/LearningConst";
  import { DETAIL_LEARNING_MODULE, EDIT_LEARNING_MODULE, EDIT_ONE_MODULE, EXPORT_LEARNING, LEARNING_MEMBER, LESSON } from "../../utils/const";
  const dispatch = createEventDispatcher();
  import { parseJwt } from "../../utils/utils";

  export let data;

  const token = parseJwt(localStorage.getItem("token"));
</script>

<table class="min-w-full divide-y divide-gray-200">
  <thead class="bg-gray-100">
    <tr>
      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left text-gray-500"> Nama Materi </th>
      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Kategori Sel </th>
      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Sel Group </th>
      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Tanggal Published </th>
      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Tanggal Deadline </th>
      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Status </th>
      {#if token.RoleID == 4}
        <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Status Submission </th>
      {/if}
      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500"> Aksi </th>
    </tr>
  </thead>
  <tbody class="bg-white divide-y divide-gray-200">
    {#if data.length == 0}
      <tr>
        <td colspan="8" class="px-4 py-4 text-sm font-medium">
          <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
        </td>
      </tr>
    {/if}
    {#each data as item, index}
      <tr>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["title"]}
          </h2>
        </td>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["covenantYearName"]}
          </h2>
        </td>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["cellGroupName"]}
          </h2>
        </td>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {formatDateForView(new Date(item["publishDate"]))}
          </h2>
        </td>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {formatDateForView(new Date(item["overdueDate"]))}
          </h2>
        </td>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <div
            class="inline px-3 py-1 text-xs font-normal rounded-full {item['status'] == 1
              ? 'text-emerald-500 bg-emerald-100/60'
              : item['status'] == 2
              ? 'text-cyan-500 bg-cyan-100/60'
              : 'text-red-500 bg-red-100/60'} gap-x-2"
          >
            {getStatusLearning(item["status"])}
          </div>
        </td>
        {#if token.RoleID == 4}
          <td class="px-4 text-sm font-medium whitespace-nowrap">
            <div
              class="inline px-3 py-1 text-xs font-normal rounded-full {item['statusAnswerUser'] == 1
                ? 'text-emerald-500 bg-emerald-100/60'
                : item['statusAnswerUser'] == 0
                ? 'text-gray-500 bg-gray-100/50'
                : item['statusAnswerUser'] == 3
                ? 'text-blue-500 bg-blue-100/50'
                : 'text-red-500 bg-red-100/60'} gap-x-2"
            >
              {getStatusAnswerUser(item["statusAnswerUser"])}
            </div>
          </td>
        {/if}

        <td class="px-4 py-4 text-sm whitespace-nowrap">
          {#if checkRoleModule($roleModules, DETAIL_LEARNING_MODULE)}
            <button
              on:click={() => dispatch("detail", { id: item["id"], type: item["type"] })}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-show text-xl" />
            </button>
          {/if}
          {#if checkRoleModule($roleModules, EDIT_LEARNING_MODULE) && item["status"] == 2}
            <button
              on:click={() => dispatch("edit", { id: item["id"], type: item["type"] })}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-pencil text-xl" />
            </button>
          {/if}
          {#if checkRoleModule($roleModules, LEARNING_MEMBER)}
            <button
              on:click={() => dispatch("member", { id: item["id"] })}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-file text-xl" />
            </button>
          {/if}
          {#if checkRoleModule($roleModules, LESSON) && token.RoleID == 4 && item["status"] != learningStatusObject.Upcoming}
            <button
              on:click={() => dispatch("lesson", { id: item["id"] })}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-book-add text-xl" />
            </button>
          {/if}
          {#if checkRoleModule($roleModules, EXPORT_LEARNING) && item["status"] != learningStatusObject.Upcoming}
            <button
              on:click={() => dispatch("export", { id: item["id"] })}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-export text-xl" />
            </button>
          {/if}
        </td>
      </tr>
    {/each}
  </tbody>
</table>
