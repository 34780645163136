<script>
  import { field, form } from "svelte-forms";
  import { required } from "svelte-forms/validators";
  import { replace } from "svelte-spa-router";
  import CellGroupForm from "./CellGroupForm.svelte";
  import Cookies from "js-cookie";
  import { EDIT_CELLGROUP_MODULE } from "../../utils/const";
  import { parseJwt, formatDate, checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import { onMount } from "svelte";
  import Modal from "../../components/Modal.svelte";
  import Loading from "../../components/Loading.svelte";
  import CellGroupServices from "./CellGroupServices";
  import AuthServices from "../Auth/AuthServices";

  export let params;

  let id = params["_id"];

  const token = parseJwt(localStorage.getItem("token"));
  let access = token.RoleID;

  let cellGroupMemberList = [];
  let cellGroupMemberListBefore = [];
  let cellGroupMemberListTemp = [];
  let covenantYearSelected = field("covenantYearSelected", "", [required()]);
  let dateCreated = field("dateCreated", new Date(), [required()]);
  let gembalaSelected;
  let pGembalaSelected;
  let headGembalaSelected;
  let name = field("name", "", [required()]);
  let wakilGembalaSelected;
  let pWakilGembalaSelected;
  let status = true;
  let wakilGembalaError = false;
  let pWakilGembalaError = false;
  let initialMembers = [];
  let memberError = false;

  let loading;
  let disableSubmit = false;
  let errors = [];
  let modalBack;
  let editCellGroupForm;

  let cellgroup;

  onMount(() => {
    if (!checkRoleModule($roleModules, EDIT_CELLGROUP_MODULE)) {
      replace("/cellgroup/");
      return;
    }
    loadData();
  });

  async function loadData() {
    const data = await CellGroupServices.getCellGroupByID(id);

    if (data["data"]) {
      cellgroup = data["data"];
      status = data["data"]["isActive"];
      $dateCreated.value = new Date(data["data"]["dateCreated"]);
      $covenantYearSelected.value = String(data["data"]["covenantYearID"]);
      gembalaSelected = cellgroup.gembalaID;
      pGembalaSelected = cellgroup.pendampingGembalaID;
      headGembalaSelected = cellgroup.headGembalaID;
      $name.value = cellgroup.name;
      wakilGembalaSelected = cellgroup.wakilGembalaID;
      pWakilGembalaSelected = cellgroup.pendampingWakilGembalaID;
      cellGroupMemberList = data["data"]["cellGroupMembers"]
        .filter((item) => item.isActive == true)
        .map((item) => {
          return {
            id: item.userID,
            name: item.userName,
            roleName: "Member",
            status: item.status,
          };
        });
      cellGroupMemberListBefore = [...cellGroupMemberList];
      cellGroupMemberListTemp = [...cellGroupMemberList];
      initialMembers = [...cellGroupMemberList];
    }
  }

  async function editCellGroup() {
    disableSubmit = true;
    errors = [];

    let headGembalaSelectedValid = field("headGembalaSelected", headGembalaSelected ? headGembalaSelected["id"] : "", [required()]);
    let gembalaSelectedValid = field("gembalaSelected", gembalaSelected ? gembalaSelected["id"] : "", [required()]);
    let wakilGembalaSelectedValid = field("wakilGembalaSelected", wakilGembalaSelected ? wakilGembalaSelected["id"] : "", [required()]);

    editCellGroupForm = form(covenantYearSelected, dateCreated, headGembalaSelectedValid, gembalaSelectedValid, wakilGembalaSelectedValid, name);

    await editCellGroupForm.validate();
    if ($editCellGroupForm.valid && !wakilGembalaError && !memberError &&!pWakilGembalaError) {
      let cellGroupMembers = cellGroupMemberList.map(function (row) {
        return { userID: row.id, status: row.status };
      });
      const reqData = {
        ID: id,
        CellGroupMembers: cellGroupMembers,
        CovenantYearID: $covenantYearSelected.value ? $covenantYearSelected.value : 0,
        DateCreated: formatDate($dateCreated.value),
        GembalaID: gembalaSelected ? parseInt(gembalaSelected["id"]) : 0,
        HeadGembalaID: headGembalaSelected ? parseInt(headGembalaSelected["id"]) : 0,
        Name: $name.value,
        PendampingGembalaID: pGembalaSelected ? parseInt(pGembalaSelected["id"]) : 0,
        PendampingWakilGembalaID: pWakilGembalaSelected ? parseInt(pWakilGembalaSelected["id"]) : 0,
        WakilGembalaID: wakilGembalaSelected ? parseInt(wakilGembalaSelected["id"]) : 0,
        IsActive: status,
      };
      const data = await CellGroupServices.editCellGroup(reqData);
      if (data["data"]["id"]) {
        const tokenData = await AuthServices.refreshToken();
        localStorage.setItem("token", tokenData["data"]["token"]);
        localStorage.setItem("tokenRole", tokenData["data"]["tokenRole"]);
        globalThis.pushToast("Berhasil Mengubah Sel Group", "success");
        setTimeout(() => replace("/cellgroup/"), 1000);
      }
    } else {
      errors = $editCellGroupForm.errors;
      errors = errors;
      disableSubmit = false;
    }
  }
</script>

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin ingin keluar dari laman ini? data yang Anda masukkan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/cellgroup/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>

{#if cellgroup}
  <section class="container px-4 py-4">
    <CellGroupForm
      {access}
      formTitle="Edit Sel Group"
      mode="edit"
      bind:cellGroupMemberList
      bind:cellGroupMemberListBefore
      bind:cellGroupMemberListTemp
      bind:concernedID={id}
      bind:covenantYearSelected={$covenantYearSelected.value}
      bind:dateCreated={$dateCreated.value}
      bind:disableSubmit
      bind:gembalaSelected
      bind:pGembalaSelected
      bind:headGembalaSelected
      bind:name={$name.value}
      bind:wakilGembalaSelected
      bind:pWakilSelected={pWakilGembalaSelected}
      bind:initialMembers
      bind:memberError
      bind:wakilGembalaError
      bind:pWakilGembalaError
      bind:status
      bind:errors
      on:back={() => modalBack.toggle()}
      on:save={() => editCellGroup()}
    />
  </section>
{/if}
