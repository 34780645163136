<script>
  import { field } from "svelte-forms";
  import Input from "../../components/control/Input.svelte";
  import { required } from "svelte-forms/validators";
  import Textarea from "../../components/control/Textarea.svelte";
  import Select from "../../components/control/Select.svelte";
  import { replace } from "svelte-spa-router";
  import { urlApi } from "../../utils/const";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import { parseJwt, checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import { onMount } from "svelte";
  import TableParticipant from "../../components/table/TableParticipant.svelte";
  import AttendanceParticipantCard from "../../components/card/AttendanceParticipantCard.svelte";
  import AttendanceCategoryServices from "../../pages/AttendanceCategory/AttendanceCategoryServices";
  import { EDIT_ATTENDANCE_MODULE } from "../../utils/const";
  import { attendanceStatus } from "../../pages/Attendance/AttendanceConst";

  export let params;

  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";
  let date = field("date", new Date(), [required()]);
  let attendanceName = field("attendanceName", "", [required()]);
  let note = field("note", "", []);
  let cancelNote = field("cancelNote", "", []);
  let attendanceCategory = field("attendanceCategory", "", [required()]);
  let id = params["_id"];

  let errors = [];
  let participants = [];
  let participantList = [];
  let participantListID = [];
  let attendanceData = [];
  let status = "";
  let attendanceCategorySelected;
  let attendanceCategoryList;
  let isCategoryNotAvailable;

  const token = parseJwt(localStorage.getItem("token"));
  let access = token.RoleID;

  let isLoading = true;

  onMount(async () => {
    await loadData();
    await loadAttendanceCategory();
  });

  async function loadData() {
    await loadAttendance();
  }
  async function loadAttendance() {
    const res = await fetch(urlApi + "/Attendance/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    if (data["data"]) {
      attendanceData = data["data"];
      status = Number(attendanceData["status"]);
      $attendanceName.value = attendanceData["attendanceName"];
      $note.value = attendanceData["note"];
      $cancelNote.value = attendanceData["cancelNote"];
      $date.value = new Date(attendanceData["date"]);
      $date.value = $date.value;
      attendanceCategorySelected = attendanceData["category"];
      isLoading = false;
      participants = attendanceData["participants"];
      participantListID = participants.map((e) => e["userID"]);
      participantList = participants
        .map((u) => ({
          id: u["userID"],
          name: u["username"],
          roleName: "Member",
        }))
        .filter((e) => participantListID.indexOf(e["id"]) != -1);
    }
  }
  async function loadAttendanceCategory() {
    const option = "perPage=9999" + "&currentPage=1";
    const data = await AttendanceCategoryServices.loadAttendanceCategory(option);
    attendanceCategoryList = data["data"].map((e) => {
      return { id: String(e.id), name: e.name, isDelete: e.isDelete, isActive: e.isActive };
    });
    const attendanceCategory = attendanceCategoryList.find((e) => e.id === attendanceCategorySelected);
    isCategoryNotAvailable = attendanceCategory.isDelete == 1 || attendanceCategory.isActive == 0;
  }
</script>

{#if !isLoading}
  <section class="container px-4 py-4">
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-x-3">
        <button on:click={() => replace("/attendances/")}>
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-md md:text-lg font-medium text-gray-800">Detail Attendance</h2>
      </div>
      {#if checkRoleModule($roleModules, EDIT_ATTENDANCE_MODULE) && status != attendanceStatus.Done && status != attendanceStatus.Batal}
        <div class="flex items-center justify-end">
          <button
            on:click={() => replace("/attendances/" + id + "/edit")}
            class="text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 first-letter:text-center transition-all"
            >Edit
          </button>
        </div>
      {/if}
    </div>

    <div class="mt-5 flex flex-col space-y-4">
      <div class={mobileClass}>
        <div class="flex-1">
          <Input disabled="true" name="attendanceName" type="text" label="Nama Attendance" bind:errors bind:value={$attendanceName.value} />
        </div>
        <div class="flex-1">
          <DatePicker
            disabled="true"
            name="date"
            isTime
            type="text"
            label="Tanggal-Waktu"
            labelSize="sm"
            textSize="sm"
            extClass="font-semibold"
            bind:errors
            bind:value={$date.value}
          />
        </div>
      </div>
      <div class={mobileClass}>
        <div class="flex-1">
          <Select
            fullWidth
            textHint="Pilih Kategori Attendance"
            name="attendanceCategorySelected"
            extClass="font-semibold {isCategoryNotAvailable && status != attendanceStatus.Aktif
              ? 'bg-red-100 border-red-500 focus:border-red-500'
              : ''}"
            label="Kategori Attendance"
            textSize="text-sm"
            options={attendanceCategoryList}
            bind:errors
            bind:selected={attendanceCategorySelected}
            disabled={true}
          />
          {#if isCategoryNotAvailable && status != attendanceStatus.Aktif}
            <p class="text-xs text-red-500 p-0 mt-1">Kategori Attendance tidak aktif atau sudah dihapus</p>
          {/if}
        </div>
      </div>
      <div class="flex flex-col space-y-4">
        <div class={mobileClass}>
          <div class="flex-1">
            <Textarea disabled="true" bind:errors name="note" label="Catatan" placeholder="Catatan" bind:value={$note.value} />
          </div>
          <div class="flex-1">
            <h3 class="mb-2 md:mt-5">Survey Attendance : {Math.round(attendanceData["surveyAttendance"])}%</h3>
            <div class="w-full bg-gray-200 rounded-full h-2.5">
              <div class="bg-blue-600 h-2.5 rounded-full" style="width:{Math.round(attendanceData['surveyAttendance'])}%" />
            </div>
          </div>
        </div>
      </div>
      {#if status == attendanceStatus.Batal}
        <div class={mobileClass}>
          <div class="flex-1">
            <Textarea
              disabled="true"
              name="cancelNote"
              label="Alasan Pembatalan Attendance"
              placeholder="Alasan Pembatalan Attendance"
              extClass=" resize-none"
              bind:errors
              bind:value={$cancelNote.value}
            />
          </div>
          <div class="flex-1" />
        </div>
      {/if}

      <div class="mt-4" />
      <div class="flex w-full items-center justify-between space-x-3">
        <h2 class="text-md md:text-lg font-medium text-gray-800 w-full">Daftar Participants</h2>
        {#if access != 5}
          <button
            on:click={() => replace("/attendances/" + id + "/attendance")}
            class="flex items-center justify-center px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-lg
              shrink-0 sm:w-auto space-x-2 hover:opacity-80"
          >
            <i class="bx bx-menu" />
            <span>ATTENDANCE</span>
          </button>
        {/if}
      </div>
      <div class="overflow-x-auto hidden md:block">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="overflow-hidden rounded-md">
            <TableParticipant bind:data={participantList} mode="detail" />
          </div>
        </div>
      </div>
      <div class="overflow-x-auto block md:hidden">
        {#each participantList as item, index}
          <AttendanceParticipantCard bind:data={item} {index} mode="detail" />
        {/each}
      </div>
    </div>
  </section>
{/if}
