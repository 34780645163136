<script>
  import { createEventDispatcher } from "svelte";
  import { checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import { formatDateTimeShortVersion, parseJwt } from "../../utils/utils";
  import { EDIT_ATTENDANCE_MODULE, SURVEY_ATTENDANCE_MODULE, ATTENDANCE_APPROVAL_MODULE, DETAIL_ATTENDANCE_MODULE } from "../../utils/const";
  import { attendanceStatus } from "../../pages/Attendance/AttendanceConst";
  const dispatch = createEventDispatcher();
  export let data;
  export let page = 1;
  const token = parseJwt(localStorage.getItem("token"));
</script>

<table class="min-w-full divide-y divide-gray-200">
  <thead class="bg-gray-100">
    <tr>
      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left text-gray-500"> #. </th>

      <th scope="col" class="py-3.5 px-12 text-sm font-normal text-left text-gray-500"> Nama</th>

      <th scope="col" class="py-3.5 px-12 text-sm font-normal text-left text-gray-500">Nama Pembuat</th>

      <th scope="col" class="py-3.5 px-12 text-sm font-normal text-left text-gray-500">Completed By</th>

      <th scope="col" class="py-3.5 px-12 text-sm font-normal text-left text-gray-500">Kategori</th>

      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Waktu </th>

      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Survey Attendance </th>

      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left text-gray-500"> Status</th>

      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500"> Action </th>
    </tr>
  </thead>
  <tbody class="bg-white divide-y divide-gray-200">
    {#if data.length == 0}
      <tr>
        <td colspan="6" class="px-4 py-4 text-sm font-medium">
          <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
        </td>
      </tr>
    {/if}
    {#each data as item, index}
      <tr class="md:h-[70px]">
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {page > 1 ? ((index + 1).toString().length > 1 ? index + 1 + 10 * (page - 1) : (page - 1).toString() + (index + 1)) : index + 1}
          </h2>
        </td>
        <td class="px-12 py-2 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["attendanceName"]}
          </h2>
        </td>
        <td class="px-12 py-2 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["createdBy"]}
          </h2>
        </td>
        <td class="px-12 py-2 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["actionBy"] ?? "-"}
          </h2>
        </td>
        <td class="px-12 py-2 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["categoryName"]}
          </h2>
        </td>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {formatDateTimeShortVersion(new Date(item["date"]))}
          </h2>
        </td>
        <td class="px-12 py-2 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {Math.round(item["surveyAttendance"])} %
          </h2>
        </td>
        <td class="px-4 py-2 text-sm font-medium whitespace-nowrap">
          <div
            class="inline px-3 py-1 text-xs font-normal rounded-full {item['status'] == 1
              ? 'text-emerald-500 bg-emerald-100/60'
              : item['status'] == 2
              ? 'text-cyan-500 bg-cyan-100/60'
              : 'text-red-500 bg-red-100/60'}  gap-x-2"
          >
            {item["status"] == 1 ? "Aktif" : item["status"] == 2 ? "Done" : "Batal"}
          </div>
        </td>

        <td class="px-4 py-4 text-sm whitespace-nowrap">
          {#if checkRoleModule($roleModules, DETAIL_ATTENDANCE_MODULE)}
            <button
              on:click={() => dispatch("detail", { id: item["id"] })}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-show text-xl" />
            </button>
          {/if}
          {#if checkRoleModule($roleModules, EDIT_ATTENDANCE_MODULE) && item["status"] == attendanceStatus.Aktif}
            <button
              on:click={() => dispatch("edit", { id: item["id"] })}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-pencil text-xl" />
            </button>
          {/if}
          {#if checkRoleModule($roleModules, ATTENDANCE_APPROVAL_MODULE) && item["status"] == attendanceStatus.Aktif}
            {#if ((token.RoleID == 3 || token.RoleID == 13) && item["roleID"] == token.RoleID && item["userID"] == token.UserID) || (token.RoleID != 3 && token.RoleID != 13)}
              <button
                on:click={() => dispatch("approve", { data: item })}
                class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
              >
                <i class="bx bx-list-check text-xl" />
              </button>
            {/if}
          {/if}
          {#if checkRoleModule($roleModules, SURVEY_ATTENDANCE_MODULE) && item["status"] == attendanceStatus.Aktif}
            <button
              disabled={item["status"] == 2 || item["status"] == 0}
              on:click={() => dispatch("attend", { data: item })}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100 disabled:opacity-50"
            >
              <i class="bx bx-copy text-xl" />
            </button>
          {/if}
        </td>
      </tr>
    {/each}
  </tbody>
</table>
