<script>
  import Input from "../../components/control/Input.svelte";
  import Textarea from "../../components/control/Textarea.svelte";
  import Switch from "../../components/control/Switch.svelte";
  import { pop, replace } from "svelte-spa-router";
  import { createEventDispatcher } from "svelte";
  import { EDIT_ATTENDANCE_CATEGORY_MODULE } from "../../utils/const";
  import { checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";

  const dispatch = createEventDispatcher();
  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";

  export let concernedID = ""; //currently viewed or edited ID
  export let disableSubmit;
  export let disableDeleteButton;
  export let errors = [];
  export let formTitle;
  export let name;
  export let mode;
  export let note;
  export let status;

  function checkBack() {
    let result = false;
    if (name != "" || note != "") {
      result = true;
    }
    return result;
  }
</script>

<div>
  <div class="sm:flex sm:items-center sm:justify-between">
    <div class="w-full flex items-center justify-between">
      <div class="flex-1 flex items-center gap-x-3">
        <button
          on:click={() => {
            if (checkBack() && mode != "detail") {
              dispatch("back");
            } else {
              pop();
            }
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-lg font-medium text-gray-800">{formTitle}</h2>
      </div>
      {#if checkRoleModule($roleModules, EDIT_ATTENDANCE_CATEGORY_MODULE) && mode == "detail"}
        <div class="flex items-center justify-end">
          <button
            on:click={() => replace("/attendancecategory/" + concernedID + "/edit")}
            class="text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 md:mt-4 text-center transition-all"
            >Edit
          </button>
        </div>
      {/if}
    </div>
  </div>

  <div class="mt-5 flex flex-col space-y-4">
    <!-- Nama Kategori Attendance -->
    <div class={mobileClass}>
      <div class="flex-1">
        <Input
          disabled={mode == "detail"}
          name="name"
          type="text"
          label="Kategori Attendance"
          placeholder="Ketik Kategori Attendance"
          bind:errors
          bind:value={name}
        />
      </div>
    </div>

    <!-- Catatan -->
    <div class="flex space-x-2">
      <div class="flex-1">
        <Textarea disabled={mode == "detail"} bind:errors name="note" label="Catatan" maxlength="3000" bind:value={note} />
      </div>
    </div>

    <!-- Status -->
    <div class={mobileClass}>
      <div class="flex-1">
        <label for="role" class="block mb-2 text-sm font-semibold text-gray-900">Status</label>
        <Switch
          on:change={(e) => {
            if (e.detail.checked == false && mode == "edit") {
              status = true;
              dispatch("deactivate");
            }
          }}
          bind:value={status}
          bind:checked={status}
          name="status"
          disabled={mode == "detail"}
          textRight={status ? "Aktif" : "Tidak Aktif"}
        />
      </div>
    </div>

    <!-- Button Cancel dan Simpan -->
    {#if mode != "detail"}
      <div class="flex flex-wrap md:flex-nowrap space-x-0 space-y-4 md:space-y-0 md:space-x-4">
        <!-- Attendance Category with id 1 and 2 must not be deleted (Pertemuan Sel and Community Gathering) -->
        {#if mode == "edit" && concernedID != 1 && concernedID != 2} 
          <div class="w-full md:w-1/3">
            <button
              on:click={() => {
                dispatch("delete");
              }}
              disabled={disableDeleteButton}
              class="w-full text-white bg-red-400 hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
              >Hapus Kategori Attendance</button
            >
          </div>
        {/if}
        <div class="w-full {mode == 'edit' && concernedID != 1 && concernedID != 2 ? 'md:w-2/3' : ''} flex space-x-2">
          <div class="w-1/2">
            <button
              on:click={() => {
                if (checkBack() && mode != "detail") {
                  dispatch("back");
                } else {
                  pop();
                }
              }}
              class="w-full text-white bg-gray-400 hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
              >Batal</button
            >
          </div>
          <div class="w-1/2">
            <button
              on:click={() => {
                dispatch("save");
              }}
              disabled={disableSubmit}
              class="w-full text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
              >Simpan</button
            >
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>
