<script>
  import { onMount } from "svelte";
  import { field, form } from "svelte-forms";
  import Input from "../../components/control/Input.svelte";
  import Loading from "../../components/Loading.svelte";
  import { required, min } from "svelte-forms/validators";
  import Textarea from "../../components/control/Textarea.svelte";
  import Select from "../../components/control/Select.svelte";
  import { pop, replace } from "svelte-spa-router";
  import Cookies from "js-cookie";
  import { paymentOptionData, urlApi } from "../../utils/const";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import { formatDate, formatNumber, parseJwt, checkEmptyInput } from "../../utils/utils";
  import Modal from "../../components/Modal.svelte";

  export let params;

  const token = parseJwt(localStorage.getItem("token"));
  let type = params["type"];
  let isChrome = true;
  let date = field("date", new Date(), [required()]);

  let place = field("place", "", []);
  let prayTime = field("prayTime", "", []);
  let prayNote = field("prayNote", "", []);
  let total = field("total", "", []);
  let payType = field("payType", "", []);
  let reader = field("reader", "", []);
  let devotional = field("devotional", "", []);
  let note = field("note", "", []);

  if (type == "Doa") {
    prayTime = field("prayTime", "", [required(), min(1)]);
    prayNote = field("prayNote", "", [required()]);
  } else if (type == "Sakramen Tobat" || type == "Misa Harian") {
    place = field("place", "", [required()]);
    prayNote = field("prayNote", "", [required()]);
  } else if (type == "Persembahan Kasih") {
    total = field("total", "", []);
    payType = field("payType", "", [required()]);
    note = field("note", "", [required()]);
  } else if (type == "Pembacaan Alkitab") {
    reader = field("reader", "", [required()]);
    devotional = field("devotional", "", [required()]);
  } else if (type == "Testimonial") {
    note = field("note", "", [required()]);
  }

  let loading;
  let disableSubmit;
  let bibleOption = [];

  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";

  let errors = [];

  let journalForm = form(date, place, prayTime, prayNote, reader, devotional, total, payType, note);
  onMount(() => {
    checkUsedBrowser();
  });
  function checkUsedBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf("OPR")) != -1) {
      console.log("Opera");
    } else if (navigator.userAgent.indexOf("Edg") != -1) {
      console.log("Edge");
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      console.log("chrome");
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      console.log("Safari");
      isChrome = false;
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      isChrome = false;
    } else if (navigator.userAgent.indexOf("MSIE") != -1 || !!document.documentMode == true) {
      //IF IE > 10
      console.log("IE");
    } else {
      console.log("unknown");
    }
  }

  async function addJournal() {
    disableSubmit = true;
    loading.toggle();
    errors = [];
    await journalForm.validate();
    if ($journalForm.valid) {
      const reqData = {
        Date: formatDate($date.value),
        Place: $place.value,
        PrayTime: $prayTime.value,
        PrayNote: $prayNote.value,
        Reader: $reader.value,
        Devotional: $devotional.value,
        Total: $total.value == "Rp" || $total.value == "" ? "0" : formatNumber($total.value).toString(),
        PaymentType: $payType.value == "" ? "CASH" : $payType.value == "1" ? "CASH" : "TRANSFER",
        Note: $note.value,
        Type: type,
        UserID: token.UserID,
        RoleID: parseInt(token.RoleID),
      };
      const res = await fetch(urlApi + "/Journals", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(reqData),
      });
      const data = await res.json();
      if (data["data"]) {
        globalThis.pushToast("Berhasil Menambahkan Journal", "success");
        setTimeout(() => replace("/journals/" + data["data"]["id"] + "/" + data["data"]["type"] + "/detail"), 1000);
      }
    } else {
      errors = $journalForm.errors;
      errors = errors;
      disableSubmit = false;
    }
    loading.toggle();
  }
  function checkBack() {
    let result = false;

    if (type == "Doa") {
      console.log(checkEmptyInput([$prayTime.value, $prayNote.value]));
      return checkEmptyInput([$prayTime.value, $prayNote.value]);
    }

    if (type == "Sakramen Tobat" || type == "Misa Harian") {
      return checkEmptyInput([$place.value, $prayNote.value]);
    }

    if (type == "Persembahan Kasih") {
      return checkEmptyInput([$total.value, $payType.value]);
    }

    if (type == "Pembacaan Alkitab") {
      return checkEmptyInput([$reader.value, $devotional.value]);
    }

    return result;
  }

  let modalBack;
</script>

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin ingin keluar dari laman ini? data yang Anda masukkan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/journals/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>
<Loading bind:this={loading} />

<section class="container px-4 py-4">
  <div class="sm:flex sm:items-center sm:justify-between">
    <div>
      <div class="flex items-center gap-x-3">
        <button
          on:click={() => {
            if (checkBack()) {
              modalBack.toggle();
            } else {
              pop();
            }
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-sm md:text-lg font-medium text-gray-800">
          Tambah Journal - {type}
        </h2>
      </div>
    </div>
  </div>

  <div class="mt-5 flex flex-col space-y-4">
    <div class={mobileClass}>
      <div class="flex-1">
        <DatePicker
          name="date"
          type="text"
          label="Tanggal"
          labelSize="sm"
          textSize="sm"
          extClass="font-semibold"
          bind:errors
          bind:value={$date.value}
        />
      </div>
    </div>

    {#if type == "Doa"}
      <div class={mobileClass}>
        <div class="flex-1">
          <Input
            name="prayTime"
            type="number"
            label="Waktu {type == 'Doa' ? 'Berdoa' : type} (menit)"
            suffix
            suffixText="menit"
            bind:errors
            bind:value={$prayTime.value}
          />
        </div>
      </div>

      <div class="flex space-x-2">
        <div class="flex-1">
          <Textarea bind:errors name="prayNote" label="Catatan {type}" maxlength="3000" bind:value={$prayNote.value} />
        </div>
      </div>
    {/if}

    {#if type == "Sakramen Tobat" || type == "Misa Harian"}
      <div class={mobileClass}>
        <div class="flex-1">
          <Input name="place" type="text" label="Tempat/Gereja" bind:errors bind:value={$place.value} />
        </div>
      </div>

      <div class="flex space-x-2">
        <div class="flex-1">
          <Textarea bind:errors name="prayNote" label="Catatan {type}" maxlength="3000" bind:value={$prayNote.value} />
        </div>
      </div>
    {/if}

    {#if type == "Pembacaan Alkitab"}
      <div class="flex space-x-2">
        <div class="flex-1">
          <Input name="reader" type="text" label="Bacaan" bind:errors bind:value={$reader.value} />
        </div>
      </div>

      <div class="flex space-x-2">
        <div class="flex-1">
          <Textarea bind:errors name="devotional" label="Renungan" maxlength="3000" bind:value={$devotional.value} />
        </div>
      </div>
    {/if}

    {#if type == "Persembahan Kasih"}
      <div class={mobileClass}>
        <div class="flex-1">
          <Input {isChrome} name="total" type={!isChrome ? "text" : "money"} label="Total Persembahan" bind:errors bind:value={$total.value} />
        </div>

        <div class="flex-1">
          <Select
            on:change={(e) => {
              $payType.value = e.detail;
            }}
            textHint="Pilih Cara Pemberian"
            fullWidth
            name="payType"
            extClass="font-semibold"
            label="Cara Pemberian"
            textSize="text-sm"
            options={paymentOptionData}
            bind:errors
            bind:selected={$payType.value}
          />
        </div>
      </div>

      <div class="flex space-x-2">
        <div class="flex-1">
          <Textarea bind:errors name="note" label="Catatan" maxlength="3000" bind:value={$note.value} />
        </div>
      </div>
    {/if}

    {#if type == "Testimonial"}
      <div class="flex space-x-2">
        <div class="flex-1">
          <Textarea bind:errors name="note" label="Testimonial" placeholder="Tulis Testimonial" maxlength="3000" bind:value={$note.value} />
        </div>
      </div>
    {/if}

    <div class="mt-4" />

    <div class="flex w-full space-x-4">
      <button
        on:click={() => {
          if (checkBack()) {
            modalBack.toggle();
          } else {
            pop();
          }
        }}
        class="w-full text-white bg-gray-400 hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
        >Batal</button
      >
      <button
        on:click={addJournal}
        disabled={disableSubmit}
        class="w-full text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
        >Simpan</button
      >
    </div>
  </div>
</section>
