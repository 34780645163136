<script>
  import { inputClass, inputErrClass } from "../../utils/const";
  import Cleave from "cleave.js";
  import { onMount, createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let name;
  export let value;
  export let errors = [];
  export let label;
  export let minLength;
  export let type = "text";
  export let placeholder = "";
  export let withEye = false;
  export let showPass = false;
  export let noLabel = false;
  export let disabled = false;
  export let suffix = false;
  export let suffixText = "";
  export let prefix = false;
  export let prefixText = "";
  export let isChrome = true;
  export let maxlength;
  export let suffixBtn = false;
  export let suffixBtnIcon = "";

  export let extClass = "";
  let extPattern = "";

  $: if (errors.length > 0) {
    errors = errors
      .filter((err) => err.split(".")[0] == name)
      .map((e) => ({
        field: e.split(".")[0],
        error: e.split(".")[1],
      }));
    errors = errors;
  }

  function handleInput(e) {
    if (type == "money") {
      const { target } = e;
      value = target.value;
    } else {
      value = e.target.value;
    }
  }
  function preventNonNumericalInput(e) {
    e = e || window.event;
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
  }

  function formatRupiah(angka) {
    var regex = /[.,\s]/g;
    var result = angka.replace(regex, ""); // remove , and .
    let nf = new Intl.NumberFormat("id-ID");
    value = nf.format(result);
  }

  onMount(() => {
    const inputArr = document.getElementsByClassName("input-money");
    const fields = Array.from(inputArr);

    if (type == "phone") {
      extClass += " pl-12 ";
      extPattern = "[0-9]*";
    }

    if (type == "number") {
      extPattern = "[0-9]*";
    }

    if (type == "money") {
      fields.forEach(function (field) {
        new Cleave(field, {
          numeral: true,
          prefix: "Rp ",
          numeralDecimalMark: ",",
          delimiter: ".",
          numericOnly: true,
        });
      });
      extPattern = "[0-9]*";
    }
  });
</script>

{#if isChrome}
  <div>
    {#if !noLabel}
      <label for={name} class="block mb-1 text-sm font-semibold text-gray-900">{label}</label>
    {/if}
    <div class="relative">
      {#if type == "phone"}
        <small class="absolute left-3 inset-y-0 flex items-center text-gray-700 font-semibold outline-none focus:ring-transparent focus:outline-none"
          >+62</small
        >
      {/if}
      {#if prefix}
        <small class="absolute left-3 inset-y-0 flex items-center text-gray-700 font-semibold outline-none focus:ring-transparent focus:outline-none"
          >{prefixText}</small
        >
      {/if}
      <input
        class="{type == 'money' ? 'input-money' : ''} appearance-none {errors.length > 0 ? inputErrClass + extClass : inputClass + extClass}"
        type={type == "money" ? "text" : type == "phone" ? "number" : type}
        {name}
        {value}
        id={name}
        pattern={extPattern}
        {placeholder}
        {disabled}
        {maxlength}
        autocomplete="on"
        on:input={handleInput}
      />
      <!-- {/if} -->

      {#if withEye}
        <button
          type="button"
          on:click={() => {
            if (!showPass) {
              type = "text";
              showPass = !showPass;
            } else {
              type = "password";
              showPass = !showPass;
            }
          }}
          class="absolute right-0 inset-y-0 flex items-center pr-3 text-gray-500 outline-none focus:ring-transparent focus:outline-none"
        >
          <i class="bx {showPass ? 'bx-show' : 'bx-hide'}" />
        </button>
      {/if}

      {#if suffixBtn}
        <button
          type="button"
          on:click={() => {
            dispatch("suffix");
          }}
          class="absolute right-0 inset-y-0 flex items-center p-3 bg-blue-500 text-white rounded-tr-md rounded-br-md outline-none focus:ring-transparent focus:outline-none"
        >
          <i class="bx {suffixBtnIcon}" />
        </button>
      {/if}
      {#if suffix}
        <p class="absolute right-3 inset-y-0 text-xs flex items-center text-gray-500">
          {suffixText}
        </p>
      {/if}
    </div>
    {#if errors.length > 0 && errors[0]["error"] == "required"}
      <p class="text-xs text-red-500 p-0 mt-1">{label ?? ""} harus diisi</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "not_an_email"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} tidak valid</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "match_field"}
      <p class="text-xs text-red-500 p-0 mt-1">Password harus sama</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "duplicate"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} telah terdaftar</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "min"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} minimal 1</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "minLength"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} minimal 6 characters</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "phoneMinLength"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} minimal {minLength} digits</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "containAlphabet"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} harus terdapat alfabet</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "containNumber"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} harus terdapat angka</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "containSymbol"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} harus terdapat simbol</p>
    {/if}
  </div>
{/if}

{#if !isChrome}
  <div>
    {#if !noLabel}
      <label for={name} class="block mb-1 text-sm font-semibold text-gray-900">{label}</label>
    {/if}
    <div class="flex items-center gap-4">
      <p class="text-sm font-semibold text-gray-900">Rp</p>
      <div class="relative flex-1">
        {#if type == "phone"}
          <small
            class="absolute left-3 inset-y-0 flex items-center text-gray-700 font-semibold outline-none focus:ring-transparent focus:outline-none"
            >+62</small
          >
        {/if}
        {#if prefix}
          <small
            class="absolute left-3 inset-y-0 flex items-center text-gray-700 font-semibold outline-none focus:ring-transparent focus:outline-none"
            >{prefixText}</small
          >
        {/if}
        <input
          class="{type == 'money' ? 'input-money' : ''} appearance-none {errors.length > 0 ? inputErrClass + extClass : inputClass + extClass}"
          type={type == "money" ? "text" : type == "phone" ? "number" : type}
          {name}
          {value}
          id={name}
          pattern={extPattern}
          {placeholder}
          {disabled}
          on:input={handleInput}
          on:keypress={(event) => preventNonNumericalInput(event)}
          on:keyup={() => formatRupiah(value)}
        />
        <!-- {/if} -->
        {#if withEye}
          <button
            type="button"
            on:click={() => {
              if (!showPass) {
                type = "text";
                showPass = !showPass;
              } else {
                type = "password";
                showPass = !showPass;
              }
            }}
            class="absolute right-0 inset-y-0 flex items-center pr-3 text-gray-500 outline-none focus:ring-transparent focus:outline-none"
          >
            <i class="bx {showPass ? 'bx-show' : 'bx-hide'}" />
          </button>
        {/if}
        {#if suffix}
          <p class="absolute right-3 inset-y-0 text-xs flex items-center text-gray-500">
            {suffixText}
          </p>
        {/if}
      </div>
    </div>
    {#if errors.length > 0 && errors[0]["error"] == "required"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} harus diisi</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "not_an_email"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} tidak valid</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "match_field"}
      <p class="text-xs text-red-500 p-0 mt-1">Password harus sama</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "duplicate"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} telah terdaftar</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "min"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} minimal 1</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "minLength"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} minimal 6 characters</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "phoneMinLength"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} minimal {minLength} digits</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "containAlphabet"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} harus terdapat alfabet</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "containNumber"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} harus terdapat angka</p>
    {/if}
    {#if errors.length > 0 && errors[0]["error"] == "containSymbol"}
      <p class="text-xs text-red-500 p-0 mt-1">{label} harus terdapat simbol</p>
    {/if}
  </div>
{/if}

<style>
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
