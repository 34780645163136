<script>
  import { field, form } from "svelte-forms";
  import Input from "../../components/control/Input.svelte";
  import { required, min } from "svelte-forms/validators";
  import Textarea from "../../components/control/Textarea.svelte";
  import Select from "../../components/control/Select.svelte";
  import { pop, replace } from "svelte-spa-router";
  import { urlApi } from "../../utils/const";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import { formatDateWithTimeForDB, parseJwt } from "../../utils/utils";
  import Modal from "../../components/Modal.svelte";
  import { onMount } from "svelte";
  import TableParticipant from "../../components/table/TableParticipant.svelte";
  import TableAddParticipant from "../../components/table/TableAddParticipant.svelte";
  import AttendanceParticipantCard from "../../components/card/AttendanceParticipantCard.svelte";
  import AddAttendanceParticipantCard from "../../components/card/AddAttendanceParticipantCard.svelte";
  import Loading from "../../components/Loading.svelte";
  import ModalPagination from "../../components/pagination/ModalPagination.svelte";
  import AttendanceCategoryServices from "../../pages/AttendanceCategory/AttendanceCategoryServices";

  const token = parseJwt(localStorage.getItem("token"));
  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";
  let date = field("date", new Date(), [required()]);
  let time = field("time", new Date(), [required()]);
  let attendanceName = field("attendanceName", "", [required()]);
  let attendanceCategory = field("attendanceCategory", "", [required()]);
  let note = field("note", "", []);

  let errors = [];
  let isParticipantEmpty = false;
  let participantList = [];
  let participantListID = [];
  let participantListBefore = [];
  let participantListIDBefore = [];
  let userList = [];
  let modalBack;
  let modalAddParticipant;
  let modalDelete;
  let modalCancelAddParticipant;
  let participantIDToDelete;
  let page = 1;
  let perPage = 5;
  let totalData;
  let totalPage;
  let allMembers = [];
  let attendanceCategoryList = [];
  let search = "";

  let loading;
  let disableSubmit = false;
  let attendanceForm = form(date, time, attendanceName, note, attendanceCategory);
  onMount(async () => {
    loading.toggle();
    await Promise.all([loadAllMember(), loadAttendanceCategory()]);
    loading.toggle();
  });
  async function loadMember() {
    loading.toggle();
    const option = "perPage=" + perPage + "&currentPage=" + page + "&searchQuery=" + search + "&onlyName=1" + "&isActive=1" + "&showAll=1";
    const res = await fetch(urlApi + "/Users?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    let member = data["data"];
    totalData = data["info"]["totalItem"];
    totalPage = data["info"]["totalPage"];
    userList = member.map((u) => ({
      id: u["id"],
      name: u["name"],
    }));
    loading.toggle();
  }
  async function loadAllMember() {
    loading.toggle();
    const option = "perPage=9999" + "&searchQuery=" + search + "&onlyName=1" + "&isActive=1" + "&showAll=1";
    const res = await fetch(urlApi + "/Users?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    let member = data["data"];
    allMembers = member.map((u) => ({
      id: u["id"],
      name: u["name"],
    }));
    loading.toggle();
  }
  async function loadAttendanceCategory() {
    const option = "perPage=9999" + "&currentPage=1"+ "&isActive=1"+ "&isDelete=0";
    const data = await AttendanceCategoryServices.loadAttendanceCategory(option);
    attendanceCategoryList = data["data"].map((e) => {
      return { id: String(e.id), name: e.name, isDelete: e.isDelete, isActive: e.isActive };
    });
  }
  function searchData() {
    page = 1;
    loadMember();
  }

  async function addAttendance() {
    loading.toggle();
    disableSubmit = true;
    errors = [];
    attendanceForm = form(date, time, attendanceName, note, attendanceCategory);
    await attendanceForm.validate();
    isParticipantEmpty = participantList.length == 0 ? true : false;
    if ($attendanceForm.valid && !isParticipantEmpty) {
      $date.value.setHours($time.value.getHours(), $time.value.getMinutes(), 0, 0);
      const reqData = {
        Date: formatDateWithTimeForDB($date.value),
        AttendanceName: $attendanceName.value,
        Note: $note.value,
        UserID: token.UserID,
        ParticipantID: participantListID,
        Category: $attendanceCategory.value,
      };
      const res = await fetch(urlApi + "/Attendance", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(reqData),
      });
      const data = await res.json();
      if (data["data"]) {
        globalThis.pushToast("Berhasil Menambahkan Attendance", "success");
        setTimeout(() => replace("/attendances/"), 1000);
      }
    } else {
      errors = $attendanceForm.errors;
      errors = errors;
      disableSubmit = false;
    }
    loading.toggle();
  }

  function checkBack() {
    return $attendanceName.value != "" || $note.value != "";
  }
  function addOrRemoveParticipant(item) {
    var index = participantList.indexOf(item);
    if (index != -1) {
      participantList.splice(index, 1);
      participantListID.splice(index, 1);
    } else {
      participantList.push(item);
      participantListID.push(item["id"]);
    }
    participantListID = participantListID;
    isParticipantEmpty = participantList.length == 0 ? true : false;
  }
  function addOrRemoveAllParticipant(isCheckedAll) {
    participantList = isCheckedAll ? [...allMembers] : [];
    participantListID = isCheckedAll ? [...allMembers].map((e) => e["id"]) : [];
    isParticipantEmpty = participantList.length == 0 ? true : false;
  }
</script>

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin ingin keluar dari laman ini? data yang Anda masukkan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/attendances/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>
<Modal index={50} title="Konfirmasi" bind:this={modalDelete} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin akan menghapus participant?.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalDelete.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={(e) => {
          modalDelete.toggle();
          addOrRemoveParticipant(participantIDToDelete);
          participantList = participantList;
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>
<Modal
  index={50}
  title="Tambah Participants"
  bind:this={modalAddParticipant}
  size="mobile"
  closed
  isScrollable
  on:close={() => {
    search = "";
    page = 1;
    if (participantList.length != participantListBefore.length) modalCancelAddParticipant.toggle();
    else modalAddParticipant.toggle();
  }}
>
  <div class="w-full flex items-center px-4">
    <div class="relative w-full">
      <div class="relative w-full">
        <input
          type="text"
          placeholder="Cari berdasarkan nama.."
          bind:value={search}
          on:input={searchData}
          class="block w-full text-xs py-2 pr-5 text-gray-700 focus:border-gray-300 focus:ring-transparent bg-white
                border border-gray-200 rounded-lg placeholder-gray-400/70 pl-8 focus:outline-none"
        />
        <span class="absolute left-2 top-0 bottom-0 flex items-center">
          <i class="bx bx-search text-gray-500" />
        </span>
      </div>
    </div>
  </div>
  <div class="relative overflow-y-auto h-72 hidden md:block pl-4 mt-4">
    <TableAddParticipant
      bind:data={userList}
      bind:allMembers
      bind:participantListID
      on:addOrRemoveParticipant={(e) => addOrRemoveParticipant(e.detail)}
      on:checkAll={(e) => addOrRemoveAllParticipant(e.detail)}
    />
  </div>
  <div class="relative overflow-x-auto h-96 block md:hidden px-4">
    <AddAttendanceParticipantCard
      bind:data={userList}
      bind:allMembers
      bind:participantListID
      on:addOrRemoveParticipant={(e) => addOrRemoveParticipant(e.detail)}
      on:checkAll={(e) => addOrRemoveAllParticipant(e.detail)}
    />
  </div>
  <div class="px-4 sm:flex sm:items-center sm:justify-between">
    <div class="text-sm text-gray-500" />
    {#if userList.length > 0 || page > 1}
      <ModalPagination
        bind:page
        {totalData}
        {totalPage}
        {perPage}
        on:prev={async () => {
          page -= 1;
          await loadMember();
        }}
        on:next={async () => {
          page += 1;
          await loadMember();
        }}
      />
    {/if}
  </div>
  <div class="flex justify-end space-x-4 p-4">
    <button
      on:click={() => {
        search = "";
        page = 1;
        if (participantList.length != participantListBefore.length) modalCancelAddParticipant.toggle();
        else modalAddParticipant.toggle();
      }}
      class="text-white bg-gray-400 hover:opacity-80 outline-none font-medium rounded-lg text-sm px-7 py-2.5 mt-4 text-center transition-all"
      >Batal</button
    >
    <button
      on:click={() => {
        search = "";
        page = 1;
        participantList = participantList;
        modalAddParticipant.toggle();
      }}
      class="text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-7 py-2.5 mt-4 text-center transition-all"
      >Simpan</button
    >
  </div>
</Modal>
<Modal index={50} title="Konfirmasi" bind:this={modalCancelAddParticipant} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin ingin akan membatalkan perubahan? Perubahan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => {
          modalCancelAddParticipant.toggle();
        }}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalCancelAddParticipant.toggle();
          modalAddParticipant.toggle();
          participantListID = participantListIDBefore;
          participantList = participantListBefore;
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>
<Loading bind:this={loading} />
<section class="container px-4 py-4">
  <div class="sm:flex sm:items-center sm:justify-between">
    <div>
      <div class="flex items-center gap-x-3">
        <button
          on:click={() => {
            if (checkBack()) {
              modalBack.toggle();
            } else {
              pop();
            }
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-sm md:text-lg font-medium text-gray-800">Buat Attendance</h2>
      </div>
    </div>
  </div>

  <div class="mt-5 flex flex-col space-y-4">
    <div class={mobileClass}>
      <div class="flex-1">
        <Input name="attendanceName" type="text" label="Nama Attendance" bind:errors bind:value={$attendanceName.value} />
      </div>
      <div class="flex-1 flex space-x-2 w-full">
        <div class="flex-1">
          <DatePicker
            name="date"
            label="Tanggal"
            labelSize="sm"
            textSize="sm"
            extClass="font-semibold"
            maxDateToday={false}
            isMinDate={true}
            bind:value={$date.value}
          />
        </div>
        <div class="flex-1">
          <DatePicker
            name="date"
            isTime
            isDate={false}
            label="Waktu"
            labelSize="sm"
            textSize="sm"
            extClass="font-semibold"
            bind:value={$time.value}
          />
        </div>
      </div>
    </div>
    <div class={mobileClass}>
      <div class="flex-1">
        <Select
          on:change={(e) => {
            $attendanceCategory.value = e.detail;
          }}
          fullWidth
          textHint="Pilih Kategori Attendance"
          name="attendanceCategory"
          extClass="font-semibold"
          label="Kategori Attendance"
          textSize="text-sm"
          options={attendanceCategoryList}
          bind:errors
          bind:selected={$attendanceCategory.value}
        />
      </div>
    </div>
    <div class="flex space-x-2">
      <div class="flex-1">
        <Textarea bind:errors name="note" label="Catatan" placeholder="Catatan" maxlength="3000" bind:value={$note.value} />
      </div>
    </div>

    <div class="mt-4" />
    <div class="flex w-full items-center justify-between space-x-3">
      <h2 class="text-sm md:text-lg font-medium text-gray-800 w-full">Daftar Participants</h2>
      <button
        on:click={async () => {
          participantListIDBefore = [...participantListID];
          participantListBefore = [...participantList];
          modalAddParticipant.toggle();
          await loadMember();
        }}
        class="flex items-center justify-center px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-lg
              shrink-0 sm:w-auto space-x-2 hover:opacity-80 disabled:bg-gray-500"
      >
        <i class="bx bx-plus" />
        <span>PARTICIPANTS</span>
      </button>
    </div>
    <div class="overflow-x-auto hidden md:block">
      <div class="inline-block min-w-full py-2 align-middle">
        <div class="overflow-hidden rounded-md">
          <TableParticipant
            bind:data={participantList}
            on:delete={(e) => {
              participantIDToDelete = e.detail;
              modalDelete.toggle();
            }}
          />
        </div>
      </div>
    </div>
    <div class="overflow-x-auto block md:hidden">
      {#each participantList as item}
        <AttendanceParticipantCard
          bind:data={item}
          on:delete={(e) => {
            participantIDToDelete = e.detail;
            modalDelete.toggle();
          }}
        />
      {/each}
    </div>
    {#if isParticipantEmpty}
      <p class="text-xs text-red-500 p-0 mt-1">Participant tidak boleh kosong</p>
    {/if}
    <div class="flex w-full space-x-4">
      <button
        on:click={() => {
          if (checkBack()) {
            modalBack.toggle();
          } else {
            pop();
          }
        }}
        class="w-full text-white bg-gray-400 hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
        >Batal</button
      >
      <button
        on:click={addAttendance}
        disabled={disableSubmit}
        class="w-full text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
        >Simpan</button
      >
    </div>
  </div>
</section>
