<script>
  import { pop, replace } from "svelte-spa-router";
  import { onMount } from "svelte";
  import { parseJwt, checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import { perPage, MEMBER_REMOVAL_CELLGROUP_MODULE } from "../../utils/const";
  import Cookies from "js-cookie";
  import Loading from "../../components/Loading.svelte";
  import Modal from "../../components/Modal.svelte";
  import Pagination from "../../components/pagination/Pagination.svelte";
  import TableCellGroupMemberRemoval from "../../components/table/CellGroup/TableCellGroupMemberRemoval.svelte";
  import CellGroupServices from "./CellGroupServices";
  import CellGroupMemberRemovalCard from "../../components/card/CellGroup/CellGroupMemberRemovalCard.svelte";

  export let params;

  let id = params["_id"];

  const token = parseJwt(localStorage.getItem("token"));
  let access = token.RoleID;

  let member = [];
  let loading;
  let disableSubmit = false;
  let modalBack;
  let page = 1;
  let totalData = 0,
    totalPage = 0;
  let initialStatus = [];
  let statusChanged = [];

  onMount(() => {
    if (!checkRoleModule($roleModules, MEMBER_REMOVAL_CELLGROUP_MODULE)) {
      replace("/cellgroup/");
      return;
    }
    loadData();
  });

  async function loadData() {
    loading.toggle();

    const status = "memberList";

    const option = "perPage=" + perPage + "&currentPage=" + page + "&status=" + status + "&cellGroupID=" + id;
    const data = await CellGroupServices.loadCellGroupMemberList(option);
    totalData = data["info"]["totalItem"];
    totalPage = data["info"]["totalPage"];
    member = data["data"];
    initialStatus = member;
    if (statusChanged.length > 0) {
      statusChanged.forEach((obj) => {
        const changed = member.find((item) => item.id == obj.id);
        if (changed) {
          changed.status = obj.status;
        }
      });
    }
    loading.toggle();
  }

  function changeStatByID(id) {
    const objIndex = member.findIndex((obj) => obj.id == id);
    member[objIndex].status = +!member[objIndex].status;

    const objChanged = statusChanged.findIndex((obj) => obj.id == id);
    if (objChanged < 0) {
      statusChanged.push({ id: id, status: +member[objIndex].status });
    } else {
      const objChangedIndex = statusChanged.findIndex((obj) => obj.id == id);
      if (member[objIndex].status == initialStatus[objIndex].status) {
        statusChanged.splice(objChangedIndex, 1);
      } else {
        statusChanged[objChangedIndex].status = +member[objIndex].status;
      }
    }
  }

  async function changeStatus() {
    loading.toggle();
    disableSubmit = true;
    const reqData = {
      StatusChanged: statusChanged,
      CellGroupID: id,
    };
    const data = await CellGroupServices.updateStatusMember(reqData);
    if (data["data"]) {
      globalThis.pushToast("Berhasil Mengubah Status Sel Group Member", "success");
      setTimeout(() => replace("/cellgroup/"), 1000);
    } else {
      disableSubmit = false;
    }
    loading.toggle();
  }

  function checkBack() {
    let result = false;

    if (statusChanged.length > 0) {
      result = true;
    }
    return result;
  }
</script>

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin ingin keluar dari laman ini? data yang Anda ubah tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/cellgroup/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>

<Loading bind:this={loading} />

<section class="container px-4 py-4">
  <div class="sm:flex sm:items-center sm:justify-between">
    <div>
      <div class="flex items-center gap-x-3">
        <button
          on:click={() => {
            if (checkBack()) {
              modalBack.toggle();
            } else {
              pop();
            }
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-sm md:text-lg font-medium text-gray-800">Daftar Member Sel Group</h2>
      </div>
    </div>
  </div>

  <div class="flex flex-col mt-4">
    <div class="overflow-x-auto hidden md:block">
      <div class="inline-block min-w-full py-2 align-middle">
        <div class="overflow-hidden rounded-md">
          <TableCellGroupMemberRemoval bind:data={member} bind:page on:action={(e) => changeStatByID(e.detail)} />
        </div>
      </div>
    </div>
    <!-- mobile -->
    <div class="overflow-x-auto block md:hidden">
      {#each member as item, index}
        <CellGroupMemberRemovalCard bind:data={item} {index} bind:page on:action={(e) => changeStatByID(e.detail)} />
      {/each}
    </div>
  </div>

  <div class="mt-6 sm:flex sm:items-center sm:justify-between">
    <div class="text-sm text-gray-500" />
    {#if member.length > 0 || page > 1}
      <Pagination
        bind:page
        {totalData}
        {totalPage}
        {perPage}
        on:prev={() => {
          page -= 1;
          loadData();
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
        on:next={() => {
          page += 1;
          loadData();
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
    {/if}
  </div>

  <div class="flex w-full space-x-4">
    <button
      on:click={() => {
        if (checkBack()) {
          modalBack.toggle();
        } else {
          pop();
        }
      }}
      class="w-full text-white bg-gray-400 hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
      >Batal</button
    >
    <button
      on:click={changeStatus}
      disabled={disableSubmit}
      class="w-full text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
      >Simpan</button
    >
  </div>
</section>
