import { format, setDefaultOptions } from "date-fns";
import { id as localeID } from "date-fns/locale";
import UserServices from "../pages/User/UserServices";
import AuthServices from "../pages/Auth/AuthServices";
import { replace } from "svelte-spa-router";

setDefaultOptions({ locale: localeID });

export const version = "v1.27.121";

export function formatNumber(angka) {
  if (!Number.isInteger(angka)) {
    return parseInt(angka.replace(/[^,\d]/g, ""));
  }
  return angka;
}

export function formatDate(date) {
  return format(date, "yyyy-MM-dd");
}
export function formatDateWithSlash(date) {
  return format(date, "dd/MM/yyyy");
}
export function formatDateTime(date, isFromDate) {
  const result = new Date(date);
  if (isFromDate) result.setHours(0, 0, 0, 0);
  else result.setHours(23, 59, 59, 59);
  return format(result, "yyyy-MM-dd HH:mm");
}

export function formatDateForView(date) {
  return format(date, "dd MMMM yyyy");
}

export function formatDateWithTime(date) {
  return format(date, "yyyy-MM-dd HH:mm");
}
export function formatDateWithTimeForView(date) {
  return format(date, "dd MMMM yyyy - HH:mm");
}
export function formatDateTimeShortVersion(date) {
  return format(date, "dd/MM/yyyy HH:mm");
}

export function formatDateWithTimeForDB(date) {
  const formattedDate = format(date, "yyyy-MM-dd");
  const time = format(date, "HH:mm:ss");
  return `${formattedDate}T${time}`;
}
export function formatRupiah(angka, prefix) {
  var number_string = angka ? angka.toString() : "0",
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  if (ribuan) {
    var separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;

  if (!rupiah) {
    rupiah = "";
  }
  if (!prefix) {
    prefix = "";
  }
  return prefix + rupiah;
}

export function checkRoleModule(roleModules, moduleCode) {
  let result = false;
  const parsedRoleModule = roleModules.length > 0 ? JSON.parse(roleModules) : [];
  const token = parseJwt(localStorage.getItem("token"));

  if (parsedRoleModule.length > 0) {
    const index = parsedRoleModule.findIndex((e) => e["Code"] == moduleCode && e["RoleID"] == parseInt(token.RoleID));
    if (index != -1) {
      result = true;
    }
  }

  return result;
}
export function parseJwt(token) {
  if (localStorage.getItem("token") != null && localStorage.getItem("token") != undefined && localStorage.getItem("token") != "") {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    return JSON.parse(jsonPayload);
  } else return "";
}

export async function lastCheckProfile(date) {
  var ONE_HOUR = 60 * 60 * 1000;
  if (date - new Date(localStorage.getItem("lastCheck")) > ONE_HOUR) {
    const data = await UserServices.getUser();
    localStorage.setItem("imageSrc", data["data"]["imageSrc"]);
    const tokenData = await AuthServices.refreshToken();
    localStorage.setItem("token", tokenData["data"]["token"]);
    localStorage.setItem("lastCheck", date);
    return true;
  }
  return false;
}

export function paginationListNumber(page, index) {
  return page > 1 ? ((index + 1).toString().length > 1 ? index + 1 + 10 * (page - 1) : (page - 1).toString() + (index + 1)) : index + 1;
}
export function setProfilePicture(imageSrc) {
  return imageSrc == "" || imageSrc == "undefined" || imageSrc == undefined || imageSrc == null
    ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
    : imageSrc;
}

export function checkEmptyInput(arrayInput) {
  let result = false;
  arrayInput.forEach((element) => {
    if (element !== "" && element !== undefined) result = true;
  });
  return result;
}

export function checkSuperAdmin(roles) {
  const index = roles.findIndex((e) => e["RolesID"] == "5");

  return index != -1 ? true : false;
}

export function getValueAccess(val) {
  let res = "";
  switch (val) {
    case 1:
      res = "Head Gembala";
      break;
    case 2:
      res = "Gembala";
      break;
    case 3:
      res = "Wakil Gembala";
      break;
    case 4:
      res = "Member";
      break;
    case 5:
      res = "Super Admin";
      break;
    case 12:
      res = "Pendamping Gembala";
      break;
    case 13:
      res = "Pendamping Wakil Gembala";
      break;
    default:
      res = "Member";
      break;
  }

  return res;
}

export function generateID(length) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function getStatusLearning(status) {
  let res = "";
  switch (status) {
    case 0:
      res = "Closed";
      break;
    case 1:
      res = "Published";
      break;
    case 2:
      res = "Upcoming";
      break;

    default:
      break;
  }
  return res;
}

export function getStatusAnswerUser(status) {
  let res = "";
  switch (status) {
    case 0:
      res = "Belum Mengisi";
      break;
    case 1:
      res = "Completed";
      break;
    case 2:
      res = "Overdue";
      break;
    case 3:
      res = "Draft";
      break;

    default:
      break;
  }
  return res;
}

export function getTypeLearning(type) {
  let res = "";

  switch (type) {
    case 1:
      res = "Video";
      break;
    case 2:
      res = "Text";
      break;
    case 3:
      res = "PDF";
      break;
    case 4:
      res = "PPT";
      break;

    default:
      break;
  }
  return res;
}

export function validateStatusLearning(publishDate) {
  let todayDate = new Date();
  let res = 1;
  if (publishDate.setHours(0, 0, 0, 0) > todayDate.setHours(0, 0, 0, 0)) {
    res = 2;
  } else if (publishDate.setHours(0, 0, 0, 0) < todayDate.setHours(0, 0, 0, 0)) {
    res = 0;
  }
  return res;
}

export async function redirectAfterChangeRole(url) {
  if (url.includes("ones")) await replace("/ones/");
  else if (url.includes("journals")) await replace("/journals/");
  else if (url.includes("attendances")) await replace("/attendances/");
  else if (url.includes("cellgroup")) await replace("/cellgroup/");
  else if (url.includes("learning")) await replace("/learning/");
  globalThis.location.reload();
}
