<script>
  import { createEventDispatcher } from "svelte";
  import { checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import { EDIT_CELL_CATEGORY_MODULE, DETAIL_CELL_CATEGORY_MODULE } from "../../utils/const";
  const dispatch = createEventDispatcher();

  export let data;
  export let page;
</script>

<table class="min-w-full divide-y divide-gray-200">
  <thead class="bg-gray-100">
    <tr>
      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left text-gray-500"> No. </th>
      <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left text-gray-500"> Kategori Sel </th>
      <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left text-gray-500"> Status </th>
      <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500"> Aksi </th>
    </tr>
  </thead>
  <tbody class="bg-white divide-y divide-gray-200">
    {#if data.length == 0}
      <tr>
        <td colspan="6" class="px-4 py-4 text-sm font-medium">
          <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
        </td>
      </tr>
    {/if}
    {#each data as item, index}
      <tr>
        <td class="px-4 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {page > 1 ? ((index + 1).toString().length > 1 ? index + 1 + 10 * (page - 1) : (page - 1).toString() + (index + 1)) : index + 1}
          </h2>
        </td>
        <td class="px-4 py-2 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["name"]}
          </h2>
        </td>
        <td class="px-12 py-2 text-sm font-medium whitespace-nowrap">
          <div
            class="inline px-3 py-1 text-xs font-normal rounded-full {item['isActive'] == 1
              ? 'text-emerald-500 bg-emerald-100/60'
              : 'text-red-500 bg-red-100/60'}  gap-x-2"
          >
            {item["isActive"] == 1 ? "Aktif" : "Tidak Aktif"}
          </div>
        </td>

        <td class="px-4 py-4 text-sm whitespace-nowrap">
          {#if checkRoleModule($roleModules, DETAIL_CELL_CATEGORY_MODULE)}
            <button
              on:click={() => dispatch("detail", { id: item["id"], type: item["type"] })}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-show text-xl" />
            </button>
          {/if}

          {#if checkRoleModule($roleModules, EDIT_CELL_CATEGORY_MODULE)}
            <button
              on:click={() => dispatch("edit", { id: item["id"], type: item["type"] })}
              class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
            >
              <i class="bx bx-pencil text-xl" />
            </button>
          {/if}
        </td>
      </tr>
    {/each}
  </tbody>
</table>
