<script>
  import { field, form } from "svelte-forms";
  import { onMount } from "svelte";
  import InputWithModal from "../../components/control/InputWithModal.svelte";
  import { required, min } from "svelte-forms/validators";
  import Textarea from "../../components/control/Textarea.svelte";
  import { pop, replace } from "svelte-spa-router";
  import Cookies from "js-cookie";
  import { urlApi, ADD_SPIRITUAL_CONVERSATION_MODULE } from "../../utils/const";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import { formatDate, formatDateForView, parseJwt, checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import Rating from "../../components/control/Rating.svelte";
  import Modal from "../../components/Modal.svelte";
  import TableAddPartner from "../../components/table/TableAddPartner.svelte";
  import AddPartnerCard from "../../components/card/AddPartnerCard.svelte";
  import ModalPagination from "../../components/pagination/ModalPagination.svelte";
  import Loading from "../../components/Loading.svelte";
  import CollapsibleSection from "../../components/control/CollapsibleSection.svelte";

  export let params;

  const token = parseJwt(localStorage.getItem("token"));
  let type = params["type"];
  let date = field("date", new Date(), [required()]);
  let partner = field("partner", "", [required()]);
  let partnerName = "";
  let note = field("note", "", []);
  let prayNote = field("prayNote", "", []);
  let duration = field("duration", "", []);
  let effective = field("effective", null, [required()]);
  let joy = field("joy", null, [required()]);
  let meeting = field("meeting", 0, [required()]);

  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";

  let errors = [],
    partners = [];
  let modalAddPartner;
  let search = "";
  let page = 1;
  let totalData = 0,
    totalPage = 0;
  const perPage = 6;
  let loading;
  let loadingPage;
  let disableSubmit = false;

  let validate = true;
  let modalErr;
  let errMsg = "";

  let spiritualQuestion = [];
  let spiritualAnswers = [];

  onMount(() => {
    if (type == "Spiritual Conversation") {
      checkRoleModule($roleModules, ADD_SPIRITUAL_CONVERSATION_MODULE) ? loadQuestionData() : replace("/ones/");
    }
  });

  async function loadQuestionData() {
    loadingPage.toggle();
    const res = await fetch(urlApi + "/Ones/SpiritualConversationQuestion", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    spiritualQuestion = data["data"];

    for (var i = 0; i < spiritualQuestion.length; i++) {
      spiritualAnswers.push({ child: [] });
      for (var j = 0; j < spiritualQuestion[i]["child"].length; j++) {
        spiritualAnswers[i]["child"].push({ child: [] });
        for (var k = 0; k < spiritualQuestion[i]["child"][j]["child"].length; k++) {
          spiritualAnswers[i]["child"][j]["child"].push({ id: spiritualQuestion[i]["child"][j]["child"][k].id, answer: "" });
        }
      }
    }
    loadingPage.toggle();
  }

  function searchData() {
    page = 1;
    loadUser();
  }
  async function openAddPartner() {
    await modalAddPartner.toggle();
    loadUser();
  }
  async function loadUser() {
    loading.toggle();
    const option =
      "perPage=" +
      perPage +
      "&currentPage=" +
      page +
      "&searchQuery=" +
      search +
      "&showAll=1" +
      "&isActive=1" +
      "&onlyName=1" +
      "&ignoredIDs=" +
      token.UserID;
    const res = await fetch(urlApi + "/Users?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();

    if (data["data"]) {
      partners = data["data"].map((e) => ({ id: e["id"], name: e["name"], roleName: e["roleName"] }));
      totalData = data["info"]["totalItem"];
      totalPage = data["info"]["totalPage"];
      loading.toggle();
    }
  }

  let onesForm;

  async function add() {
    disableSubmit = true;
    loadingPage.toggle();
    note = field("note", $note.value, type == "121" ? [required()] : []);
    prayNote = field("prayNote", $prayNote.value, type == "121" ? [required()] : []);

    onesForm = form(date, partner, note, prayNote, duration, effective, joy, meeting);

    errors = [];
    errMsg = "";
    await onesForm.validate();
    if ($onesForm.valid) {
      const reqData = {
        Date: formatDate($date.value),
        Note: $note.value,
        Partner: $partner.value,
        PrayNote: $prayNote.value,
        Duration: $duration.value,
        Effective: $effective.value.toString(),
        Joy: $joy.value.toString(),
        Meeting: $meeting.value,
        UserID: token.UserID,
        SpiritualConvAnswer: spiritualAnswers,
        Validate: validate ? 1 : 0,
        Type: type,
        RoleID: token.RoleID,
      };
      const res = await fetch(urlApi + "/Ones", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(reqData),
      });
      const data = await res.json();
      if (data["data"]["errorMessage"]) {
        if (data["data"]["errorMessage"] == "exist") {
          errMsg =
            "Anda sudah membuat " +
            type +
            " di tanggal <b>" +
            formatDateForView($date.value) +
            "</b>. Apakah Anda yakin akan melanjutkan membuat " +
            type +
            " ?";
        }
        if (data["data"]["errorMessage"] == "exist_with_partner") {
          errMsg =
            "Anda sudah membuat " +
            type +
            " dengan rekan <b>" +
            partnerName +
            "</b> di tanggal <b>" +
            formatDateForView($date.value) +
            "</b>. Apakah Anda yakin akan melanjutkan membuat " +
            type +
            " ?";
        }
        disableSubmit = false;
        modalErr.toggle();
      } else if (data["data"]) {
        globalThis.pushToast("Berhasil Menambahkan Data", "success");
        setTimeout(() => replace("/ones/"), 1000);
      }
    } else {
      errors = $onesForm.errors;
      errors = errors;
      disableSubmit = false;
    }
    loadingPage.toggle();
  }

  let modalBack;

  function checkBack() {
    let result = false;

    if (
      $date.value != "" ||
      $partner.value != "" ||
      $note.value != "" ||
      $prayNote.value != "" ||
      $duration != "" ||
      $effective.value != "" ||
      $joy.value != "" ||
      $meeting.value != ""
    ) {
      result = true;
    }

    return result;
  }
</script>

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin ingin keluar dari laman ini? data yang Anda masukkan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/ones/");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>

<Modal index={50} title="Konfirmasi" bind:this={modalErr} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">
      {@html errMsg}
    </p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalErr.toggle()}
        class="flex-1 px-3 py-2 text-gray-500 bg-gray-300 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Tidak
      </button>
      <button
        on:click={() => {
          validate = false;
          modalErr.toggle();
          add();
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>

<Modal
  index={50}
  title="Cari Rekan"
  bind:this={modalAddPartner}
  size="mobile"
  isScrollable
  closed
  on:close={() => {
    partnerName = "";
    $partner.value = "";
    search = "";
    page = 1;
    modalAddPartner.toggle();
  }}
>
  <Loading bind:this={loading} />
  <div class="w-full flex items-center px-4">
    <div class="relative w-full">
      <div class="relative w-full">
        <input
          type="text"
          placeholder="Cari nama.."
          bind:value={search}
          on:input={searchData}
          class="block w-full text-xs py-2 pr-5 text-gray-700 focus:border-gray-300 focus:ring-transparent bg-white
    border border-gray-200 rounded-lg placeholder-gray-400/70 pl-8 focus:outline-none"
        />
        <span class="absolute left-2 top-0 bottom-0 flex items-center">
          <i class="bx bx-search text-gray-500" />
        </span>
      </div>
    </div>
  </div>
  <div class="relative overflow-y-auto h-72 hidden md:block pl-4">
    <TableAddPartner bind:data={partners} bind:value={$partner.value} bind:displayedValue={partnerName} bind:page />
  </div>

  <!-- Mobile -->
  <div class="relative overflow-x-auto h-3/4 mt-4 block md:hidden px-4">
    <AddPartnerCard bind:data={partners} bind:value={$partner.value} bind:displayedValue={partnerName} />
  </div>

  <div class="px-4 sm:flex sm:items-center sm:justify-between">
    <div class="text-sm text-gray-500" />
    {#if partners.length > 0 || page > 1}
      <ModalPagination
        bind:page
        {totalData}
        {totalPage}
        {perPage}
        on:prev={() => {
          page -= 1;
          loadUser();
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
        on:next={() => {
          page += 1;
          loadUser();
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
    {/if}
  </div>
  <div class="flex justify-end space-x-4 p-4">
    <button
      on:click={() => {
        page = 1;
        search = "";
        partnerName = "";
        $partner.value = "";
        modalAddPartner.toggle();
      }}
      class="text-white bg-gray-400 hover:opacity-80 outline-none font-medium rounded-lg text-sm px-7 py-2.5 mt-4 text-center transition-all"
      >Batal</button
    >
    <button
      on:click={() => {
        page = 1;
        search = "";
        partnerName = partnerName;
        modalAddPartner.toggle();
      }}
      class="text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-7 py-2.5 mt-4 text-center transition-all"
      >Simpan</button
    >
  </div>
</Modal>

<Loading bind:this={loadingPage} />

<section class="container px-4 py-4">
  <div class="sm:flex sm:items-center sm:justify-between">
    <div>
      <div class="flex items-center gap-x-3">
        <button
          on:click={() => {
            if (checkBack()) {
              modalBack.toggle();
            } else {
              pop();
            }
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-lg font-medium text-gray-800">Tambah {type}</h2>
      </div>
    </div>
  </div>

  <div class="mt-5 flex flex-col space-y-4">
    <div class={mobileClass}>
      <div class="flex-1">
        <DatePicker
          name="date"
          type="text"
          label="Tanggal"
          labelSize="sm"
          textSize="sm"
          extClass="font-semibold"
          bind:errors
          bind:value={$date.value}
        />
      </div>
    </div>

    <div class={mobileClass}>
      <div class="flex-1">
        <InputWithModal
          name="partner"
          type="text"
          label="Rekan"
          placeholder="Pilih User"
          disabled={partnerName}
          bind:errors
          bind:value={partnerName}
          on:click={() => openAddPartner()}
          on:clear={() => {
            partnerName = "";
            $partner.value = null;
          }}
        />
      </div>
    </div>

    <div class="flex items-center mb-4">
      <input
        id="meeting-checkbox"
        type="checkbox"
        value="1"
        checked={$meeting.value == 1}
        on:change={(e) => {
          if ($meeting.value == 0) {
            $meeting.value = 1;
          } else {
            $meeting.value = 0;
          }
        }}
        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer focus:ring-transparent ring-transparent"
      />
      <label for="default-checkbox" class="ml-2 text-sm font-medium cursor-pointer text-gray-500">Saya yang membuat meeting</label>
    </div>

    {#if type == "Spiritual Conversation"}
      {#each spiritualQuestion as question, i}
        <CollapsibleSection headerText={question.title}>
          {#each question.child as sub, ix}
            <div class="font-semibold text-sm mb-2">{sub.subTitle}</div>
            {#each sub.child as child, index}
              <div class="flex-1 mb-2">
                {#if spiritualAnswers.length > 0}
                  <Textarea
                    bind:errors
                    name={child.id}
                    label={child.name}
                    labelBold={false}
                    maxlength="3000"
                    placeholder="Ketik jawaban disini"
                    bind:value={spiritualAnswers[i].child[ix].child[index].answer}
                  />
                {/if}
              </div>
            {/each}
          {/each}
        </CollapsibleSection>
      {/each}
    {/if}

    <div class="flex space-x-2">
      <div class="flex-1">
        <Textarea bind:errors name="note" label="Catatan {type}" maxlength="3000" placeholder="Sharing apa aja?" bind:value={$note.value} />
      </div>
    </div>

    {#if type == "121"}
      <div class="flex space-x-2">
        <div class="flex-1">
          <Textarea bind:errors name="prayNote" label="Catatan Doa" maxlength="3000" bind:value={$prayNote.value} />
        </div>
      </div>
    {/if}

    <!-- <div class={mobileClass}>
      <div class="flex-1">
        <Input
          name="duration"
          type="number"
          label="Durasi (menit)"
          suffix
          suffixText="menit"
          bind:errors
          bind:value={$duration.value}
        />
      </div>
    </div> -->

    <h4 class="font-semibold text-gray-700">Polling Yuk!</h4>

    <div class={mobileClass}>
      <div class="flex-1">
        <Rating label="Efektifitas {type}" name="effective" bind:value={$effective.value} bind:errors />
      </div>
      {#if errors.length > 0 && errors[0]["error"] == "required"}
        <div />
      {/if}
      <div class="flex-1 pt-6 md:pt-0">
        <Rating label="Joyful Feeling" name="joy" bind:value={$joy.value} bind:errors />
      </div>
    </div>

    <div class="mt-4" />

    <div class="flex w-full space-x-4">
      <button
        on:click={() => {
          if (checkBack()) {
            modalBack.toggle();
          } else {
            pop();
          }
        }}
        class="w-full text-white bg-gray-400 hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
        >Batal</button
      >
      <button
        on:click={add}
        disabled={disableSubmit}
        class="w-full text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
        >Simpan</button
      >
    </div>
  </div>
</section>

<style>
  .content {
    background-color: #f4f4f4;
    padding: 0.5em;
  }
</style>
