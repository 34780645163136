<script>
  export let headerText;
</script>

<details class="bg-white shadow rounded group mb-2">
  <summary
    class="list-none flex flex-wrap items-center cursor-pointer
    rounded group-open:rounded-b-none group-open:z-[1] relative
    "
  >
    <p class="flex flex-1 p-2 text-sm font-semibold">{headerText}</p>
    <div class="flex w-10 items-center justify-center">
      <div
        class="border-l-2 border-b-2 p-1 inline-block -rotate-45 border-l-gray-600 border-b-gray-600
        group-open:-rotate-[225deg] transition-transform origin-left
        "
      />
    </div>
  </summary>
  <div class="p-4">
    <slot />
  </div>
</details>
