<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  export let allMembers;
  export let data;
  export let cellGroupMemberList;
  let isCheckedAll = cellGroupMemberList.length == allMembers.length;

  function handleClick() {
    isCheckedAll = !isCheckedAll;
    let checkboxes = document.getElementsByName("foo");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = isCheckedAll;
    }
  }
</script>

<div class="flex items-center mb-4 mt-2">
  <input
    type="checkbox"
    id="checkAll"
    class="cursor-pointer"
    checked={isCheckedAll}
    on:change={() => {
      handleClick();
      dispatch("checkAll", isCheckedAll);
    }}
  />
  <label for="checkAll" class="ml-2 text-sm font-medium text-gray-500">Pilih Semua</label>
</div>
<table class="min-w-full divide-y divide-gray-200 mt-4" id="myTable">
  <thead class="bg-gray-100">
    <tr>
      <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500">Pilih</th>
      <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Nama </th>
      <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left text-gray-500"> Role </th>
    </tr>
  </thead>
  <tbody class="bg-white divide-y divide-gray-200">
    {#if data.length == 0}
      <tr>
        <td colspan="6" class="px-4 py-4 text-sm font-medium">
          <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
        </td>
      </tr>
    {/if}
    {#each data as item}
      <tr>
        <td class="px-3.5 text-sm font-medium whitespace-nowrap">
          <input
            name="foo"
            class="cursor-pointer"
            type="checkbox"
            checked={isCheckedAll ? true : cellGroupMemberList.findIndex((e) => e.id == item.id) != -1}
            on:click={() => dispatch("addOrRemoveMember", item)}
          />
        </td>
        <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">
            {item["name"]}
          </h2>
        </td>
        <td class="px-12 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-gray-800 text-xs">Member</h2>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
