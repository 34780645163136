<script>
  import { push } from "svelte-spa-router";
  import { onMount } from "svelte";
  import { checkRoleModule, parseJwt } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import { perPage, ADD_CELLGROUP_MODULE, urlApi, EXPORT_CELLGROUP } from "../../utils/const";
  import Loading from "../../components/Loading.svelte";
  import Modal from "../../components/Modal.svelte";
  import Select from "../../components/control/Select.svelte";
  import TableCellGroup from "../../components/table/CellGroup/TableCellGroup.svelte";
  import Pagination from "../../components/pagination/Pagination.svelte";
  import CellGroupCard from "../../components/card/CellGroup/CellGroupCard.svelte";
  import CellGroupServices from "./CellGroupServices.js";
  import CellGroupExportService from "./CellGroupExportService.js";
  import CellCategoryServices from "../CellCategory/CellCategoryServices";

  let cellgroups = [];
  let roleList = [];
  let loading;
  let search = "";
  let covenantYearSelected = "0";
  let statusSelected = "0";
  let page = 1;
  let totalData = 0,
    totalPage = 0;
  let modalCellGroupRegistryRequest;
  let cellGroupID = "";
  let cellGroupName = "";
  let covenantYearName = "";
  let cellGroupRegistryAccept = false;

  const token = parseJwt(localStorage.getItem("token"));
  let access = token.RoleID;
  let userId = token.UserID;

  let covenantYearOption = [{ id: "0", name: "Semua Kategori Sel" }];

  let statusOption = [
    { id: "0", name: "Semua Status" },
    { id: "1", name: "Aktif" },
    { id: "2", name: "Tidak Aktif" },
  ];
  $: reactiveRoleModules = $roleModules;
  $: if (reactiveRoleModules.length > 0 && loading) loading.toggle();
  onMount(async () => {
    await loadUserCellGroupAsMember();
    await loadAllData();
  });
  async function loadAllData() {
    loading.toggle();
    await loadCovenantYear();
    await loadData();
  }
  async function loadCovenantYear() {
    const option = "perPage=9999" + "&currentPage=1" + "&isDelete=0";
    const data = await CellCategoryServices.loadCellCategory(option);
    for (const item of data["data"]) {
      covenantYearOption.push({
        id: String(item.id),
        name: item.name,
      });
    }
    covenantYearOption = covenantYearOption;
  }

  async function loadData() {
    const covenantYearOption = covenantYearSelected == "0" ? "" : covenantYearSelected;
    const isActiveOption = statusSelected == "0" ? "" : statusSelected == "2" ? "0" : statusSelected;

    const option =
      "perPage=" +
      perPage +
      "&currentPage=" +
      page +
      "&searchQuery=" +
      search +
      "&isActive=" +
      isActiveOption +
      "&convenantYear=" +
      covenantYearOption +
      "&roleID=" +
      token.RoleID;
    const data = await CellGroupServices.loadCellGroupList(option);
    totalData = data["info"]["totalItem"];
    totalPage = data["info"]["totalPage"];
    cellgroups = data["data"];
    if (token.RoleID == 4) cellgroups = cellgroups.map((obj) => ({ ...obj, isShowDetail: roleList.indexOf(obj.id) != -1 }));
  }

  async function requestRegistryCellGroup() {
    loading.toggle();
    const reqData = {
      CellGroupID: cellGroupID,
      UserID: userId,
      Status: 2,
    };
    const data = await CellGroupServices.requestRegistryCellGroup(reqData);
    if (data["data"]["errorMessage"]) {
      globalThis.pushToast(data["data"]["errorMessage"], "error");
    } else {
      globalThis.pushToast("Request Register sudah dikirim", "success");
    }
    modalCellGroupRegistryRequest.toggle();
    loading.toggle();
  }

  function toggleCellGroupRegistryRequestModal(data) {
    cellGroupRegistryAccept = false;
    cellGroupID = data.id;
    cellGroupName = data.name;
    covenantYearName = data.covenantName;
    modalCellGroupRegistryRequest.toggle();
  }

  function searchData() {
    page = 1;
    loadData();
  }

  async function loadUserCellGroupAsMember() {
    if (token.RoleID != 4) return;
    const res = await fetch(urlApi + "/Users/" + token.UserID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    if (data["data"]) {
      roleList = data["data"]["roles"];
      roleList = roleList.filter((x) => x.rolesID == 4 && x.groupID != 0).map((x) => x.groupID);
    }
  }
  async function exportCellGroupReporting(id) {
    const isActiveOption = statusSelected == "0" ? "" : statusSelected == "2" ? "0" : statusSelected;
    const covenantYearOption = covenantYearSelected == "0" ? "" : covenantYearSelected;
    if (id) await CellGroupExportService.exportCellGroupReporting(id, null, null, null);
    else await CellGroupExportService.exportCellGroupReporting(null, search, isActiveOption, covenantYearOption);
  }
</script>

<Modal index={50} title="Sel Group Registry Request" bind:this={modalCellGroupRegistryRequest} size="default">
  <div class="flex flex-col items-start justify-start space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium mb-2">
      Apakah Anda yakin ingin mengikuti Sel Group <span class="font-bold">"{cellGroupName}"</span> untuk {covenantYearName}?
    </p>
    <div class="flex items-start">
      <input
        class="rounded-lg"
        id="default-checkbox"
        type="checkbox"
        checked={cellGroupRegistryAccept}
        on:click={() => (cellGroupRegistryAccept = !cellGroupRegistryAccept)}
      />
      <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-700"
        >Ya, saya ingin mengikuti Sel Group <span class="font-bold">"{cellGroupName}"</span> untuk {covenantYearName}
      </label>
    </div>

    <div class="flex space-x-2 justify-end items-end mt-4 w-full h-full px-5">
      <button
        on:click={() => modalCellGroupRegistryRequest.toggle()}
        class="px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg w-fit"
      >
        Batal
      </button>
      <button
        disabled={!cellGroupRegistryAccept}
        on:click={() => requestRegistryCellGroup()}
        class="px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg w-fit disabled:bg-gray-400"
      >
        Kirim
      </button>
    </div>
  </div>
</Modal>

<Loading bind:this={loading} />

<div class="p-0 md:p-4">
  <!-- component -->
  <section class="container mx-auto px-4 md:px-0">
    <div class="sm:flex sm:items-center sm:justify-between">
      <div>
        <div class="flex items-center gap-x-3">
          <h2 class="text-lg font-medium text-gray-800">Daftar Sel Group</h2>
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-end justify-between flex-col space-y-3 md:flex-row md:space-x-3 md:space-y-0">
      {#if checkRoleModule($roleModules, ADD_CELLGROUP_MODULE)}
        <div class="flex w-full md:w-auto">
          <button
            on:click={() => push("/cellgroup/add")}
            class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-lg
          shrink-0 sm:w-auto space-x-2 hover:opacity-80"
          >
            <i class="bx bx-plus" />

            <span>Tambah Sel Group</span>
          </button>
        </div>
      {/if}

      <div class="flex-1 flex flex-col space-y-3 w-full md:space-y-0 md:flex-row md:space-x-2">
        <div class="flex-1 flex items-center">
          <div class="relative w-full">
            <label class="block text-gray-900 text-xs font-semibold mb-1" for="search"> Cari </label>
            <div class="relative w-full">
              <input
                type="text"
                placeholder="Cari Nama Sel Group"
                bind:value={search}
                on:input={searchData}
                class="block w-full text-xs py-2 pr-5 text-gray-700 focus:border-gray-300 focus:ring-transparent bg-white
            border border-gray-200 rounded-lg placeholder-gray-400/70 pl-8 focus:outline-none"
              />
              <span class="absolute left-2 top-0 bottom-0 flex items-center">
                <i class="bx bx-search text-gray-500" />
              </span>
            </div>
          </div>
        </div>

        <div class="flex-1 flex space-x-2 w-full">
          <div class="flex-1">
            <Select
              on:change={(e) => {
                covenantYearSelected = e.detail;
                searchData();
              }}
              textHint="Pilih Kategori Sel"
              label="Kategori Sel"
              fullWidth
              name="covenant_year_search"
              options={covenantYearOption}
              bind:selected={covenantYearSelected}
            />
          </div>

          <div class="flex-1">
            <Select
              on:change={(e) => {
                statusSelected = e.detail;
                searchData();
              }}
              textHint="Pilih Status"
              label="Status"
              fullWidth
              name="status_search"
              options={statusOption}
              bind:selected={statusSelected}
            />
          </div>
        </div>
      </div>
    </div>
    {#if checkRoleModule($roleModules, EXPORT_CELLGROUP)}
      <div class="mt-4 flex justify-end">
        <button
          on:click={() => exportCellGroupReporting(null)}
          class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-md
              shrink-0 sm:w-auto space-x-2 hover:opacity-80"
        >
          <span>Export</span>
        </button>
      </div>
    {/if}
    <div class="flex flex-col mt-4">
      <div class="overflow-x-auto hidden md:block">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="overflow-hidden rounded-md">
            <TableCellGroup
              bind:data={cellgroups}
              bind:page
              on:detail={(e) => push("/cellgroup/" + e.detail + "/detail")}
              on:edit={(e) => push("/cellgroup/" + e.detail + "/edit")}
              on:member={(e) => push("/cellgroup/" + e.detail + "/member/removal")}
              on:request={(e) => toggleCellGroupRegistryRequestModal(e.detail)}
              on:export={(e) => exportCellGroupReporting(e.detail)}
            />
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="overflow-x-auto block md:hidden">
        {#if cellgroups.length == 0}
          <p class="font-medium text-gray-500 text-xs text-center mt-2">Belum ada data</p>
        {/if}
        {#each cellgroups as item, index}
          <CellGroupCard
            bind:data={item}
            {index}
            bind:page
            on:detail={() => push("/cellgroup/" + item["id"] + "/detail")}
            on:edit={() => push("/cellgroup/" + item["id"] + "/edit")}
            on:member={(e) => push("/cellgroup/" + e.detail + "/member/removal")}
            on:request={(e) => toggleCellGroupRegistryRequestModal(e.detail)}
            on:export={(e) => exportCellGroupReporting(e.detail)}
          />
        {/each}
      </div>
    </div>

    <div class="mt-6 sm:flex sm:items-center sm:justify-between">
      <div class="text-sm text-gray-500" />
      {#if cellgroups.length > 0 || page > 1}
        <Pagination
          bind:page
          {totalData}
          {totalPage}
          {perPage}
          on:prev={() => {
            page -= 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          on:next={() => {
            page += 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
      {/if}
    </div>
  </section>
</div>
