<script>
  import { format, parse } from "date-fns";
  import flatpickr from "flatpickr";
  import indonesian from "flatpickr/dist/l10n/id.js";
  import { onMount, afterUpdate, createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let form = undefined;
  export let name = undefined;
  export let pattern = "dd MMM yyyy";
  export let patternTime = "HH:mm";

  export let value = null;
  export let from = null;
  export let to = null;
  export let isLimitedMonth = false;
  export let maxDateToday = true;
  export let isMinDate = false;
  export let minDate = new Date();

  minDate.setHours(0, 0, 0, 0);

  export let label = undefined;
  export let circle = undefined;
  export let ranged = false;
  export let isTime = false;
  export let isDate = true;
  export let disabled = false;
  export let noIcon = false;
  export let textSize = "xs";
  export let labelSize = "xs";
  export let extClass = "";

  let element;
  let formattedValue = "";
  function formatDate(value1, value2) {
    return `${format(value1, pattern)} to ${format(value2, pattern)}`;
  }

  $: if (ranged) {
    formattedValue = formatDate(from, to);
  } else if (isTime && !isDate) {
    formattedValue = format(value, patternTime);
  } else if (isTime && isDate) {
    formattedValue = value;
  } else {
    formattedValue = format(value, pattern);
  }

  if (form && $form.fields[name]) {
    $form.fields[name].errors = "";
  }

  $: flatPickroptions = ranged
    ? {
        onChange: (selectedDates, dateStr, instance) => {
          if (selectedDates.length < 2) {
            return;
          }
          const fromDate = new Date(selectedDates[0]);
          const toDate = new Date(selectedDates[1]);
          const diffTime = Math.abs(toDate - fromDate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          fromDate.setHours(0, 0, 1);
          toDate.setHours(23, 59, 59);

          from = fromDate;
          to = toDate;

          dispatch("update", {
            fromDate,
            toDate,
          });
        },
        parseDate: (datestr) => {
          return parse(datestr, pattern, new Date());
        },
        formatDate: (date, _pattern, locale) => {
          return format(date, pattern);
        },
        disableMobile: true,
        mode: "range",
      }
    : isDate && isTime
    ? {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        time_24hr: true,
        defaultDate: value,
        onChange: (selectedDates, dateStr, instance) => {
          const fromDate = new Date(selectedDates[0]);
          dispatch("update", {
            fromDate,
          });
        },
        disableMobile: true,
        minDate: value,
      }
    : !isDate && isTime
    ? {
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        dateFormat: "H:i",
        onChange: (val) => {
          const newDate = new Date(val);
          value = newDate;
        },
        disableMobile: true,
      }
    : {
        onChange: (selectedDates, dateStr, instance) => {
          value = new Date(selectedDates[0]);
        },
        parseDate: (datestr) => {
          return parse(datestr, pattern, value);
        },
        formatDate: (date, _pattern, locale) => {
          return format(date, pattern);
        },
        disableMobile: true,
        minDate: isMinDate ? minDate : null,
        maxDate: maxDateToday ? new Date() : null,
      };

  function initFlatpickr() {
    if (element) {
      flatpickr(element, disabled ? { ...flatPickroptions, clickOpens: false, locale: indonesian } : flatPickroptions);
    }
  }

  onMount(() => {
    if (ranged) {
      if (!from) {
        from = new Date();
      }
      if (!to) {
        to = new Date();
      }
    } else {
      if (!isTime && !value) {
        value = new Date();
      }
    }
    initFlatpickr();
  });

  afterUpdate(initFlatpickr);
</script>

<div>
  {#if label}
    <label class="block text-gray-900 text-{labelSize} font-semibold mb-1" for={value}>
      {label}
    </label>
  {/if}
  <div class="relative">
    <input
      type="text"
      bind:this={element}
      value={formattedValue}
      class="border-gray-200
            {circle
        ? 'rounded-full'
        : 'rounded-lg  '} relative ring-transparent focus:ring-transparent focus:border-gray-200 block w-full text-left text-{textSize} text-gray-700 {extClass} border py-2 px-3 cursor-pointer"
      placeholder={ranged ? "Select date range" : isTime && isDate ? "Select Date" : "Select Time"}
    />
    {#if !noIcon}
      <div class="absolute top-0 bottom-0 right-2 hidden md:flex items-center">
        <i class="bx bx-calendar bx-xs text-gray-300" />
      </div>
    {/if}
  </div>
</div>
