export function minLength(length) {
  return (value) => ({ valid: value.length >= length, name: "minLength" });
}

export function containAlphabet() {
  var regExp = /[A-Za-z]/;
  return (value) => ({ valid: regExp.test(value), name: "containAlphabet" });
}

export function containNumber() {
  var regExp = /[0-9]/;
  return (value) => ({ valid: regExp.test(value), name: "containNumber" });
}

export function containSymbol() {
  var regExp = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return (value) => ({ valid: regExp.test(value), name: "containSymbol" });
}

export function phoneMinLength(length) {
  return (value) => ({ valid: value.length >= length, name: "phoneMinLength" });
}
