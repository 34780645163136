<script>
  import { createEventDispatcher } from "svelte";
  import CellGroupServices from "../../../pages/CellGroup/CellGroupServices";

  const dispatch = createEventDispatcher();

  export let data;
  export let covenantYearName;
  export let mode;
</script>

<div class="card-data min-w-full mb-3 p-2 border border-primary shadow rounded-md">
  <div class="flex justify-between items-center text-xs font-medium text-gray-800">
    <div class="flex flex-col justify-start items-start">
      <p class="flex-1 line-clamp-2 text-right text-lg font-bold">{data["name"]}</p>
      <p class="flex-1 line-clamp-2 text-right text-xs">Member</p>
      {#if mode == "add" || mode == "edit"}
        <p class="flex-1 line-clamp-2 text-left text-xs font-semibold {data['status'] == 1 ? 'text-gray-800' : 'text-red-500'} ">
          {CellGroupServices.resolveCellGroupMemberKeterangan(data["status"],covenantYearName)}
        </p>
      {/if}
    </div>
    {#if mode == "add" || mode == "edit"}
      <div class="flex-1 flex space-x-1 justify-end">
        <button on:click={() => dispatch("delete", data)} class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100">
          <i class="bx bx-trash text-xl text-red-500" />
        </button>
      </div>
    {/if}
  </div>
</div>
