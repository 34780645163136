import { wrap } from "svelte-spa-router/wrap";
import Home from "./pages/Home.svelte";
import Auth from "./pages/Auth/Auth.svelte";
import Reset from "./pages/Auth/Reset.svelte";
import User from "./pages/User/User.svelte";
import Journal from "./pages/Journal/Journal.svelte";
import NewPassword from "./pages/Auth/NewPassword.svelte";
import NotFound from "./pages/NotFound.svelte";
import One from "./pages/One/One.svelte";
import Attendance from "./pages/Attendance/Attendance.svelte";
import CellGroup from "./pages/CellGroup/CellGroup.svelte";
import Learning from "./pages/Learning/Learning.svelte";
import AttendanceCategory from "./pages/AttendanceCategory/AttendanceCategory.svelte";
import CellCategory from "./pages/CellCategory/CellCategory.svelte";
import { parseJwt } from "./utils/utils";

function routeConditions() {
  return [
    () => {
      return localStorage.getItem("token") != undefined &&
        localStorage.getItem("token") != null &&
        localStorage.getItem("name") != undefined &&
        localStorage.getItem("name") != null
        ? true
        : false;
    },
  ];
}
const token = parseJwt(localStorage.getItem("token"));
const routes = {
  "/": wrap({
    component: Home,
    userData: {
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/login": Auth,
  "/login/*": Auth,
  "/email-verification/*": Auth,
  "/register": Auth,
  "/reset-password": Reset,
  "/new-password/*": NewPassword,
  "/new-password": NewPassword,
  // pages
  "/users": wrap({
    component: User,
    userData: {
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/users/*": wrap({
    component: User,
    userData: {
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),

  "/journals": wrap({
    component: Journal,
    userData: {
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/journals/*": wrap({
    component: Journal,
    userData: {
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/ones": wrap({
    component: One,
    userData: {
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/ones/*": wrap({
    component: One,
    userData: {
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/attendances": wrap({
    component: Attendance,
    userData: {
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/attendances/*": wrap({
    component: Attendance,
    userData: {
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/cellgroup": wrap({
    component: CellGroup,
    userData: {
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/cellgroup/*": wrap({
    component: CellGroup,
    userData: {
      token: localStorage.getItem("token"),
      name: localStorage.getItem("name"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/learning": wrap({
    component: Learning,
    userData: {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/learning/*": wrap({
    component: Learning,
    userData: {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/attendancecategory": wrap({
    component: AttendanceCategory,
    userData: {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/attendancecategory/*": wrap({
    component: AttendanceCategory,
    userData: {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/cellcategory": wrap({
    component: CellCategory,
    userData: {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),
  "/cellcategory/*": wrap({
    component: CellCategory,
    userData: {
      token: localStorage.getItem("token"),
      email: localStorage.getItem("email"),
      id: token.UserID,
    },
    conditions: routeConditions(),
  }),

  // Catch-all
  // This is optional, but if present it must be the last
  "*": NotFound,
};

export default routes;
