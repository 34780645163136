<script>
  import { inputClass, inputErrClass } from "../../utils/const";
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();

  export let name;
  export let value; // value only to display the text, real value (id) needs to be reassign on parent
  export let errors = [];
  export let label;
  export let type = "text";
  export let placeholder = "";
  export let noLabel = false;
  export let disabled = false;
  export let readonly = true;

  export let extClass = "";
  let extPattern = "";

  $: if (errors.length > 0) {
    errors = errors
      .filter((err) => err.split(".")[0] == name)
      .map((e) => ({
        field: e.split(".")[0],
        error: e.split(".")[1],
      }));
    errors = errors;
  }
</script>

<div>
  {#if !noLabel}
    <label for={name} class="block mb-1 text-sm font-semibold text-gray-900">{label}</label>
  {/if}
  <div class="relative">
    <input
      class="appearance-none cursor-pointer {errors.length > 0 ? inputErrClass + extClass : inputClass + extClass}"
      {type}
      {name}
      {value}
      id={name}
      pattern={extPattern}
      {placeholder}
      {disabled}
      {readonly}
      on:click={() => {
        dispatch("click");
      }}
    />
    {#if !value}
      <div class="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
        <svg aria-hidden="true" class="w-5 h-5 text-blue-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
          ><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg
        >
      </div>
    {/if}
    {#if value}
      <div class="absolute inset-y-0 right-2 flex items-center pl-3 cursor-pointer" on:click={() => dispatch("clear")}>
        <svg
          fill="none"
          class="w-5 h-5 text-red-600 pointer-events-none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    {/if}
  </div>
  {#if errors.length > 0 && errors[0]["error"] == "required"}
    <p class="text-xs text-red-500 p-0 mt-1">{label} harus diisi</p>
  {/if}
  {#if errors.length > 0 && errors[0]["error"] == "not_an_email"}
    <p class="text-xs text-red-500 p-0 mt-1">{label} tidak valid</p>
  {/if}
  {#if errors.length > 0 && errors[0]["error"] == "match_field"}
    <p class="text-xs text-red-500 p-0 mt-1">Password harus sama</p>
  {/if}
  {#if errors.length > 0 && errors[0]["error"] == "duplicate"}
    <p class="text-xs text-red-500 p-0 mt-1">{label} telah terdaftar</p>
  {/if}
  {#if errors.length > 0 && errors[0]["error"] == "min"}
    <p class="text-xs text-red-500 p-0 mt-1">{label} minimal 1</p>
  {/if}
</div>

<style>
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
