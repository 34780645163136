<script>
  import { push } from "svelte-spa-router";
  import { onMount } from "svelte";
  import Loading from "../../components/Loading.svelte";
  import JournalExportService from "./JournalExportService";
  import JournalService from "./JournalService";
  import Select from "../../components/control/Select.svelte";
  import Pagination from "../../components/pagination/Pagination.svelte";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import { addDays, differenceInDays } from "date-fns";
  import TableJournal from "../../components/table/TableJournal.svelte";
  import { formatDate, parseJwt, checkRoleModule, formatDateForView } from "../../utils/utils";
  import JournalCard from "../../components/card/JournalCard.svelte";
  import { typeJournalOption, perPage, urlApi, EXPORT_JOURNAL } from "../../utils/const";
  import { roleModules } from "../../stores/store";
  import Modal from "../../components/Modal.svelte";

  const token = parseJwt(localStorage.getItem("token"));
  let journals = [];
  let loading;
  let page = 1;
  let search = "";
  let totalData = 0,
    totalPage = 0;

  let access = token.RoleID;

  let from = addDays(new Date(), -7);
  let to = new Date();

  let typeJournalSelected = "0";
  let modalDeleteJournal;
  let idToDelete;
  let disableSimpan = false;
  let creatorName;
  let jouralType;
  let journalDate;
  let showType = false;

  onMount(() => {
    loadData();
  });

  async function loadData() {
    loading.toggle();
    const journalTypeOption = typeJournalSelected == 0 ? "" : typeJournalOption.find((item) => item.id == typeJournalSelected).name;
    const option =
      "perPage=" +
      perPage +
      "&currentPage=" +
      page +
      "&searchQuery=" +
      search +
      "&fromDate=" +
      formatDate(from) +
      "&toDate=" +
      formatDate(to) +
      "&journalType=" +
      journalTypeOption;
    const res = await fetch(urlApi + "/Journals?" + option, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    totalData = data["data"] ? data["info"]["totalItem"] : data;
    totalPage = data["data"] ? data["info"]["totalPage"] : 1;
    journals = access == 4 || access == 5 ? data["data"] : []; // only display data for member and SA
    loading.toggle();
  }

  function searchData() {
    page = 1;
    loadData();
  }
  async function exportJournalReporting() {
    const diffInDays = differenceInDays(to, from) + 1;
    await JournalExportService.exportJournalReporting(from, to, Math.floor(diffInDays));
  }
  function onDeleteJournal(journalID, name, type, date) {
    idToDelete = journalID;
    creatorName = name;
    jouralType = type;
    journalDate = date;
    modalDeleteJournal.toggle();
  }
  async function deleteJournal() {
    disableSimpan = true;
    loading.toggle();
    const reqData = { ID: idToDelete };
    const data = await JournalService.deleteJournal(reqData);
    if (data["data"] == 1) {
      globalThis.pushToast("Berhasil Menghapus Journal", "success");
      loadData();
    }
    disableSimpan = false;
    loading.toggle();
    modalDeleteJournal.toggle();
  }
</script>

<Loading bind:this={loading} />
<Modal
  index={50}
  title="Hapus Covenant Journal"
  bind:this={modalDeleteJournal}
  size="default"
  closed
  on:close={() => {
    modalDeleteJournal.toggle();
  }}
>
  <div class="flex flex-col items-start justify-start space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">
      Apakah Anda yakin akan menghapus data Covenant Journal <span class="font-bold">"{jouralType}""</span> miliki
      <span class="font-bold">"{creatorName}"</span>
      di tanggal <span class="font-bold">" {formatDateForView(new Date(journalDate))}"</span>?
    </p>
    <div class="flex space-x-2 justify-end items-end mt-4 w-full h-full px-5">
      <button
        on:click={() => {
          modalDeleteJournal.toggle();
        }}
        class="px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg w-fit"
      >
        Batal
      </button>
      <button
        on:click={() => {
          deleteJournal();
        }}
        disabled={disableSimpan}
        class="px-3 py-2 text-white text-sm font-medium bg-red-500 transition-colors duration-200 rounded-lg w-fit"
      >
        Hapus
      </button>
    </div>
  </div>
</Modal>
<div class="p-0 md:p-4">
  <!-- component -->
  <section class="container mx-auto px-4 md:px-0">
    <div class="sm:flex sm:items-center sm:justify-between">
      <div>
        <div class="flex items-center gap-x-3">
          <h2 class="text-lg font-medium text-gray-800">Covenant Journal</h2>
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-end justify-between flex-col space-y-3 md:flex-row md:space-y-0">
      {#if access == 4}
        <div class="flex w-full md:w-auto relative mr-2">
          <button
            on:click={() => (showType = !showType)}
            class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-lg
            shrink-0 sm:w-auto space-x-2 hover:opacity-80"
          >
            <i class="bx bx-plus" />

            <span>Tambah Journal</span>
          </button>
          {#if showType}
            <div class="absolute top-10 left-0 flex flex-col space-y-1 w-full bg-white z-20">
              {#each typeJournalOption as type}
                {#if type.id != 0}
                  <button
                    on:click={() => push("/journals/add/" + type.name)}
                    class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-md
            shrink-0 sm:w-auto space-x-2 hover:opacity-80"
                  >
                    <span>{type.name}</span>
                  </button>
                {/if}
              {/each}
            </div>
          {/if}
        </div>
      {/if}

      <div class="flex-1 flex flex-col space-y-3 w-full md:space-y-0 md:flex-row md:space-x-2">
        <!-- Search bar -->
        {#if access == 4 || access == 5}
          <div class="flex-1 flex items-center">
            <div class="relative w-full">
              <label class="block text-gray-900 text-xs font-semibold mb-1" for="search"> Cari </label>
              <div class="relative w-full">
                <input
                  type="text"
                  placeholder="Cari pemilik journal.."
                  bind:value={search}
                  on:input={searchData}
                  class="block w-full text-xs py-2 pr-5 text-gray-700 focus:border-gray-300 focus:ring-transparent bg-white
              border border-gray-200 rounded-lg placeholder-gray-400/70 pl-8 focus:outline-none"
                />
                <span class="absolute left-2 top-0 bottom-0 flex items-center">
                  <i class="bx bx-search text-gray-500" />
                </span>
              </div>
            </div>
          </div>
        {/if}
        <!-- Select -->
        <div class="flex-1 flex space-x-1 w-full">
          <div class="flex-1">
            <Select
              on:change={(e) => {
                typeJournalSelected = e.detail;
                searchData();
              }}
              textHint="Pilih Tipe"
              label="Tipe Journal"
              fullWidth
              name="typeJournal_search"
              options={typeJournalOption}
              bind:selected={typeJournalSelected}
            />
          </div>
        </div>

        <!-- Datepicker -->
        <div class="flex-1 flex space-x-1 w-full">
          <div class="flex-1">
            <DatePicker label="Tanggal" name="date" bind:from bind:to ranged on:update={searchData} />
          </div>
        </div>
      </div>
    </div>
    {#if checkRoleModule($roleModules, EXPORT_JOURNAL)}
      <div class="mt-4 flex justify-end">
        <button
          on:click={() => exportJournalReporting()}
          class="flex items-center justify-center w-full px-5 py-2 text-xs text-white transition-colors duration-200 bg-primary border border-primary rounded-md
              shrink-0 sm:w-auto space-x-2 hover:opacity-80"
        >
          <span>Export</span>
        </button>
      </div>
    {/if}

    <div class="flex flex-col mt-4">
      <div class="overflow-x-auto hidden md:block">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="overflow-hidden rounded-md">
            <TableJournal
              bind:data={journals}
              bind:page
              bind:totalData
              on:detail={(e) => push("/journals/" + e.detail["id"] + "/" + e.detail["type"] + "/detail")}
              on:edit={(e) => push("/journals/" + e.detail["id"] + "/" + e.detail["type"] + "/edit")}
              on:delete={(e) => onDeleteJournal(e.detail["id"], e.detail["name"], e.detail["type"], e.detail["date"])}
            />
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="overflow-x-auto block md:hidden">
        {#if journals.length == 0 && totalData == 0}
          <p class="font-medium text-gray-500 text-xs text-center mt-2">Belum ada data</p>
        {:else if journals.length == 0 && totalData > 0}
          <p class="font-medium text-gray-500 text-xs text-center mt-2">Terdapat {totalData} Data Covenant Journal</p>
        {/if}
        {#each journals as item, index}
          <JournalCard
            bind:data={item}
            {index}
            bind:page
            on:detail={(e) => push("/journals/" + e.detail["id"] + "/" + e.detail["type"] + "/detail")}
            on:edit={(e) => push("/journals/" + e.detail["id"] + "/" + e.detail["type"] + "/edit")}
            on:delete={() => onDeleteJournal(item["id"], item["name"], item["type"], item["date"])}
          />
        {/each}
      </div>
    </div>

    <div class="mt-6 sm:flex sm:items-center sm:justify-between">
      <div class="text-sm text-gray-500" />
      {#if journals.length > 0 || page > 1}
        <Pagination
          bind:page
          {totalData}
          {totalPage}
          {perPage}
          on:prev={() => {
            page -= 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          on:next={() => {
            page += 1;
            loadData();
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />
      {/if}
    </div>
  </section>
</div>
