<script>
  import Input from "../../components/control/Input.svelte";
  import Textarea from "../../components/control/Textarea.svelte";
  import Select from "../../components/control/Select.svelte";
  import Switch from "../../components/control/Switch.svelte";
  import DatePicker from "../../components/control/DatePicker.svelte";
  import { pop, replace } from "svelte-spa-router";
  import { createEventDispatcher, onMount } from "svelte";
  import { departmentOptionData, roleOptionData, educationOptionData } from "../../utils/const";
  import { EDIT_USER_BASIC_MODULE, EDIT_USER_SUPER_MODULE } from "../../utils/const";
  import { parseJwt, checkRoleModule, getValueAccess } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import Compressor from "compressorjs";
  import Modal from "../Modal.svelte";
  import TableRole from "../table/TableRole.svelte";
  import RoleCard from "../card/RoleCard.svelte";

  const dispatch = createEventDispatcher();

  export let access;
  export let address;
  export let baptismalName;
  export let departmentSelected;
  export let dateOfBirth;
  export let disableSubmit;
  export let concernedID = ""; //currently viewed or edited ID
  export let educationSelected;
  export let emails;
  export let errors = [];
  export let formTitle;
  export let fullName;
  export let imageSrc = "";
  export let base64Image;
  export let job;
  export let mode;
  export let nickname;
  export let note;
  export let parokiChurch;
  export let phone;
  export let placeOfBirth;
  export let roleList = [];
  export let roleSelected;
  export let status;

  const token = parseJwt(localStorage.getItem("token"));

  let departmentOption = departmentOptionData;
  let educationOption = educationOptionData;
  let loggedInID = token.UserID;
  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";
  let noProfilePictureSrc = "https://archive.org/download/no-photo-available/no-photo-available.png";
  let rolesOption = roleOptionData;

  let modalRoles;

  onMount(async () => {
    // redirect kalau head,gembala,wakil mencoba edit akun orang lain
    if (mode == "edit" && loggedInID != concernedID && !checkRoleModule($roleModules, EDIT_USER_SUPER_MODULE)) replace("/journals/");
  });

  function checkBack() {
    let result = false;
    if (fullName != "" || emails != "" || phone != "") {
      result = true;
    }
    return result;
  }

  function handleChange(event) {
    // accept single file only
    compressImage(event.target.files[0]);
  }
  function compressImage(file) {
    new Compressor(file, {
      maxWidth: "1000",
      success(result) {
        convertImage(result);
      },
      error(err) {
        // eslint-disable-next-line
        console.log(err.message);
      },
    });
  }
  function convertImage(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      imageSrc = URL.createObjectURL(file);
      base64Image = reader.result;
      // imgSrc, imgText, file;
    };
    reader.onerror = function (error) {
      // eslint-disable-next-line
      console.log(error);
      this.$emit(error);
    };
  }

  //disable for input name,email
  function checkBasicSuperDisabled() {
    if (mode == "detail") return true;
    else if (mode == "edit" && loggedInID != concernedID && access != 5) return true; // only super admin and concerned user can edit
    else return false;
  }

  // disable super admin input (role,department,covenantYear...)
  function checkSuperDisabled() {
    if (mode == "detail") return true;
    else if ((mode == "edit" || mode == "add") && (!checkRoleModule($roleModules, EDIT_USER_SUPER_MODULE) || concernedID == token.UserID))
      return true; // disable input if not user admin or admin editing themselves
    else return false;
  }
  //disable basic input (nickname,job....)
  function checkBasicDisabled() {
    if (mode == "detail" || mode == "add") return true;
    else if (mode == "edit" && !checkRoleModule($roleModules, EDIT_USER_BASIC_MODULE) && loggedInID != concernedID)
      return true; // disable input if not basic user
    else return false;
  }
</script>

<Modal withTitle title="Role Akses" index="20" bind:this={modalRoles} size="large">
  <div class="hidden md:block">
    <TableRole roles={roleList} />
  </div>
  <div class="max-h-96 sm:max-h-[480px] overflow-y-auto block md:hidden">
    {#each roleList as role}
      <RoleCard bind:data={role} />
    {/each}
  </div>
</Modal>

<div>
  <div class="sm:flex sm:items-center sm:justify-between">
    <div class="w-full flex items-center justify-between">
      <div class="flex-1 flex items-center gap-x-3">
        <button
          on:click={() => {
            if (checkBack() && mode != "detail") {
              dispatch("back");
            } else {
              pop();
            }
          }}
        >
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-lg font-medium text-gray-800">{formTitle}</h2>
      </div>
      {#if (access == 5 || loggedInID == concernedID) && mode == "detail"}
        <div class="flex items-center justify-end">
          <button
            on:click={() => replace("/users/" + concernedID + "/edit")}
            class="text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 md:mt-4 text-center transition-all"
            >Edit
          </button>
        </div>
      {/if}
    </div>
  </div>
  <!-- Foto Profil -->
  <div class="flex flex-col items-center gap-3">
    <div class="relative">
      <img
        class="w-20 h-20 border rounded-full object-cover"
        src={imageSrc != null && imageSrc != undefined && imageSrc != "" ? imageSrc : noProfilePictureSrc}
        alt="profile"
      />

      {#if mode != "detail"}
        <i
          class="bx bx-pencil md:hidden text-xl text-white absolute bottom-0 right-0 rounded-full px-1 {checkBasicDisabled()
            ? 'bg-gray-500'
            : 'bg-blue-500'}"
        >
          <input
            class="md:hidden absolute top-0 block py-2 px-4 w-full opacity-0 pin-r pin-t"
            type="file"
            name="image"
            accept="image/*"
            disabled={checkBasicDisabled()}
            on:change={handleChange}
          />
        </i>
      {/if}
    </div>
    {#if mode != "detail"}
      <div class="overflow-hidden relative w-40">
        <button
          disabled={checkBasicDisabled()}
          class="hidden md:flex items-center justify-center bg-blue-500 hover:bg-blue-600 rounded cursor-pointer text-white font-bold py-2 px-4 w-full disabled:bg-gray-500"
        >
          <svg fill="#FFF" height="16" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
          </svg>
          <span class="ml-1 text-sm font-semibold">Upload Image</span>
        </button>
        <input
          class="hidden md:block cursor-pointer absolute top-0 py-2 px-4 w-full opacity-0 pin-r pin-t"
          type="file"
          name="image"
          accept="image/*"
          disabled={checkBasicDisabled()}
          on:change={handleChange}
        />
      </div>
    {/if}
  </div>

  <div class="mt-5 flex flex-col space-y-4">
    <!-- Nama Lengkop AND Nama Baptis -->
    <div class={mobileClass}>
      <div class="flex-1">
        <Input
          name="fullName"
          type="text"
          label="Nama Lengkap"
          placeholder="Ketik Nama Lengkap"
          maxlength="50"
          bind:errors
          bind:value={fullName}
          extClass={checkBasicSuperDisabled() ? " bg-gray-100 text-slate-400 " : ""}
          disabled={checkBasicSuperDisabled()}
        />
      </div>
      <div class="flex-1">
        <Input
          name="baptismalName"
          type="text"
          label="Nama Baptis"
          placeholder="Ketik Nama Baptis"
          maxlength="50"
          bind:errors
          bind:value={baptismalName}
          extClass={checkBasicSuperDisabled() ? " bg-gray-100 text-slate-400 " : ""}
          disabled={checkBasicDisabled()}
        />
      </div>
    </div>

    <!-- Nama Panggilan  AND Tempat Lahir -->
    <div class={mobileClass}>
      <div class="flex-1">
        <Input
          name="nickname"
          type="text"
          label="Nama Panggilan"
          placeholder="Ketik Nama Panggilan"
          maxlength="30"
          bind:errors
          bind:value={nickname}
          extClass={checkBasicSuperDisabled() ? " bg-gray-100 text-slate-400 " : ""}
          disabled={checkBasicDisabled()}
        />
      </div>
      <div class="flex-1">
        <Input
          name="placeOfBirth"
          type="text"
          label="Tempat Lahir"
          placeholder="Ketik Tempat Lahir"
          maxlength="30"
          bind:errors
          bind:value={placeOfBirth}
          extClass={checkBasicSuperDisabled() ? " bg-gray-100 text-slate-400 " : ""}
          disabled={checkBasicDisabled()}
        />
      </div>
    </div>

    <!-- Tanggal Lahir AND Pendidikan Terakhir -->
    <div class={mobileClass}>
      <div class="flex-1">
        <DatePicker
          name="dateOfBirth"
          label="Tanggal Lahir"
          labelSize="sm"
          textSize="sm"
          bind:value={dateOfBirth}
          extClass={checkBasicDisabled() ? " bg-gray-100 text-slate-400 font-semibold " : "font-semibold"}
          disabled={checkBasicDisabled()}
        />
      </div>
      <div class="flex-1">
        <Select
          on:change={(e) => {
            educationSelected = e.detail;
          }}
          textHint="Pilih Pendidikan Terakhir"
          fullWidth
          name="educationSelected"
          label="Pendidikan Terakhir"
          textSize="text-sm"
          options={educationOption}
          extClass={checkBasicDisabled() ? " bg-gray-100 text-slate-400 font-semibold " : "font-semibold"}
          disabled={checkBasicDisabled()}
          bind:errors
          bind:selected={educationSelected}
        />
      </div>
    </div>

    <!-- Pekerjaan AND Alamat Lengkap -->
    <div class={mobileClass}>
      <div class="flex-1">
        <Input
          name="job"
          type="text"
          label="Pekerjaan"
          placeholder="Ketik Pekerjaan"
          maxlength="30"
          bind:errors
          bind:value={job}
          extClass={checkBasicDisabled() ? " bg-gray-100 text-slate-400 " : ""}
          disabled={checkBasicDisabled()}
        />
      </div>
      <div class="flex-1">
        <Input
          name="address"
          type="text"
          label="Alamat Lengkap"
          placeholder="Ketik Alamat Lengkap"
          maxlength="150"
          bind:errors
          bind:value={address}
          extClass={checkBasicDisabled() ? " bg-gray-100 text-slate-400 " : ""}
          disabled={checkBasicDisabled()}
        />
      </div>
    </div>

    <!-- No Handphone and Email -->
    <div class={mobileClass}>
      <div class="flex-1">
        <Input
          name="phone"
          type="phone"
          label="No. Whatsapp"
          maxlength="20"
          minLength="9"
          bind:errors
          bind:value={phone}
          extClass={checkBasicDisabled() ? " bg-gray-100 text-slate-400 " : ""}
          disabled={checkBasicDisabled()}
        />
      </div>
      <div class="flex-1">
        <!-- disable edit email for Super Admin -->
        <Input
          name="emails"
          type="email"
          maxlength="50"
          label="Email"
          placeholder="Ketik Email"
          bind:errors
          bind:value={emails}
          extClass={checkBasicSuperDisabled() || roleSelected == 5 ? " bg-gray-100 text-slate-400 " : ""}
          disabled={checkBasicSuperDisabled() || roleSelected == 5}
        />
      </div>

      <div class="flex-1">
        <Input
          name="parokiChurch"
          type="text"
          label="Paroki Gereja"
          placeholder="Ketik Paroki Gereja"
          maxlength="50"
          bind:errors
          bind:value={parokiChurch}
          extClass={checkBasicDisabled() ? " bg-gray-100 text-slate-400 " : ""}
          disabled={checkBasicDisabled()}
        />
      </div>
    </div>

    <!-- Role, Supervisor and Department -->
    <div class={mobileClass}>
      <div class="flex-1">
        {#if mode == "add" || mode == "edit"}
          <Select
            textHint="Pilih Role"
            fullWidth
            name="roleSelected"
            extClass="font-semibold"
            label="Role Akses"
            textSize="text-sm"
            options={rolesOption}
            disabled={true}
            bind:errors
            bind:selected={roleSelected}
          />
        {:else}
          <Input
            name="roleSelected"
            label="Role Akses"
            suffixBtn
            suffixBtnIcon="bx-search"
            value={getValueAccess(roleSelected)}
            on:suffix={() => {
              modalRoles.toggle();
            }}
            disabled
          />
        {/if}
      </div>

      <div class="flex-1">
        <Select
          on:change={(e) => {
            departmentSelected = e.detail;
          }}
          textHint="Pilih Departement"
          fullWidth
          name="departmentSelected"
          extClass="font-semibold"
          label="Departemen"
          textSize="text-sm"
          bind:errors
          options={departmentOption}
          disabled={checkSuperDisabled()}
          bind:selected={departmentSelected}
        />
      </div>
    </div>

    <!-- Status -->
    <div class={mobileClass}>
      <div class="flex-1">
        <label for="role" class="block mb-2 text-sm font-semibold text-gray-900">Status</label>
        <Switch
          on:change={(e) => {
            status = e.detail.checked;
          }}
          bind:value={status}
          disabled={checkSuperDisabled()}
          checked={status}
          name="status"
          textRight={status ? "Aktif" : "Tidak Aktif"}
        />
      </div>
    </div>

    <!-- Catatan -->
    <div class="flex space-x-2">
      <div class="flex-1">
        <Textarea
          bind:errors
          name="note"
          label="Catatan"
          maxlength="3000"
          bind:value={note}
          disabled={checkBasicSuperDisabled()}
          extClass={checkBasicSuperDisabled() ? " bg-gray-100 text-slate-400 " : ""}
        />
      </div>
    </div>

    <!-- Button Cancel dan Simpan -->
    {#if mode != "detail"}
      <div class="flex w-full space-x-4">
        <button
          on:click={() => {
            if (checkBack() && mode != "detail") {
              dispatch("back");
            } else {
              pop();
            }
          }}
          class="w-full text-white bg-gray-400 hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
          >Batal</button
        >
        <button
          on:click={() => {
            dispatch("save");
          }}
          disabled={disableSubmit}
          class="w-full text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
          >Simpan</button
        >
      </div>
    {/if}
  </div>
</div>
