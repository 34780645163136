<script>
  import { field, form } from "svelte-forms";
  import { replace } from "svelte-spa-router";
  import { required } from "svelte-forms/validators";
  import CellGroupForm from "./CellGroupForm.svelte";
  import Cookies from "js-cookie";
  import Loading from "../../components/Loading.svelte";
  import { onMount } from "svelte";
  import CellGroupServices from "./CellGroupServices.js";
  import { parseJwt, checkRoleModule } from "../../utils/utils";
  import { roleModules } from "../../stores/store";
  import { DETAIL_CELLGROUP_MODULE } from "../../utils/const";

  const token = parseJwt(localStorage.getItem("token"));
  let access = token.RoleID;
  export let params;

  let cellGroupMemberList = [];
  let covenantYearSelected = field("covenantYearSelected", "", [required()]);
  let dateCreated = field("dateCreated", new Date(), [required()]);
  let gembalaSelected;
  let pGembalaSelected;
  let headGembalaSelected;
  let name = field("name", "", [required()]);
  let wakilGembalaSelected;
  let pWakilGembalaSelected;
  let status = true;
  let cellgroup;

  let errors = [];

  onMount(async () => {
    await loadCellGroupMemberList();
    if (
      !checkRoleModule($roleModules, DETAIL_CELLGROUP_MODULE) &&
      token.RoleID == 4 &&
      cellGroupMemberList.findIndex((x) => x.id == token.UserID) == -1
    ) {
      replace("/cellgroup/");
      return;
    }
    loadData();
  });

  async function loadData() {
    await loadCellGroupData();
  }
  async function loadCellGroupData() {
    const option = "id=" + params["_id"];
    const data = await CellGroupServices.loadCellGroupList(option);
    cellgroup = data["data"];
    $covenantYearSelected.value = cellgroup[0].covenantYearID.toString();
    $dateCreated.value = new Date(cellgroup[0].dateCreated);

    gembalaSelected = cellgroup[0].gembalaID;
    pGembalaSelected = cellgroup[0].pendampingGembalaID;
    headGembalaSelected = cellgroup[0].headGembalaID;
    $name.value = cellgroup[0].name;
    wakilGembalaSelected = cellgroup[0].wakilGembalaID;
    pWakilGembalaSelected = cellgroup[0].pendampingWakilGembalaID;
    status = cellgroup[0].isActive;
  }

  async function loadCellGroupMemberList() {
    const status = "memberList";
    const option = "perPage=9999" + "&cellGroupID=" + params["_id"] + "&status=" + status;
    const data = await CellGroupServices.loadCellGroupMemberList(option);
    let member = data["data"];
    cellGroupMemberList = member
      .filter((item) => item.isActive == true)
      .map((u) => ({
        id: u["userID"],
        name: u["userName"],
        roleName: u["roleName"],
      }));
  }
</script>

{#if cellgroup}
  <section class="container px-4 py-4">
    <CellGroupForm
      {access}
      formTitle="Detail Sel Group"
      mode="detail"
      bind:cellGroupMemberList
      bind:concernedID={params["_id"]}
      bind:covenantYearSelected={$covenantYearSelected.value}
      bind:dateCreated={$dateCreated.value}
      bind:gembalaSelected
      bind:pGembalaSelected
      bind:headGembalaSelected
      bind:name={$name.value}
      bind:wakilGembalaSelected
      bind:pWakilSelected={pWakilGembalaSelected}
      bind:status
      bind:errors
    />
  </section>
{/if}
