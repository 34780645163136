export const learningRatingObject = [
  {
    name: "Inspiring",
    value: 1,
  },
  {
    name: "Just Okay",
    value: 2,
  },
  {
    name: "Need Help/Struggling",
    value: 3,
  },
  {
    name: "Disengage",
    value: 4,
  },
];

export const learningStatusObject = {
  Closed: 0,
  Published: 1,
  Upcoming: 2,
};

export const submissionStatus = {
  Draft: 0,
  Submitted: 1,
  Overdue: 2,
};
