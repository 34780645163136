<script>
  import { createEventDispatcher } from "svelte";
  import { checkRoleModule, formatDateForView } from "../../utils/utils";
  import { EDIT_ONE_MODULE, DETAIL_ONE_MODULE, DELETE_ONE_MODULE } from "../../utils/const";
  import { roleModules } from "../../stores/store";
  const dispatch = createEventDispatcher();
  import { parseJwt } from "../../utils/utils";

  export let data;
  export let index;
  export let page = 1;
  const token = parseJwt(localStorage.getItem("token"));
  let id = token.UserID;
</script>

<div class="card-data min-w-full mb-3 p-2 border border-primary shadow rounded-md">
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p>No.</p>
    <p>
      {page > 1 ? ((index + 1).toString().length > 1 ? index + 1 + 10 * (page - 1) : (page - 1).toString() + (index + 1)) : index + 1}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Tanggal</p>
    <p class="flex-1 line-clamp-2 text-right">
      {formatDateForView(new Date(data["date"]))}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Pembuat 121</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["name"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Rekan 121</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["partnerName"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Tipe 121</p>
    <p class="flex-1 line-clamp-2 text-right">
      {data["type"]}
    </p>
  </div>
  <hr class="divide-y-2 my-2" />
  <div class="flex justify-between items-center text-xs font-medium text-gray-800">
    <p class="flex-1 text-left">Aksi</p>
    <div class="flex-1 flex space-x-1 justify-end">
      {#if checkRoleModule($roleModules, DETAIL_ONE_MODULE) && id == data["userID"]}
        <button
          on:click={() => dispatch("detail", data["id"])}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-show text-md" />
        </button>
      {/if}

      {#if checkRoleModule($roleModules, EDIT_ONE_MODULE) && id == data["userID"]}
        <button
          on:click={() => dispatch("edit", data["id"])}
          class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-pencil text-md" />
        </button>
      {/if}

      {#if checkRoleModule($roleModules, DELETE_ONE_MODULE)}
        <button
          on:click={() => dispatch("delete", data["id"])}
          class="px-1 py-1 text-red-500 transition-colors duration-200 rounded-lg hover:bg-gray-100"
        >
          <i class="bx bx-trash text-md" />
        </button>
      {/if}
    </div>
  </div>
</div>
