<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let allMembers;
  export let data;
  export let cellGroupMemberList;
  let isCheckedAll = cellGroupMemberList.length == allMembers.length;

  function handleClick() {
    isCheckedAll = !isCheckedAll;
    let checkboxes = document.getElementsByName("foo");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = isCheckedAll;
    }
  }
</script>

<div class="flex items-center mb-4">
  <input
    type="checkbox"
    id="checkAll"
    checked={isCheckedAll}
    on:change={() => {
      handleClick();
      dispatch("checkAll", isCheckedAll);
    }}
  />
  <label for="checkAll" class="ml-2 text-sm font-medium text-gray-500">Pilih Semua</label>
</div>
{#each data as item}
  <div class="card-data min-w-full mb-3 p-2 border border-primary shadow rounded-md">
    <div class="flex justify-between items-center text-xs font-medium text-gray-800">
      <div class="flex flex-col justify-start items-start">
        <p class="flex-1 line-clamp-2 text-left text-lg font-bold">{item["name"]}</p>
        <p class="flex-1 line-clamp-2 text-right text-sm">Member</p>
      </div>
      <div class="flex-1 flex space-x-1 justify-end">
        <input
          name="foo"
          checked={cellGroupMemberList.findIndex((e) => e.id == item.id) != -1}
          type="checkbox"
          on:click={() => dispatch("addOrRemoveMember", item)}
        />
      </div>
    </div>
  </div>
{/each}
