<script>
  import { createEventDispatcher } from "svelte";
  export let data;
  export let value;
  export let displayedValue;
  export let page = 1;

  function handleClick(e, data) {
    var table = document.getElementById("myTable");
    [...table.rows].forEach((el) => {
      el.classList.remove("bg-gray-400");
      el.classList.remove("hover:bg-gray-400");
      el.classList.add("hover:bg-gray-200");
    });
    e.currentTarget.classList.remove("hover:bg-gray-200");
    e.currentTarget.classList.add("hover:bg-gray-400");
    e.currentTarget.classList.add("bg-gray-400");
    value = data["id"];
    displayedValue = data["name"];
  }
</script>

<table class="min-w-full divide-y divide-gray-200 mt-4" id="myTable">
  <thead class="bg-gray-100">
    <tr>
      <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> # </th>
      <th scope="col" class="px-3.5 py-3.5 text-sm font-normal text-left text-gray-500"> Nama </th>
    </tr>
  </thead>
  <tbody class="bg-white divide-y divide-gray-200">
    {#if data.length == 0}
      <tr>
        <td colspan="6" class="px-4 py-4 text-sm font-medium">
          <h2 class="font-medium text-gray-500 text-xs">Belum ada data</h2>
        </td>
      </tr>
    {/if}
    {#each data as item, index}
      <tr
        class="hover:bg-gray-200 cursor-pointer {value == item['id'] ? 'bg-primary text-white hover:bg-primary' : 'text-gray-800'}"
        on:click={(e) => handleClick(e, item)}
      >
        <td class="px-3.5 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-xs">
            {page > 1 ? index + 1 + 6 * (page - 1) : index + 1}
          </h2>
        </td>
        <td class="px-3.5 py-2 text-sm font-medium whitespace-nowrap">
          <h2 class="font-medium text-xs">
            {item["name"]}
          </h2>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
