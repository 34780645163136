<script>
  import { field } from "svelte-forms";
  import Input from "../../components/control/Input.svelte";
  import { required } from "svelte-forms/validators";
  import Textarea from "../../components/control/Textarea.svelte";
  import { replace } from "svelte-spa-router";
  import { urlApi } from "../../utils/const";
  import { attendanceStatus, attendType } from "./AttendanceConst";
  import { formatDateWithTimeForDB, parseJwt } from "../../utils/utils";
  import Modal from "../../components/Modal.svelte";
  import { onMount } from "svelte";
  import TableParticipant from "../../components/table/TableParticipant.svelte";
  import AttendanceParticipantCard from "../../components/card/AttendanceParticipantCard.svelte";
  import Loading from "../../components/Loading.svelte";
  import Select from "../../components/control/Select.svelte";
  import { format } from "date-fns";
  import AttendanceCategoryServices from "../../pages/AttendanceCategory/AttendanceCategoryServices";

  export let params;

  const token = parseJwt(localStorage.getItem("token"));
  let mobileClass = "flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row";
  let date = field("date", new Date(), [required()]);
  let attendanceName = field("attendanceName", "", [required()]);
  let note = field("note", "", []);
  let cancelNote = field("cancelNote", "", []);
  let id = params["_id"];

  let errors = [];
  let participants = [];
  let participantList = [];
  let participantListID = [];
  let participantIsAttend = [];
  let attendanceData = [];
  let modalBack;
  let disableSubmit = false;
  let loading;
  let isLoading = true;
  let status;
  let attendanceCategorySelected;
  let dateToDisplay;
  let attendanceCategoryList;
  let isCategoryNotAvailable;

  onMount(async () => {
    await loadData();
    await loadAttendanceCategory();
  });
  async function loadData() {
    await loadAttendance();
  }

  async function loadAttendance() {
    const res = await fetch(urlApi + "/Attendance/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
    });
    const data = await res.json();
    if (data["data"]) {
      attendanceData = data["data"];
      status = Number(attendanceData["status"]);
      $attendanceName.value = attendanceData["attendanceName"];
      $note.value = attendanceData["note"];
      $date.value = new Date(attendanceData["date"]);
      $date.value = $date.value;
      $cancelNote.value = attendanceData["cancelNote"];
      dateToDisplay = format($date.value, "dd-MM-yyyy HH:mm");
      participants = attendanceData["participants"];
      attendanceCategorySelected = attendanceData["category"];
      isLoading = false;
      participantListID = participants.map((e) => e["userID"]);
      participantIsAttend = participants.filter((e) => e["isAttend"]).map((e) => e.userID);
      participantList = participants
        .map((u) => ({
          id: u["userID"],
          name: u["username"],
          note: u["note"],
          attendTypeID: u["attendTypeID"],
        }))
        .filter((e) => participantListID.indexOf(e["id"]) != -1);
      participantList.map((e) => (e["isAttend"] = participantIsAttend.indexOf(e["id"]) != -1 ? 1 : 0));
      participantList.map((e) => (e["note"] = participants[participants.findIndex((item) => item.userID == e["id"])]["note"]));
    }
  }
  async function editAttendance() {
    const isAttendTypeEmpty = participantList.some((obj) => obj.isAttend === 1 && obj.attendTypeID === attendType.None);
    if (isAttendTypeEmpty) return;
    loading.toggle();
    disableSubmit = true;
    participantList.map((e) => (e["userID"] = e["id"]));
    const reqData = {
      Date: formatDateWithTimeForDB($date.value),
      AttendanceName: $attendanceName.value,
      Note: $note.value,
      ID: id,
      Participants: participantList,
      Category: attendanceCategorySelected,
    };
    const res = await fetch(urlApi + "/Attendance/Edit/", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(reqData),
    });
    const data = await res.json();
    if (data["data"]) {
      globalThis.pushToast("Berhasil Mengubah Attendance", "success");
      replace("/attendances/" + id + "/detail");
    }
    loading.toggle();
    disableSubmit = false;
  }
  function verifyAttendance(item) {
    // if previous isAttend = 1, which means it is going to be 0 in this method. Reset AttendType to 0
    if (item["isAttend"] == 1) participantList.filter((e) => e["id"] == item["id"]).map((e) => (e["attendTypeID"] = attendType.None));
    participantList.filter((e) => e["id"] == item["id"]).map((e) => (e["isAttend"] = +!e["isAttend"]));
  }
  function verifyAttendanceType(item, value) {
    participantList.filter((e) => e["id"] == item["id"]).map((e) => (e["attendTypeID"] = value));
  }
  async function loadAttendanceCategory() {
    const option = "perPage=9999" + "&currentPage=1";
    const data = await AttendanceCategoryServices.loadAttendanceCategory(option);
    attendanceCategoryList = data["data"].map((e) => {
      return { id: String(e.id), name: e.name, isDelete: e.isDelete, isActive: e.isActive };
    });
    const attendanceCategory = attendanceCategoryList.find((e) => e.id === attendanceCategorySelected);
    isCategoryNotAvailable = attendanceCategory.isDelete == 1 || attendanceCategory.isActive == 0;
  }
</script>

<Modal index={50} title="Konfirmasi" bind:this={modalBack} size="small">
  <div class="flex flex-col items-center justify-center space-x-3">
    <p class="text-center text-sm text-gray-700 font-medium">Anda yakin ingin keluar dari laman ini? data yang Anda masukkan tidak akan tersimpan.</p>
    <div class="flex space-x-2 items-center mt-4">
      <button
        on:click={() => modalBack.toggle()}
        class="flex-1 px-3 py-2 text-white bg-gray-400 text-sm font-medium transition-colors duration-200 rounded-lg"
      >
        Batal
      </button>
      <button
        on:click={() => {
          modalBack.toggle();
          replace("/attendances/" + id + "/detail");
        }}
        class="flex-1 px-3 py-2 text-white text-sm font-medium bg-green-500 transition-colors duration-200 rounded-lg"
      >
        Lanjutkan
      </button>
    </div>
  </div>
</Modal>
<Loading bind:this={loading} />
{#if !isLoading}
  <section class="container px-4 py-4">
    <div class="sm:flex sm:items-center sm:justify-between">
      <div class="flex items-center gap-x-3">
        <button on:click={() => modalBack.toggle()}>
          <i class="bx bx-chevron-left text-xl cursor-pointer hover:opacity-90" />
        </button>
        <h2 class="text-sm md:text-lg font-medium text-gray-800">Attendance Event</h2>
      </div>
    </div>

    <div class="mt-5 flex flex-col space-y-4">
      <div class={mobileClass}>
        <div class="flex-1">
          <Input disabled="true" name="attendanceName" type="text" label="Nama Attendance" bind:errors bind:value={$attendanceName.value} />
        </div>
        <div class="flex-1">
          <Input name="date" type="text" label="Tanggal-Waktu" placeholder="Ketik Kategori" bind:value={dateToDisplay} disabled={true} />
        </div>
      </div>
      <div class={mobileClass}>
        <div class="flex-1">
          <Select
            fullWidth
            textHint="Pilih Kategori Attendance"
            name="attendanceCategorySelected"
            extClass="font-semibold {isCategoryNotAvailable && status != attendanceStatus.Aktif
              ? 'bg-red-100 border-red-500 focus:border-red-500'
              : ''}"
            label="Kategori Attendance"
            textSize="text-sm"
            options={attendanceCategoryList}
            bind:errors
            bind:selected={attendanceCategorySelected}
            disabled={true}
          />
          {#if isCategoryNotAvailable && status != attendanceStatus.Aktif}
            <p class="text-xs text-red-500 p-0 mt-1">Kategori Attendance tidak aktif atau sudah dihapus</p>
          {/if}
        </div>
      </div>
      <div class="flex flex-col space-y-4">
        <div class={mobileClass}>
          <div class="flex-1">
            <Textarea disabled="true" bind:errors name="note" label="Catatan" placeholder="Catatan" maxlength="3000" bind:value={$note.value} />
          </div>
        </div>
      </div>

      {#if status == attendanceStatus.Batal}
        <div class={mobileClass}>
          <div class="flex-1">
            <Textarea
              disabled="true"
              name="cancelNote"
              label="Alasan Pembatalan Attendance"
              placeholder="Alasan Pembatalan Attendance"
              extClass=" resize-none"
              bind:errors
              bind:value={$cancelNote.value}
            />
          </div>
          <div class="flex-1" />
        </div>
      {/if}

      <div class="mt-4" />
      <div class="flex w-full items-center justify-between space-x-3">
        <h2 class="text-sm md:text-lg font-medium text-gray-800 w-full">Daftar Participants</h2>
      </div>
      <div class="overflow-x-auto hidden md:block">
        <div class="inline-block min-w-full py-2 align-middle">
          <div class="overflow-hidden rounded-md">
            <TableParticipant
              bind:data={participantList}
              bind:status
              mode="attendance"
              on:verifyAttendance={(e) => verifyAttendance(e.detail)}
              on:verifyAttendanceType={(e) => verifyAttendanceType(e.detail["item"], e.detail["value"])}
            />
          </div>
        </div>
      </div>
      <div class="overflow-x-auto block md:hidden">
        {#each participantList as item}
          <AttendanceParticipantCard
            bind:data={item}
            bind:status
            mode="attendance"
            on:verifyAttendance={(e) => verifyAttendance(e.detail)}
            on:verifyAttendanceType={(e) => verifyAttendanceType(e.detail["item"], e.detail["value"])}
          />
        {/each}
      </div>
      {#if status == attendanceStatus.Aktif}
        <div class="flex w-full space-x-4">
          <button
            on:click={() => {
              modalBack.toggle();
            }}
            class="w-full text-white bg-gray-400 hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
            >Batal</button
          >
          <button
            disabled={disableSubmit}
            on:click={() => editAttendance()}
            class="w-full text-white bg-primary hover:opacity-80 outline-none font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center transition-all"
            >Simpan</button
          >
        </div>
      {/if}
    </div>
  </section>
{/if}
